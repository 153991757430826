<script>
import dayjs from 'dayjs'
export default {
  name: 'DateTime',
  props: {
    date: {
      type: [Number, String],
      default: 0,
    },
    onlyDate: {
      type: Boolean,
      default: false,
    },
    withSeconds: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    formattedDate: function() {
      const parsedDate = this.date ? this.date * 1000 : null

      if (!parsedDate) {
        return '--'
      }

      if (this.onlyDate) {
        return dayjs(parsedDate).format('DD/MM/YYYY')
      }
      if (this.withSeconds) {
        return dayjs(parsedDate).format('DD/MM/YYYY HH:mm:ss')
      }

      return dayjs(parsedDate).format('DD/MM/YYYY HH:mm')
    },
  },
}
</script>

<template>
  <time :datetime="formattedDate">{{ formattedDate }}</time>
</template>

