<template>
  <v-card outlined rounded="lg" :loading="loading">
    <template slot="progress">
      <v-progress-linear
        color="accent"
        height="8"
        indeterminate
      ></v-progress-linear>
    </template>

    <v-card-title class="py-2">
      <v-row align="center" justify="space-between">
        <v-col cols="auto">
          <v-icon class="mr-1" small>mdi-office-building-marker</v-icon>
          <span class="text-button">
            Address Information
          </span>
        </v-col>
        <v-col cols="6" v-if="addresses.length > 1">
          <v-pagination
            circle
            v-model="pagination"
            :length="paginationLength"
          ></v-pagination>
        </v-col>
        <v-col cols="auto">
          <v-btn 
          color="blue" 
          depressed 
          icon 
          small 
          @click="addNewAddress">
            <v-icon small>mdi-plus</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>
    <v-divider></v-divider>
    <LoadingSpinner v-if="loading" />
    <v-sheet v-else class="pa-3">
      <EmptyCard v-if="!addresses.length" content="No addresses" />
      <v-window v-else v-model="currentWindow">
        <v-window-item v-for="(group, index) in paginationLength" :key="group">
          <v-row>
            <v-col
              v-for="address in groupedAddresses[index]"
              :key="address.id"
              cols="4"
            >
              <AddressWidget
                :address-id="address.addressId.toString()"
                :site-name="address.siteName"
                :cot-date="address.cotDate"
                :first-line="address.add1"
                :second-line="address.add2"
                :town="address.town"
                :county="address.county"
                :post-code="address.postcode"
                :primary="address.primaryAddress"
                :show-remove="!address.isMetersAvailable"
              ></AddressWidget>
            </v-col>
          </v-row>
        </v-window-item>
      </v-window>
    </v-sheet>
  </v-card>
</template>

<script>
import { mapMutations, mapActions, mapGetters } from "vuex"

import AddressWidget from "@/components/address-widget"
import LoadingSpinner from "@/components/loading-spinner"

export default {
  components: {
    AddressWidget,
    LoadingSpinner,
    EmptyCard: () => import("@/components/empty-card")
  },
  data: () => ({ pagination: 1 }),
  computed: {
    ...mapGetters("addresses", ["addresses", "groupedAddresses", "loading"]),
    paginationLength() {
      const pages = Math.round(this.addresses.length / 3)

      return pages || 1
    },
    currentWindow() {
      return this.pagination - 1
    }
  },
  methods: {
    ...mapMutations("contacts", {
      selectContact: "setSelectedContact"
    }),
    ...mapActions("addresses", ["fetchAddresses"]),
     ...mapActions("ui", ["openForm"]),
     addNewAddress() {
       this.selectContact(null)
       this.openForm({ formName: 'address-information'})
     }
  },
  mounted() {
    setTimeout(() => {
    this.fetchAddresses()
    }, 1000)
  }
}
</script>

<style></style>
