import axios from "axios"

const api = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL
})

api.interceptors.request.use(
  function(config) {
    // Do something before request is sent
    return config
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error)
  }
)

api.interceptors.response.use(
  function(response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response
  },
  function(error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject({
      status: error.response.status,
      message: error.response.statusText || "",
      url: error.response.request.responseURL
    })
  }
)

export default api
