import dateHelpers from "@/helpers/dates"
import stringsHelpers from "@/helpers/strings"
import sicIcons from "@/helpers/sic-icons"

export default {
  install: Vue => {
    Vue.prototype.$helpers = { ...dateHelpers, ...stringsHelpers, sicIcons }
    Vue.$helpers = { ...dateHelpers, ...stringsHelpers, sicIcons }
  }
}
