<template>
  <v-dialog v-model="visible" width="700">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        depressed
        :color="color"
        plain
        small
        v-on="on"
        v-bind="attrs"
        :disabled="!localLoas.length"
      >
        {{ saleType }}
        <v-badge
          class="pl-2"
          :content="localLoas.length.toString()"
          :color="color"
          inline
        >
        </v-badge>
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        <v-row >
          <v-col cols="auto">
            <span :class="isUtilityHive ? 'text-capitalize' : 'text-uppercase'">
              {{ saleType }}
            </span>
            LOA's
          </v-col>
          <v-col>
            <v-chip :color="color" text-color="white" small>
              {{ localLoas.length }}
            </v-chip>
          </v-col>
          <v-col  cols="8" v-if="localLoas.length > 1">
            <v-pagination
              circle
              v-model="pagination"
              :length="paginationLength"
              :total-visible="false"
            ></v-pagination>
        </v-col>
        </v-row>
      </v-card-title>
      <v-divider></v-divider>

      <LoadingSpinner v-if="loading" />
      <v-window v-else  v-model="currentWindow">
        <v-window-item v-for="(group, index) in paginationLength" :key="group">
          <v-row>
            <v-col
              v-for="loa in groupedLoas[index]"
              cols="4"
              :key="loa.loaId"
            >
              <!-- TODO: Remove .toString method, expect contactId to be a string from API response -->
              <LoaItem
              :loa-id="loa.loaId"
              :loa-type="loa.loaType"
              :signed-date="loa.loaDate"
              :signed-by="loa.signedByContact"
              :uploaded-by="loa.uploadedByEmployee"
              :user-id="loa.uploadedByEmployeeId"
              :download="downloadFile"
            />
            </v-col>
          </v-row>
        </v-window-item>
      </v-window>

      <v-divider></v-divider>

      <v-card-actions>
        <v-btn text @click="visible = false">
          Close
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import LoadingSpinner from "@/components/loading-spinner"
import { chunkArray } from "@/store/helpers/utils"

export default {
  components: {
    LoaItem: () => import("@/components/loa-item"),
    LoadingSpinner
  },
  props: {
    company: {
      type: String,
      default: ""
    },
    saleType: {
      type: String,
      required: true
    }
  },
  data: () => ({ pagination: 1, visible: false}),
  computed: {
    ...mapGetters("loas", ["getLoasByType", "loading"]),
    groupedLoas() {
      return chunkArray(this.localLoas, 3)
    },
    paginationLength() {
      const pages = Math.round(this.sorteditems.length / 2)

      return pages || 1
    },
    currentWindow() {
      return this.pagination - 1
    },
    sorteditems(){
      return this.localLoas
      .slice()
      .sort((a, b) => {
          return new Date(b.loaDate) - new Date(a.loaDate)
      })
      .map((item) => {
        const now = new Date().getTime()
          const loaDate = new Date(item.loaDate)
          return { ...item, nearExpiry: now - loaDate > 30 * 24 * 60 * 60 * 1000 }
      })
    },
    isUtilityHive() {
      const type = this.saleType.toLowerCase()
      return type === "utility hive"
    },
    saleTypeId() {
      return this.isUtilityHive ? 2 : 1
    },
    localLoas() {
      return this.getLoasByType(this.saleTypeId)
    },
    color() {
      return this.isUtilityHive ? "accent" : "green"
    }
  },
  methods: {
    ...mapActions("loas", ["fetchLoas"]),
    downloadFile(id) {
      const apiUrl = process.env.VUE_APP_BASE_URL
      window.location = `${apiUrl}/downloadLoa/${id}`
    }
  },
  mounted() {
    this.fetchLoas()
  }
}
</script>

<style></style>
