import Vue from "vue"
import Vuetify from "vuetify/lib/framework"

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    // change color based on system preferences (not applied yet, remove this once done)
    // https://stackoverflow.com/a/60584438
    dark: false,
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: "#30425a",
        secondary: "#424242",
        accent: "#ed6e92",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107"
      }
    }
  }
})
