<template>
  <v-list-item>
    <v-list-item-content>
      <v-row align="center">
        <v-col cols="1">
          <v-icon class="d-flex justify-center" size="32" :color="typeColor">
            {{ typeIcon }}
          </v-icon>
        </v-col>
        <v-col cols="auto">
          <v-img
            style="width: 110px; height: 54px"
            contain
            :src="$helpers.supplierLogo(supplierId)"
          ></v-img>
        </v-col>
        <v-col cols="3">
          <div v-if="estimate" class="text-center">Estimate</div>
          <SupplyNumber
            v-else
            :type="this.type"
            :supply-number="supplyNumber"
            :pc="pc"
            :mtc="mtc"
            :llf="llf"
            :disabled="true"
          />
        </v-col>

        <v-col v-if="estimate" cols="1" style="margin-right: 29px"></v-col>

        <v-col cols="auto">
          <v-row>
            <v-col v-if="!estimate" cols="auto">
              <v-row>
                <v-col v-if="!estimate" class="" cols="6">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <div v-on="on" v-bind="attrs">
                        <RegReports :supply-number="supplyNumber" />
                      </div>
                    </template>
                    <span>REGISTRATION REPORTS</span>
                  </v-tooltip>
                </v-col>
                <v-col v-if="!estimate" class="" cols="6">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <div v-on="on" v-bind="attrs">
                        <Invoices :supply-number="supplyNumber" />
                      </div>
                    </template>
                    <span>INVOICES</span>
                  </v-tooltip>
                </v-col>
              </v-row>
              <v-row>
                <v-col v-if="!estimate" class="" cols="6">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <div v-on="on" v-bind="attrs">
                        <PreviousSales :supply-number="supplyNumber" />
                      </div>
                    </template>
                    <span>SALES ACHIVE</span>
                  </v-tooltip>
                </v-col>

                <v-col v-if="!estimate" class="" cols="6">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <div v-on="on" v-bind="attrs">
                        <MeterDocuments
                          :meter-id="meterId"
                          :user-id="userId"
                          :key="meterId"
                        />
                      </div>
                    </template>
                    <span>METER DOCUMENTS</span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-col>

            <!-- <v-col v-if="!estimate" cols="8"></v-col> -->
            <v-col class="d-flex" cols="auto" align="center">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-row dense align="center" v-bind="attrs" v-on="on">
                    <v-col cols="auto">
                      <v-icon small color="deep-orange"
                        >mdi-calendar-remove</v-icon
                      >
                    </v-col>
                    <v-col>
                      <span>{{ $helpers.formatDate(contractEndDate) }}</span>
                    </v-col>
                  </v-row>
                </template>
                <span>Contract End Date</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-col>
        <div v-if="estimate" style="margin-left: 2px"></div>
        <v-col>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-row dense align="center" v-bind="attrs" v-on="on">
                <v-col cols="auto">
                  <v-icon
                    small
                    :color="renwalDate(renewalDate) ? 'green' : 'red'"
                    >mdi-calendar-refresh</v-icon
                  >
                </v-col>
                <v-col>
                  <span
                    :class="
                      renwalDate(renewalDate) ? 'green--text' : 'red--text'
                    "
                    >{{ $helpers.formatDate(renewalDate) }}</span
                  >
                  <div class="text-body-2 grey--text mt-1">
                    {{ window }} Days
                  </div>
                </v-col>
              </v-row>
            </template>
            <span>Renewal Date</span>
          </v-tooltip>
        </v-col>
        <v-col cols="auto">
          <v-tooltip top v-if="!isHideMeterEdit">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" color="blue" depressed icon small>
                <v-icon small @click.stop="editMeter">mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Edit Meter</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" depressed icon small>
                <div>
                  <GenerateQuoteModal :selectedMeter="meter" />
                </div>
              </v-btn>
            </template>
            <span>Generate Quote</span>
          </v-tooltip>
          <v-tooltip
            top
            v-if="
              !isHideMeterEdit &&
              ((!estimate && userId === readyForQuoteData.assignedById) ||
                details.dataSourceId === 2725)
            "
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" depressed icon small>
                <div>
                  <SubmitContract
                    :contract-end-date="contractEndDate"
                    :meter-id="meterId"
                    :address-id="addressId"
                    :meter-type-id="meterTypeId"
                    :supplier-name="supplierName"
                    :pc="pc"
                    :supplier-id="parseInt(supplierId)"
                  ></SubmitContract>
                  <!-- <SubmitContract></SubmitContract> -->
                </div>
                <!-- <v-icon small>mdi-file-send</v-icon> -->
              </v-btn>
            </template>
            <span>Submit Contract</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" depressed icon small>
                <div>
                  <RemoveMeter :meter-id="meterId" :address-id="addressId" />
                </div>
              </v-btn>
            </template>
            <span>Delete Meter</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import dayjs from "dayjs"
import SupplyNumber from "./supply-number"
import { mapActions, mapGetters, mapMutations } from "vuex"
import SubmitContract from "@/components/forms/submit-contract"
import RemoveMeter from "@/components/dialogs/remove-meter"
import PreviousSales from "@/components/dialogs/previous-sales.vue"
import GenerateQuoteModal from "@/components/dialogs/generate-quote-modal.vue"
import MeterDocuments from "@/components/dialogs/meter-documents.vue"
import RegReports from "@/components/dialogs/reg-reports.vue"
import Invoices from "@/components/dialogs/invoices.vue"
import api from "@/store/api"

export default {
  components: {
    SupplyNumber,
    GenerateQuoteModal,
    SubmitContract,
    RemoveMeter,
    PreviousSales,
    MeterDocuments,
    RegReports,
    Invoices,
  },
  data: () => ({
    currentDate: dayjs(),
    readyForQuoteData: {},
  }),
  props: {
    meter: {
      type: Object,
    },
    addressId: {
      type: Number,
    },
    isHideMeterEdit: {
      type: Boolean,
      default: false,
    },
    type: String,
    supplierId: String,
    supplierName: String,
    supplyNumber: {
      type: String,
      default: "",
    },
    estimate: {
      type: Boolean,
      default: false,
    },
    pc: {
      type: String,
      default: "",
    },
    mtc: {
      type: String,
      default: "",
    },
    llf: {
      type: String,
      default: "",
    },
    meterId: Number,
    // userId: Number,
    meterTypeId: Number,
    contractEndDate: {
      type: Number,
      default: () => 0,
    },
    renewalDate: {
      type: Number,
      default: () => 0,
    },
    window: {
      type: Number,
      default: () => 0,
    },
  },

  computed: {
    ...mapGetters("employee", ["userId"]),
    ...mapGetters("meters", ["meters"]),
    ...mapGetters("company", ["companyId", "details"]),

    isGas() {
      return this.type.toLowerCase() === "gas"
    },
    typeColor() {
      // return this.isGas ? "red" : "amber"
      return this.meterTypeId === 10
        ? "red"
        : this.meterTypeId === 2705
        ? "blue"
        : "amber"
    },
    typeIcon() {
      // return this.isGas ? "mdi-fire" : "mdi-lightbulb"
      return this.meterTypeId === 10
        ? "mdi-fire"
        : this.meterTypeId === 2705
        ? "mdi-water"
        : "mdi-lightbulb"
    },
  },
  mounted() {
    // this.selectAddress(this.addressId)
    this.fetchReadyForQuote()
  },
  methods: {
    ...mapActions("ui", ["openForm"]),
    ...mapActions("meters", ["fetchPrimaryMeters"]),
    ...mapActions("forms", ["postSupplyDetails", "fetchBESNatureOfBusiness"]),
    ...mapActions("addresses", ["selectAddress"]),
    ...mapMutations("contacts", {
      selectedMeter: "setSelectedMeter",
    }),

    async fetchReadyForQuote() {
      const response = await api.get(`readyForQuote/${this.companyId}`)
      if (response && response.status === 200) {
        this.readyForQuoteData = response.data
      }
    },

    renwalDate(renewalDate) {
      return dayjs(renewalDate * 1000) <= dayjs()
    },
    editMeter() {
      // this.fetchPrimaryMeters(this.addressId)
      const supplyDetails = {
        addressId: this.addressId,
        type: this.type,
        supplierId: this.supplierId,
        supplierName: this.supplierName,
        meterId: this.meterId,
        meterTypeId: this.meterTypeId,
        pc: this.pc,
        mtc: this.mtc,
        llf: this.llf,
        contractEndDate: this.contractEndDate,
        renewalDate: this.renewalDate,
        supplyNumber: this.supplyNumber,
        estimate: this.estimate ? this.estimate : false,
      }
      this.postSupplyDetails(supplyDetails)

      this.openForm({ formName: "meter-info-info", edit: true })
    },
  },
}
</script>

<style></style>
