import Vue from "vue"
import vuetify from "./plugins/vuetify"
import App from "./App.vue"
import store from "./store"

import api from "./store/api"
import helpers from "@/plugins/helpers"

Vue.use(helpers)

Vue.config.productionTip = false
Vue.prototype.$http = api

new Vue({
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app")
