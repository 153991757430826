<template>
  <v-dialog
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
    v-model="valdaFormmodal"
    width="1300"
    @input="valdaOnClickMountedCalled"
  >
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="closeModal">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>
          <v-tabs
            class="mb-4"
            v-model="tab"
            dark
            background-color="primary"
            grow
          >
            <v-tab
              v-for="(contractWidget, index) in contractWidgets"
              :key="index"
              >{{ contractWidget.widgetDescription }}</v-tab
            >
          </v-tabs>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn dark text @click="dialog = false"> Save </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <!-- <v-tabs-items v-model="tab">
      <v-tab-item> -->

      <v-form class="px-5 mt-3" ref="form" v-model="valid" lazy-validation>
        <v-row class="alignment">
          <v-col cols="1">
            <v-img
              style="width: 110px; height: 54px"
              contain
              :src="$helpers.supplierLogo(eFormDetails.soldSupplierId)"
            ></v-img>
          </v-col>
          <v-col v-if="tab === 0">
            <h2>
              {{
                contractWidgets &&
                contractWidgets[0] &&
                contractWidgets[0].widgetDescription
              }}
            </h2>
          </v-col>
          <v-col v-else>
            <h2>
              {{
                contractWidgets &&
                contractWidgets[1] &&
                contractWidgets[1].widgetDescription
              }}
            </h2>
          </v-col>
        </v-row>
        <div class="p-5 mt-4">
          <!-- BUSINESS CONTACT DETAILS -->
          <div class="box-shadow p-3">
            <div class="mt-2">
              <v-row>
                <v-col>
                  <DataValue
                    label="Company Details"
                    :value="eFormDetails.companyName"
                    icon="office-building"
                  />
                </v-col>
                <v-col>
                  <DataValue
                    label="Billing Address"
                    :value="eFormDetails.billingAddress"
                    icon="domain"
                  />
                </v-col>
              </v-row>

              <v-row
                v-if="
                  details.tradingTypeId === 7 ||
                  details.tradingTypeId === 31 ||
                  details.tradingTypeId === 32
                "
              >
                <v-col>
                  <div class="row row--dense align-center">
                    <div class="col col-auto">
                      <div
                        class="v-avatar indigo lighten-4"
                        style="height: 32px; min-width: 32px; width: 32px"
                      >
                        <div>
                          <i
                            aria-hidden="true"
                            class="v-icon notranslate mdi mdi-office-building theme--dark"
                            style="font-size: 16px"
                          ></i>
                        </div>
                      </div>
                    </div>
                    <div class="col">
                      <div
                        class="grey--text"
                        style="font-size: 11px"
                        :style="
                          (details.tradingTypeId === 7 ||
                            details.tradingTypeId === 31 ||
                            details.tradingTypeId === 32) &&
                          (details.registrationNo === '' ||
                            details.registrationNo === null)
                            ? 'color: red !important;'
                            : ''
                        "
                      >
                        Registration Number
                      </div>
                      <div
                        class=""
                        style="font-size: 11px"
                        :style="
                          (details.tradingTypeId === 7 ||
                            details.tradingTypeId === 31 ||
                            details.tradingTypeId === 32) &&
                          (details.registrationNo === '' ||
                            details.registrationNo === null)
                            ? 'color: red !important;'
                            : ''
                        "
                      >
                        {{ details.registrationNo }}
                      </div>
                      <!---->
                    </div>
                  </div>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <DataValue
                    label="Email Address"
                    :value="eFormDetails.emailAddress"
                    icon="mail"
                  />
                </v-col>
                <v-col>
                  <DataValue
                    label="Telephone"
                    :value="eFormDetails.telephone"
                    icon="phone"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <DataValue
                    label="Customer Details"
                    :value="
                      `${eFormDetails.title} ${eFormDetails.firstName} ${eFormDetails.lastName}`.trim()
                    "
                    :extra-info="eFormDetails.position"
                    icon="account"
                  />
                </v-col>
                <v-col>
                  <DataValue
                    label="Trading Type"
                    :value="eFormDetails.tradingType"
                    icon="briefcase"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <div class="row row--dense align-center">
                    <div class="col col-auto">
                      <div
                        class="v-avatar indigo lighten-4"
                        style="height: 32px; min-width: 32px; width: 32px"
                      >
                        <div>
                          <i
                            aria-hidden="true"
                            class="v-icon notranslate mdi mdi-home theme--dark"
                            style="font-size: 16px"
                          ></i>
                        </div>
                      </div>
                    </div>
                    <div class="col">
                      <div
                        class="grey--text"
                        style="font-size: 11px"
                        :style="
                          (details.tradingTypeId === 6 ||
                            details.tradingTypeId === 8) &&
                          (selectedHomeAddress === '' ||
                            selectedHomeAddress === null)
                            ? 'color: red !important;'
                            : ''
                        "
                      >
                        Home Address
                      </div>
                      <div
                        class=""
                        style="font-size: 11px"
                        :style="
                          (details.tradingTypeId === 6 ||
                            details.tradingTypeId === 8) &&
                          (selectedHomeAddress === '' ||
                            selectedHomeAddress === null)
                            ? 'color: red !important;'
                            : ''
                        "
                      >
                        {{ selectedHomeAddress }}
                      </div>
                      <!---->
                    </div>
                  </div>
                </v-col>
                <v-col>
                  <div class="row row--dense align-center">
                    <div class="col col-auto">
                      <div
                        class="v-avatar indigo lighten-4"
                        style="height: 32px; min-width: 32px; width: 32px"
                      >
                        <div>
                          <i
                            aria-hidden="true"
                            class="v-icon notranslate mdi mdi-calendar theme--dark"
                            style="font-size: 16px"
                          ></i>
                        </div>
                      </div>
                    </div>
                    <div class="col">
                      <div
                        class="grey--text"
                        style="font-size: 11px"
                        :style="
                          (details.tradingTypeId === 6 ||
                            details.tradingTypeId === 8) &&
                          (eFormDetails.dobText === '' ||
                            eFormDetails.dobText === null ||
                            eFormDetails.dobText.length < 10)
                            ? 'color: red !important;'
                            : ''
                        "
                      >
                        Date of Birth
                      </div>
                      <div
                        class=""
                        style="font-size: 11px"
                        :style="
                          (details.tradingTypeId === 6 ||
                            details.tradingTypeId === 8) &&
                          (eFormDetails.dobText === '' ||
                            eFormDetails.dobText === null ||
                            eFormDetails.dobText.length < 10)
                            ? 'color: red !important;'
                            : ''
                        "
                      >
                        {{ eFormDetails.dobText }}
                      </div>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </div>
          </div>
        </div>

        <!-- SUPPLY DETAILS -->
        <div class="box-shadow p-3 mt-5">
          <div class="mt-2">
            <v-row>
              <v-col>
                <DataValue
                  label="SUPPLY NUMBER"
                  :value="selectedMeter.supplyNumber"
                />
              </v-col>

              <v-col>
                <DataValue
                  label="Supply Address"
                  :value="eFormDetails.supplyAddress"
                />
              </v-col>
            </v-row>
            <v-row>
              <!-- <v-col>
                <div
                  class="grey--text"
                  style="font-size: 11px"
                  :style="!eac || eac === 0 ? 'color: red !important;' : ''"
                >
                  EAC
                </div>
                <div class="" style="font-size: 11px">
                  {{ eac ? Math.round(eac) : "" }}
                </div>
              </v-col> -->
              <v-col>
                <DataValue
                  label="Contract Type"
                  :value="
                    eFormDetails.currentSupplierID === 13
                      ? 'Change of Tenancy'
                      : 'Acquisition'
                  "
                />
              </v-col>
              <v-col>
                <DataValue label="COT Date" :value="cotDateText" />
              </v-col>
            </v-row>

            <!-- <v-row>
        
              <v-col></v-col>
            </v-row> -->
          </div>
        </div>
        <div class="box-shadow p-3 mt-5">
          <div class="mt-2">
            <v-row>
              <v-col cols="2">
                <DataValue
                  label="Contract Start Date"
                  :value="eFormDetails.csdText"
                />
              </v-col>
              <v-col cols="2">
                <DataValue label="Term" :value="eFormDetails.term" />
              </v-col>

              <v-col cols="2">
                <DataValue
                  label="Payment Method"
                  :value="eFormDetails.paymentType"
                />
              </v-col>

              <v-col cols="3" style="padding-bottom: 0">
                <div
                  class="grey--text"
                  style="font-size: 11px"
                  :style="
                    !upfrontPaymentAmount || upfrontPaymentAmount === 0
                      ? 'color: red !important;'
                      : ''
                  "
                >
                  Upfront Payment Amount
                </div>
                <v-text-field
                  v-model="upfrontPaymentAmount"
                  ref="upfrontPaymentAmount"
                  class="upfront"
                  :rules="upfrontPaymentAmountRules"
                  prepend-icon="mdi-currency-gbp"
                  style="padding: 0; margin: 0"
                ></v-text-field>
                <!-- <div class="" style="font-size: 11px">
                  {{
                    upfrontPaymentAmount
                      ? formatPrice(upfrontPaymentAmount)
                      : "£"
                  }}
                </div> -->
              </v-col>
              <v-col cols="3">
                <DataValue
                  label="Total Est Contract Value"
                  :value="eFormDetails.processedContractValue"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3">
                <div
                  class="grey--text"
                  style="font-size: 11px"
                  :style="
                    !eFormDetails.quoteTypePriceList ||
                    !eFormDetails.quoteTypePriceList[0] ||
                    eFormDetails.quoteTypePriceList[0].baseRate === null ||
                    eFormDetails.quoteTypePriceList[0].baseRate === ''
                      ? 'color: red !important;'
                      : ''
                  "
                >
                  Standing Charge
                </div>
                <div
                  class=""
                  style="font-size: 13px"
                  :style="
                    !eFormDetails.quoteTypePriceList ||
                    !eFormDetails.quoteTypePriceList[0] ||
                    eFormDetails.quoteTypePriceList[0].baseRate === null ||
                    eFormDetails.quoteTypePriceList[0].baseRate === ''
                      ? 'color: red !important;'
                      : ''
                  "
                >
                  {{
                    eFormDetails.quoteTypePriceList &&
                    eFormDetails.quoteTypePriceList[0] &&
                    eFormDetails.quoteTypePriceList[0].baseRate
                  }}
                </div>
              </v-col>

              <v-col
                v-for="(q, index) in quoteTypesOnly"
                :key="index"
                :cols="quoteTypesOnly.length > 3 ? 2 : 3"
              >
                <div
                  class="grey--text"
                  style="font-size: 11px"
                  :style="
                    q.baseRate === null || q.baseRate === ''
                      ? 'color: red !important;'
                      : ''
                  "
                >
                  {{ q.typeName }}
                </div>
                <div
                  class=""
                  style="font-size: 13px"
                  :style="
                    q.baseRate === null || q.baseRate === ''
                      ? 'color: red !important;'
                      : ''
                  "
                >
                  {{ q.baseRate }}
                </div>
              </v-col>
            </v-row>
          </div>
        </div>
        <div
          class="box-shadow p-3 mt-5"
          v-if="eFormDetails.paymentTypeId !== 2"
        >
          <div class="mt-2">
            <v-row>
              <v-col>
                <DataValue label="Bank Name" :value="eFormDetails.bankName" />
              </v-col>
              <v-col>
                <DataValue label="Sort Code" :value="eFormDetails.sortCode" />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <DataValue
                  label="Account Name"
                  :value="eFormDetails.accountName"
                />
              </v-col>
              <v-col>
                <DataValue
                  label="Account Number"
                  :value="eFormDetails.accountNumber"
                />
              </v-col>
            </v-row>
          </div>
        </div>

        <div class="mt-4">
          <v-btn
            :disabled="isDisable"
            depressed
            color="accent"
            @click="submitEForm"
            block
            dark
            large
          >
            SEND TO
            {{
              eFormDetails.selectedAgreedByContact &&
              eFormDetails.selectedAgreedByContact.emailAddress
                ? eFormDetails.selectedAgreedByContact.emailAddress
                : ""
            }}
          </v-btn>
        </div>
      </v-form>
      <!-- </v-tab-item>
    </v-tabs-items> -->
    </v-card>
  </v-dialog>
</template>

<script>
// import { mergeExistingEntriesIntoForm } from "@/helpers/utilities"
import { mapGetters } from "vuex"
import api from "@/store/api"
import { mask } from "vue-the-mask"

import { changesSaved, somethingWentWrong } from "@/helpers/utilities"
import dayjs from "dayjs"
import DataValue from "@/components/data-value"

// import { required } from "@/helpers/form-validations"

export default {
  props: {
    valdaFormmodal: { Type: Boolean, default: false },
    contractMeterTypesList: { Type: Array, default: null },
    meterId: { Type: Number, default: null },
    contactId: { Type: Number, default: null },
    meterTypeId: Number,
    supplierId: Number,
  },
  components: {
    DataValue,
  },
  data: () => ({
    contractWidgets: null,
    cclValue: 0.00775,
    widgetVal: "",
    cotDateText: null,
    selectedHomeAddress: null,
    contactAddresses: [],
    payment: {
      accountName: null,
      accountNumber: null,
      addressId: null,
      bankName: null,
      id: 0,
      sortCode: null,
      type: null,
      typeId: null,
    },

    termInMonths: 1,
    isDobTextChanged: false,
    dob: null,
    menuDOB: false,
    valid: true,
    dobText: null,
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
    contactNumber: null,
    isSendingRequest: false,
    formHasErrors: false,
    errorMsg: "",
    errorMsgCC: "",
    showMessage: false,
    tab: null,
    ePriceReviewDate: null,
    ePriceReviewNoticeDate: null,
    csdDateText: null,
    gPriceReviewDate: null,
    dateOfBirth: null,
    gPriceReviewNoticeDate: null,
    priceQuote: {},
    selectedMeter: {},
    upfrontPaymentAmount: null,
    form: {
      billingName: null,
      billingAddress: null,
      billingTowncountry: null,
      billingPostCode: null,
      eProductCode: null,
      ePriceReviewDate: null,
      ePriceReviewNoticeDate: null,
      cotDateText: null,
      csdDateText: null,
      cot: null,
      csd: null,
      eStandingCharge: null,
      unitRate: null,
      unitRateDay: null,
      unitRateNight: null,
      unitRateEvening: null,
      unitRateWeekend: null,
      upfrontPaymentAmount: null,
      kva: null,
      greenEnergy: null,
      eMeterSerailNumber: null,
      eMeterType: null,
      EAC: null,
      gProductCode: null,
      gStandingCharge: null,
      gUnitRate: null,
      gMeterSerailNumber: null,
      gTariffType: null,
      AQ: null,
      totalEstCommissionCost: null,
      isLoa: false,
      currentSuppElec: null,
      currentSupGas: null,
      fixedTermElecEndDate: null,
      fixedTermGasEndDate: null,
      expectedYearlySpend: null,
      elecStandardPayment: null,
      gasStandardPayment: null,
      selectedMeterTypeList: null,
    },
    valueNotSelected: true,
  }),
  directives: { mask },
  mounted() {
    // this.validateBESBusinessType()
    // this.setStandingChargeUplift()
    // this.setUnitRateCommission()
    // this.setPerDayRate()
    // this.setTotalCommission()
  },
  watch: {
    valdaFormmodal(newVal, oldVal) {
      if (newVal || oldVal) {
        this.valdaOnClickMountedCalled()
        console.log("true", this.$refs.upfrontPaymentAmount)
        this.$refs.upfrontPaymentAmount.validate(true)
      }
    },
    meterTypeId() {
      this.fetchBesTabTitles()
    },
    supplierId() {
      this.fetchBesTabTitles()
    },
    contactId: function (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.fetchAddressesByContactId()
      }
    },
    // modalOpened(newVal, oldVal) {
    //   if(newVal !== oldVal && newVal === true) {
    //     this.fetchAddressesByContactId()
    //   }
    // },
    tab() {
      //   console.log(val)
    },
    selectedAddress(val) {
      console.log(val)
      this.cotDateText =
        this.selectedAddress.cotDate === 0
          ? ""
          : dayjs(this.selectedAddress.cotDate * 1000).format("DD/MM/YYYY")
    },
    besFormmodal: function (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.validateBESBusinessType()
        this.fetchAddressesByContactId()
        this.fetchCCLValueForMeter()
      }
    },
    eFormDetails: {
      handler(newVal, oldVal) {
        console.log(oldVal)
        if (newVal.saleType) {
          this.fetchBesTabTitles()
        }
        if (
          newVal.processedContractValue === 0 ||
          newVal.processedContractValue === 0
        ) {
          this.eFormDetails.processedContractValue = "0"
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters("meters", ["meters"]),
    ...mapGetters("company", ["details"]),
    ...mapGetters("forms", ["eFormDetails", "titleList"]),
    ...mapGetters("addresses", ["selectedAddress"]),
    ...mapGetters("forms", [
      "natureOfBusinessList",
      "besNatureOfBusinessList",
      "tradingTypeList",
      "supplierList",
      "paymentTypesList",
      "contractTypesList",
    ]),
    ...mapGetters("employee", ["userId"]),
    processedUpfrontPaymentAmount() {
      const amount = this.upfrontPaymentAmount

      if (!amount || amount === "") {
        return 0
      }

      if (typeof amount === "string") {
        return Number(amount.replace(/,/g, ""))
      }

      return Number(amount)
    },
    upfrontPaymentAmountRules() {
      if (!this.valueNotSelected) {
        return [(value) => !value || "Please Provide Upfront Payment Amount"]
      } else {
        return [(value) => !!value || "Please Provide Upfront Payment Amount"]
      }
    },
    isDisable() {
      let isOneOfBaseRateIsEmpty = false
      if (
        this.eFormDetails.quoteTypePriceList &&
        this.eFormDetails.quoteTypePriceList.length > 0
      ) {
        this.eFormDetails.quoteTypePriceList.forEach((item) => {
          if (item.baseRate === null || item.baseRate === "") {
            isOneOfBaseRateIsEmpty = true
            console.log("true")
          }
        })
      }

      return this.upfrontPaymentAmount === null ||
        this.upfrontPaymentAmount === "" ||
        ((this.details.tradingTypeId === 6 ||
          this.details.tradingTypeId === 8) &&
          (this.eFormDetails.dobText === "" ||
            this.eFormDetails.dobText === null ||
            this.eFormDetails.dobText.length < 10)) ||
        ((this.details.tradingTypeId === 6 ||
          this.details.tradingTypeId === 8) &&
          (this.selectedHomeAddress === "" ||
            this.selectedHomeAddress === null)) ||
        ((this.details.tradingTypeId === 7 ||
          this.details.tradingTypeId === 31 ||
          this.details.tradingTypeId === 32) &&
          (this.details.registrationNo === "" ||
            this.details.registrationNo === null)) ||
        isOneOfBaseRateIsEmpty
        ? true
        : false
    },
    selectedItemErrors() {
      return []
    },

    eac() {
      let tempEAC = 0
      const selectItem = this.besNatureOfBusinessList.find(
        (item) => item.id === this.eFormDetails.besBusinessType
      )
      if (selectItem && selectItem.path) {
        tempEAC = parseFloat(selectItem.path.replaceAll(",", ""))
      }
      return tempEAC
    },
    // upfrontPaymentAmount() {
    //   let upfrontPayment = 0
    //   if (
    //     this.eac &&
    //     this.eFormDetails.quoteTypePriceList &&
    //     this.eFormDetails.quoteTypePriceList.length > 0
    //   ) {
    //     if ((parseFloat(this.eac) > 12000 && this.meterTypeId === 9) || (parseFloat(this.eac) > 52764 && this.meterTypeId === 10)) {
    //       upfrontPayment =
    //         (((this.eFormDetails.quoteTypePriceList[0].baseRate / 100) * 365 +
    //           (this.eFormDetails.quoteTypePriceList[1].baseRate / 100) *
    //             this.eac) *
    //           (1 + this.cclValue) *
    //           1.2 *
    //           0.75) /
    //         12
    //     } else {
    //       console.log(
    //         "upfrontPayment",
    //         ((this.eFormDetails.quoteTypePriceList[0].baseRate / 100) * 365 +
    //           (this.eFormDetails.quoteTypePriceList[1].baseRate / 100) *
    //             this.eac) *
    //           1.05 *
    //           0.75
    //       )
    //       upfrontPayment =
    //         (((this.eFormDetails.quoteTypePriceList[0].baseRate / 100) * 365 +
    //           (this.eFormDetails.quoteTypePriceList[1].baseRate / 100) *
    //             this.eac) *
    //           1.05 *
    //           0.75) /
    //         12
    //     }
    //   }
    //   return upfrontPayment
    // },
    formattedMaxDateOfBirth() {
      const maxDate = dayjs()
        .subtract(18, "year")
        .add(1, "day")
        .format("YYYY-MM-DD")
      return dayjs(maxDate).toISOString()
    },
    termInMonthsRules() {
      return [
        (value) => !!value || "Please provide the term",
        (value) => !!value <= 60 || "Term must not be greater than 60",
        // value => !!((value * 30) <= this.window) || `Term must be less than ${this.window} days`
      ]
    },
    registrationNoRules() {
      return [
        (value) => !!value || "Please Provide Correct Registration Number",
      ]
    },
    dobTextRules() {
      return [
        (v) => {
          const maxDate = dayjs().subtract(18, "year").format("YYYY-MM-DD")
          const dobText = v ? v.split("/").reverse().join("-") : ""
          const currentDOB = dayjs(dobText).format("YYYY-MM-DD")
          const diff = dayjs(currentDOB).diff(maxDate, "day")
          let validate = true
          if (diff >= 1 && v.length >= 10) {
            validate = false
          }

          return !!validate || "Must be at least 18 years old"
        },
      ]
    },
    setCedDate() {
      return dayjs(this.eFormDetails.ced * 1000).format("DD/MM/YYYY")
    },
    setCsdDate() {
      return dayjs(this.eFormDetails.csd * 1000).format("DD/MM/YYYY")
    },
    setBirthDate() {
      return dayjs(this.eFormDetails.dateOfBirth * 1000).format("DD/MM/YYYY")
    },
    ePriceFormattedDate() {
      if (
        this.eFormDetails.ePriceReviewDate === "" &&
        this.eFormDetails.ePriceReviewDate === 0
      ) {
        return ""
      }

      return this.eFormDetails.ePriceReviewDate
        ? dayjs(this.eFormDetails.ePriceReviewDate).format("DD/MM/YYYY")
        : ""
    },
    ePriceReviewFormattedDate() {
      if (
        this.eFormDetails.ePriceReviewNoticeDate === "" &&
        this.eFormDetails.ePriceReviewNoticeDate === 0
      ) {
        return ""
      }

      return this.eFormDetails.ePriceReviewNoticeDate
        ? dayjs(this.eFormDetails.ePriceReviewNoticeDate).format("DD/MM/YYYY")
        : ""
    },
    gPriceFormattedDate() {
      if (
        this.eFormDetails.gPriceReviewDate === "" &&
        this.eFormDetails.gPriceReviewDate === 0
      ) {
        return ""
      }

      return this.eFormDetails.gPriceReviewDate
        ? dayjs(this.eFormDetails.gPriceReviewDate).format("DD/MM/YYYY")
        : ""
    },
    dobFormattedDate() {
      if (
        this.eFormDetails.dateOfBirth === "" &&
        this.eFormDetails.dateOfBirth === 0
      ) {
        return ""
      }

      return this.eFormDetails.dateOfBirth
        ? dayjs(this.eFormDetails.dateOfBirth).format("DD/MM/YYYY")
        : ""
    },
    gPriceReviewFormattedDate() {
      if (
        this.eFormDetails.gPriceReviewNoticeDate === "" &&
        this.eFormDetails.gPriceReviewNoticeDate === 0
      ) {
        return ""
      }

      return this.eFormDetails.gPriceReviewNoticeDate
        ? dayjs(this.eFormDetails.gPriceReviewNoticeDate).format("DD/MM/YYYY")
        : ""
    },

    nameValidation() {
      return [(v) => /[a-zA-Z]+$/.test(v) || "Field must only contain letters"]
    },
    surnameValidation() {
      return [(v) => /[a-zA-Z]+$/.test(v) || "Field must only contain letters"]
    },
    jobTitleValidation() {
      return [(v) => /[a-zA-Z]+$/.test(v) || "Field must only contain letters"]
    },
    quoteTypesOnly() {
      return this.eFormDetails && this.eFormDetails.quoteTypePriceList
        ? this.eFormDetails.quoteTypePriceList.filter(
            (x) => x.quoteTypePriceId !== 1
          )
        : []
    },
  },

  methods: {
    valdaOnClickMountedCalled() {
      this.eFormDetails.processedContractValue =
        +this.eFormDetails.processedContractValue
      this.cotDateText =
        this.selectedAddress.cotDate === 0
          ? ""
          : dayjs(this.selectedAddress.cotDate * 1000).format("DD/MM/YYYY")
      const filterMeter = this.meters.filter(
        (meter) => meter.meterId === this.meterId
      )
      this.selectedMeter = filterMeter[0]
      console.log(this.selectedMeter, "selcted meter valda")
      // this.setCsdDate()
      // this.setCedDate()
      console.log("herre in this")
      this.fetchBesTabTitles()
      this.fetchAddressesByContactId()
      if (this.eFormDetails.quoteTypePriceList) {
        this.initialSetup()
      }
    },
    async fetchCCLValueForMeter() {
      const response = await api.get(`getCCLCharge`)
      if (response.status === 200) {
        const filteredItem = response.data.find(
          (item) => parseInt(item.path) === this.meterTypeId
        )
        this.cclValue = parseFloat(filteredItem.valueDescription)
      }
    },
    async fetchBesTabTitles() {
      console.log(this.eFormDetails.saleType, this.supplierId, this.meterTypeId)
      if (this.eFormDetails.saleType && this.meterTypeId && this.supplierId) {
        const response = await api.get(
          `getSignableContractWidget/${this.eFormDetails.saleType}/${this.meterTypeId}/${this.supplierId}`
        )
        this.contractWidgets = response.data
        console.log(
          response.data,
          "Wiget",
          this.contractWidgets,
          this.contractWidgets[0],
          this.contractWidgets[1]
        )
      }
    },
    formatPrice(value) {
      var val = (value / 1).toFixed(2)
      return "£" + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },

    validateBESBusinessType() {
      setTimeout(() => {
        const form = {
          besBusinessType: this.eFormDetails.besBusinessType || 0,
        }
        this.formHasErrors = false

        Object.keys(form).forEach((f) => {
          if (!this.form[f]) this.formHasErrors = true

          this.$refs[f].validate(true)
        })
      }, 3000)
    },

    async fetchAddressesByContactId() {
      if (this.contactId) {
        const response = await api.get(
          `addressesByContactId/${this.contactId}`
        )
        this.contactAddresses = response.data
        const sortedAddressItems = this.contactAddresses.sort((r1, r2) =>
          r1.untilDate > r2.untilDate ? 1 : r1.untilDate < r2.untilDate ? -1 : 0
        )
        const selectedAddressForEdit =
          sortedAddressItems && sortedAddressItems.length > 0
            ? sortedAddressItems[sortedAddressItems.length - 1]
            : null

        if (selectedAddressForEdit) {
          this.selectedHomeAddress =
            selectedAddressForEdit.add1 +
            " " +
            selectedAddressForEdit.add2 +
            " " +
            selectedAddressForEdit.town +
            " " +
            selectedAddressForEdit.county +
            " " +
            selectedAddressForEdit.postcode
        } else {
          this.selectedHomeAddress = ""
        }
      } else {
        this.selectedHomeAddress = ""
      }
    },

    bankNameChanged() {
      this.payment.bankName = this.payment.bankName
        .toLowerCase()
        .split(" ")
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(" ")
    },
    accountNameChanged() {
      this.payment.accountName = this.payment.accountName
        .toLowerCase()
        .split(" ")
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(" ")
    },

    isSortCodeNumberKey(evt) {
      const charCode = evt.which ? evt.which : evt.keyCode
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        event.returnValue = false
        return false
      } else {
        if (
          this.payment &&
          this.payment.sortCode &&
          this.payment.sortCode.length >= 6
        ) {
          event.returnValue = false
          return false
        }
        event.returnValue = true
        return true
      }
    },
    isAccountNoNumberKey(evt) {
      const charCode = evt.which ? evt.which : evt.keyCode
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        event.returnValue = false
        return false
      } else {
        if (
          this.payment &&
          this.payment.accountNumber &&
          this.payment.accountNumber.length >= 8
        ) {
          event.returnValue = false
          return false
        }
        event.returnValue = true
        return true
      }
    },
    saveAndContinue() {
      changesSaved()
    },
    async saveDOBAndContinue() {
      if (
        (this.dob === null || this.dob === "" || this.dobText === "") &&
        !this.isDobTextChanged
      ) {
        this.saveAndContinue()
        return
      }
      const maxDate = dayjs().subtract(18, "year").format("YYYY-MM-DD")
      const dobText = this.dobText.split("/").reverse().join("-")
      const currentDOB = dayjs(dobText).format("YYYY-MM-DD")
      const diff = dayjs(currentDOB).diff(maxDate, "day")
      if (diff >= 1) {
        const form = {
          dobText: this.dobText,
        }
        this.formHasErrors = false

        Object.keys(form).forEach((f) => {
          if (!this.form[f]) this.formHasErrors = true

          this.$refs[f].validate(true)
        })

        return false
      }

      const dobData = {
        contactId: this.selectedSignificantPerson.contactId,
        dob: dayjs(this.dob).unix(),
      }
      try {
        const response = await api.post("updateDob", dobData)
        if (response && response.status === 200) {
          this.saveAndContinue()
        } else {
          this.somethingWentWrong()
        }
      } catch (error) {
        this.somethingWentWrong()
      }
    },
    dateOfBirthChanged() {
      this.dobText = dayjs(this.eFormDetails.dateOfBirth).format("DD/MM/YYYY")
      // const vCleaveBirthDateElements= document.querySelectorAll('#vCleaveBirthDate')
      // vCleaveBirthDateElements[1].value = dayjs(this.dob).format("DD/MM/YYYY")
    },
    cotDateChanged() {
      this.cotDateText = dayjs(this.form.cotDate).format("DD/MM/YYYY")
    },
    csdDateChanged() {
      this.csdDateText = dayjs(this.form.csdDate).format("DD/MM/YYYY")
    },

    dobTextChanged() {
      this.isDobTextChanged = true
      if (this.dobText.length >= 1) {
        const tempDay = this.dobText.slice(0, 2)
        const tempMonth = this.dobText.slice(3, 5)

        if (parseInt(tempDay) > 31) {
          const temp = this.dobText.slice(2, 10)
          this.dobText = "31" + temp
        }
        if (this.dobText.length >= 2 && parseInt(tempDay) === 0) {
          const temp = this.dobText.slice(2, 10)
          this.dobText = "01" + temp
        }
        if (parseInt(tempMonth) > 12) {
          const temp = this.dobText.slice(5, 10)
          this.dobText = tempDay + "/12" + temp
        }
        if (this.dobText.length >= 5 && parseInt(tempMonth) === 0) {
          const temp = this.dobText.slice(5, 10)
          this.dobText = tempDay + "/01" + temp
        }
        const dobText = this.dobText.split("/").reverse().join("-")
        // const maxDate = dayjs().subtract(18, 'year').format('YYYY-MM-DD')
        const currentDOB = dayjs(dobText).format("YYYY-MM-DD")
        // const diff = dayjs(currentDOB).diff(maxDate, 'day')
        // if(diff >= 1 && this.dobText.length >= 10) {
        //     this.dobText = dayjs().subtract(18, 'year').subtract(1, 'day').format('DD/MM/YYYY')
        //     this.dob = dayjs().subtract(18, 'year').subtract(1, 'day').format('YYYY-MM-DD')
        // }else {

        this.eFormDetails.dateOfBirth = currentDOB
        if (this.eFormDetails.dateOfBirth === "Invalid Date") {
          this.eFormDetails.dateOfBirth = ""
        }
        // }
      }
    },
    jobTitleCapitalLetter() {
      this.eFormDetails.position = this.eFormDetails.position
        .toLowerCase()
        .split(" ")
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(" ")
    },
    initialSetup() {
      this.priceQuote = JSON.parse(
        JSON.stringify(this.eFormDetails.quoteTypePriceList)
      )

      this.form.selectedMeterTypeList =
        this.eFormDetails.selectedSupplierMeterTypeID

      this.setTotalYearlySpend()
      this.setBillingdetails()

      this.priceQuote.forEach((el, index) => {
        console.log("el", el.price)
        console.log(typeof el)
        console.log("in", index)
        if (index === 0) {
          this.form.eStandingCharge = el.uplift
          this.form.gStandingCharge = el.uplift
        }
        if (index === 1) {
          this.form.unitRate = el.uplift
          this.form.gUniteRate = el.uplift
          this.form.unitRateDay = el.price
          this.form.totalEstCommissionCost = el.eac
        }
        if (index === 2) {
          this.form.unitRateNight = el.price
        }
      })
    },
    setBillingdetails() {
      //   this.form.billingName = this.eFormDetails.siteName
      this.form.billingAddress =
        this.eFormDetails.add1 || this.eFormDetails.add2
          ? this.eFormDetails.add1 + this.eFormDetails.add2
          : ""
      //   this.form.billingTowncountry =
      //     this.eFormDetails.town + this.eFormDetails.county
      //   this.form.billingPostCode = this.eFormDetails.postCode
    },
    setTotalYearlySpend() {
      const total =
        this.priceQuote.reduce(
          (total, item) => total + Number(item.price),
          0
        ) || 0

      this.form.expectedYearlySpend = total
    },

    // setStandingChargeUplift() {
    //     if(this.priceQuote) {
    //         this.form.eStandingCharge =   this.priceQuote[0].uplift
    //         this.form.gStandingCharge =   this.priceQuote[0].uplift
    //     }
    // },
    // setUnitRateCommission() {
    //     if(this.priceQuote) {
    //         this.form.unitRate =   this.priceQuote[1].uplift
    //         this.form.gUniteRate =   this.priceQuote[1].uplift
    //     }
    // },

    // setPerDayRate() {
    //     if(this.priceQuote) {
    //         this.form.unitRateDay =   this.priceQuote[1].price
    //         this.form.unitRateNight =   this.priceQuote[2].price
    //     }
    // },

    // setTotalCommission() {
    //     if(this.priceQuote) {
    //         this.form.totalEstCommissionCost =   this.priceQuote[1].eac
    //     }
    // },

    // setCsdDate() {
    //     this.eFormDetails.ePriceReviewDate = dayjs(this.eFormDetails.csd * 1000).format("DD/MM/YYYY")
    //     this.eFormDetails.gPriceReviewDate = dayjs(this.eFormDetails.csd * 1000).format("DD/MM/YYYY")
    // },
    // setCedDate() {
    //     this.eFormDetails.ePriceReviewNoticeDate = dayjs(this.eFormDetails.ced * 1000).format("DD/MM/YYYY")
    //     this.eFormDetails.gPriceReviewNoticeDate = dayjs(this.eFormDetails.ced * 1000).format("DD/MM/YYYY")
    // },
    async submitEForm() {
      if (this.upfrontPaymentAmount === null) {
        const form = {
          upfrontPaymentAmount: this.upfrontPaymentAmount,
        }
        this.formHasErrors = false

        Object.keys(form).forEach((f) => {
          if (!this.form[f]) this.formHasErrors = true

          this.$refs[f].validate(true)
        })

        return false
      }
      const sortedAddressItems = this.contactAddresses.sort((r1, r2) =>
        r1.untilDate > r2.untilDate ? 1 : r1.untilDate < r2.untilDate ? -1 : 0
      )
      //   widgetVal = 'CYwYpy5C5T';
      console.log("here", this.contractWidgets)
      const formData = {
        supplierID: this.selectedMeter.supplierId,
        employeeId: this.userId,
        saleTypeID: this.eFormDetails.saleType,
        meterTypeID: this.eFormDetails.meterTypeID,
        masterContractTypeID:
          this.eFormDetails.currentSupplierID === 13 ? 0 : 2,
        soldSupplierId: this.eFormDetails.soldSupplierId,
        addressId: this.eFormDetails.addressId
          ? this.eFormDetails.addressId
          : 0,
        customerEmail: this.eFormDetails.emailAddress,
        significantId: this.eFormDetails.significantId,
        agreedContractId: this.eFormDetails.agreedContractId,
        meterId: this.eFormDetails.meterID,
        term: this.eFormDetails.term,
        masterContractType: this.eFormDetails.currentSupplierID === 13 ? 0 : 2,
        csd: this.eFormDetails.csd,
        eac: this.eac ? parseFloat(this.eac).toFixed(2) : 0,
        companyId: this.details.companyId ? this.details.companyId : 0,
        businessType: "",
        upfrontPaymentAmount: this.processedUpfrontPaymentAmount,
        totalEst: parseInt(this.eFormDetails.processedContractValue),
        greenEnergy: "",
        quoteTypePriceList: this.eFormDetails.quoteTypePriceList,
        contractType:
          this.eFormDetails.currentSupplierID === 13
            ? "Change of Tenancy"
            : "Acquisition",
        paymentTypeName: this.eFormDetails.paymentType,
        homeAddressId:
          sortedAddressItems && sortedAddressItems.length > 0
            ? sortedAddressItems[sortedAddressItems.length - 1].addressId
            : this.eFormDetails.selectedAddressId
            ? this.eFormDetails.selectedAddressId
            : 0,
        widget: this.contractWidgets[this.tab].widget,
      }
      // 'CYwYpy5C5T'

      console.log("formData", formData)

      const res = await api.post("sendSignableContractValda", formData)
      if (res && res.status === 200) {
        changesSaved("Valda Form submitted Successfully")
        this.closeModal()
      } else {
        somethingWentWrong()
      }
    },

    closeModal() {
      this.$emit("closeBesFormModal")
    },
    isNumberKey(evt) {
      this.showInvalidFormatError = false
      const charCode = evt.which ? evt.which : evt.keyCode
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        event.returnValue = false
        return false
      } else {
        event.returnValue = true
        return true
      }
    },
    // jobTitleCapitalLetter() {
    //   this.form.jobTitle = this.form.jobTitle
    //     .toLowerCase()
    //     .split(" ")
    //     .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    //     .join(" ")
    // },
    lastNameCapitalLetter() {
      this.form.lastName = this.form.lastName
        .toLowerCase()
        .split(" ")
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(" ")
    },
    firstNameCapitalLetter() {
      this.form.firstName = this.form.firstName
        .toLowerCase()
        .split(" ")
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(" ")
    },
    validateContactAndSave() {
      const contactNumber = this.form.contactNumber
        ? this.form.contactNumber
        : ""
      const firstName = this.form.firstName
      const lastName = this.form.lastName
      const jobTitle = this.form.jobTitle
      // console.log(this.$refs.contactNumber.$refs['contactNumber'])
      if (
        this.formEditMode &&
        (!/[a-zA-Z]+$/.test(firstName) ||
          this.firstName === "" ||
          (lastName !== "" && !/[a-zA-Z]+$/.test(lastName)) ||
          (jobTitle !== "" && !/[a-zA-Z]+$/.test(jobTitle)))
      ) {
        const form = {
          firstName: this.form.firstName,
          lastName: this.form.lastName,
          jobTitle: "",
        }
        this.formHasErrors = false
        Object.keys(form).forEach((f) => {
          if (!this.form[f]) this.formHasErrors = true
          this.$refs[f].validate(true)
        })
        return false
      }
      if (
        this.formEditMode &&
        contactNumber &&
        contactNumber !== "" &&
        (contactNumber.length < 10 ||
          /^(00|034|084|087|04|05|06|09)/.test(contactNumber))
      ) {
        this.$refs.contactNumber.$refs["contactNumber"].validate(true)
        return false
      }
      if (
        !this.formEditMode &&
        (!/[a-zA-Z]+$/.test(firstName) || this.firstName === "")
      ) {
        const form = {
          firstName: this.form.firstName,
        }
        this.formHasErrors = false
        Object.keys(form).forEach((f) => {
          if (!this.form[f]) this.formHasErrors = true
          this.$refs[f].validate(true)
        })
        return false
      }

      this.submitForm()
    },

    // -------Lets separate the function Update and edit Contactac -----
    submitForm() {
      if (!this.formEditMode) {
        this.addContact()
      } else {
        this.editContact()
      }
    },
    // async addContact() {
    //   if(this.isSendingRequest) {
    //     return false
    //   }
    //   this.isSendingRequest = true
    //   const defaultContact = getContactState()
    //   const noteMessage = `Contact Added - ${this.form.title} ${this.form.firstName} ${this.form.lastName}<br>
    //                       ${this.form.jobTitle}<br>
    //                       ${this.form.contactNumber}<br>
    //                       ${this.form.emailAddress}`
    //   const res = await this.createContact({
    //     ...defaultContact,
    //     ...this.form,
    //     companyId: this.companyId,
    //     noteMessage: noteMessage,
    //     currentUserId: this.userId,
    //   })
    //   const self = this
    //   if (res.status === 200) {
    //     this.isSendingRequest = true

    //     if (isNaN(parseFloat(res.data))) {
    //       this.showMessage = true
    //       if (res.data.includes("alternate")) {
    //         self.errorMsg = res.data
    //       } else {
    //         self.errorMsgCC = res.data
    //         setTimeout(() => {
    //           this.showMessage = false
    //         }, 3000)
    //       }
    //       this.isSendingRequest = false
    //         somethingWentWrong()
    //     } else {

    //       changesSaved("Contact Added Sucessfully")
    //       this.fetchMainData(this.companyId)
    //       this.fetchContacts()
    //       this.fetchNotes()
    //       this.contactNumber = null
    //       this.isSendingRequest = false
    //       this.form = {
    //         title: "",
    //         firstName: "",
    //         lastName: "",
    //         jobTitle: "",
    //         contactNumber: "",
    //         emailAddress: "",
    //       }
    //       this.formHasErrors = false
    //       this.errorMsg = ""
    //       this.errorMsgCC = ""
    //       this.$refs.form.resetValidation()
    //       this.closeForm()
    //     }
    //   }
    // },
    // async editContact() {
    //   // const noteMessage = `Contact Updated - ${this.form.title} ${this.form.firstName} ${this.form.lastName}`

    //   let noteMessage = ''

    //   if (this.form.title !== this.selectedContact.title) {
    //     noteMessage += `Title changed from ${
    //       this.selectedContact.title ? this.selectedContact.title : ''
    //     } to ${this.form.title}<br>`
    //   }

    //   if (this.form.firstName !== this.selectedContact.firstName) {
    //     noteMessage += `First Name changed from ${
    //       this.selectedContact.firstName ? this.selectedContact.firstName : ''
    //     } to ${this.form.firstName}<br>`
    //   }

    //   if (this.form.lastName !== this.selectedContact.lastName) {
    //     noteMessage += `Last Name changed from ${
    //       this.selectedContact.lastName ? this.selectedContact.lastName : ''
    //     } to ${this.form.lastName}<br>`
    //   }

    //   if (this.form.jobTitle !== this.selectedContact.jobTitle) {
    //     noteMessage += `Job Title changed from ${
    //       this.selectedContact.jobTitle ? this.selectedContact.jobTitle : ''
    //     } to ${this.form.jobTitle}<br>`
    //   }

    //   if (this.form.contactNumber !== this.selectedContact.contactNumber) {
    //     noteMessage += `Contact Number changed from ${
    //       this.selectedContact.contactNumber ? this.selectedContact.contactNumber : ''
    //     } to ${this.form.contactNumber}<br>`
    //   }

    //   if (this.form.emailAddress !== this.selectedContact.emailAddress) {
    //     noteMessage += `Email changed from ${
    //       this.selectedContact.emailAddress ? this.selectedContact.emailAddress : ''
    //     } to ${this.form.emailAddress}<br>`
    //   }

    //   const res = await this.updateContact({
    //     ...this.selectedContact,
    //     ...this.form,
    //     companyId: this.companyId,
    //     noteMessage: noteMessage === '' ? '' : `Contact Updated - ${this.form.title} ${this.form.firstName} ${this.form.lastName}<br>${noteMessage}`,
    //     currentUserId: this.userId,
    //     leadId: this.selectedContact.leadId
    //       ? this.selectedContact.leadId
    //       : 0,
    //     securityPassword: this.selectedContact.securityPassword ? this.selectedContact.securityPassword : '',
    //   })
    //   const self = this
    //   if (res.status === 200) {
    //     if (isNaN(parseFloat(res.data))) {
    //       this.showMessage = true

    //       if (res.data.includes("alternate")) {
    //         self.errorMsg = res.data
    //       } else {
    //         self.errorMsgCC = res.data
    //         setTimeout(() => {
    //           this.showMessage = false
    //         }, 3000)
    //       }
    //       somethingWentWrong()
    //     } else {
    //       changesSaved("Contact Successfully Edited")
    //       this.fetchNotes()
    //       this.fetchMainData(this.companyId)
    //       this.fetchContacts()
    //       this.closeForm()
    //     }
    //   }
    // },

    // bindContactDetails() {
    //   if (this.formEditMode) {
    //     Object.entries(this.selectedContact).forEach(
    //       mergeExistingEntriesIntoForm(this.form)
    //     )
    //   }
    // },
  },
}
</script>

<style>
.upfront .v-input__prepend-outer {
  margin: 0;
}
.upfront .v-icon.mdi.mdi-currency-gbp {
  font-size: 1.2rem;
}
.upfront .v-text-field__slot input {
  padding: 0;
}
/* .upfront .v-text-field__details{
  display:none
} */
.theme--dark.v-btn.v-btn--disabled.v-btn--has-bg {
  background-color: #444 !important;
}
.v-input--solo-inverted .v-input__control .v-input__slot::before {
  border-bottom: none !important;
}
.box-shadow {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}
.p-3 {
  padding: 1.5rem;
}
.alignment {
  align-items: center;
}
</style>
