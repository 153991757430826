<template>
  <v-main>
    <v-container fluid>
      <v-row>
        <v-col cols="3">
          <company-card v-if="!isActive"></company-card>
          <additional-services-card v-if="!isActive"></additional-services-card>
        </v-col>
        <v-col cols="6">
          <v-row>
            <v-col cols="12">
              <contact-card v-if="!isActive"></contact-card>
              <active-card v-if="isActive" />
            </v-col>
            <v-col cols="12">
              <address-card v-if="!isActive"></address-card>
            </v-col>
            <v-col cols="12">
              <meters-card v-if="!isActive"></meters-card>
            </v-col>
            <v-col cols="12" v-if="testHide">
              <ComplaintCard @addComplaintModal="addComplaintModal=!addComplaintModal" @complaintDetailsModal="openComplaintDetailsModal"> </ComplaintCard>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="3">
          <notes-card v-if="!isActive"></notes-card>
        </v-col>
      </v-row>
    </v-container>

    <!-- Unassigned LEad Outstanding Modal -->
    <v-dialog v-model="unassignedLeadOutstanding" max-width="580">
      <v-card>
        <v-card-title class="text-h5">
          {{modalTitle}}
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col class="d-flex justify-center" cols="12">
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-headers text-left">
                        Company Details
                      </th>
                      <th class="text-headers text-left">
                        Telephone Number
                      </th>
                      <th class="text-headers text-left">
                        Assigned Date
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      class="p-2 "
                      style="height:90px"
                      v-for="item in unassignedLeadOutstandingData"
                      :key="item.companyId"
                    >
                      <td style="width:300px">
                        <div>
                          <span
                            ><strong>{{ item.companyName }}</strong></span
                          >
                        </div>
                        <div>
                          <span>
                            <strong>Company ID: </strong>
                            <span>
                              {{ item.companyId }}
                            </span>
                          </span>
                        </div>
                      </td>
                      <td style="width:150px">
                        <div class="estimate-container">
                          <v-row no-gutters>
                            <v-col cols="auto">
                              <p>
                                <a
                                  class="cursor-pointer blue--text"
                                  button
                                  style="text-decoration:none"
                                  @click="handleCopy(item.telephone)"
                                >
                                  <slot name="value">
                                    {{ item.telephone }}
                                  </slot>
                                </a>
                              </p>
                            </v-col>
                          </v-row>
                        </div>
                      </td>
                      <td style="width:150px">
                        <div class="estimate-container">
                          <v-row no-gutters>
                            <v-col cols="auto">
                              <p>
                                {{formatDate(item.assignedDate)}}
                              </p>
                            </v-col>
                          </v-row>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green darken-1"
            text
            @click="unassignedLeadOutstanding = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Add Complaint Modal -->
    <v-dialog v-model="addComplaintModal" max-width="580" transition="dialog-top-transition">
      <v-card>
        <v-card-title class="text-h5">
          Log a Complaint
        </v-card-title>
      </v-card>
    </v-dialog>

    <!-- Complaint Details Modal -->
    <v-dialog v-model="complaintDetailsModal" max-width="580" transition="dialog-top-transition">
      <v-card>
        <v-card-title class="text-h5">
          Complaint Details
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col class="d-flex justify-center" cols="12">
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-headers text-left">
                        Company Details
                      </th>
                      <th class="text-headers text-left">
                        Telephone Number
                      </th>
                      <th class="text-headers text-left">
                        Assigned Date
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      class="p-2 "
                      style="height:90px"
                      v-for="item in unassignedLeadOutstandingData"
                      :key="item.companyId"
                    >
                      <td style="width:300px">
                        <div>
                          <span
                            ><strong>{{ item.companyName }}</strong></span
                          >
                        </div>
                        <div>
                          <span>
                            <strong>Company ID: </strong>
                            <span>
                              {{ item.companyId }}
                            </span>
                          </span>
                        </div>
                      </td>
                      <td style="width:150px">
                        <div class="estimate-container">
                          <v-row no-gutters>
                            <v-col cols="auto">
                              <p>
                                <a
                                  class="cursor-pointer blue--text"
                                  button
                                  style="text-decoration:none"
                                  @click="handleCopy(item.telephone)"
                                >
                                  <slot name="value">
                                    {{ item.telephone }}
                                  </slot>
                                </a>
                              </p>
                            </v-col>
                          </v-row>
                        </div>
                      </td>
                      <td style="width:150px">
                        <div class="estimate-container">
                          <v-row no-gutters>
                            <v-col cols="auto">
                              <p>
                                {{formatDate(item.assignedDate)}}
                              </p>
                            </v-col>
                          </v-row>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green darken-1"
            text
            @click="complaintDetailsModal = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-main>
</template>

<script>
import CompanyCard from "@/components/cards/company-card"
import AdditionalServicesCard from "@/components/cards/additional-services-card"
import ContactCard from "@/components/cards/contact-card"
import AddressCard from "@/components/cards/address-card"
import MetersCard from "@/components/cards/meters-card.vue"
import ComplaintCard from "@/components/cards/complaint-card.vue"
import NotesCard from "@/components/cards/notes-card.vue"
import ActiveCard from "@/components/cards/active-card.vue"
import { copyTextToClipboard } from "@/helpers/utilities"
import dayjs from 'dayjs'
import { mapGetters } from "vuex"
import api from "@/store/api"

export default {
  components: {
    AdditionalServicesCard,
    CompanyCard,
    ContactCard,
    AddressCard,
    MetersCard,
    NotesCard,
    ActiveCard,
    ComplaintCard,
  },
  props: {
    isActive: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      unassignedLeadOutstanding: false,
      unassignedLeadOutstandingData: [],
      modalTitle:'',
      addComplaintModal:false,
      complaintDetailsModal:false,
      complaintDetails:[],
      testHide:false
    }
  },
  mounted() {
    setTimeout(() => {
      this.fetchUnassignedLeadOutstanding()
    }, 3000)
  },
  computed: {
    ...mapGetters("employee", ["userId"])
  },
  methods: {
    formatDate(date) {
      if (!date || date === 0) {
        return null
      }
      return dayjs(date * 1000).format('DD/MM/YYYY HH:mm')
    },
    async fetchUnassignedLeadOutstanding() {
      const res = await api.get(`unassignedLeadOutstanding/${this.userId}`)
      if (res && res.status === 200) {
        if (res.data.length > 0) {
          this.modalTitle = 'Ready For Quote - Unassigned Lead Outstanding'
          this.unassignedLeadOutstanding = true
          this.unassignedLeadOutstandingData = res.data
        }else {
          const res = await api.get(`dispositionOutstanding/${this.userId}`)
          // const res = await api.get(`/dispositionOutstanding/600788`)
          if (res.data.length > 0) {
            this.unassignedLeadOutstanding = true
            this.unassignedLeadOutstandingData = res.data
            this.modalTitle = 'Ready For Quote - Disposition Outstanding'
          }
        }
      }
    },
    handleCopy(telephoneNumber) {
      copyTextToClipboard(telephoneNumber)
    },
    async openComplaintDetailsModal(complaintId) {
      const res = await api.get(`getWebComplaintByComplaintId/${complaintId}`)
      console.log('detailssssss', res)
      if (res && res.status === 200) {
        this.complaintDetails = res.data
        this.complaintDetailsModal = true
      }
    }
  }
}
</script>

<style></style>
