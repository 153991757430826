<template>
  <v-card
    height="100%"
    class="d-flex flex-column"
    flat
    :color="color"
    rounded="lg"
    light
  >
    <v-card-text style="font-size: 13px">
      <div class="blue-grey--text">
        <div>
          {{ firstLine }}
        </div>
        <div>
          {{ secondLine }}
        </div>
        <div>
          {{ town }}
        </div>
        <div>
          {{ county }}
        </div>
        <div>
          {{ postCode }}
        </div>
      </div>

      <div class="pt-3" v-if="showDetails">
        <v-row>
          <v-col>
            <div class="text-caption">From</div>
            <div class="teal--text">{{ $helpers.formatDate(startDate) }}</div>
          </v-col>
          <v-col>
            <div class="text-caption">To</div>
            <div class="teal--text">{{ $helpers.formatDate(endDate) }}</div>
          </v-col>
        </v-row>
        <v-row class="text-caption">
          <v-col cols="7" class="mb-3">{{ timeFromStartDate }}</v-col>
          <v-col v-if="showEdit" cols="2" class="align-self-start p-0" style="">
            <p
              @click="editAddressClicked"
              class="blue--text cursor-pointer font-weight-bold mb-0 teal--text"
              style="width: 25px"
            >
              EDIT
            </p>
          </v-col>
          <v-col cols="3" class="align-self-start" style="">
            <p
              @click="deleteAddressClicked"
              class="blue--text cursor-pointer font-weight-bold mb-0 teal--text"
              style="width: 45px"
            >
              DELETE
            </p>
          </v-col>
        </v-row>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex"
import { changesSaved, somethingWentWrong } from "@/helpers/utilities"

export default {
  components: {},
  props: {
    showEdit: {
      type: Boolean,
      default: false
    },
    addressId: {
      type: String,
      default: ""
    },

    firstLine: {
      type: String,
      default: ""
    },
    secondLine: {
      type: String,
      default: ""
    },
    town: {
      type: String,
      default: ""
    },
    county: {
      type: String,
      default: ""
    },
    postCode: {
      type: String,
      default: ""
    },
    startDate: {
      type: Number,
      default: 0
    },
    endDate: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      toggleEdit: false
    }
  },
  computed: {
    ...mapGetters("meters", ["showCard", "meters", "selectedMeter"]),
    ...mapGetters("employee", ["userId"]),
    ...mapGetters("company", ["details"]),

    color() {
      return this.primary ? "green lighten-5" : "blue lighten-5"
    },
    showDetails() {
      return this.startDate || this.endDate
    },
    timeFromStartDate() {
      const totalMonths = this.$helpers.diffDates(
        this.endDate,
        this.startDate,
        "M"
      )
      const years = Math.floor(totalMonths / 12)
      const months = totalMonths - 12 * years || 1

      const monthsPlural = this.$helpers.isPlural(months)
      const yearsPlural = this.$helpers.isPlural(years)

      const monthsText = months ? `${months} month${monthsPlural}` : ""
      const yearsText = years ? `${years} year${yearsPlural}` : ""

      return years ? `${yearsText} ${monthsText}`.trim() : monthsText
    }
  },
  methods: {
    ...mapActions("meters", ["fetchMeters", "toggleCardVisibility"]),
    ...mapActions("notes", ["fetchNotes"]),
    ...mapActions("addresses", [
      "fetchPaymentInfo",
      "fetchLandlordInfo",
      "fetchPreviousTenantInfo",
      "fetchBreweryInfo",
      "deleteAddressById",
      "fetchAddresses",
      "fetchAddressesAfterRemove"
    ]),
    ...mapActions("ui", ["openForm"]),
    ...mapMutations("addresses", {
      selectAddress: "setSelectedAddress"
    }),

    deleteAddressClicked() {
      this.$emit("deleteAddressClicked")
    },

    editAddressClicked() {
      this.toggleEdit = !this.toggleEdit
      this.$emit("editAddressClicked")
      this.$emit("editAddressClickedToggle", this.toggleEdit)
    },

    editAddress() {
      this.fetchPaymentInfo(this.addressId),
        this.fetchLandlordInfo(this.addressId),
        this.fetchPreviousTenantInfo(this.addressId),
        this.fetchBreweryInfo(this.addressId),
        this.selectAddress(this.addressId),
        this.openForm({ formName: "address-form", edit: true })
    },
    toggleMetersCard() {
      this.toggleCardVisibility(this.addressId)
      this.selectAddress(this.addressId)
      this.fetchPaymentInfo(this.addressId)
      this.fetchMeters(this.addressId)
    },
    async handleDeleteAddress() {
      try {
        const noteMessage = `Address Deleted - ${this.siteName} <br/>
                            ${this.firstLine} <br/>
                            ${this.secondLine} <br/>
                            ${this.town} <br/>
                            ${this.county} <br/>
                            ${this.postCode}`
        const details = {
          addressId: this.addressId,
          userId: this.userId,
          noteMessage: noteMessage,
          companyId: this.details.companyId
        }
        const response = await this.deleteAddressById(details)
        if (response && response.status === 200) {
          console.log(response)
          changesSaved("Address Removed Successfully!")
          setTimeout(() => {
            this.fetchAddressesAfterRemove(this.details.companyId)
            this.toggleCardVisibility(this.addressId)

            this.fetchNotes()
          }, 1000)
        }
      } catch (err) {
        somethingWentWrong()

        console.log(err)
      }
    }
  }
}
</script>
