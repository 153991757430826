import api from "@/store/api"
import { getCompanyState } from "@/store/helpers/default-state"

import { SOLE_TRADER, PARTNERSHIP } from "@/store/helpers/trading-types"


const state = getCompanyState()

const getters = {
  companies: state => state.companies,
  company: state => state.company,
  companyId: state => state.id,
  details: state => state.details,
  webCustomerReviewRequest: state => state.webCustomerReviewRequest,
  cID: state => state.details.companyId,
  loading: state => state.loading,
  error: state => state.error,
  hasRegistrationNo: state => {
    const tradingType = state.details.tradingType.toLowerCase()
    return tradingType !== SOLE_TRADER && tradingType !== PARTNERSHIP
  },
  sicSection: state => state.details.section.split(" ")[1]
}

const mutations = {
  setCompany: (state, company) => (state.company = company),
  setCompanyDetails: (state, details) => (state.details = details),
  setWebCustomerReviewRequest: (state, details) => (state.webCustomerReviewRequest = details),
  setLoading: (state, status) => (state.loading = status),
  setError: (state, error) => (state.error = error),
  updateCompany: (state, company) => (state.details = company),
  updateCreditScore: (state, creditScore) => (state.details = creditScore),
  setCompanyId: (state, companyId) => (state.companyId = companyId),
  setId: (state, id) => (state.id = id),
  // {
  //   const index = state.companies.findIndex(
  //     ({ companyId }) => companyId === company.companyId
  //   )

  //   state.companies.splice(index, 1, company)
  // },
}

const actions = {
  async fetchCompanyDetails({ commit, state }, companyId = state.id) {
    commit("setLoading", true)
    commit("setId", companyId)
    commit("setCompanyId", companyId)
    try {
      const response = await api.get(`company/${companyId}`)
      if (response.status === 200) {
        commit("setCompanyDetails", response.data)
        commit("setCompany", response.data)
      }

    } catch (error) {
      commit("setError", error)
    }
    commit("setLoading", false)
  },


  async getWebCustomerReviewRequest({ commit, state }, companyId = state.id) {
    commit("setLoading", true)
    try {
      const response = await api.get(`getWebCustomerReviewRequest/${companyId}`)
      if (response.status === 200) {
        commit("setWebCustomerReviewRequest", response.data)
      }

    } catch (error) {
      commit("setError", error)
    }
    commit("setLoading", false)
  },


  async updateCompany({ commit}, company ) {
    try {
      const response = await api.post('updateCompany', company)
      commit("updateCompany", company)
      return response
    } catch (error) {
      commit("setError", company)
      return error
    }
  },
  async updateCreditScore({ commit}, creditScore ) {
    try {
      const response = await api.post('updateCreditScore', creditScore)
      commit("updateCreditScore", creditScore)
      return response
    } catch (error) {
      commit("setError", creditScore)
      return error
    }
  },
   async setCompany({ commit }, company) {
    commit('setCompany', company)
  },
}

export default { state, getters, mutations, actions, namespaced: true }
