<template>
  <v-container style="padding-top: 0px; margin-top: -24px">
    <v-row>
      <v-col>
        <!-- <v-select @change="selectAddressOnChange" label="Select Address to Edit" :items="addressList" ></v-select> -->
        <v-container v-if="showAddAddress">
          <v-row>
            <v-col cols="6">
              <v-menu
                ref="menuFromDate"
                v-model="menuFromDate"
                :close-on-content-click="true"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    ref="dateOfFromText"
                    :rules="fromDateRules"
                    label="From"
                    placeholder="DD/MM/YYYY"
                    v-model="form.dateOfFromText"
                    prepend-icon="mdi-calendar"
                    v-bind="attrs"
                    v-on="on"
                    @input="dateOfFromTextChanged"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="form.fromDate"
                  ref="picker"
                  locale="en-GB"
                  min="1950-01-01"
                  @change="dateOfFromChanged"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="6">
              <v-menu
                ref="menuToDate"
                v-model="menuToDate"
                :close-on-content-click="true"
                transition="scale-transition"
                origin="top right"
                offset-y
                nudge-left="290px"
                nudge-top="50px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    ref="dateOfToText"
                    :rules="toDateRules"
                    label="To"
                    placeholder="DD/MM/YYYY"
                    v-model="form.dateOfToText"
                    prepend-icon="mdi-calendar"
                    v-bind="attrs"
                    v-on="on"
                    @input="dateOfToTextChanged"
                  ></v-text-field>
                </template>

                <v-date-picker
                  v-model="form.toDate"
                  :rules="toDateRules"
                  ref="picker"
                  locale="en-GB"
                  min="1950-01-01"
                  @change="dateOfToChanged"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <div
            v-if="
              (showPostcode &&
                !inCorrectPostcodeSelected &&
                postcodeSelected) ||
              editMode
            "
          >
            <v-text-field
              label="Address Line 1"
              v-model="form.add1"
            ></v-text-field>
            <v-text-field
              label="Address Line 2"
              v-model="form.add2"
            ></v-text-field>
            <v-text-field label="Town/City" v-model="form.town"></v-text-field>
            <v-text-field label="County" v-model="form.county"></v-text-field>
          </div>
          <v-row class="d-flex" style="justify-content: space-between">
            <v-col
              cols="auto"
              style="margin-top: 0.4rem; flex-grow: 100 !important"
            >
              <v-text-field
                label="Postcode"
                v-model="form.postcode"
                placeholder="Enter Postcode"
                @input="postCodeChanged"
              ></v-text-field>
            </v-col>
            <v-col
              class="d-flex"
              v-if="showAddAddress"
              cols="auto"
              style="
                align-items: center;
                justify-content: center;

                gap: 3rem;
              "
            >
              <div>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <!-- <v-icon v-bind="attrs" v-on="on" color="primary ml-3" style="font-size:20px;">mdi-information</v-icon> -->
                    <i
                      @click="fetchPostCodeAddresses"
                      v-on="on"
                      v-bind="attrs"
                      class="fas fa-lg fa-search-location"
                      style="color: #008000; font-size: 1.4em; cursor: pointer"
                    ></i>
                  </template>
                  <span> Find address</span>
                </v-tooltip>
              </div>
              <!-- <v-btn
                  style="position: absolute;
    position: relative;
    left: -10px;"
                  class="mt-2 mb-2"
                        color="accent"
                        block
                        depressed
                        @click="fetchPostCodeAddresses"
                    >
                        Find Address
                    </v-btn> -->
              <div>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <i
                      @click="deleteSelectedAddress"
                      v-on="on"
                      v-bind="attrs"
                      class="fas fa-trash fa-lg"
                      style="color: #ff5252; font-size: 1.4em; cursor: pointer"
                    ></i>
                  </template>
                  <span>Delete selected address</span>
                </v-tooltip>
              </div>
              <!-- <v-btn
                    style="position: absolute;
    position: relative;
    left: -20px;"
                        class="mt-2 mb-2"
                        color="error"
                        block
                        depressed
                        @click="deleteSelectedAddress"
                    >
                        Delete Selected Address
                    </v-btn> -->
            </v-col>
          </v-row>
          <v-select
            v-if="showPostcode && !inCorrectPostcodeSelected"
            label="Select Address"
            :items="addresses"
            v-model="postcodeSelected"
            @change="addressSelected"
          ></v-select>
        </v-container>
        <v-btn color="info" block depressed @click="addNewAddressFormShow">
          Add New Address
        </v-btn>

        <v-btn
          class="mt-2"
          color="accent"
          block
          depressed
          :disabled="
            (selectedAddressId === null && form.add1 === '') || form.add1 === ''
          "
          @click="saveChanges"
        >
          Save Changes
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import dayjs from "dayjs"
import { mapActions, mapGetters } from "vuex"

import { changesSaved, somethingWentWrong } from "@/helpers/utilities"
import api from "@/store/api"

export default {
  name: "CreditInfoSc",
  data() {
    return {
      fromDateRules: [(value) => !!value || "Please Pick a date"],
      toDateRules: [(value) => !!value || "Please Pick a date"],
      errorMessages: "",
      formHasErrors: false,

      employeeDetails: {},
      addresses: [],
      addressList: [],
      postcodeSelected: null,
      inCorrectPostcodeSelected: false,
      showPostcode: false,
      showAddAddress: false,
      menuDOB: false,
      menuFromDate: false,
      menuToDate: false,
      contactAddressesSC: [],
      editMode: false,
      selectedAddressId: null,
      selectedAddressForEdit: {},

      oldAdd1: "",
      oldAdd2: "",
      oldTown: "",
      oldCounty: "",
      oldPostcode: "",

      form: {
        dateOfBirth: "",
        dateOfBirthText: "",
        dateFrom: "",
        dateOfFromText: "",
        dateTo: "",
        dateOfToText: "",
        add1: "",
        add2: "",
        town: "",
        county: "",
        postcode: "",
        fromDate: "",
        toDate: "",
      },
      formattedDate: {
        date: true,
        datePattern: ["d", "m", "Y"],
        delimiter: "/",
      },
    }
  },
  components: {},
  props: {
    contactId: Number,
    selectedAddressIdProp: {
      type: Number,
      default: null,
    },
  },
  watch: {
    async selectedAddressIdProp() {
      console.log(this.selectedAddressIdProp, "id")
      if (this.contactAddresses && this.contactAddresses.length > 0) {
        this.selectAddressOnChange(this.selectedAddressIdProp)
      } else {
        await this.fetchAddressesByContactId()
        this.selectAddressOnChange(this.selectedAddressIdProp)
      }
    },
    contactId: function (newValue, oldValue) {
      if (newValue !== oldValue) {
        if (this.contactAddresses && this.contactAddresses.length > 0) {
          this.addNewAddressFormShow(false)
        } else {
          this.addNewAddressFormShow(false)
          this.fetchAddressesByContactId()
        }
      }
    },
    birthDate() {
      this.birthDate = this.birthDate
        .replace(/[^0-9]/g, "")
        .replace(/^(\d{2})(\d{2})?(\d{4})?/g, "$1/$2/$3")
    },
  },
  mounted() {
    if (this.contactAddresses && this.contactAddresses.length > 0) {
      this.setAddressDetails()
    } else {
      this.fetchAddressesByContactId()
    }
  },
  computed: {
    ...mapGetters("contacts", ["contactAddresses"]),
    formattedDateOfBirth() {
      if (this.form.dateOfBirth === undefined) {
        return ""
      }
      return this.form.dateOfBirth !== "" && this.form.dateOfBirth !== 0
        ? this.form.dateOfBirth
        : ""
    },
    formattedFromDate() {
      return this.form.fromDate !== "" && this.form.fromDate !== 0
        ? dayjs(this.form.fromDate).format("DD/MM/YYYY")
        : ""
    },
    formattedToDate() {
      return this.form.toDate !== "" && this.form.toDate !== 0
        ? dayjs(this.form.toDate).format("DD/MM/YYYY")
        : ""
    },
  },
  methods: {
    ...mapActions("addresses", ["fetchAddressesByPostcode"]),
    ...mapActions("notes", ["fetchNotes"]),
    setAddressDetails() {
      let singleAddress = ""
      const addresses = []

      const tempAddress = this.contactAddresses
      this.contactAddressesSC = this.contactAddresses
      tempAddress.forEach((address) => {
        singleAddress = ""
        address.add1
          ? (singleAddress += address.add1 + ", ")
          : (singleAddress += "")
        address.add2
          ? (singleAddress += address.add2 + ", ")
          : (singleAddress += "")
        address.town
          ? (singleAddress += address.town + (address.county ? ", " : ""))
          : (singleAddress += "")
        address.county
          ? (singleAddress += address.county)
          : (singleAddress += "")

        addresses.push({ text: singleAddress, value: address.addressId })
      })
      this.addressList = addresses
    },
    async fetchAddressesByContactId() {
      if (this.contactId) {
        const response = await api.get(
          `addressesByContactId/${this.contactId}`
        )
        let singleAddress = ""
        const addresses = []
        const tempAddress = response.data
        this.contactAddressesSC = response.data
        tempAddress.forEach((address) => {
          singleAddress = ""
          address.add1
            ? (singleAddress += address.add1 + ", ")
            : (singleAddress += "")
          address.add2
            ? (singleAddress += address.add2 + ", ")
            : (singleAddress += "")
          address.town
            ? (singleAddress += address.town + (address.county ? ", " : ""))
            : (singleAddress += "")
          address.county
            ? (singleAddress += address.county)
            : (singleAddress += "")

          addresses.push({ text: singleAddress, value: address.addressId })
        })
        this.addressList = addresses
      }
    },
    dateOfBirthChanged() {
      this.form.dateOfBirthText = dayjs(this.form.dateOfBirth).format(
        "DDMMYYYY"
      )
      const vCleaveBirthDateElements =
        document.querySelectorAll("#vCleaveBirthDate")
      vCleaveBirthDateElements[1].value = dayjs(this.form.dateOfBirth).format(
        "DD/MM/YYYY"
      )
    },
    dateOfBirthTextChanged() {
      const dateOfBirthText = this.form.dateOfBirthText
        .replace(/[^0-9]/g, "")
        .replace(/^(\d{2})(\d{2})?(\d{4})?/g, "$1/$2/$3")
      this.form.dateOfBirth = dayjs(dateOfBirthText).format("YYYY-MM-DD")
      if (this.form.dateOfBirth === "Invalid Date") {
        this.form.dateOfBirth = ""
      }
    },
    dateOfFromChanged() {
      this.form.dateOfFromText = dayjs(this.form.fromDate).format("DD/MM/YYYY")
    },
    dateOfFromTextChanged() {
      const dateOfFromText = this.form.dateOfFromText
        .replace(/[^0-9]/g, "")
        .replace(/^(\d{2})(\d{2})?(\d{4})?/g, "$1/$2/$3")
      this.form.fromDate = dayjs(dateOfFromText).format("YYYY-MM-DD")
      if (this.form.fromDate === "Invalid Date") {
        this.form.fromDate = ""
      }
    },
    dateOfToChanged() {
      this.form.dateOfToText = dayjs(this.form.toDate).format("DD/MM/YYYY")
    },
    dateOfToTextChanged() {
      const dateOfToText = this.form.dateOfToText
        .replace(/[^0-9]/g, "")
        .replace(/^(\d{2})(\d{2})?(\d{4})?/g, "$1/$2/$3")
      this.form.toDate = dayjs(dateOfToText).format("YYYY-MM-DD")
      if (this.form.toDate === "Invalid Date") {
        this.form.toDate = ""
      }
    },
    postCodeChanged() {
      this.form.postcode = this.form.postcode.toUpperCase()
    },
    async fetchPostCodeAddresses() {
      const response = await this.fetchAddressesByPostcode(this.form.postcode)
      if (response.status === 200) {
        this.inCorrectPostcodeSelected = false
        this.showPostcode = true
        this.addresses = response.addresses
      } else {
        this.inCorrectPostcodeSelected = true
        this.showPostcode = false
      }
    },

    addressSelected() {
      const splitPostCode = this.postcodeSelected.split(",")
      if (this.postcodeSelected !== "Select your address") {
        this.form.add1 = splitPostCode[0].trim()
        this.form.add2 = splitPostCode[1].trim()
        this.form.town = splitPostCode[2].trim()
        splitPostCode.length - 1 === 3
          ? (this.form.county = splitPostCode[3].trim())
          : (this.form.county = "")
      }
    },
    dateFormatIntoSeconds(date) {
      let formattedDate = new Date(date)
      formattedDate = formattedDate.getTime() / 1000
      return formattedDate
    },
    async deleteSelectedAddress() {
      const response = await api.post(
        `deleteHomeAddress/${this.selectedAddressId}`
      )
      if (response.status === 200) {
        this.addressList = this.addressList.filter(
          (address) => address.value !== this.selectedAddressId
        )
        this.addNewAddressFormShow(false)
        changesSaved()
      }
    },
    isAgeGreaterThan18() {
      const date1 = dayjs("2019-01-25")
      const date2 = date1.diff("2018-06-05", "month")
      console.log(date2)
      // if(this.form.dateOfBirth !== 'Invalid Date') {
      //     const dateOfBirth = dayjs(this.form.dateOfBirth).format('YYYY-MM-DD')
      //     const now = dayjs().format('YYYY-MM-DD')

      //     const age = dateOfBirth.diff(now, 'year')
      //     console.log('age', age)
      //     console.log(age.format('YYYY'))
      //     return true
      // }
      // return false
    },
    async saveChanges() {
      console.log("call 1")
      if (!this.form.dateOfToText || !this.form.dateOfFromText) {
        const form = {
          dateOfToText: this.form.dateOfToText,
          dateOfFromText: this.form.dateOfFromText,
        }
        this.formHasErrors = false

        this.isSubmitting = false
        Object.keys(form).forEach((f) => {
          if (!this.form[f]) this.formHasErrors = true

          this.$refs[f].validate(true)
        })
        console.log("call 2")

        return false
      }
      this.isAgeGreaterThan18()
      // console.log(resp)
      // const now = dayjs().format('YYYY-MM-DD')
      // const dateOfBirth = dayjs(this.form.dateOfBirth).format('YYYY-MM-DD')

      // if(!this.form.dateOfFromText || this.form.dateOfFromText === 'Invalid Date' || !this.form.dateOfToText || this.form.dateOfToText === 'Invalid Date') {
      //     const form = {
      //         dateOfFromText: this.form.dateOfFromText,
      //         dateOfToText: this.form.dateOfToText,
      //     }
      //     this.formHasErrors = false

      //     Object.keys(form).forEach(f => {
      //         if (!this.form[f]) this.formHasErrors = true
      //         console.log(this.$refs)
      //         this.$refs[f].validate(true)
      //     })

      //     return false
      // }
      let noteMessage = ""

      if (this.editMode) {
        const add1Changed =
          this.oldAdd1 === this.form.add1
            ? ""
            : `${this.oldAdd1} changed to ${this.form.add1}<br>`
        const add2Changed =
          this.oldAdd2 === this.form.add2
            ? ""
            : `${this.oldAdd2} changed to ${this.form.add2}<br>`
        const townChanged =
          this.oldTown === this.form.town
            ? ""
            : `${this.oldTown} changed to ${this.form.town}<br>`
        const countyChanged =
          this.oldCounty === this.form.county
            ? ""
            : `${this.oldCounty} changed to ${this.form.county}<br>`
        const postcodeChanged =
          this.oldPostcode === this.form.postcode
            ? ""
            : `${this.oldPostcode} changed to ${this.form.postcode}<br>`

        noteMessage =
          add1Changed ||
          add2Changed ||
          townChanged ||
          countyChanged ||
          postcodeChanged
            ? `Address Edited - ${add1Changed}
                        ${add2Changed}
                        ${townChanged}
                        ${countyChanged}
        
                        ${postcodeChanged}`
            : ""
      } else {
        noteMessage = `Address Added - ${this.form.add1}<br>
                        ${this.form.add2}<br>
                        ${this.form.town}<br>
                        ${this.form.county}<br>
        
                        ${this.form.postcode}<br>`
      }

      const addressDetails = {
        addressId: this.editMode ? this.selectedAddressId : 0,
        siteName: this.form.add1,
        add1: this.form.add1,
        add2: this.form.add2,
        town: this.form.town,
        county: this.form.county,
        postcode: this.form.postcode,
        noteMessage: noteMessage,
        companyId: parseInt(this.$store.getters["company/companyId"]),
        employeeId: this.$store.getters["employee/userId"],
        addressTypeId: this.editMode
          ? this.selectedAddressForEdit.addressTypeId
          : 0,
        fromDate:
          this.form.fromDate === ""
            ? 0
            : this.dateFormatIntoSeconds(this.form.fromDate),
        untilDate:
          this.form.toDate === ""
            ? 0
            : this.dateFormatIntoSeconds(this.form.toDate),
        leadId: this.editMode ? this.selectedAddressForEdit.leadId : 0,
        oldAddressId: this.editMode
          ? this.selectedAddressForEdit.oldAddressId
          : 0,
        primaryAddress: this.editMode
          ? this.selectedAddressForEdit.primaryAddress
          : true,
        active: this.editMode ? this.selectedAddressForEdit.active : true,
        cotDate: this.editMode ? this.selectedAddressForEdit.cotDate : 0,
        currentUserId: 0,
        contactId: this.contactId,
        dob:
          this.form.dateOfBirth === ""
            ? 0
            : this.dateFormatIntoSeconds(this.form.dateOfBirth),
      }
      const response = await api.post("updateHomeAddress", addressDetails)
      if (response.status === 200) {
        this.fetchAddressesByContactId()
        if (!this.editMode) {
          this.addNewAddressFormShow(false)
        }
        this.fetchNotes()
        changesSaved()
        this.$emit("fetchContactAddress")
      } else {
        somethingWentWrong()
      }
    },
    selectAddressOnChange(value) {
      this.$emit("selected-address-id", value)
      this.selectedAddressId = value
      const filteredSelectedAddress = this.contactAddressesSC.filter(
        (address) => address.addressId === value
      )
      this.showAddAddress = true
      this.editMode = true
      this.selectedAddressForEdit = filteredSelectedAddress[0]
      this.form.add1 = this.selectedAddressForEdit.add1.trim()
      this.form.add2 = this.selectedAddressForEdit.add2.trim()
      this.form.town = this.selectedAddressForEdit.town.trim()
      this.form.county = this.selectedAddressForEdit.county.trim()
      this.form.postcode = this.selectedAddressForEdit.postcode.trim()
      this.form.fromDate =
        this.selectedAddressForEdit.fromDate !== 0
          ? dayjs(this.selectedAddressForEdit.fromDate * 1000).format(
              "YYYY-MM-DD"
            )
          : ""

      // this.contactAddressesSC.forEach((addressArray) => {
      //     addressArray.untilDate
      // })
      this.$emit(
        "selected-home-address",
        this.form.add1 +
          " " +
          this.form.add2 +
          " " +
          this.form.town +
          " " +
          this.form.county +
          " " +
          this.form.postcode
      )

      this.oldAdd1 = this.selectedAddressForEdit.add1
      this.oldAdd2 = this.selectedAddressForEdit.add2
      this.oldTown = this.selectedAddressForEdit.town
      this.oldCounty = this.selectedAddressForEdit.county
      this.oldPostcode = this.selectedAddressForEdit.postcode

      this.form.toDate =
        this.selectedAddressForEdit.untilDate !== 0
          ? dayjs(this.selectedAddressForEdit.untilDate * 1000).format(
              "YYYY-MM-DD"
            )
          : ""
      this.form.dateOfBirth =
        this.selectedAddressForEdit.dob !== 0
          ? dayjs(this.selectedAddressForEdit.dob * 1000).format("YYYY-MM-DD")
          : ""

      setTimeout(() => {
        this.form.dateOfBirthText = dayjs(this.form.dateOfBirth).format(
          "DD/MM/YYYY"
        )
        if (this.form.dateOfBirthText !== "Invalid Date") {
          const vCleaveBirthDateElements =
            document.querySelectorAll("#vCleaveBirthDate")
          vCleaveBirthDateElements[1].value = dayjs(
            this.form.dateOfBirth
          ).format("DD/MM/YYYY")
        }

        this.form.dateOfFromText = dayjs(this.form.fromDate).format(
          "DD/MM/YYYY"
        )
        if (this.form.dateOfFromText === "Invalid Date") {
          this.form.dateOfFromText = ""
        }
        this.form.dateOfToText = dayjs(this.form.toDate).format("DD/MM/YYYY")
        if (this.form.dateOfToText === "Invalid Date") {
          this.form.dateOfToText = ""
        }
      }, 200)
    },
    addNewAddressFormShow(showAddAddressForm = true) {
      if (this.showAddAddress && showAddAddressForm && !this.editMode) {
        if (this.form.add1 !== "") {
          this.saveChanges()
          return false
        }
      }

      this.form.add1 = ""
      this.form.add2 = ""
      this.form.town = ""
      this.form.county = ""
      this.form.postcode = ""
      this.form.fromDate = ""
      this.form.toDate = ""
      this.form.dateOfBirth = ""
      this.form.dateOfToText = ""
      this.form.dateOfFromText = ""
      this.showAddAddress = showAddAddressForm
      this.editMode = false
      this.addresses = []
      this.showPostcode = false
      this.postcodeSelected = null
    },
  },
}
</script>
