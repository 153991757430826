<template>
  <v-card v-if="details.companyActiveStatus === 0" outlined rounded="lg">
    <!-- <v-card-title class="py-2"> -->
    <div class="py-4 px-6">
      <v-row class="mt-4">
        <v-col>
          <div class="d-flex justify-center">
            <h2>This Company Is No Longer Active</h2>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <CompanySearch></CompanySearch>
        </v-col>
      </v-row>
    </div>
    <!-- </v-card-title> -->
  </v-card>
</template>
<script>
import { mapGetters } from 'vuex'
import CompanySearch from '../company-search.vue'

export default {
    components: { CompanySearch },
    computed: {
    ...mapGetters("company", ["details"]),
    }
}
</script>