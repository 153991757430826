<template>
  <v-card color="blue lighten-5" flat>
    <v-card-text>
      <v-container>
        <span class="subtitle-1 font-weight-medium">
          Standing Charge Commission</span
        >
        <v-row>
          <v-col cols="4">
            <v-text-field
              suffix="p"
              disabled
              label="Standing Charge Uplift"
              v-model="localStandingChargeUplift"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-slider
              color="blue"
              min="0"
              :step="scIncUplift"
              :max="scMaxUplift"
              thumb-label
              v-model="localStandingChargeUplift"
              @change="onStandingChargeUpliftChange"
            ></v-slider>
          </v-col>
        </v-row>
        <v-row align="center" justify="space-between">
          <v-col cols="4">
            <span class="subtitle-1 font-weight-medium">Base Prices</span>
            <div>Standing Charge</div>
            <v-text-field
              suffix="p/day"
              v-model="localStandingCharge.baseRate"
              @input="onBaseRateChange"
              @keypress="isSortCodeNumberKey"
              oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1').replace(/(\.\d{3}).+/g, '$1');"
            ></v-text-field>
          </v-col>
          <v-col cols="4" align-self="end" style="margin-top: 19px !important">
            <span class="subtitle-1 font-weight-medium">Prices</span>

            <div style="margin-bottom: -18px; transform: translateY(-3px)">
              Standing Charge
            </div>
            <v-text-field
              suffix="p/day"
              disabled
              v-model="localStandingCharge.price"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    localStandingChargeUplift: 0,
    localStandingCharge: {
      baseRate: null,
      price: 0,
    },
  }),
  props: {
    standingChargeUplift: {
      type: Number,
      default: 0,
    },
    standingCharge: {
      type: Number,
      default: null,
    },
    scMaxUplift: {
      type: Number,
      default: 0,
    },
    scIncUplift: {
      type: Number,
      default: 0,
    },
    quoteTypePriceList: {
      type: Array,
    },
  },
  computed: {
    standingCharges() {
      return this.quoteTypePriceList.filter((x) => x.quoteTypePriceId === 1)
    },
  },
  mounted() {
    this.localStandingChargeUplift = this.standingChargeUplift
  },
  methods: {
    isSortCodeNumberKey(evt) {
      const charCode = evt.which ? evt.which : evt.keyCode
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.returnValue = false
        return false
      } else {
        if (
          this.localStandingCharge &&
          this.localStandingCharge.baseRate &&
          this.localStandingCharge.baseRate.length >= 8
        ) {
          event.returnValue = false
          return false
        }
        event.returnValue = true
        return true
      }
    },
    onStandingChargeUpliftChange(value) {
      this.$emit("standing-charge-uplift-changed", value)
      this.localStandingCharge.price =
        parseFloat(
          this.localStandingCharge.baseRate
            ? this.localStandingCharge.baseRate
            : 0
        ) + parseFloat(this.localStandingChargeUplift)
      this.quoteTypePriceList.forEach((q) => {
        if (q.quoteTypePriceId === 1) {
          q.price = this.localStandingCharge.price
          q.uplift = parseFloat(this.localStandingChargeUplift)
        }
      })
    },
    onBaseRateChange() {
      const price =
        parseFloat(
          this.localStandingCharge.baseRate
            ? this.localStandingCharge.baseRate
            : 0
        ) + parseFloat(this.localStandingChargeUplift)
      this.localStandingCharge.price = parseFloat(price).toFixed(2)
      this.quoteTypePriceList.forEach((q) => {
        if (q.quoteTypePriceId === 1) {
          q.price = this.localStandingCharge.price
          q.baseRate = this.localStandingCharge.baseRate
            ? parseFloat(this.localStandingCharge.baseRate)
            : null
        }
      })
      this.localStandingCharge.baseRate = this.localStandingCharge.baseRate
        ? this.localStandingCharge.baseRate
            .replace(/[^0-9.]/g, "")
            .replace(/(\..*?)\..*/g, "$1")
            .replace(/(\.\d{3}).+/g, "$1")
        : null
      //    var index = this.quoteTypePriceList.findIndex(x=>x.quoteTypePriceId === 1)
      //    this.quoteTypePriceList[index].price = parseFloat( this.quoteTypePriceList[index].baseRate?  this.quoteTypePriceList[index].baseRate : 0) + parseFloat(this.localStandingChargeUplift)
    },
  },
}
</script>
