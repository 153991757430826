<template>
  <div class="mt-4">
    <v-row>
      <v-col>
        <v-text-field
        value="Telephone Number"
        readonly
        required
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
        v-model="searchText"
        append-icon="mdi-magnify"
        @input="startFetching"
        required
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div>
          <v-row v-for="c in companies" :key="c.companyId" align-content="center">
            <v-col cols="auto">
              <a @click="fetchCompany(c.companyId)" style="text-decoration:none; color:#ed6e92">
                {{ c.companyId }}
              </a>
            </v-col>
            <v-col cols="auto">
              {{ c.companyName }}
            </v-col>
            <v-col >
              {{ c.primaryContact }}
            </v-col>
            <v-col cols="auto">
              <div>
                <div>{{ c.add1 }}</div>
                <div>{{ c.add2 }}</div>
                <div>{{ c.town }}</div>
                <div>{{ c.county }}</div>
                <div>{{ c.postcode }}</div>
              </div>
            </v-col>
            <v-col cols="2">
              {{ c.telephone }}
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex"

import axios from 'axios'
import api from "@/store/api"

export default {
    props: {
      isActive: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        selected: 2,
        searchText: '',
        companies: [],
        details: {},
        connexUser: '',
      }
    },
    computed: {
    ...mapGetters("company", ["companyId"]),
    },
    created(){
    const urlParams = new URLSearchParams(window.location.search)
      const user = urlParams.get("u")
      this.connexUser = user
    },
    mounted(){
        this.startFetching()
      this.fetchCompanyMainDetails()
    },
    methods: {
      ...mapActions("company", ["fetchCompanyDetails"]),
      ...mapActions("forms", ["fetchMainDataImmediately"]),

      fetchCompany(companyId) {
        this.fetchCompanyDetails(companyId)
        this.fetchMainDataImmediately(companyId)
      },
      async fetchCompanyMainDetails(){
        const response = await api.get(`mainData/${this.companyId}`)
        if(response.status === 200){
            // this.details = response.data.mainData
            this.searchText = response.data.mainData.telephone

            // this.searchText = this.details.telephone
        }
      },
      async fetchSales() {
        const apiUrl = 'https://apidev.utilityhive.com/api/'
        const res = await axios.post(`${apiUrl}General/searchCompany`, {
         searchText: this.searchText,
          type: this.selected,
          activeOnly: true
        })
        if (res.status === 200) {
          this.companies = res.data
        }
      },
    startFetching() {
      if (this.selected === 2  ||  this.searchText.length >= 3) {
        setTimeout(() => {
          this.fetchSales()
        }, 1000)
      } else {
        this.companies = []
      }
    },
    }
}
</script>