<template>
  <v-card
    height="100%"
    class="d-flex flex-column"
    flat
    :color="color"
    rounded="lg"
    light
  >
    <v-card-title class="subtitle-1 font-weight-medium">
      {{ name }}
    </v-card-title>

    <v-card-subtitle class="blue-grey--text text--darken-2">
      {{ jobRole }}
    </v-card-subtitle>

    <v-card-text class="blue-grey--text" style="font-size: 13px">
      <v-row dense>
        <v-col cols="12">
          <div>
            <v-icon v-if="telephone" class="mr-1" small>mdi-phone</v-icon>
            {{ telephone }}
          </div>
        </v-col>
        <v-col cols="12" v-if="email">
          <div>
            <v-icon class="mr-1" small>mdi-email</v-icon>
            {{ email }}
          </div>
        </v-col>
      </v-row>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-row justify="center">
        <v-col cols="auto">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <CreditInfo
                  :primary-contact="primary"
                  :full-name="name"
                  :date-of-birth="dateOfBirth"
                  :contact-id="contactId"
                />
              </div>
            </template>
            <span>Credit Information</span>
          </v-tooltip>
        </v-col>
        <v-col cols="auto">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <Password
                  :primary-contact="primary"
                  :full-name="name"
                  :password="password"
                  :contact-id="contactId"
                />
              </div>
            </template>
            <span>Security Password</span>
          </v-tooltip>
        </v-col>
        <v-col cols="auto">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <CustomerInteraction :contact-id="contactId" />
              </div>
            </template>
            <span>Customer Interaction</span>
          </v-tooltip>
        </v-col>
        <v-col cols="auto">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="blue"
                v-bind="attrs"
                v-on="on"
                depressed
                icon
                small
                @click.stop="editContact"
              >
                <v-icon small>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Edit Contact</span>
          </v-tooltip>
        </v-col>
        <v-col cols="auto">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" depressed icon small>
                <div>
                  <ConfirmDelete
                    element-type="contact"
                    :body-content="name"
                    @delete-confirmed="handleDeleteContact"
                  />
                </div>
              </v-btn>
            </template>
            <span>Delete Contact</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex"
import { changesSaved, somethingWentWrong } from "@/helpers/utilities"

export default {
  components: {
    CreditInfo: () => import("@/components/dialogs/credit-info"),
    Password: () => import("@/components/dialogs/password"),
    ConfirmDelete: () => import("@/components/dialogs/confirm-delete"),
    CustomerInteraction: () =>
      import("@/components/dialogs/customer-interaction"),
  },
  // mounted() {
  //   this.userId = this.$store.state
  // },
  props: {
    contactId: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    jobRole: {
      type: String,
      default: "",
    },
    telephone: {
      type: String,
      default: "",
    },

    email: {
      type: String,
      default: "",
    },
    password: {
      type: String,
      default: "",
    },
    dateOfBirth: {
      type: Number,
      default: () => 0,
    },
    primary: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      telephoneValidation: [
        (v) => /[0-9]+$/.test(v) || "Only Numeric Characters Allowed.",
        (v) =>
          (v && v.length <= 10) ||
          "Phone Number must have at least 10 letters.",
      ],
    }
  },
  computed: {
    ...mapGetters("employee", ["userId"]),
    ...mapGetters("company", ["details", "companyId"]),

    color() {
      return this.primary ? "green lighten-5" : "blue lighten-5"
    },
  },
  methods: {
    ...mapActions("contacts", ["fetchContacts"]),
    ...mapMutations("contacts", {
      selectContact: "setSelectedContact",
    }),
    ...mapActions("ui", ["openForm"]),
    ...mapActions("contacts", ["deleteContactById", "fetchContactAddresses"]),
    ...mapActions("notes", ["fetchNotes"]),
    ...mapActions("forms", ["fetchMainData"]),

    // async editContact() {
    //   await this.fetchContactAddresses(this.contactId)
    //   // this.selectContact(0)
    //   // setTimeout(() => {
    //   this.selectContact(this.contactId)
    //   // }, 1000)

    //   this.openForm({ formName: "contact-form", edit: true })
    // },
    async editContact() {
      this.openForm({ formName: "contact-form", edit: true })
      await this.fetchContactAddresses(this.contactId)

      this.selectContact(this.contactId)
    },
    async handleDeleteContact() {
      try {
        const jobRole = this.jobRole
          ? this.jobRole === "null" || this.jobRole === null
            ? ""
            : `${this.jobRole} <br/>`
          : ""
        const telephone = this.telephone
          ? this.telephone === "null" || this.telephone === null
            ? ""
            : `${this.telephone} <br/>`
          : ""
        const email = this.email
          ? this.email === "null" || this.email === null
            ? ""
            : `${this.email}`
          : ""

        const noteMessage = `Contact  Deleted - ${this.name} <br/>
                            ${jobRole}
                            ${telephone}
                            ${email}`
        const details = {
          contactId: parseInt(this.contactId),
          userId: this.userId,
          noteMessage: noteMessage,
          companyId: this.details.companyId,
        }
        const response = await this.deleteContactById(details)
        if (response && response.status === 200) {
          console.log(response)
          this.details = ""
          changesSaved("Contact Removed Successfully!")
          setTimeout(() => {
            this.fetchNotes()
            this.fetchMainData(this.companyId)
            this.fetchContacts()
          }, 1000)
        }
      } catch (err) {
        somethingWentWrong()

        console.log(err)
      }
    },
  },
}
</script>

<style></style>
