<template>
  <div class="text-center">
    <div class="icon-container">
      <div class="d-flex justify-center items-center" style="width:30px; height: 30px; border-radius: 100%" :style="bgColor">
        <v-icon size="20" color="white">{{ icon }}</v-icon>
      </div>
      <div
      v-if="previousSale.saleStatus === 'Action Needed'"
      v-b-tooltip.hover.top="'Action Needed'"
      class="d-flex justify-center"
    >
      <span
        class="ml-2 mt-1 d-flex justify-center"
        :class="deadlineColor(previousSale.actionNeededDeadline)"
        style="font-size: 2.2em;"
      >
        <i class="fas fa-exclamation-triangle"></i>
      </span>
    </div>
    </div>
    <div v-if="previousSale.status === 'live'" class="d-block mt-1 justify-content-center" :style="textColor">
        <p>Live</p>
    </div>
    
  <div v-if="previousSale.status === 'failed'" class="mt-2">
    <p style="margin-bottom:0;line-height:14px">
      {{ previousSale.failedReason }}
    </p>
  </div>

  <div
    v-if="previousSale.saleStatus && previousSale.saleStatus !== 'Action Needed'"
    class="mt-1 estimate-container"
  >
    <span class="text-sm text-center">{{ previousSale.saleStatus }}</span>
  </div>
  <div
    v-if="previousSale.saleStatus && previousSale.saleStatus === 'Action Needed'"
    class="mt-1 estimate-container flex-column"
  >
    <a
      class="cursor-pointer"
      style="text-decoration: none;"
      @click="openActionNeededModal(previousSale)"
    >
      <span
        class="text-sm text-center"
        :class="deadlineColor(previousSale.actionNeededDeadline)"
        >{{ previousSale.saleStatus }}</span
      >
    </a>

    <div
      class="d-flex justify-content-center mt-2 text-sm"
      :class="deadlineColor(previousSale.actionNeededDeadline)"
    >
      Deadline Date: {{ $helpers.formatDate(previousSale.actionNeededDeadline) }}
    </div>
  </div>
  </div>
</template>
<script>

import dayjs from 'dayjs'
export default {
  props: {
    status: {
      type: String,
      default: ''
    },
    previousSale: {
      type: Object,
      default:null,
    }
  },
  computed: {
    icon(){
      if (this.status === "processing"){
        return 'mdi-cogs'
      }
      if (this.status === "qualityCheck"){
        return 'mdi-headphones'
      }
      if (this.status === "failed"){
        return 'mdi-close-circle'
      }
      if (this.status === "live"){
        return 'mdi-chevron-down-box'
      }
      if (this.status === "declared"){
        return 'mdi-file-document-edit'
      }
      if (this.status === "sold"){
        return 'mdi-tag'
      }
      return 'mdi-cogs'
    },
    bgColor(){
      if(this.status === "qualityCheck"){
        return 'background-color: #FFC107'
      }
      if(this.status === "failed"){
        return 'background-color: #DC3545'
      }
      if(this.status === "live"){
        return 'background-color: #28A745'
      }
      if(this.status === "declared"){
        return 'background-color: #007BFF'
      }
      if(this.status === "sold"){
        return 'background-color: #9575CD'
      }
      return 'background-color: #FFA507'
    },
    textColor(){
      if(this.status === "qualityCheck"){
        return 'color: #FFC107'
      }
      if(this.status === "failed"){
        return 'color: #DC3545'
      }
      if(this.status === "live"){
        return 'color: #28A745'
      }
      if(this.status === "declared"){
        return 'color: #007BFF'
      }
      if(this.status === "sold"){
        return 'color: #9575CD'
      }
      return 'color: #FFA507'
    },
    getText(){
      if (this.status === "processing"){
        return 'Processing'
      }
      if (this.status === "qualityCheck"){
        return 'Quality Check'
      }
      if (this.status === "failed"){
        return 'Failed'
      }
      if (this.status === "live"){
        return 'Live'
      }
      if (this.status === "declared"){
        return 'Declared'
      }
      if (this.status === "sold"){
        return 'Sold'
      }
      return ''
    },
  },

  methods: {
    deadlineColor(deadline) {
      const deadlineDate = dayjs(deadline * 1000).format('YYYY-MM-DD')
      const todayDate = dayjs().format('YYYY-MM-DD')
      const dealineUnixDatte = dayjs(deadlineDate)
      const todayUnixDate = dayjs(todayDate)
      const diff = dealineUnixDatte.diff(todayUnixDate, 'day')
      if (diff === 0 || diff === 1 || diff < 0) {
        return 'danger--text'
      }

      if (diff > 1 && diff < 8) {
        return 'orange--text'
      }
      if (diff >= 8) {
        return 'primary--text'
      }
      return 'secondary--text'
    },
  }
}
</script>
<style scoped>
.icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>