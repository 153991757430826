<template>
  <div>
    <v-row
      v-if="loadingMainData"
      class="rounded-lg"
      align="center"
      style="background-color:#30425A"
      justify="center"
    >
      <LoadingSpinner height="71" />
    </v-row>
    <v-row
      v-if="!loadingMainData"
      class="rounded-lg"
      align="center"
      style="background-color:#30425A"
      rounded="lg"
    >
      <v-col cols="auto" v-if="!loadingMainData">
        <div>
          <div
            class="text-button"
            style="font-size:11px;color: var(--v-accent-base);"
          >
            Company ID
          </div>
          <DataValue :value="companyId" :custom-class="className" />
        </div>
      </v-col>
      <v-col cols="auto" v-if="!loadingMainData">
        <div>
          <div
            class="text-button"
            style="font-size:11px;color: var(--v-accent-base);"
          >
            Company Name
          </div>
          <DataValue :value="mainData.companyName" :custom-class="className" />
        </div>
      </v-col>
      <v-col cols="auto" v-if="!loadingMainData">
        <div>
          <div
            class="text-button"
            style="font-size:11px; color: var(--v-accent-base);"
          >
            Data Source
          </div>
          <DataValue :value="mainData.dataSource" :custom-class="className" />
        </div>
      </v-col>
      <v-col cols="auto" v-if="!loadingMainData">
        <div>
          <div
            class="text-button"
            style="font-size:11px; color: var(--v-accent-base);"
          >
            Data Inserted
          </div>
          <DataValue
            :value="$helpers.dateTime(mainData.createdDate)"
            :custom-class="className"
          />
        </div>
      </v-col>
      <v-col cols="auto" v-if="!loadingMainData">
        <div
          class="text-button"
          style="font-size:11px; color: var(--v-accent-base);"
        >
          Primary Phone Number
        </div>
        <div>
          <DataValue :value="mainData.telephone" :custom-class="className" />
        </div>
      </v-col>
      <v-col cols="auto" v-if="!loadingMainData">
        <div
          class="text-button"
          style="font-size:11px; color: var(--v-accent-base);"
        >
          Primary Contact
        </div>
        <div>
          <DataValue
            :value="
              mainData.firstName === null && mainData.lastName === null
                ? 'No Primary Contact Found'
                : mainData.firstName + ' ' + mainData.lastName
            "
            :custom-class="className"
          />
        </div>
      </v-col>
      <v-col v-if="!metersData.length && !loadingMainData">
        <div
          class="text-button "
          style="font-size:11px; color: var(--v-accent-base);"
        >
          Suppliers
        </div>
        <div class="d-flex white--text">
          No Suppliers Found
        </div>
      </v-col>
      <v-col v-if="metersData.length && !loadingMainData">
        <div
          class="text-button"
          style="font-size:11px; color: var(--v-accent-base);"
        >
          Suppliers
        </div>
        <div class="d-flex">
          <div v-for="(pm, index) in metersData" :key="index">
            <DataValue
              class="mr-1 gray--text"
              :value="pm.supplierName"
              style="font-size:12.5px"
              :custom-class="className"
            />
          </div>
        </div>
      </v-col>
      <!-- {{permission}}  -->
      <!-- Deactivate buttin -->
      <v-col style="max-width:85px; !important" v-if="permission.deactivatecompany">
        <v-dialog
          v-model="dialog"
          max-width="290"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn 
              color="error" 
              v-bind="attrs"
              v-on="on" 
              fab
            >
              <i class="fas fa-user-times light--text" style="font-size: 20px;"></i>
            </v-btn>
          </template>
          <v-card>
            <v-card-title class="text-h5">
              Dectivation Confirmation 
            </v-card-title>
            <v-card-text>Please confirm that you want to de-active this company along with any associated Contacts, Addresses and Meters ?</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="green darken-1"
                text
                @click="deactivateCompany"
              >
                Confirm
              </v-btn>
              <v-btn
                color="red darken-1"
                text
                @click="dialog = false"
              >
                Close
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
      
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import LoadingSpinner from "@/components/loading-spinner"
import DataValue from "@/components/data-value"
import api from '@/store/api'
import { changesSaved, somethingWentWrong } from "@/helpers/utilities"

export default {
  components: { DataValue, LoadingSpinner },
  data() {
    return {
      className: "white--text",
      dialog:false,
      permission: {},
    }
  },
  computed: {
    ...mapGetters("forms", ["mainData", "metersData", "loadingMainData"]),
    ...mapGetters("company", ["companyId"]),
    ...mapGetters('employee', ['userId']),
  },
  created() {},
  mounted() {
    this.fetchMainDataImmediately(this.companyId)
    this.fetchPermission()
  },
  methods: {
    ...mapActions("forms", ["fetchMainDataImmediately"]),
    async fetchPermission() {
          const pagePermission = this.permission
          const res = await api.get(
           `permission/${this.userId}`
          )
          if (res.status === 200) {
            const result = res.data
            for (var p in result) {
              console.log(result[p])
              pagePermission[result[p].name] = result[p].hasPermission
            }
          }
        },
    async deactivateCompany() {
      const res = await api.post(`companyDeactive/${this.companyId}`)
      console.log(res)

      if(res && res.status === 200) {
        changesSaved("Company Successfully Deactivated")
        this.$forceUpdate();
      }else {
        somethingWentWrong()
      }

    }
  }
}
</script>
