<template>
  <v-row :align="rowAlignment" dense>
    <v-col cols="auto" v-if="icon">
      <v-avatar :size="iconSize" :color="value === 'Unassigned' ? 'red--text': color" @click="iconClicked">
        <img v-if="userAvatar"  :src="userAvatar" :alt="`Employee ${userId}`" @error="$helpers.onProfilePictureError"/>
        <div v-else>
          <v-icon v-if="userId === 0"  dark   :color="userId === 0 ? 'red': ''" size="50" >mdi-{{ icon }}</v-icon>
          <v-icon v-else   dark small  :color="userId === 0 ? 'red': ''" >mdi-{{ icon }}</v-icon>
        </div>
      </v-avatar>
    </v-col>
    <v-col v-if="target !== ''">
      <div class="grey--text" style="font-size:11px">{{ label }}</div>
      <div>
        <a :href="href" :target="target" onclick="window.open(href,'name','width=1200,height=1000')">{{ localValue }}</a>
      </div>
    </v-col>
    <v-col v-else>
      <div class="grey--text" style="font-size:11px">{{ label }}</div>
      <div class="font-weight-bold" style="font-size:11px">{{ boldLabel }}</div>
      <div>
        <div v-if="addressType">
          <div>{{ value.firstLine }}</div>
          <div>{{ value.secondLine }}</div>
          <div>{{ value.town }}</div>
          <div>{{ value.county }}</div>
          <div>{{ value.postcode }}</div>
        </div>
        <div v-else>
          <a v-if="href" :href="href" >{{ localValue }}</a>
          <span v-else :class="customClass">{{ value === 'Unassigned' ? 'No Closer Assigned' : localValue }}</span>
        </div>
      </div>
      <div v-if="extraInfo" class=" grey--text" style="font-size:11px">
        {{ extraInfo }}
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: ""
    },
    value: {
      type: [String, Number, Object],
      dafault: ""
    },
    extraInfo: {
      type: [String, Number],
      default: ""
    },
    icon: {
      type: String
    },
    userId: {
      type: String,
      default: ""
    },
    href: {
      type: String
    },
    target: {
      type: String,
      default: ""
    },
    iconColor: {
      type: String
    },
    prefix: {
      type: String,
      default: ""
    },
    suffix: {
      type: String,
      default: ""
    },
    addressType: {
      type: Boolean,
      default: false
    },
    customClass: {
      type: String,
      default: ""
    },
     profilePic: {
      type: String,
      default: ""
    },
    boldLabel: {
      type: String,
      default: null,
    },
  },
  computed: {
    color() {
      return this.iconColor || "indigo lighten-4"
    },
    localValue() {
      return `${this.prefix} ${this.value || ""} ${this.suffix}`.trim()
    },
    userAvatar() {
      // if(this.profilePic !== '') return this.profilePic
      return this.userId ? this.$helpers.profilePicture(this.userId) : null

    },
    iconSize() {
      return this.userId || this.userId === 0  ? "50" : "32"
    },
    rowAlignment() {
      const isAddress = this.addressType
      return isAddress && this.value.firstLine ? "start" : "center"
    }
  },
  methods: {
    iconClicked() {
      this.$emit("icon-click")
    }
  }
}
</script>

<style></style>
