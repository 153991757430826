<template>
  <v-card outlined rounded="lg" class="mt-2">
    <v-card-title class="py-2">
      <v-row align="center" justify="space-between">
        <v-col class="text-button">
          <v-icon class="mr-1" small>mdi-office-building-marker</v-icon>
          Additional Services
        </v-col>
      </v-row>
    </v-card-title>
    <v-divider></v-divider>
    <v-tabs
      v-model="tab"
      fixed-tabs 
      slider-color="accent" 
      center-active
    >
      <v-tab value="Merchant Services">Merchant Services</v-tab>
      <v-tab value="Broadband">Broadband</v-tab>
      <v-tab value="Telecoms">Telecoms</v-tab>
      <v-tab value="Commercial Waste">Commercial Waste</v-tab>
      
     
    </v-tabs>

    <div v-if="leadDetails.length > 0" >
      <div v-for="(leadDetail, index) in getFilteredServices" :key="index" >

        <v-row  v-if="!leadDetail.id" class="px-4 py-2 justify-space-between" >
        <!-- <v-row class="px-4 py-5" > -->
            <v-col v-if="leadDetail.additionalService" cols="4" class="pt-1 d-flex flex-column">
                <!-- <label 
                  class="text-secondary"
                  style="
                      z-index: 2;
                      margin-top: 0px !important;
                  "
                >Service</label> -->
                <!-- <p class="mt-1">{{ leadDetail.additionalService }}</p> -->
                <img 
                  :src="leadDetail.logoFilePath"
                  style="
                    z-index: 1;
                    width: 110px;
                    height: 50px;
                  "
                >
            </v-col>

            <v-col cols="3" style="display: flex;justify-content: center; flex-direction: column;">
              
              <!-- <div>
                {{ leadDetail.additionalService }}
              </div> -->


              <div>
                {{ leadDetail.productDescription }}
              </div>

            </v-col>
  
            <v-col cols="5" style="display: flex;justify-content: center;align-items: center;">
                <v-btn v-if="!leadDetail.leadGenCloser" depressed color="primary" size="sm" @click="submitLead(leadDetail)">
                    Submit Lead
                </v-btn>
                <v-dialog
                v-if="leadDetail.leadGenCloser"
                  v-model="dialog"
                  max-width="350"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" depressed color="primary" size="sm" >
                      Submit Sale
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title class="text-h5">
                      Please Select a Lead Generator 
                    </v-card-title>

                    <v-card-text>
                      <v-select
                        ref="uploadSelected"
                        v-model="selectedLeadGenerator"
                        size="sm"
                        :items="leadGenOptions"
                        placeholder="Please Select"
                        label="Lead Generator"
                        
                      >
                        <template slot="item" slot-scope="data">
                          <v-avatar class="" size="38">
                            <img  :src="`${userAvatarLink(data.item.profile)}`" alt="" />
                            </v-avatar>
                          <span class="ml-2"> {{ data.item.text }} </span>
                        </template>
                      </v-select>
                      <label v-if="showLeadGenerror" class="red--text">Please Select a Lead Generator</label>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="green darken-1"
                        text
                        @click="submitLead(leadDetail,true)"
                      >
                        Submit Sale
                      </v-btn>
                      <v-btn
                        color="red darken-1"
                        text
                        @click="dialog = false"
                      >
                        Close
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
            </v-col>
        </v-row>
        <v-row v-if="leadDetail.id" class="px-4 py-5 justify-space-between">
          <v-col v-if="leadDetail.additionalService" cols="4" class="pt-1 d-flex flex-column">
              <label 
                class="text-secondary"
                style="
                    z-index: 2;
                    margin-top: 0px !important;
                "
              >Service</label>
              <!-- <p class="mt-1">{{ leadDetail.additionalService }}</p> -->
              <img 
                :src="leadDetail.logoFilePath"
                style="
                  margin-top: 8px;
                  z-index: 1;
                  width: 110px;
                  height: 50px;
                "
              >
          </v-col>

          <v-col cols="3" style="margin-top: 8px; display: flex;justify-content: center; flex-direction: column;">
              
              <div>
                {{ leadDetail.additionalService }} 
              </div>

              <div>
                {{ leadDetail.productDescription }}
              </div>

            </v-col>


          <v-col cols="5">
            <div class="">
                <UserAvatar
                    class="d-flex justify-content-start"
                    :small="false"
                    :portrait="leadDetail.leadPassedBy"
                    :full-name="leadDetail.leadPassedByName"
                    :label="leadStatus(leadDetail)"
                    :caption="$helpers.formatDate(leadDetail.leadDate, true)"
                ></UserAvatar>
            </div>
          </v-col>
        </v-row>
      </div>

    </div>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import { changesSaved, copyTextToClipboard, somethingWentWrong } from "@/helpers/utilities"
import { trackerMethod } from '@/helpers/logs.js'
import UserAvatar from '@/components/user-avatar'
import dayjs from 'dayjs'
import api from '@/store/api'


export default {
  components: {
    UserAvatar
  },
  data(){
    return {
      SupplyNumber: '',
      leadDetails: {},
      dialog:false,
      selectedLeadGenerator:null,
      showLeadGenerror:false,
      tab:0,
    }
  },
  computed: {
    ...mapGetters("company", ["details", "hasRegistrationNo", "sicSection", "companyId"]),
    ...mapGetters("contacts", ["primaryContact", "primaryContactFullName", "primaryContactEmailAddress"]),
    ...mapGetters("employee", ["userId", "userName"]),

    getFilteredServices() {
      const tab = this.tab === 0 ? 'Merchant Services' : this.tab === 1 ? 'Broadband' : this.tab === 2 ? 'Telecoms' :  'Commercial Waste' 
      return this.leadDetails.filter(x => {
        return x.productDescription === tab
      })
    },

    sicIcon() {
      const section = this.sicSection
      return section ? this.$helpers.sicIcons[section] : "help"
    }
  },
  mounted() {
    this.fetchLeadDetails()
    this.getLeadGenOptions()
  },
  methods: {
    ...mapActions("ui", ["openForm"]),

    userAvatarLink(id) {
      return this.$helpers.profilePicture(id)
    },

    async getLeadGenOptions() {
      const res = await api.get("getWebActiveEmployee")
      console.log('leadgenoptions',res)
      if (res && res.status === 200) {
        // this.uploads = res.data.map(option => ({
        //   text: option.value,
        //   value: option.id
        // }))

        this.leadGenOptions = res.data.map(option => ({
          text: `${option.firstName} ${option.lastName}`,
          value: option.employeeId,
          profile: option.employeeId
        }))
      }
    },

    leadStatus(leadDetails) {
        if(leadDetails.leadDate && !leadDetails.leadSold && !leadDetails.leadAccepted) {
            return 'Lead Passed'
        }else if(!leadDetails.leadSold && leadDetails.leadAccepted) {
            return "Lead Accepted"
        }else if(leadDetails.leadSold) {
            return "Lead Sold"
        }else if(!leadDetails.leadSold) {
            return "Lead Failed"
        }
    },

    async fetchLeadDetails() {
        try {
            const response = await api.get(`getLead/${this.companyId}`)
            if (response && response.status === 200) {
                this.leadDetails = response.data
            }
        } catch (error) {
            console.log(error)
        }
        
    },
    

    async submitLead(tempLeadDetails,isSaleSubmit = false) {
        let leadDetails = {}

        if(isSaleSubmit) {
          this.showLeadGenerror = false
          if(this.selectedLeadGenerator === null ) {
            this.showLeadGenerror = true
            return false
          }

            leadDetails = {
              id: 0,
              companyID: this.companyId,
              closerID: this.userId,
              leadPassedBy: this.selectedLeadGenerator,
              leadPassedByName: this.userName,
              leadDate: dayjs().unix(),
              leadAccepted: true,
              leadSold: true,
              leadPassed: true,
              additionalServiceID: tempLeadDetails.additionalServiceID,
          }
        }else {
          leadDetails = {
              id: 0,
              companyID: this.companyId,
              leadPassedBy: this.userId,
              leadPassedByName: this.userName,
              leadDate: dayjs().unix(),
              leadAccepted: true,
              leadSold: true,
              leadPassed: true,
              additionalServiceID: tempLeadDetails.additionalServiceID,
          }
        }

          
        try {
            const response = await api.post("submitLead", leadDetails)
            if (response && response.status === 200) {
                changesSaved("Lead Successfully Submitted!")
                this.fetchLeadDetails()
            }
        } catch (error) {
            somethingWentWrong()
        }
        
    },

    handleCopy() {
      const page = window.location.href

      trackerMethod(
        this.userId,
        page,
        this.details.telephone,
        this.SupplyNumber,
        this.companyId
      )
      copyTextToClipboard(this.details.telephone)
    },
  }
}
</script>
