<template>
  <v-sheet
    class="d-flex align-center justify-center"
    color="grey lighten-4"
    height="100vh"
    width="100vw"
  >
    <v-alert
      prominent
      type="error"
      elevation="10"
      min-width="400px"
      max-width="800px"
    >
      <v-row align="center" v-if="error.status">
        <v-col class="grow">
          <h3 class="headline mb-2">
            {{ error.message }} - Error Code {{ error.status }}
          </h3>

          <div class="text-body-2">Company ID: {{ companyId }}</div>
          <div class="text-body-2">API URL: {{ error.url }}</div>
          <div class="mt-2">
            <a
              :href="
                `https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/${error.status}`
              "
              target="_blank"
              rel="noopener noreferrer"
            >
              What this code error means?
            </a>
          </div>
        </v-col>
        <v-col class="shrink">
          <v-btn @click="reloadPage">Reload Page</v-btn>
        </v-col>
      </v-row>
      <v-form v-else ref="form" class="ml-4" @submit.prevent="setParameters">
        <h3 class="headline mb-2">Error: {{ error.message }}</h3>

        <p>You can manually set company id and username</p>

        <v-text-field v-model="id" label="Company ID"></v-text-field>
        <v-text-field v-model="user" label="Connex Username"></v-text-field>
        <v-btn type="submit" :disabled="!id">
          Try Again
        </v-btn>
      </v-form>
    </v-alert>
  </v-sheet>
</template>

<script>
import { mapGetters } from "vuex"
export default {
  data: () => ({ id: "", user: "" }),
  computed: {
    ...mapGetters("company", ["companyId", "error"])
  },
  methods: {
    reloadPage() {
      window.location.reload()
    },
    setParameters() {
      window.location.href = `/?c=${this.id}&u=${this.user}`
    },
    resetForm() {
      this.$refs.form.reset()
    }
  }
}
</script>

<style></style>
