<template>
  <v-dialog v-model="visible" width="1200">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        color="info"
        depressed
        icon
        small
        @click="fetchDocuments"
      >
        <!-- <v-icon  small>fa-photos</v-icon> -->
        <i class="fas fa-images" style="font-size: 14px; color: #ffc107;"></i>
      </v-btn>
    </template>

    <v-card>
      <v-card-title style="background-color: #ffc107;color: #fff !important;">
        METER DOCUMENTS
      </v-card-title>
      <v-card-subtitle
        class="pt-1 pb-2"
        style="background-color: #ffc107;color: #fff !important;"
      >
        Document Uploads
      </v-card-subtitle>
      <v-card-text>
        <v-row class="d-flex justify-content-space-between my-2">
          <v-col> </v-col>
          <div class="d-flex justify-content-end mr-2">
            <a
              class="btn text-primary"
              style="color: #2196f3"
              @click="addDocumentSelected"
            >
              <i :class="!showForm ? 'fas fa-plus fa-lg' : 'fas fa-minus'"></i>
            </a>
          </div>
        </v-row>

        <v-row v-if="showForm">
          <v-col cols="3">
            <div class="data-displayer">
              <v-text-field
                ref="description"
                v-model="description"
                :rules="descriptionRules"
                label="Description"
                size="sm"
              ></v-text-field>
            </div>
          </v-col>
          <v-col cols="3">
            <div class="data-displayer">
              <!-- <v-select
                ref="uploadSelected"
                v-model="uploadSelected"
                size="sm"
                :rules="uploadSelectedRules"
                :items="uploads"
                placeholder="Please Select"
                label="Upload Type"

              >
              </v-select> -->
              <v-select
                ref="uploadSelected"
                v-model="uploadSelected"
                size="sm"
                :rules="uploadSelectedRules"
                :items="uploads"
                placeholder="Please Select"
                label="Upload Type"
                
              >
                <template slot="item" slot-scope="data">
                  {{ data.item.text }}
                  <v-chip
                    v-if="data.item.valid"
                    class="ma-2"
                    x-small
                    color="green"
                    text-color="white"
                  >
                    VALID
                  </v-chip>
                </template>
              </v-select>
            </div>
          </v-col>
          <v-col cols="3">
            <v-file-input
              ref="file"
              label="Choose a file"
              id="file"
              :disabled="editDocumentMode ? true : false"
              :rules="fileRules"
              v-model="file"
            ></v-file-input>
          </v-col>
          <v-col
            cols="2"
            style="display: flex;justify-content: center;align-items: center;"
          >
            <v-btn depressed color="primary" size="sm" @click="uploadContract">
              Save
            </v-btn>
          </v-col>
        </v-row>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-headers text-left">
                  File
                </th>
                <th class="text-headers text-left">
                  Description
                </th>
                <th class="text-headers text-left">
                  Upload Type
                </th>
                <th class="text-headers text-left">
                  Uploaded By
                </th>
                <th
                  v-if="
                    permission.allowdeleteonmeterdocumentuploads ||
                      permission.alloweditonmeterdocumentuploads
                  "
                  class="text-headers text-left"
                >
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="!documents.length">
                <td></td>
                <td></td>
                <td
                  colspan="10"
                  style="display: flex; justify-content: center; flex-direction: column; height: 80px;"
                >
                  <div
                    class="text-md text-secondary mt-4"
                    style="display: flex; justify-content: center"
                  >
                    There is no documents for this sale.
                  </div>
                  <div
                    class="mt-2"
                    style="display: flex; justify-content: center"
                  >
                    <v-btn
                      color="primary"
                      size="sm"
                      @click="addDocumentSelected"
                    >
                      Add New
                    </v-btn>
                  </div>
                </td>
              </tr>

              <tr
                class="p-2 "
                style="height:90px"
                v-for="document in documents"
                :key="document.saleId"
              >
                <td
                  @click="downloadDocument(document.id)"
                  style="cursor: pointer"
                >
                  <DataValue
                    value=""
                    icon-color="blue"
                    href="#"
                    icon="file-download-outline"
                  />
                </td>
                <!-- 13 -->
                <td>
                  {{ document.description }}
                </td>
                <td>
                  <span>{{ document.meterDocumentTypeName }}</span>
                  <v-chip
                      v-if="document.valid"
                      class="ma-2"
                      x-small
                      color="green"
                      text-color="white"
                    >
                      valid
                    </v-chip>
                </td>
                <!-- Uploaded By -->
                <td style="width: 250px;">
                  <UserAvatar
                    class="d-flex justify-content-start"
                    small
                    :portrait="document.uploadedBy"
                    :label="document.uploadedByName"
                    :full-name="formatDateTime(document.uploadedDate)"
                  ></UserAvatar>
                </td>
                <!-- Actions -->
                <!-- v-if="permission.alloweditcontractuploads" -->
                <td
                  v-if="
                    permission.allowdeleteonmeterdocumentuploads ||
                      permission.alloweditonmeterdocumentuploads
                  "
                  style="width: 220px"
                >
                  <button
                    v-if="permission.alloweditonmeterdocumentuploads"
                    class="mx-2"
                    type="button"
                    style="color: #007bff;"
                    @click="editDocument(document.id)"
                  >
                    <i class="fas fa-edit fa-lg"></i>
                  </button>

                  <button
                    class="mx-2"
                    type="button"
                    style="color: #dc3545;"
                    @click="removeDocument(document.id)"
                  >
                    <i class="fas fa-trash fa-lg"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
      <v-divider></v-divider>

      <v-card-actions>
        <v-btn text @click="visible = false">
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex"
import UserAvatar from "@/components/user-avatar"
import DataValue from "@/components/data-value"

import api from "@/store/api"
import dayjs from "dayjs"
import { changesSaved, somethingWentWrong } from "@/helpers/utilities"

// import LoadingSpinner from "@/components/loading-spinner"
// import EmptyCard from "@/components/empty-card"
// import api from "@/store/api"
// import { chunkArray } from "@/store/helpers/utils"
// import dayjs from "dayjs"

export default {
  components: {
    UserAvatar,
    DataValue
  },
  props: {
    meterId: {
      type: [String, Number],
      default: null
    }
  },
  data() {
    return {
      descriptionRules: [value => !!value || "Please Add a Description"],
      uploadSelectedRules: [value => !!value || "Please Select"],
      fileRules: [value => !!value || "Please Upload a File"],
      visible: false,
      previousSales: [],
      permission: {
        wfallowsaleidlink: false,
        alloweditonmeterdocumentuploads: false,
        allowdeleteonmeterdocumentuploads: false
      },
      editable: false,
      editDocumentMode: false,
      showForm: false,
      selectedContractType: null,
      contractype: [],
      uploads: [],
      formatNames: [],
      documents: [],
      form: {
        contractype: null,
        contractTypeId: 0,
        id: 0
      },
      selectedDocument: {
        id: 0,
        saleId: 0,
        description: "",
        contractUploadType: 0,
        filePath: null,
        uploadDateTime: null,
        uploadedBy: 0,
        uploadedByName: ""
      },
      description: "",
      file: "",
      // userId: '',
      uploadSelected: 0,
      uploadedBy: 0,
      id: 0,
      formHasErrors: false,
      saleId: 0
      // regReports: [],
    }
  },
  watch: {
    editDocumentMode(value) {
      if (value) {
        this.description = this.selectedDocument.description
        this.uploadSelected = this.selectedDocument.meterDocumentTypeID
        this.file = ""
        this.id = this.selectedDocument.id
      } else {
        this.description = ""
        this.uploadSelected = 0
        this.file = ""
        this.id = 0
      }
    }
  },
  computed: {
    ...mapGetters("company", ["details"]),
    ...mapGetters("employee", ["userId"]),
    sortedItems() {
      const sales = this.previousSales
      return sales.sort((a, b) => {
        return b.processedDate - a.processedDate
      })
    }
  },
  created() {
    this.fetchDocuments()
    this.fetchPermission()
    this.getContractUploadTypes()
  },
  methods: {
    addDocumentSelected() {
      this.editDocumentMode = false
      this.showForm = !this.showForm
    },

    setSelectedContract(id) {
      const document = this.documents.find(document => document.id === id)
      this.selectedDocument = document
    },
    async editDocument(id) {
      this.setSelectedContract(id)
      this.showForm = true
      this.editDocumentMode = true
    },

    async removeDocument(id) {
      const response = await api.post(`deleteMeterDocument/${id}`)
      if (response.status === 200) {
        changesSaved("Document Successfully Removed!")
        this.fetchDocuments()
      } else {
        somethingWentWrong()
      }
    },
    typesOfDocuments(id) {
      console.log(id)
      const foundDocumentType = this.uploads.find(up => up.value === id)
      console.log("foundDocumentType", foundDocumentType)
      return foundDocumentType ? foundDocumentType.text : ""
    },

    formatDateTime(date) {
      if (!date || date === 0) {
        return null
      }
      return dayjs(date * 1000).format('DD/MM/YYYY HH:mm:ss')
    },

    async fetchPermission() {
      const pagePermission = this.permission
      const res = await api.get(`permission/${this.userId}`)
      if (res.status === 200) {
        const result = res.data
        for (var p in result) {
          console.log(result[p])
          pagePermission[result[p].name] = result[p].hasPermission
        }
      }
    },

    async getContractUploadTypes() {
      const res = await api.get("meterDocumentTypes")
      if (res && res.status === 200) {
        // this.uploads = res.data.map(option => ({
        //   text: option.value,
        //   value: option.id
        // }))

        this.uploads = res.data.map(option => ({
          text: option.value,
          value: option.id,
          valid: option.valid
        }))
      }
    },

    async downloadDocument(id) {
      await api
        .get(`downloadMeterDocumentFile/${id}`)
        .then(res => {
          if (res.status === 200) {
            const apiUrl = process.env.VUE_APP_BASE_URL
            window.location = `${apiUrl}/downloadMeterDocumentFile/${id}`
          }
        })
        .catch(err => {
          console.log(err)
          somethingWentWrong("Sorry! Document for this sale doesn't Exist")
        })
    },

    async fetchDocuments() {
      const response = await api.get(`getAllMeterDocuement/${this.meterId}`)
      if (response.status === 200) {
        this.documents = response.data
      }
    },

    async uploadContract() {
      const formData = new FormData()
      // const file = 0
      formData.append("ID", this.id)
      formData.append("MeterID", this.meterId)
      formData.append("File", this.file)
      formData.append("CompanyID", this.details.companyId)
      formData.append("Description", this.description)
      formData.append("MeterDocumentTypeID", this.uploadSelected)
      formData.append("UploadedBy", this.userId)

      if (
        this.description === "" ||
        this.uploadSelected === "" ||
        this.uploadSelected === 0 ||
        (!this.editDocumentMode && (this.file === "" || this.file === 0))
      ) {
        const form = {
          description: this.description,
          uploadSelected: this.uploadSelected,
          file: this.file
        }
        this.formHasErrors = false
        Object.keys(form).forEach(f => {
          if (!this.form[f]) this.formHasErrors = true
          this.$refs[f].validate(true)
        })
        return false
      }

      const response = await api.post("uploadMeterDocument", formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
      if (response.status === 200) {
        if (this.id === 0) {
          this.description = ""
          this.uploadSelected = 0
          this.file = ""
          this.showForm = false
          changesSaved("Document Successfully Uploaded!")
          //   this.fetchDetails()
        } else {
          changesSaved("Document Successfully Updated!")
          this.fetchDocuments()
          //   this.fetchDetails()
          this.editDocumentMode = false
          this.showForm = false
        }
        this.fetchDocuments()
      } else {
        somethingWentWrong()
      }
    },

    async previousSalesOpened() {
      const res = await api.get(`previousSales/${this.supplyNumber}`)
      if (res && res.status === 200) {
        this.previousSales = res.data
      }
    },
    allowDetails(employeeId) {
      if (employeeId) return true
      // return this.userId === employeeId || this.permission.allowsaleidlink
    },
    supplierLogo(id) {
      return `https://hive.utilityhive.com/uploads/SupplierLogos/${id}.jpg`
    },
    formattedDate(date) {
      return dayjs(date * 1000).format("DD/MM/YYYY")
    },
    formatPrice(value) {
      var val = (value / 1).toFixed(2)
      return "£" + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    tooltipStatus(status) {
      if (status === "qualityCheck") {
        return "Quality Check"
      }
      if (status === "failed") {
        return "Failed"
      }
      if (status === "live") {
        return "Live"
      }
      if (status === "declared") {
        return "Declared"
      }
      if (status === "sold") {
        return "Sold"
      }
      return "Processing"
    }
  }
}
</script>

<style scoped>
.text-headers {
  font-family: Inter, Rubik;
  font-weight: 700;
  color: #ea5791 !important;
  font-size: 1rem !important;
}
.disabled-anchor {
  pointer-events: none;
  opacity: 75%;
}
</style>
