var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"outlined":"","rounded":"lg"}},[_c('v-card-title',{staticClass:"py-2"},[_c('v-row',{attrs:{"align":"center","justify":"space-between"}},[_c('v-col',{staticClass:"text-button"},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-office-building")]),_vm._v(" Company Information ")],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"blue","depressed":"","icon":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.editCompany($event)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1)]}}])},[_c('span',[_vm._v("Edit Company")])])],1)],1)],1),_c('v-divider'),_c('div',{staticClass:"px-4 pt-4"},[_c('CreditScore',{attrs:{"creditSafeCompanyName":_vm.details.creditSafeCompanyName,"creditSafeScore":_vm.details.creditSafeScore,"creditSafeLimit":_vm.details.creditSafeLimit,"creditScoreDate":_vm.details.creditScoreDate,"experianCompanyName":_vm.details.experianCompanyName,"experianScore":_vm.details.experianScore,"experianLimit":_vm.details.experianLimit,"experianScoreDate":_vm.details.experianScoreDate,"companyId":_vm.details.companyId,"companyName":_vm.details.companyName}})],1),_c('v-list',{attrs:{"two-line":""}},[_c('v-list-item',[_c('v-row',[_c('v-col',{attrs:{"cols":"1"}},[_c('v-avatar',{attrs:{"color":"blue lighten-1","size":"32"}},[_c('v-icon',{attrs:{"dark":"","small":""}},[_vm._v("mdi-phone")])],1)],1),_c('v-col',{staticClass:"ml-1"},[_c('div',{staticClass:"grey--text",staticStyle:{"font-size":"11px"}},[_vm._v("Primary Phone Number")]),_c('span',{staticClass:"cursor-pointer",on:{"click":_vm.handleCopy}},[_vm._v(_vm._s(_vm.details.telephone))])])],1)],1),_c('v-list-item',[_c('DataValue',{attrs:{"label":"Primary Address","value":{
          firstLine: _vm.details.add1,
          secondLine: _vm.details.add2,
          town: _vm.details.town,
          county: _vm.details.county,
          postcode: _vm.details.postcode
        },"icon":"map-marker","icon-color":"red lighten-1","address-type":""}})],1),_c('v-list-item',[_c('DataValue',{attrs:{"label":"Trading Type","value":_vm.details.tradingType,"icon":"domain","icon-color":"teal lighten-1"}})],1),(_vm.hasRegistrationNo)?_c('v-list-item',[_c('DataValue',{attrs:{"label":"Registration Number","value":_vm.details.registrationNo === 'undefined' || _vm.details.registrationNo === '' || _vm.details.registrationNo === null ? 'No Registration Number Found' : _vm.details.registrationNo,"icon":"pound","icon-color":"indigo lighten-1"}})],1):_vm._e(),_c('v-list-item',[_c('DataValue',{attrs:{"label":"Nature of Business","value":_vm.details.businessType,"icon":_vm.sicIcon,"icon-color":"amber lighten-1"}})],1),_c('v-list-item',[_c('DataValue',{attrs:{"label":"Primary Contact","value":_vm.primaryContactFullName === 'undefined undefined' ? 'No Primary Contact Found' : _vm.primaryContactFullName,"icon":"account-box","icon-color":"accent lighten-1"}})],1),_c('v-list-item',[(_vm.primaryContactEmailAddress === 'undefined' || _vm.primaryContactEmailAddress === '' || _vm.primaryContactEmailAddress === null)?_c('DataValue',{attrs:{"label":"Primary Email","value":"No Primary Email Found","icon":"email","icon-color":"green lighten-1"}}):_c('DataValue',{attrs:{"label":"Primary Email","value":_vm.primaryContactEmailAddress,"icon":"email","href":("mailto:" + _vm.primaryContactEmailAddress),"icon-color":"green lighten-1"}})],1),_c('v-list-item',[_c('DataValue',{attrs:{"label":"Company Website","value":_vm.details.website,"href":("https://" + (_vm.details.website)),"target":"popup","icon":"at","icon-color":"blue lighten-1"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }