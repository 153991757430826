export default {
  A: "tractor-variant", // AGRICULTURE, FORESTRY AND FISHING
  B: "pickaxe", // MINING AND QUARRYING
  C: "factory", // MANUFACTURING
  D: "lightbulb", //  ELECTRICITY, GAS, STEAM AND AIR CONDITIONING SUPPLY
  E: "water-pump", // WATER SUPPLY; SEWERAGE, WASTE MANAGEMENT AND REMEDIATION ACTIVITIES
  F: "crane", // CONSTRUCTION
  G: "engine", // WHOLESALE AND RETAIL TRADE; REPAIR OF MOTOR VEHICLES AND MOTORCYCLES
  H: "truck", // TRANSPORTATION AND STORAGE
  I: "silverware-fork-knife", // ACCOMMODATION AND FOOD SERVICE ACTIVITIES
  J: "antenna", // INFORMATION AND COMMUNICATION
  K: "shield-car", // FINANCIAL AND INSURANCE ACTIVITIES
  L: "home-group", // REAL ESTATE ACTIVITIES
  M: "test-tube", // PROFESSIONAL, SCIENTIFIC AND TECHNICAL ACTIVITIES
  N: "face-agent", // ADMINISTRATIVE AND SUPPORT SERVICE ACTIVITIES
  O: "pistol", // PUBLIC ADMINISTRATION AND DEFENCE; COMPULSORY SOCIAL SECURITY
  P: "school", // EDUCATION
  Q: "hospital-building", // HUMAN HEALTH AND SOCIAL WORK ACTIVITIES
  R: "palette", // ARTS, ENTERTAINMENT AND RECREATION
  S: "hammer-wrench", // OTHER SERVICE ACTIVITIES
  T: "home-variant-outline", // ACTIVITIES OF HOUSEHOLDS AS EMPLOYERS; UNDIFFERENTIATED GOODS- AND SERVICES-PRODUCING ACTIVITIES OF HOUSEHOLDS FOR OWN USE
  U: "bank" // ACTIVITIES OF EXTRATERRITORIAL ORGANISATIONS AND BODIES
}
