<template>
  <v-card color="blue lighten-5" flat>
    <v-card-text>
      <v-container>
        <v-row align="center" justify="space-between" v-if="standingCharges">
          <v-col cols="4">
            <span class="subtitle-1 font-weight-medium">Base Prices</span>
            <div>Standing Charge</div>
            <v-text-field
              suffix="p/day"
              v-model="localStandingCharge.baseRate"
              @input="onBaseRateChange"
            ></v-text-field>
          </v-col>
          <v-col cols="4" align-self="end">
            <span class="subtitle-1 font-weight-medium">Prices</span>
            <div style="margin-bottom: -18px; transform: translateY(-3px)">
              Standing Charge
            </div>
            <v-text-field
              suffix="p/day"
              disabled
              v-model="localStandingCharge.price"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    localStandingCharge: {
      baseRate: null,
      price: 0,
    },
  }),
  props: {
    quoteTypePriceList: {
      type: Array,
    },
    changedMeterType: {
      type: Boolean,
    },
  },
  watch: {
    changedMeterType(newVal, old) {
      if (newVal !== old) {
        this.localStandingCharge = {
          baseRate: null,
          price: 0,
        }
      }
    },
  },
  computed: {
    standingCharges() {
      return this.quoteTypePriceList.filter((x) => x.quoteTypePriceId === 1)
    },
  },
  methods: {
    onBaseRateChange() {
      this.localStandingCharge.price = parseFloat(
        this.localStandingCharge.baseRate
          ? this.localStandingCharge.baseRate
          : 0
      )
      this.quoteTypePriceList.forEach((q) => {
        if (q.quoteTypePriceId === 1) {
          q.price = this.localStandingCharge.price
          q.baseRate = this.localStandingCharge.baseRate
            ? parseFloat(this.localStandingCharge.baseRate)
            : null
        }
      })

      //    var index = this.quoteTypePriceList.findIndex(x=>x.quoteTypePriceId === 1)
      //    this.quoteTypePriceList[index].price = parseFloat( this.quoteTypePriceList[index].baseRate ?  this.quoteTypePriceList[index].baseRate : 0)
    },
  },
}
</script>
