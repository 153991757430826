<template>
  <v-card class="mb-0" color="green lighten-5" flat>
    <v-card-text>
      <v-container>
        <span class="subtitle-1 font-weight-medium"> Comparative Prices</span>
        <v-row align="center" v-if="standingCharges">
          <v-col cols="3">
            <div
              class=""
              style="
                font-size: 12px;

                color: var(--v-primary-base);
                caret-color: var(--v-primary-base);
              "
            >
              Standing Charge
            </div>
            <v-text-field
              style="padding: 0; margin: 0"
              type="number"
              hide-details="auto"
              v-model="localStandingCharge.baseRate"
              @input="onBaseRateChange"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex"
export default {
  data: () => ({
    localStandingCharge: {
      baseRate: 0,
      price: 0,
    },
  }),
  props: {
    quoteTypePriceList: {
      type: Array,
    },
    changedMeterType: {
      type: Boolean,
    },
  },
  watch: {
    changedMeterType(newVal, old) {
      if (newVal !== old) {
        console.log("here")
        this.localStandingCharge = {
          baseRate: null,
          price: 0,
        }
      }
    },
  },
  mounted() {
    this.quoteTypePriceList.forEach((q) => {
      if (q.quoteTypePriceId === 1) {
        this.localStandingCharge.price = q.price
        this.localStandingCharge.baseRate = q.baseRate
      }
    })
  },
  computed: {
    ...mapGetters("company", ["mobileView"]),
    standingCharges() {
      return this.quoteTypePriceList.filter((x) => x.quoteTypePriceId === 1)
    },
  },
  methods: {
    onBaseRateChange() {
      this.localStandingCharge.price = parseFloat(
        this.localStandingCharge.baseRate
          ? this.localStandingCharge.baseRate
          : 0
      )
      this.quoteTypePriceList.forEach((q) => {
        if (q.quoteTypePriceId === 1) {
          q.price = this.localStandingCharge.price
          q.baseRate = this.localStandingCharge.baseRate
            ? parseFloat(this.localStandingCharge.baseRate)
            : null
        }
      })
    },
  },
}
</script>
