var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"1200"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"info","depressed":"","icon":"","small":""},on:{"click":_vm.previousSalesOpened}},'v-btn',attrs,false),on),[_c('i',{staticClass:"fas fa-archive",staticStyle:{"font-size":"14px","color":"#9575cd"}})])]}}]),model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('v-card',[_c('v-card-title',{staticStyle:{"background-color":"#9575cd","color":"#fff !important"}},[_vm._v(" SALES ARCHIVE ")]),_c('v-card-subtitle',{staticClass:"pt-1 pb-2",staticStyle:{"background-color":"#9575cd","color":"#fff !important"}},[_vm._v(" Sales Archive Information ")]),_c('v-card-text',{staticClass:"mt-2"},[_c('v-row',[_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"12"}},[_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-headers text-left"},[_vm._v("Company Details")]),_c('th',{staticClass:"text-headers text-left"},[_vm._v("Current Supplier")]),_c('th',{staticClass:"text-headers text-left"},[_vm._v("Sold Supplier")]),_c('th',{staticClass:"text-headers text-center"},[_vm._v("Status")]),_c('th',{staticClass:"text-headers text-left",staticStyle:{"width":"220px"}},[_vm._v(" Contract Details ")]),_c('th',{staticClass:"text-headers text-left",staticStyle:{"width":"220px"}},[_vm._v(" Contract Value ")]),_c('th',{staticClass:"text-headers text-left",staticStyle:{"width":"250px"}},[_vm._v(" Sales Log ")])])]),_c('tbody',_vm._l((_vm.sortedItems),function(previousSale){return _c('tr',{key:previousSale.saleId,staticClass:"p-2",staticStyle:{"height":"90px"}},[_c('td',{staticStyle:{"width":"250px"}},[_c('div',[_c('span',[_c('strong',[_vm._v(_vm._s(previousSale.companyName))])])]),_c('div',[_c('span',[_c('strong',[_vm._v("Sale ID: ")]),_c('a',{class:_vm.permission.wfallowsaleidlink
                              ? 'blue--text cursor-pointer'
                              : 'disabled-anchor text-black',staticStyle:{"text-decoration":"none","opacity":"1 !important"},attrs:{"href":'http://hive.utilityhive.com/sales/sales-form?SaleID=' +
                            previousSale.saleId,"button":"","target":"_blank"}},[_vm._v(" "+_vm._s(previousSale.saleId)+" ")])])]),_c('div',[_c('span',[_c('strong',[_vm._v("Processed Date: ")]),_c('span',{class:!previousSale.processedDate ? 'red--text' : ''},[(!previousSale.processedDate)?_c('i',{staticClass:"fas fa-handshake-alt-slash red--text ml-2"}):_vm._e(),_vm._v(" "+_vm._s(previousSale.processedDate === 0 || previousSale.processedDate === "0" ? "Not Processed" : _vm.formattedDate(previousSale.processedDate))+" ")])])])]),_c('td',[_c('div',{staticClass:"estimate-container",staticStyle:{"width":"110px","height":"50px"}},[_c('v-img',{attrs:{"src":_vm.supplierLogo(previousSale.currentSupplierId)}})],1)]),_c('td',[_c('div',{staticClass:"estimate-container",staticStyle:{"width":"110px","height":"50px"}},[_c('v-img',{attrs:{"src":_vm.supplierLogo(previousSale.soldSupplierId)}})],1)]),_c('td',{staticStyle:{"min-width":"180px"}},[_c('div',{staticClass:"mt-5 pt-2"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
                            var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('Statuses',{attrs:{"status":previousSale.status,"previousSale":previousSale}})],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.tooltipStatus(previousSale.status)))])])],1)]),_c('td',{staticStyle:{"width":"220px"}},[_c('ContractInfo',{attrs:{"master-type":previousSale.masterContractTypeId,"csd":previousSale.startDate,"ced":previousSale.endDate,"term":previousSale.months,"resell":previousSale.reSell}})],1),_c('td',{staticStyle:{"width":"250px"}},[_c('div',{staticClass:"d-flex flex-column text-left"},[_c('span',[_vm._v("Sold Value: "+_vm._s(_vm.formatPrice(previousSale.soldContractValue))+" ")]),_c('span',[_vm._v("Processed Value: "+_vm._s(_vm.formatPrice(previousSale.processedValue))+" ")])])]),_c('td',{},[_c('div',{staticClass:"mt-1",staticStyle:{"width":"180px"}},[_c('UserAvatar',{staticClass:"d-flex justify-content-start",attrs:{"small":"","portrait":previousSale.leadGeneratorId,"full-name":previousSale.leadGeneratorName,"label":"Lead Generator"}})],1),_c('div',{staticClass:"mt-2 mb-1",staticStyle:{"width":"180px"}},[_c('UserAvatar',{staticClass:"d-flex justify-content-start",attrs:{"small":"","portrait":previousSale.closerId,"full-name":previousSale.closerName,"label":"Closer"}})],1)])])}),0)]},proxy:true}])})],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.visible = false}}},[_vm._v(" Close ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }