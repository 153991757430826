<template>
  <v-card color="green lighten-5" flat>
    <v-card-text>
      <v-container>
        <span class="subtitle-1 font-weight-medium"> Unit Rate Commission</span>
        <v-row align="center" v-if="!isFixedCommission">
          <v-col cols="4">
            <v-text-field
              suffix="p/kWh"
              disabled
              label="Unit Rate Uplift"
              v-model="localUnitRateUplift"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-slider
              color="blue"
              min="0"
              :step="urIncUplift"
              :max="urMaxUplift"
              thumb-label
              v-model="localUnitRateUplift"
              @change="unitRateUpliftChanged"
            ></v-slider>
          </v-col>
        </v-row>
        <v-row
          v-for="(q, index) in quoteTypesOnly"
          align="center"
          justify="space-between"
          :key="index"
        >
          <v-col>
            <span class="subtitle-1 font-weight-medium">Base Prices</span>
            <div>{{ q.typeName }}</div>
            <v-text-field
              suffix="p/kWh"
              v-model="q.baseRate"
              @input="onBaseRateChange(q)"
              @keypress="isSortCodeNumberKey($event, q)"
              oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1').replace(/(\.\d{3}).+/g, '$1');"
            ></v-text-field>
          </v-col>
          <v-col style="margin-top: 19px !important">
            <span class="subtitle-1 font-weight-medium">EAC</span>
            <v-text-field
              @keypress="eacValidation"
              v-model="q.eac"
              @paste="onPaste"
              :disabled="eacDisabled"
            ></v-text-field>
          </v-col>
          <v-col align-self="end" style="margin-top: 19px !important">
            <span class="subtitle-1 font-weight-medium">Prices</span>
            <div style="margin-bottom: -18px; transform: translateY(-3px)">
              {{ q.typeName }}
            </div>
            <v-text-field
              suffix="p/kWh"
              disabled
              v-model="q.price"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    selectedTypeIds: [],
    localUnitRateUplift: 0,
  }),
  props: {
    unitRateUplift: {
      type: Number,
      default: 0,
    },
    eacGuidedValue: {
      type: Number,
      default: 0,
    },
    eacDisabled: {
      type: Boolean,
    },
    isFixedCommission: {
      type: Boolean,
    },
    urMaxUplift: {
      type: Number,
      default: 0,
    },
    urIncUplift: {
      type: Number,
      default: 0,
    },
    quoteTypePriceList: {
      type: Array,
    },
  },
  mounted() {
    this.localUnitRateUplift = this.unitRateUplift
    if (this.eacDisabled) {
      this.quoteTypePriceList.map((q, index) => {
        if (index === 1) {
          q.eac = this.eacGuidedValue
        } else {
          q.eac = 0
        }
      })
    }
  },
  watch: {
    eacDisabled: {
      handler(newVal, oldVal) {
        console.log(newVal, oldVal)
        if (newVal) {
          this.quoteTypePriceList.map((q, index) => {
            if (index === 1) {
              q.eac = this.eacGuidedValue
            } else {
              q.eac = 0
            }
          })
        }
      },
      deep: true,
    },
  },
  computed: {
    quoteTypesOnly() {
      return this.quoteTypePriceList.filter((x) => x.quoteTypePriceId !== 1)
    },
  },
  methods: {
    unitRateUpliftChanged(value) {
      this.$emit("unit-rate-uplift-changed", value)
      this.quoteTypesOnly.forEach((q) => {
        const index = this.quoteTypePriceList.findIndex(
          (x) => x.quoteTypePriceId === q.quoteTypePriceId
        )
        let price
        if (q.quoteTypePriceId === 10) {
          price = parseFloat(
            this.quoteTypePriceList[index].baseRate
              ? this.quoteTypePriceList[index].baseRate
              : 0
          )
        } else {
          price =
            parseFloat(
              this.quoteTypePriceList[index].baseRate
                ? this.quoteTypePriceList[index].baseRate
                : 0
            ) + parseFloat(value)
        }
        this.quoteTypePriceList[index].price = price
        q.price = parseFloat(price).toFixed(2)
        q.uplift = parseFloat(this.localUnitRateUplift)
      })
    },
    onBaseRateChange(q) {
      let price
      if (q.quoteTypePriceId === 10) {
        price = parseFloat(q.baseRate ? q.baseRate : 0)
      } else {
        price =
          parseFloat(q.baseRate ? q.baseRate : 0) +
          parseFloat(this.localUnitRateUplift)
      }
      q.price = parseFloat(price).toFixed(2)
      q.baseRate = q.baseRate
        .replace(/[^0-9.]/g, "")
        .replace(/(\..*?)\..*/g, "$1")
        .replace(/(\.\d{3}).+/g, "$1")
    },

    onPaste(evt) {
      const data = evt.clipboardData.getData("text")
      const splitData = data.split("")
      for (let i = 0; i < splitData.length; i++) {
        if (isNaN(parseInt(splitData[i])) || splitData[i].indexOf(".") !== -1) {
          event.returnValue = false
          return false
        }
      }
    },

    isSortCodeNumberKey(event, q) {
      const charCode = event.which ? event.which : event.keyCode
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        event.returnValue = false
        return false
      } else {
        if (this.quoteTypesOnly) {
          const regex = /^\d{0,5}(\.\d{0,3})?$/

          if (!regex.test(q.baseRate) && q.baseRate) {
            console.log("called", q.baseRate)
            event.returnValue = false
            return false
          }
        }
        event.returnValue = true
        return true
      }
    },
    eacValidation(evt) {
      const charCode = evt.which ? evt.which : evt.keyCode
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        event.returnValue = false
        return false
      } else {
        if (this.quoteTypesOnly) {
          if (this.quoteTypesOnly.baseRate.length >= 6) {
            event.returnValue = false
            return false
          }
        }
        event.returnValue = true
        return true
      }
    },
  },
}
</script>
