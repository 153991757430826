import api from "@/store/api"
import {
  ALL,
  USER,
  SYSTEM,
  METER_INFORMATION,
  INFORMATION_REQUEST,
  LOA,
  QUALITY_CHECK
} from "@/store/helpers/note-types"

const matchType = type => ({ noteType }) => noteType === type

const noteTypes = {
  [ALL]: null,
  [USER]: "account",
  [SYSTEM]: "server",
  [METER_INFORMATION]: "gauge",
  [INFORMATION_REQUEST]: "info",
  [LOA]: "user-shield",
  [QUALITY_CHECK]: "check-bold"
}

const state = {
  loading: false,
  error: null,
  notes: [],
  allNotes: [],
  noteTypes
}

const getters = {
  notes: state => state.notes,
  allNotes: state => state.allNotes,
  getNotesByType: state => type => {
    console.log('type type type', type)
    if (type === ALL) {
      return state.notes
    } else {
      return state.notes.filter(matchType(type))
    }
  },
  error: state => state.error,
  loading: state => state.loading,
  noteTypes: state => {
    return Object.keys(state.noteTypes).map(type => ({
      type,
      icon: state.noteTypes[type]
    }))
  }
}

const mutations = {
  setNotes: (state, notes) => {
    state.notes = notes.map(note => {
      const type = note.noteType ? note.noteType.toLowerCase() : ""
      return {
        ...note,
        icon: state.noteTypes[type],
        noteType: type
      }
    })
  },
  setAllNotes: (state, notes) => {
    state.allNotes = notes.map(note => {
      const type = note.noteType ? note.noteType.toLowerCase() : "quality check"
      return {
        ...note,
        icon: state.noteTypes[type],
        noteType: type
      }
    })
  },
  setLoading: (state, status) => (state.loading = status),
  setError: (state, error) => (state.error = error),
  createNote: (state, note) => {
    state.notes.unshift(note)
  },
}

const actions = {
  async fetchNotes({ commit, rootGetters }) {
    commit("setLoading", true)

    try {
      const response = await api.get(
        `notes/${rootGetters["company/companyId"]}`
      )
      commit("setNotes", response.data)
      commit("setAllNotes", response.data)     
    } catch (error) {
      commit("setError", error)
    }
    commit("setLoading", false)
  },
  async fetchNotesAfterSomeDelay({ commit, rootGetters }) {
    commit("setLoading", true)

    setTimeout(async () => {
      try {
        const response = await api.get(
          `notes/${rootGetters["company/companyId"]}`
        )
        commit("setNotes", response.data)
        commit("setAllNotes", response.data)     
      } catch (error) {
        commit("setError", error)
      }
      commit("setLoading", false)
    }, 2500);
  },
  async createNotes({ commit }, note) {
    try {
      const noteDetails = {
        companyId: note.companyId,
        noteTypeId: note.noteTypeId,
        note: note.note,
        userId: note.userId,
    }

      // eslint-disable-next-line
      const response = await api.post("saveNote", noteDetails)
      commit("createNote", note) // we should pass the response.data, but API is returning only id
      return response
    } catch (error) {
      commit("setError", error)
      return error
    }
  },
}

export default { state, getters, mutations, actions, namespaced: true }
