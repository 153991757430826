const state = {
  visibility: {
    drawer: false
  },
  currentForm: "",
  formEditMode: false
}

const getters = {
  drawerIsVisible: state => state.visibility.drawer,
  currentForm: state => state.currentForm,
  formEditMode: state => state.formEditMode
}

const mutations = {
  toggleDrawer: (state, value) => (state.visibility.drawer = value),
  setCurrentForm: (state, formName) => (state.currentForm = formName),
  setEditMode: (state, status) => (state.formEditMode = status)
}

const actions = {
  toggleDrawer({ commit }, value = true) {
    commit("toggleDrawer", value)
  },
  setCurrentForm({ commit }, formName) {
    commit("setCurrentForm", formName)
  },
  openForm({ commit, dispatch }, { formName, edit = false }) {
    dispatch("toggleDrawer")
    commit("setCurrentForm", formName)
    commit("setEditMode", edit)
  },
  closeForm({ commit, dispatch }) {
    dispatch("toggleDrawer", false)
    commit("setCurrentForm", "")
    commit("setEditMode", false)
  },

}

export default { state, getters, mutations, actions, namespaced: true }
