<template>
  <div class="px-2">
    <v-row class="fill-height" align="center" dense>
      <v-col cols="auto">
        <v-icon :color="typeColor">{{ typeIcon }}</v-icon>
      </v-col>
      <v-col cols="auto" class="fill-height">
        <div class="fill-height d-flex flex-column">
          <v-img
            max-height="25"
            max-width="90"
            :src="$helpers.supplierLogo(supplierId)"
            contain
          ></v-img>
          <div
            class="flex-grow-1 flex-shrink-0"
            style="min-height: 35px;"
          >
            <div v-if="estimate">
              Estimate
            </div>
            <div v-else>
              <div v-if="typeIcon !== 'amber'">{{ pc }} {{ mtc }} {{ llf }}</div>
              <div>{{ supplyNumber }}</div>
            </div>
          </div>
          <div class="caption grey--text flex-shrink-1">
            <time :datetime="contractEndDate">
              {{ $helpers.formatDate(contractEndDate) }}
            </time>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: ""
    },
    estimate: {
      type: Boolean,
      default: false
    },
    supplierId: {
      type: String,
      default: ""
    },
    pc: { type: String, default: "" },
    mtc: { type: String, default: "" },
    llf: { type: String, default: "" },
    supplyNumber: {
      type: String,
      default: ""
    },
    contractEndDate: {
      type: Number,
      default: () => 0
    }
  },
  computed: {
    isGas() {
      return this.type.toLowerCase() === "gas"
    },
    typeColor() {
      // return this.isGas ? "red" : "amber"
      return this.meterTypeId === 10 ? 'red' : this.meterTypeId === 2705 ? 'blue' : 'amber'
    },
    typeIcon() {
      // return this.isGas ? "mdi-fire" : "mdi-lightbulb"
      return this.meterTypeId === 10 ? 'mdi-fire' : this.meterTypeId === 2705 ? 'mdi-water' : 'mdi-lightbulb'
    }
  }
}
</script>

<style></style>
