<template>
  <v-row justify="center">
    <v-dialog v-model="visible" width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" color="red" depressed icon small>
          <v-icon small>mdi-delete</v-icon>
        </v-btn>
      </template>
      <v-card flat>
        <v-card-title> Delete Meter </v-card-title>
        <v-container :key="meterID" fluid>
          <v-row class="mb-4">
            <v-col
              align-self="center"
              class="d-flex justify-center"
              cols="auto"
            >
              <div>
                <span v-if="selectedMeter.meterType === 'Electricity'">
                  <v-icon size="32" class="mt-2" color="amber"
                    >mdi-lightbulb</v-icon
                  >
                </span>
                <span v-if="selectedMeter.meterType === 'Gas'">
                  <v-icon size="32" color="red">mdi-fire</v-icon>
                </span>
                <span v-if="selectedMeter.meterType === 'Water'">
                  <v-icon size="32" color="blue">mdi-water</v-icon>
                </span>
              </div>
            </v-col>
            <v-col align-self="center" class="d-flex justify-center" cols="4">
              <v-img
                max-height="54"
                max-width="110"
                :src="$helpers.supplierLogo(selectedMeter.supplierId)"
                contain
              ></v-img>
            </v-col>
            <v-col align-self="center" cols="6">
              <div
                class="text-center"
                v-if="selectedMeter.estimateActual === 'Estimate'"
              >
                Estimate
              </div>
              <SupplyNumber
                v-else
                :type="selectedMeter.meterType"
                :supply-number="selectedMeter.supplyNumber"
                :pc="selectedMeter.pc"
                :mtc="selectedMeter.mtc"
                :llf="selectedMeter.llf"
                :disabled="true"
              />
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn text @click="visible = false"> Close </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="blue" text @click="handleDeleteMeter"> Delete </v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import { changesSaved, somethingWentWrong } from "@/helpers/utilities"
import { mergeExistingEntriesIntoForm } from "@/helpers/utilities"
import SupplyNumber from "@/components/supply-number"
import api from "@/store/api"
// import dayjs from "dayjs"
export default {
  components: {
    SupplyNumber
  },
  props: {
    meterId: Number,
    type: String,
    supplierId: String,
    addressId: Number
  },
  data() {
    return {
      suppliers: [],
      meterID: null,
      selectedSupplierId: null,
      visible: false,
      selectedMeter: {},
      form: {
        meterType: "",
        supplyNumber: "",
        pc: "",
        mtc: "",
        llf: "",
        contractEndDate: "",
        supplierName: "",
        renewalDate: ""
      }
    }
  },
  watch: {
    selectedMeter() {
      this.bindMeterDetails()
    }
  },
  computed: {
    ...mapGetters("employee", ["userId"]),
    ...mapGetters("meters", ["meters"]),
    ...mapGetters("forms", ["supplyDetails"]),
    ...mapGetters("company", ["details", "companyId"])
  },
  created() {
    this.bindMeterDetails()
    // this.intializeComponent()
  },
  mounted() {
    this.meterID = this.meterId
    // this.fetchSuppliers()
    if (this.meters) {
      const filterMeter = this.meters.filter(
        meter => meter.meterId === this.meterId
      )
      this.selectedMeter = filterMeter[0]
    }
  },
  methods: {
    ...mapActions("meters", [
      "removeMeter",
      "fetchMetersAfterRemove",
      "fetchPrimaryMetersAfterSave"
    ]),
    ...mapActions("notes", ["fetchNotes"]),
    ...mapActions("forms", ["fetchMainData"]),

    async fetchSuppliers() {
      const res = await api.get(`suppliers/${this.selectedMeter.meterTypeId}`)
      if (res.status === 200) {
        this.suppliers = res.data
      }
    },

    async handleDeleteMeter() {
      try {
        const supplyNumber =
          this.selectedMeter.meterType === "Electricity"
            ? this.selectedMeter.pc +
              " " +
              this.selectedMeter.mtc +
              "" +
              this.selectedMeter.llf +
              `<br/>` +
              this.selectedMeter.supplyNumber
            : this.selectedMeter.supplyNumber

        const isEstimate =
          this.selectedMeter.estimateActual === "Estimate"
            ? "Estimate"
            : supplyNumber
        const product =
          this.selectedMeter.meterType === "Electricity" ? "Electricity" : "Gas"
        const ced = this.$helpers.formatDate(this.selectedMeter.ced)

        const noteMessage = `Meter   Deleted - ${isEstimate} <br/>
                            ${product} <br/>
                            ${this.selectedMeter.supplierName} <br/>
                            ${ced === null ? "" : ced} `
        const details = {
          meterId: this.meterId,
          userId: this.userId,
          noteMessage: noteMessage,
          companyId: this.details.companyId
        }
        const response = await this.removeMeter(details)
        if (response && response.status === 200) {
          console.log(response)
          this.details = ""
          changesSaved("Meter Successfully Deleted")
          setTimeout(() => {
            this.fetchMainData(this.companyId)
            this.fetchMetersAfterRemove(this.addressId)
            this.fetchPrimaryMetersAfterSave(this.companyId)
            this.fetchNotes()
            //  this.selectedMeter
          }, 1000)
          this.visible = false
        }
      } catch (err) {
        somethingWentWrong()
        console.log(err)
      }
    },
    bindMeterDetails() {
      if (this.formEditMode) {
        Object.entries(this.meters).forEach(
          mergeExistingEntriesIntoForm(this.form)
        )
      }
    }
  }
}
</script>
