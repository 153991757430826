import { getInitialState } from "@/helpers/init"
import company from "../modules/company"

const { companyId, error, loading } = getInitialState()

export const getContactState = () => ({
  contactId: 0,
  companyId: 0,
  leadId: 0,
  title: "",
  firstName: "",
  lastName: "",
  jobTitle: "",
  contactNumber: "",
  altContactNumber: "",
  emailAddress: "",
  dob: 0,
  securityPassword: "",
  primaryContact: false,
  currentUserId: 0
})

export const getSupplierState = () => ({
    supplierId: 0,
    supplierName: "",
    active: false,
    internalLogo: "",
    meterType: [],
    electricity: 0,
    gas: 0,
    customerTelephone: "",
    brokerSupportTelephone: "",
    supplierWebsite: "",
    terminationEmail: "",
    cotEmail: "",
    generalEmail: "",
    uhtpiAgreement: true,
    uhDirect: true,
    uhSubBroker: false,
    csstpiAgreement: false,
    cssDirect: false,
    cssSubBroker: false,
})

export const getCompanyState = () => ({
  loading: loading,
  error: error,
  id: companyId,
  details: {
    companyId: 0,
    companyName: "",
    add1: "",
    add2: "",
    town: "",
    county: "",
    postcode: "",
    registrationNo: null,
    tradingType: "",
    businessType: "Unknown",
    website: "",
    section: "",
    dataSource: "",
    creditSafeCompanyName: null,
    creditSafeScore: null,
    creditSafeLimit: null,
    creditScoreDate: 0,
    creditSafeID: 0,
    telephone: ""
  },
  company: company,
  companies: [],
  webCustomerReviewRequest: [],
})

export const getAddressState = () => ({
  companyId: 0,
  addressTypeId: 0,
  fromDate: 0,
  untilDate: 0,
  leadId: 0,
  primaryAddress: false,
  active: true,
  cotDate: 0,
  addressId: 0,
  siteName: "",
  add1: "",
  add2: "",
  town: "",
  county: "",
  postcode: ""
})

export const getPaymentInfoState = () => ({
  id: "",
  type: "",
  typeId: "",
  bankName: "",
  sortCode: "",
  accountName: "",
  accountNumber: "",
  addressId: ""
})

export const getLandlordInfoState = () => ({
  landlordId: 0,
  saleId: 0,
  companyName: "",
  title: "",
  firstName: "",
  surname: "",
  contactNumber: "",
  email: "",
  companyId: 0,
  addressId: 0,
  siteName: "",
  add1: "",
  add2: "",
  town: "",
  county: "",
  postcode: ""
})

export const getPreviousTenantInfoState = () => ({
  previousTenantId: 0,
  saleId: 0,
  companyName: "",
  title: "",
  firstName: "",
  surname: "",
  contactNumber: "",
  email: "",
  compnayId: 0,
  addressId: 0,
  siteName: "",
  add1: "",
  add2: "",
  town: "",
  county: "",
  postcode: ""
})

export const getBreweryInfoState = () => ({
  saleId: 0,
  breweryId: 0,
  owned: false,
  name: "",
  contactNumber: "",
  title: "",
  firstName: "",
  surname: "",
  companyId: 0,
  addressId: 0,
  siteName: "",
  add1: "",
  add2: "",
  town: "",
  county: "",
  postcode: ""
})

export const getMeterInfoState = () => ({
    meterId: 0,
    addressId: 0,
    estimateActual: "",
    insertedBy: 0,
    insertedDate: 0,
    modifiedBy: 0,
    modifiedDate: 0,
    meterTypeId: 10,
    meterType: "",
    pc: null,
    mtc: null,
    llf: null,
    supplyNumber: "",
    supplierId: 0,
    supplierName: "",
    ced: 0,
    companyId: 0,
    renewalDate: 0,
    window: 0
})
