<template>
  <v-row align="center">
    <v-col
      v-if="company.dataSourceId === 2725 || company.dataSourceId === 2730"
      cols="auto"
      class="pt-0"
      style="max-width: 60px"
    >
      <v-avatar color="accent" size="50">
        <img
          v-if="details.internalLogoFileName"
          :src="$helpers.profilePicture(details.internalLogoFileName)"
          :alt="details.subBrokerName"
          @error="$helpers.onProfilePictureError"
        />
        <v-icon v-else dark> mdi-account-circle </v-icon>
      </v-avatar>
    </v-col>
    <v-col v-else cols="auto" class="pt-0" style="max-width: 60px">
      <v-avatar color="accent" size="50">
        <img
          v-if="userId"
          :src="$helpers.profilePicture(userId)"
          :alt="userName"
          @error="$helpers.onProfilePictureError"
        />
        <v-icon v-else dark> mdi-account-circle </v-icon>
      </v-avatar>
    </v-col>
    <v-col cols="auto" class="pt-0" style="max-width: 190px">
      <DataValue
        label="Data Source"
        :value="details.dataSource"
        :boldLabel="details.subBrokerName"
      />
      <template>
        <div class="text-center">
          <v-dialog v-model="dialog" width="500">
            <template v-slot:activator="{ on, attrs }">
              <div class="d-flex justify-between">
                <a
                  v-if="
                    (company.dataSourceId === 13 ||
                      company.dataSourceId === 578) &&
                    company.proofLink.length
                  "
                  v-b-tooltip.hover.top="'Proof Link'"
                  class="btn"
                  target="_blank"
                  :href="company.proofLink.trim()"
                >
                  <!-- <FaIcon icon="clipboard" class="text-primary"></FaIcon> -->
                  <div class="d-inline-block">
                    <i
                      class="fas fa-link text-primary"
                      style="
                        font-size: 15px;
                        width: 15px;
                        height: 15px;
                        padding: 2px 0 0 0;
                        color: blue;
                      "
                    ></i>
                  </div>
                </a>
                <a @click="setNote(company.proofNote)">
                  <div class="d-inline-block ml-2 mr-2">
                    <i
                      v-if="
                        (company.dataSourceId === 13 ||
                          company.dataSourceId === 578) &&
                        company.proofNote.length
                      "
                      v-b-tooltip.hover.top="'Proof Note'"
                      class="fas fa-sticky-note text-orange"
                      style="
                        font-size: 15px;
                        width: 15px;
                        height: 15px;
                        padding: 2px 0 0 0;
                      "
                    ></i>
                  </div>
                </a>

                <v-btn
                  v-if="!nfdFeedback && company.dataSourceId === 13"
                  x-small
                  color="green lighten-2 d-flex justify-start"
                  dark
                  v-bind="attrs"
                  v-on="on"
                  @click="getOptions"
                >
                  Feedback
                </v-btn>
              </div>
            </template>

            <v-card>
              <v-card-title class="text-h5 green lighten-2 white--text">
                Feedback
              </v-card-title>
              <v-container fluid>
                <v-row>
                  <v-col>
                    <v-select
                      class="pt-2"
                      :items="saleOptions"
                      item-text="value"
                      item-value="id"
                      label="Reason"
                      @input="setReason($event)"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-textarea
                      class="pt-2"
                      name="input-7-1"
                      label="Feedback Comment"
                      v-model="feedbackComment"
                      hint="Please leave constructive feedback in order to help improve the data."
                    ></v-textarea>
                  </v-col>
                </v-row>
                <v-row>
                  <v-container>
                    <p :class="alertColor">{{ alertText }}</p>
                  </v-container>
                </v-row>
              </v-container>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="green lighten-2 white--text"
                  @click="submitFeedback()"
                >
                  Submit
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
      </template>
    </v-col>
    <v-col cols="auto">
      <v-sheet rounded class="px-3" color="transparent">
        <div class="text-overline pl-3 grey--text">
          LOA's
          <!-- <v-icon small> mdi-file-document-edit-outline </v-icon> -->
          <i
            class="fas fa-user-shield"
            style="font-size: 14px; color: #28a745"
          ></i>
        </div>
        <v-row dense class="flex-column" no-gutters>
          <v-col>
            <LoaInfo sale-type="utility hive" />
          </v-col>
          <v-col class="justify-content-end">
            <LoaInfoCss sale-type="css" />
          </v-col>
        </v-row>
      </v-sheet>
    </v-col>
    <v-col cols="auto" class="" style="">
      <v-sheet rounded class="px-3" color="transparent">
        <div class="text-overline pl-3 grey--text">
          Reviews
          <v-icon small> mdi-star </v-icon>
        </div>
        <Reviews />
      </v-sheet>
    </v-col>

    <v-col cols="auto" class="pt-0" style="margin-top: -1.2rem">
      <v-sheet rounded class="px-3" color="transparent">
        <div class="text-overline pl-3 grey--text">
          Ready For Quote's
          <v-icon small> mdi-comment-quote </v-icon>
        </div>
        <PreviousQuotes />
      </v-sheet>
    </v-col>
    <v-col cols="auto">
      <LoadingSpinner height="50" v-if="loadingHeaderMeters" />
      <v-sheet v-else width="720px" color="transparent">
        <v-slide-group v-if="primaryMeters.length" show-arrows>
          <v-slide-item
            v-for="meter in primaryMeters"
            :key="meter.id"
            v-slot="{ active, toggle }"
          >
            <MeterWidget
              :type="meter.meterType"
              :supplier-id="meter.supplierId.toString()"
              :estimate="meter.estimateActual.toLowerCase() === 'estimate'"
              :pc="meter.pc"
              :mtc="meter.mtc"
              :llf="meter.llf"
              :supply-number="meter.supplyNumber"
              :contract-end-date="parseInt(meter.ced)"
              @click="toggle"
              :input-value="active"
            />
          </v-slide-item>
        </v-slide-group>
      </v-sheet>
    </v-col>
    <v-col v-if="!isMobile" cols="1.5" class="ml-5">
      <v-row justify="middle">
        <div class="col col-auto nav__circle-btn__container">
          <button
            type="button"
            id="microphoneBtn"
            class="nav__circle-btn microphone-btn rounded-circle"
            @click="startRecording"
            :disabled="isDisabledForFiveSeconds"
          >
            <i class="fas fa-microphone fa-fw" style="font-size: 1.5em"></i>
            <div class="circle-btn__tooltip">
              <div id="microphoneMessage" class="mb-2 microphone-message">
                Click to Start Recording
              </div>
              <span id="microphoneTime" class="microphone-time">0:00</span>
            </div>
          </button>
        </div>
        <div  v-if="details.dataSourceId !== 2725" class="col col-auto nav__circle-btn__container">
          <button
            class="ready-btn nav__circle-btn rounded-circle"
            :style="faCheckCircle"
            type="button"
            @click="saveReadyForQuote"
            :disabled="isDisabled"
          >
            <i
              v-if="readyForQuoteData && !assignedDate && !quoteDate"
              class="fas fa-pound-sign fa-fw"
              style="font-size: 1.5em"
            ></i>
            <i
              v-if="readyForQuoteData && !assignedDate && quoteDate"
              class="fas fa-check-circle fa-fw"
              style="font-size: 1.5em"
            ></i>
            <i
              v-if="readyForQuoteData && assignedDate && quoteDate"
              class="fas fa-user-check fa-fw"
              style="font-size: 1.5em; color: #00c851"
            ></i>

            <div class="circle-btn__tooltip">
              <div
                v-if="readyForQuoteData && !assignedDate && !quoteDate"
                class="ready-btn__title mb-2"
              >
                Ready For Quote
              </div>

              <div
                v-if="readyForQuoteData && !assignedDate && quoteDate"
                class="ready-btn__title mb-2"
              >
                Assign Ready For Quote
              </div>
              <div v-if="readyForQuoteData && !assignedDate && quoteDate">
                <!-- <span class="tooltip__message">Ready For Quote</span><br> -->
                <div>
                  Lead Generator:
                  <span>{{ readyForQuoteData.readyForQuoteFromName }}</span>
                </div>
                <span class="ready-btn__time-stamp">{{ quoteDate }}</span>
              </div>
              <div
                v-if="readyForQuoteData && assignedDate && quoteDate"
                class="ready-btn__asigned-to"
              >
                <div class="pb-2">Ready For Quote</div>
                <div>
                  Lead Generator:
                  <span>{{ readyForQuoteData.readyForQuoteFromName }}</span>
                </div>
                <time
                  ><span>{{ quoteDate }}</span></time
                >

                <div class="mt-2">
                  Closer:
                  <span>{{ readyForQuoteData.assignedByName }}</span>
                </div>
                <time
                  ><span>{{ assignedDate }}</span></time
                >
              </div>
            </div>
          </button>
        </div>
        <!-- <v-col cols="auto">
              <v-btn outlined depressed icon>
                <v-icon>mdi-currency-gbp</v-icon>
              </v-btn>
            </v-col> -->
        <v-col cols="auto">
          <v-btn outlined depressed icon @click="toggleTheme">
            <v-icon>mdi-brightness-4</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
    <v-col v-if="isMobile" cols="1.5">
      <v-row justify="start">
        <div class="col col-auto nav__circle-btn__container">
          <button
            type="button"
            id="microphoneBtn"
            class="nav__circle-btn microphone-btn rounded-circle"
            @click="startRecording"
            :disabled="isDisabledForFiveSeconds"
          >
            <i class="fas fa-microphone fa-fw" style="font-size: 1.5em"></i>
            <div class="circle-btn__tooltip">
              <div id="microphoneMessage" class="mb-2 microphone-message">
                Click to Start Recording
              </div>
              <span id="microphoneTime" class="microphone-time">0:00</span>
            </div>
          </button>
        </div>
        <div  v-if="details.dataSourceId !== 2725" class="col col-auto nav__circle-btn__container">
          <button
            class="ready-btn nav__circle-btn rounded-circle"
            :style="faCheckCircle"
            type="button"
            @click="saveReadyForQuote"
            :disabled="isDisabled"
          >
            <i
              v-if="readyForQuoteData && !assignedDate && !quoteDate"
              class="fas fa-pound-sign fa-fw"
              style="font-size: 1.5em"
            ></i>
            <i
              v-if="readyForQuoteData && !assignedDate && quoteDate"
              class="fas fa-check-circle fa-fw"
              style="font-size: 1.5em"
            ></i>
            <i
              v-if="readyForQuoteData && assignedDate && quoteDate"
              class="fas fa-user-check fa-fw"
              style="font-size: 1.5em; color: #00c851"
            ></i>

            <div class="circle-btn__tooltip">
              <div
                v-if="readyForQuoteData && !assignedDate && !quoteDate"
                class="ready-btn__title mb-2"
              >
                Ready For Quote
              </div>

              <div
                v-if="readyForQuoteData && !assignedDate && quoteDate"
                class="ready-btn__title mb-2"
              >
                Assign Ready For Quote
              </div>
              <div v-if="readyForQuoteData && !assignedDate && quoteDate">
                <div>
                  Lead Generator:
                  <span>{{ readyForQuoteData.readyForQuoteFromName }}</span>
                </div>
                <span class="ready-btn__time-stamp">{{ quoteDate }}</span>
              </div>
              <div
                v-if="readyForQuoteData && assignedDate && quoteDate"
                class="ready-btn__asigned-to"
              >
                <div class="pb-2">Ready For Quote</div>
                <div>
                  Lead Generator:
                  <span>{{ readyForQuoteData.readyForQuoteFromName }}</span>
                </div>
                <time
                  ><span>{{ quoteDate }}</span></time
                >

                <div class="mt-2">
                  Closer:
                  <span>{{ readyForQuoteData.assignedByName }}</span>
                </div>
                <time
                  ><span>{{ assignedDate }}</span></time
                >
              </div>
            </div>
          </button>
        </div>
      </v-row>
    </v-col>

    <v-dialog
      content-class="overflow-hidden-content"
      v-model="isDispositionModalActive"
      width="900"
      style="overflow: hidden"
    >
      <v-card>
        <v-card-title>
          <v-row>
            <v-col cols="auto"> Disposition </v-col>
          </v-row>
        </v-card-title>

        <v-row
          v-if="readyForQuoteDispositions.length >= 1"
          class="ml-5 mb-4 mt-5 d-flex justify-center"
        >
          <v-col
            v-if="sortedPositiveReadyForQuoteDispositions.length > 0"
            cols="3"
            class=""
          >
            <v-btn
              v-for="readyForQuoteDisposition in sortedPositiveReadyForQuoteDispositions"
              :key="readyForQuoteDisposition.id"
              :color="readyForQuoteDisposition.class"
              stacked
              class="mt-2"
              @click="
                updateDisposition(
                  readyForQuoteDisposition.id,
                  readyForQuoteDisposition.disposition
                )
              "
            >
              {{ readyForQuoteDisposition.disposition }}
            </v-btn>
          </v-col>

          <v-col
            v-if="sortedNeutralReadyForQuoteDispositions.length > 0"
            cols="5"
            class="ml-2"
          >
            <v-btn
              v-for="readyForQuoteDisposition in sortedNeutralReadyForQuoteDispositions"
              :key="readyForQuoteDisposition.id"
              :color="readyForQuoteDisposition.class"
              class="mt-2"
              @click="
                updateDisposition(
                  readyForQuoteDisposition.id,
                  readyForQuoteDisposition.disposition
                )
              "
            >
              {{ readyForQuoteDisposition.disposition }}
            </v-btn>
          </v-col>

          <v-col
            v-if="sortedNegativeReadyForQuoteDispositions.length > 0"
            cols="3"
            class="ml-2"
          >
            <v-btn
              v-for="readyForQuoteDisposition in sortedNegativeReadyForQuoteDispositions"
              :key="readyForQuoteDisposition.id"
              :color="readyForQuoteDisposition.class"
              stacked
              class="mt-2"
              @click="
                updateDisposition(
                  readyForQuoteDisposition.id,
                  readyForQuoteDisposition.disposition
                )
              "
            >
              {{ readyForQuoteDisposition.disposition }}
            </v-btn>
          </v-col>
        </v-row>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn text @click="isDispositionModalActive = false"> Close </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="assignReadyForQuoteModal"
      persistent
      max-width="500"
      content-class="overflow-hidden-content"
      style="overflow: hidden"
    >
      <v-card>
        <v-card-title class="text-h5"> Assign Ready For Quote </v-card-title>
        <v-card-text>
          <v-row
            justify="middle"
            style="height: 156px; justify-content: center; margin-top: 15px"
          >
            <div class="col col-auto nav__circle-btn__container">
              <button
                class="ready-btn nav__circle-btn rounded-circle"
                :style="faCheckCircle"
                type="button"
                @click="saveReadyForQuote"
                :disabled="isDisabled"
              >
                <i
                  v-if="readyForQuoteData && !assignedDate && !quoteDate"
                  class="fas fa-pound-sign fa-fw"
                  style="font-size: 1.5em"
                ></i>
                <i
                  v-if="readyForQuoteData && !assignedDate && quoteDate"
                  class="fas fa-check-circle fa-fw"
                  style="font-size: 1.5em"
                ></i>
                <i
                  v-if="readyForQuoteData && assignedDate && quoteDate"
                  class="fas fa-user-check fa-fw"
                  style="font-size: 1.5em; color: #00c851"
                ></i>

                <div class="circle-btn__tooltip">
                  <div
                    v-if="readyForQuoteData && !assignedDate && !quoteDate"
                    class="ready-btn__title mb-2"
                  >
                    Ready For Quote
                  </div>

                  <div
                    v-if="readyForQuoteData && !assignedDate && quoteDate"
                    class="ready-btn__title mb-2"
                  >
                    Assign Ready For Quote
                  </div>
                  <div v-if="readyForQuoteData && !assignedDate && quoteDate">
                    <!-- <span class="tooltip__message">Ready For Quote</span><br> -->
                    <div>
                      Lead Generator:
                      <span>{{ readyForQuoteData.readyForQuoteFromName }}</span>
                    </div>
                    <span class="ready-btn__time-stamp">{{ quoteDate }}</span>
                  </div>
                  <div
                    v-if="readyForQuoteData && assignedDate && quoteDate"
                    class="ready-btn__asigned-to"
                  >
                    <div class="pb-2">Ready For Quote</div>
                    <div>
                      Lead Generator:
                      <span>{{ readyForQuoteData.readyForQuoteFromName }}</span>
                    </div>
                    <time
                      ><span>{{ quoteDate }}</span></time
                    >

                    <div class="mt-2">
                      Closer:
                      <span>{{ readyForQuoteData.assignedByName }}</span>
                    </div>
                    <time
                      ><span>{{ assignedDate }}</span></time
                    >
                  </div>
                </div>
              </button>
            </div>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      content-class="overflow-hidden-content"
      v-model="showProofNoteModal"
      width="500"
      style="overflow: hidden"
    >
      <v-card>
        <v-card-title>
          <v-row>
            <v-col cols="auto"> Proof Note </v-col>
          </v-row>
        </v-card-title>

        <v-row class="ml-4 mb-4 mt-4">
          <p>{{ proofNote }}</p>
        </v-row>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn text @click="showProofNoteModal = false"> Close </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import dayjs from "dayjs"

import MeterWidget from "@/components/meter-widget"
import DataValue from "@/components/data-value"
import LoaInfo from "@/components/dialogs/loa-info"
import LoaInfoCss from "@/components/dialogs/loa-info-css"
import PreviousQuotes from "@/components/dialogs/previous-quotes"
import Reviews from "@/components/dialogs/reviews.vue"
import LoadingSpinner from "@/components/loading-spinner"
import api from "@/store/api"
// import axios from 'axios'
import { changesSaved } from "@/helpers/utilities"

export default {
  data() {
    return {
      proofNote: "",
      showProofNoteModal: false,
      readyForQuoteDispositions: [],
      selectedReadyForQuoteDisposition: null,
      isDisabledForFiveSeconds: false,
      isDispositionModalActive: false,
      isDisabled: false,
      quoteExpired: false,
      recording: false,
      nfdFeedback: false,
      minutes: 0,
      seconds: 0,
      tick: null,
      readyForQuoteData: {},
      assignedDate: null,
      quoteDate: null,
      faCheckCircle: null,
      saleOptions: [],
      dialog: false,
      reasonId: "",
      feedbackComment: "",
      alert: false,
      alertText: "",
      alertColor: "",
      assignReadyForQuoteModal: false,
      isFirstTime: true,
    }
  },
  components: {
    DataValue,
    MeterWidget,
    LoaInfo,
    LoaInfoCss,
    PreviousQuotes,
    LoadingSpinner,
    Reviews,
  },
  created() {
    this.fetchReadyForQuote()
    this.getReadyForQuoteDispositions()
  },
  mounted() {
    this.nfdFeedback = this.company.nfdFeedback
  },
  computed: {
    ...mapGetters("employee", ["userId", "userName", "connexUser"]),
    ...mapGetters("company", ["details", "company", "companyId"]),
    ...mapGetters("meters", ["loadingHeaderMeters", "primaryMeters"]),
    ...mapGetters("contacts", ["primaryContactFullName"]),
    isMobile() {
      return this.$vuetify.breakpoint.lgOnly
    },

    sortedPositiveReadyForQuoteDispositions() {
      if (this.readyForQuoteDispositions.length > 0) {
        const readyForQuoteDispositions = []
        this.readyForQuoteDispositions.forEach((el) => {
          if (el.positive) {
            el.class = "success"
            readyForQuoteDispositions.push(el)
          }
        })

        return readyForQuoteDispositions
      } else {
        return []
      }
    },

    sortedNeutralReadyForQuoteDispositions() {
      if (this.readyForQuoteDispositions.length > 0) {
        const readyForQuoteDispositions = []

        this.readyForQuoteDispositions.forEach((el) => {
          if (el.neutral) {
            el.class = "warning"
            readyForQuoteDispositions.push(el)
          }
        })

        return readyForQuoteDispositions
      } else {
        return []
      }
    },
    sortedNegativeReadyForQuoteDispositions() {
      if (this.readyForQuoteDispositions.length > 0) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        const readyForQuoteDispositions = []

        this.readyForQuoteDispositions.forEach((el) => {
          if (el.negative) {
            el.class = "error"
            readyForQuoteDispositions.push(el)
          }
        })

        return readyForQuoteDispositions
      } else {
        return []
      }
    },
  },
  methods: {
    ...mapActions("notes", ["fetchNotesAfterSomeDelay", "fetchNotes"]),
    ...mapActions("quotes", ["fetchQuotes"]),
    ...mapActions("meters", ["fetchPrimaryMetersAfterSave", "fetchMeters"]),

    toggleTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark
    },
    async saveReadyForQuote() {
      if (this.readyForQuoteData && this.assignedDate && this.quoteDate) {
        if (this.userId === this.readyForQuoteData.assignedById) {
          this.isDispositionModalActive = true
        }
        return false
      }

      const readyForQuoteData = {}
      readyForQuoteData.companyId = this.companyId

      if (this.readyForQuoteData && !this.assignedDate && !this.quoteDate) {
        readyForQuoteData.readyForQuoteFrom = this.userId
        this.isDisabled = true
        setTimeout(() => {
          this.isDisabled = false
        }, 30000)
      } else if (
        this.readyForQuoteData &&
        !this.assignedDate &&
        this.quoteDate
      ) {
        readyForQuoteData.readyForQuoteId =
          this.readyForQuoteData.readyForQuoteId
        readyForQuoteData.assignedById = this.userId
      } else {
        return false
      }

      const response = await api.post("saveReadyForQuote", readyForQuoteData)
      // eslint-disable-next-line no-debugger
      // debugger
      if (response && response.status === 200) {
        this.readyForQuoteData = response.data
        this.readyForQuoteSetup(this.readyForQuoteData)
        setTimeout(() => {
          this.fetchReadyForQuote()
          this.fetchNotes()
          this.fetchQuotes()
          this.fetchPrimaryMetersAfterSave(this.companyId)
          this.fetchMeters()
        }, 1000)
      }
    },
    readyForQuoteSetup(readyForQuoteData) {
      const quoteDate =
        readyForQuoteData && readyForQuoteData.quoteDate
          ? dayjs(readyForQuoteData.quoteDate * 1000).format("YYYY-MM-DD")
          : 0
      const assignedDate =
        readyForQuoteData && readyForQuoteData.assignedDate
          ? dayjs(readyForQuoteData.assignedDate * 1000).format("YYYY-MM-DD")
          : 0
      this.quoteExpired = false

      //if (assignedDate == 0 || readyForQuoteData.leadNotActive) {
      if (assignedDate || quoteDate) {
        //const now = dayjs().format("YYYY-MM-DD")
        //const quoteDiffInHours = dayjs(now).diff(quoteDate, "hour")
        //const assignDiffInHours = dayjs(now).diff(assignedDate, "hour")
        // if(quoteDiffInHours >= 12 || assignDiffInHours >= 12) {
        if (readyForQuoteData.leadNotActive) {
          this.quoteExpired = true
        } else {
          this.quoteExpired = false
        }
      }
      if (this.quoteExpired) {
        this.assignedDate = ""
        this.quoteDate = ""
      } else {
        this.assignedDate =
          readyForQuoteData && readyForQuoteData.assignedDate
            ? dayjs(readyForQuoteData.assignedDate * 1000).format(
                "DD/MM/YYYY HH:mm:ss"
              )
            : ""
        this.quoteDate =
          readyForQuoteData && readyForQuoteData.quoteDate
            ? dayjs(readyForQuoteData.quoteDate * 1000).format(
                "DD/MM/YYYY HH:mm:ss"
              )
            : ""
        if (readyForQuoteData && !this.assignedDate && this.quoteDate) {
          this.faCheckCircle = {
            backgroundColor: "#007bff",
            color: "#fff",
          }
        } else {
          this.faCheckCircle = null
        }
      }

      if (
        this.readyForQuoteData &&
        !this.assignedDate &&
        this.quoteDate &&
        this.isFirstTime
      ) {
        this.assignReadyForQuoteModal = true
      } else {
        this.assignReadyForQuoteModal = false
      }
      this.isFirstTime = false
    },
    async fetchReadyForQuote() {
      const response = await api.get(`readyForQuote/${this.companyId}`)
      if (response && response.status === 200) {
        this.readyForQuoteData = response.data
        this.readyForQuoteSetup(this.readyForQuoteData)
      }
    },

    // ReadyForQuote/updateDisposition/{id}/{dispositionId}

    async updateDisposition(dispositionId, disposition) {
      const noteMessage = `Ready For Quote Disposition as ${disposition} From ${this.userName}`
      const updateDispositionData = {
        readyForQuoteId: this.readyForQuoteData.readyForQuoteId,
        dispositionId: dispositionId,
        currentUserId: this.userId,
        companyId: parseInt(this.companyId),
        noteMessage: noteMessage,
      }

      const response = await api.post(
        `updateDisposition`,
        updateDispositionData
      )
      if (response && response.status === 200) {
        changesSaved("Ready For Quote Successfully Dispositioned!")
        this.isDispositionModalActive = false
        this.selectedReadyForQuoteDisposition = null
        this.fetchReadyForQuote()
        this.fetchQuotes()
        this.fetchNotes()
      }
    },

    async getReadyForQuoteDispositions() {
      const response = await api.get(`getReadyForQuoteDispositions`)
      if (response && response.status === 200) {
        this.readyForQuoteDispositions = response.data.sort((a, b) => {
          if (a.id > b.id) return 1
          if (a.id < b.id) return -1
          return 0
        })
      }
    },

    startRecording() {
      this.isDisabledForFiveSeconds = true
      setTimeout(() => {
        this.isDisabledForFiveSeconds = false
      }, 5000)
      const microphoneBtn = document.querySelector("#microphoneBtn")
      const microphoneMessage = document.querySelector("#microphoneMessage")
      microphoneBtn.classList.toggle("microphone-active")
      microphoneMessage.innerHTML = "Click to Stop Recording"
      // const self =this
      // this.saveStartRecording()
      // setTimeout(function () { self.saveStopRecording() }, 2000)

      if (!this.recording) {
        this.saveStartRecording()
      } else {
        this.saveStopRecording()
      }

      this.fetchNotesAfterSomeDelay()
      //this.initRecording();
      if (!this.seconds && !this.tick) {
        this.recording = true
        // $("#btnLOASave").attr("disabled", true)
        // $editButtons.each(function () {
        //     $(this).attr("disabled", true)
        //     $(this).css({ 'cursor': 'not-allowed', 'pointer-events': 'none' })
        // })
        this.recordTimer()
        this.tick = window.setInterval(() => {
          this.seconds += 1
          this.recordTimer()
        }, 1000)
      } else {
        // stopRecording(currentUsername)
        this.recording = false
        microphoneMessage.innerHTML = "Click to Start Recording"
        // $("#btnLOASave").attr("disabled", false)
        // $editButtons.each(function () {
        //     $(this).attr("disabled", false)
        //     $(this).css({ 'cursor': '', 'pointer-events': '' })
        // })
        this.minutes = 0
        this.seconds = 0
        this.recordTimer()
        window.clearInterval(this.tick)
        this.tick = null
      }
    },
    recordTimer() {
      const microphoneTime = document.querySelector("#microphoneTime")

      if (this.seconds === 60) {
        this.seconds = 0
        this.minutes += 1
      }
      microphoneTime.innerHTML = `${this.minutes}:${this.seconds
        .toString()
        .padStart(2, "0")}`
    },

    async saveStartRecording() {
      const response = await api.post(
        `startRecording/${this.connexUser}/${this.userId}/${this.companyId}`
      )

      if (response && response.status === 200) {
        return response
      }
    },

    async saveStopRecording() {
      const response = await api.post(
        `stopRecording/${this.connexUser}/${this.userId}/${this.companyId}`
      )

      if (response && response.status === 200) {
        return response
      }
    },
    initRecording() {
      const response = api.post(`recording/${this.connexUser}`)

      if (response && response.status === 200) {
        return response
      }
    },
    async getOptions() {
      const response = await api.get(`reasonOption`)
      this.saleOptions = response.data
    },
    setReason(e) {
      this.reasonId = e
    },
    async submitFeedback() {
      if (this.reasonId === "" || this.reasonId === null) {
        this.alertText = "Please select a Reason"
        this.alertColor = "red--text"
        this.alert = true
        return false
      }

      const feedbackForm = {
        companyId: this.companyId,
        feedbackReasonID: this.reasonId,
        comments: this.feedbackComment,
        feedbackBy: this.userId,
        feedbackByName: this.userName,
      }

      const response = await api.post("saveFeedback", feedbackForm)
      console.log(response)

      if (response && response.status === 200) {
        this.alertText = "Thank you for your Feedback"
        this.alertColor = "green--text"
        this.alert = true
        this.nfdFeedback = true
        this.dialog = false
      }

      this.reasonId = ""
      this.feedbackComment = ""
    },
    setNote(proofNote) {
      // alert(proofNote)
      this.proofNote = proofNote
      this.showProofNoteModal = true
    },
  },
}
</script>

<style scoped>
.nav__circle-btn {
  background-color: #fff;
  color: rgba(0, 0, 0, 0.54);
  padding: 0rem;
  height: 48px;
  width: 48px;
  border: thin solid currentColor;
  line-height: 1;
  position: relative;
}

.microphone-time {
  display: block;
  transition: opacity 300ms ease-in-out;
  color: #fff;
  font-size: 1.5rem;
}

.microphone-btn i {
  transition: transform 300ms linear;
}

.microphone-btn:focus {
  outline: none;
}

.nav__circle-btn.microphone-active .microphone-time {
  opacity: 1;
}

.nav__circle-btn.microphone-active i {
  color: #ff3547;
  transition: color 300ms ease-in;
  animation-name: recording;
  animation-duration: 1200ms;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes recording {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}

.nav__circle-btn__container .circle-btn__tooltip {
  background: #000;
  color: #fff;
  display: block;
  margin-top: 20px;
  opacity: 0;
  padding: 1rem;
  pointer-events: none;
  position: absolute;
  left: -88px;
  width: 235px;
  transition: all 0.25s ease-out;
  border: 1px solid transparent;
  border-radius: 3px;
  box-shadow: 0px 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

.nav__circle-btn .circle-btn__tooltip:before {
  top: -20px;
  content: " ";
  display: block;
  height: 20px;
  left: 0;
  position: absolute;
  width: 100%;
}

.nav__circle-btn .circle-btn__tooltip:after {
  border-left: solid transparent 10px;
  border-right: solid transparent 10px;
  border-bottom: solid #000 10px;
  top: -10px;
  content: " ";
  height: 0;
  left: 50%;
  margin-left: -13px;
  position: absolute;
  width: 0;
}

.nav__circle-btn__container:hover .circle-btn__tooltip {
  opacity: 0.85;
  pointer-events: auto;
}

.microphone-btn.microphone-active .circle-btn__tooltip {
  background-color: #ff3547;
}

.microphone-btn.microphone-active .circle-btn__tooltip:after {
  border-bottom-color: #ff3547;
}
.ready-btn.ready-btn--ready {
  background-color: #007bff;
  color: #fff;
}

.ready-btn.ready-btn--ready .tooltip__message {
  display: inline-block;
}

.ready-btn.ready-btn--assigned {
  color: #00c851;
}
.ready-btn.ready-btn--assigned .circle-btn__tooltip,
.ready-btn.ready-btn--ready .circle-btn__tooltip {
  width: 250px;
  left: -92px;
}
.ready-btn:focus {
  outline: none;
}

.v-dialog.overflow-hidden-content {
  overflow: hidden !important;
}
.text-orange {
  color: #ffa500;
}
.text-primary {
  color: #007bff !important;
}
</style>
