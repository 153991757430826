<template>
  <v-card outlined rounded="lg">
    <v-card-title class="py-2">
      <v-row align="center" justify="space-between">
        <v-col class="text-button">
          <v-icon class="mr-1" small>mdi-office-building</v-icon>
          Company Information
        </v-col>
        <v-col cols="auto">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
             <v-btn
             color="blue"
              v-bind="attrs"
              v-on="on"
              depressed
              icon
              small
              @click.stop="editCompany">
               <v-icon small>mdi-pencil</v-icon>
             </v-btn>
            </template>
            <span>Edit Company</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-card-title>
    <v-divider></v-divider>
    <div class="px-4 pt-4">
      <!-- {{details}} -->
      <CreditScore
        :creditSafeCompanyName="details.creditSafeCompanyName"
        :creditSafeScore="details.creditSafeScore"
        :creditSafeLimit="details.creditSafeLimit"
        :creditScoreDate="details.creditScoreDate"
        :experianCompanyName="details.experianCompanyName"
        :experianScore="details.experianScore"
        :experianLimit="details.experianLimit"
        :experianScoreDate="details.experianScoreDate"
        :companyId="details.companyId"
        :companyName="details.companyName"
      />
    </div>

    <v-list two-line>
      <v-list-item>
        <v-row>
          <v-col cols="1">
            <v-avatar color="blue lighten-1" size="32">
              <v-icon dark small>mdi-phone</v-icon>
            </v-avatar>
          </v-col>
          <v-col class="ml-1">
            <div class="grey--text" style="font-size:11px">Primary Phone Number</div>
            <span class="cursor-pointer" @click="handleCopy">{{ details.telephone }}</span>
          </v-col>
        </v-row>
      </v-list-item>
      <v-list-item>
        <DataValue
          label="Primary Address"
          :value="{
            firstLine: details.add1,
            secondLine: details.add2,
            town: details.town,
            county: details.county,
            postcode: details.postcode
          }"
          icon="map-marker"
          icon-color="red lighten-1"
          address-type
        />
      </v-list-item>
      <v-list-item>
        <DataValue
          label="Trading Type"
          :value="details.tradingType"
          icon="domain"
          icon-color="teal lighten-1"
        />
      </v-list-item>
      <v-list-item v-if="hasRegistrationNo">
        <DataValue
          label="Registration Number"
          :value="details.registrationNo === 'undefined' || details.registrationNo === '' || details.registrationNo === null ? 'No Registration Number Found' : details.registrationNo"
          icon="pound"
          icon-color="indigo lighten-1"
        />
      </v-list-item>
      <v-list-item>
        <DataValue
          label="Nature of Business"
          :value="details.businessType"
          :icon="sicIcon"
          icon-color="amber lighten-1"
        />
      </v-list-item>
      <v-list-item >
        <DataValue
          label="Primary Contact"
          :value="primaryContactFullName === 'undefined undefined' ? 'No Primary Contact Found' : primaryContactFullName"
          icon="account-box"
          icon-color="accent lighten-1"
        />
      </v-list-item>
      <v-list-item>
        <DataValue
          v-if="primaryContactEmailAddress === 'undefined' || primaryContactEmailAddress === '' || primaryContactEmailAddress === null"
          label="Primary Email"
          value="No Primary Email Found"
          icon="email"
          icon-color="green lighten-1"
        />
        <DataValue
          v-else
          label="Primary Email"
          :value="primaryContactEmailAddress"
          icon="email"
          :href="`mailto:${primaryContactEmailAddress}`"
          icon-color="green lighten-1"
        />
      </v-list-item>
      <v-list-item>
        <DataValue
          label="Company Website"
          :value="details.website"
          :href="`https://${details.website}`"
          :target="`popup`"
          icon="at"
          icon-color="blue lighten-1"
        />
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
import DataValue from "@/components/data-value"
import { mapActions, mapGetters } from "vuex"
import CreditScore from "@/components/credit-score"
import { copyTextToClipboard } from "@/helpers/utilities"
import { trackerMethod } from '@/helpers/logs.js'

export default {
  components: {
    DataValue,
    CreditScore
  },
  data(){
    return {
      SupplyNumber: ''
    }
  },
  computed: {
    ...mapGetters("company", ["details", "hasRegistrationNo", "sicSection", "companyId"]),
    ...mapGetters("contacts", ["primaryContact", "primaryContactFullName", "primaryContactEmailAddress"]),
    ...mapGetters("employee", ["userId"]),

    sicIcon() {
      const section = this.sicSection
      return section ? this.$helpers.sicIcons[section] : "help"
    }
  },
  methods: {
    ...mapActions("ui", ["openForm"]),
    editCompany(){
      // this.details(this.companyId),
      this.openForm({ formName: 'company-form', edit: true })
    },
    handleCopy() {
      const page = window.location.href

      trackerMethod(
        this.userId,
        page,
        this.details.telephone,
        this.SupplyNumber,
        this.companyId
      )
      copyTextToClipboard(this.details.telephone)
    },
  }
}
</script>
