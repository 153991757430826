<template>
    <v-dialog v-model="visible" width="700">
        <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              color="info"
              depressed
              icon
              small
              @click="regReportsOpened"
            >
                <v-icon  small>mdi-information</v-icon>
            </v-btn>
        </template>

        <v-card>
            <v-card-title style="background-color: #007bff ;color: #fff !important;">
                REGISTRATION REPORTS
            </v-card-title>
            <v-card-subtitle class="pt-1 pb-2" style="background-color: #007bff ;color: #fff !important;">
                Registration Reports Information
            </v-card-subtitle>
            <v-card-text class="mt-2">
                <v-row>
                    <v-col v-if="!regReports.length" class="d-flex justify-center" cols="12" style="display: flex; justify-content: center; align-items: center; width: 600px; height: 700">
                      <h3>No Reg Reports Found For This Sale</h3>
                    </v-col>
                    <v-col v-else class="d-flex justify-center" cols="12" style="display: flex; justify-content: center; width: 600px; height: 700">
                      <v-carousel v-if="regReports.length > 0" hide-delimiters height="700" width="600">
                        <template v-for="(regReport, index) in regReports" >
                          <v-carousel-item :key="index" height="700" >
                            <!-- <Reports v-if="index % 2 === 0" class="mr-2" :reg-report="regReport" :supply-number="supplyNumber" /> -->
                            <Reports :reg-report="regReport" :supply-number="supplyNumber" />
                            <v-row class="d-flex justify-center " style="color: #000" >
                              {{ index + 1 }} of {{ regReports.length }}
                            </v-row>
                          </v-carousel-item>
                        </template>
                        <!-- <v-slide-item>
                          <Reports />
                        </v-slide-item> -->
                      </v-carousel>
                    </v-col>




                </v-row>
            </v-card-text>
            <v-divider></v-divider>

            <v-card-actions>
              <v-btn text @click="visible = false">
                Close
              </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
// import LoadingSpinner from "@/components/loading-spinner"
// import EmptyCard from "@/components/empty-card"
import api from "@/store/api"
// import { chunkArray } from "@/store/helpers/utils"
// import dayjs from "dayjs"


import Reports from '@/components/reports.vue'
export default {
    components: {
        Reports
    },
    data() {
        return {
            visible: false,
            // regReports: [],
            chunkedRegReports: [],
            regReports: [{
                id: 1,
                customerName: 'The four Ferns Cafe',
                broker: 'plt@utilityhice.com',
                crd: '07/09/2020',
                itemId: '153805679',
                product: 'Gas',
                mpan: '1547710910',
                profileClass: '',
                supplier: 'GnEnergy',
                oldSupplier: 'Axis',
              },
              {
                id: 1,
                csd: '07/09/2020',
                applicationDate: '07/09/2020',
                fsd: '07/09/2020',
                term: '36',
                ced: '07/09/2020s',
                uplift: '',
              },
              {
                id: 1,
                status: 'Rejected',
                status1: 'Failed Check',
                status2: 'Failed Check',
                status3: 'Failed Check',
                objectionsReceived: '0',
                oad: '1/05/2021',
                brokerReference: '65757742',
              },
              {
                id: 1,
                plc: '£ 500.00',
                pcv: '£ 500.00',
                tcr: '£ 500.00',
                tcpa: '£ 500.00',
                amountPaid: '£ 500.00',
                datePaid: '11/05/2021',
                importDate: '11/05/2021',
                saleId: '41561',
              },
            ]
        }
    },
    props: {
      supplyNumber: {
        type: String,
        default: ''
      }
    },
    methods: {
      regReportsOpened() {
        this.fetchRegReports()
      },
      async fetchRegReports() {
        const res = await api.get(
          `regReport/${this.supplyNumber}`
          // `regReport/1012363679671`
          
        )
        if (res && res.status === 200) {
          console.log('Reports', res.data)
          this.regReports = res.data
          // this.regReports.push(res.data)
          // this.chunkedRegReports = chunkArray(this.regReports, 4)
        }

      }
    }
}
</script>