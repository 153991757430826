<template>
    <div>
        <v-text-field
            :ref="refName"
            maxlength="11"
            :value="value"
            :label="label"
            :rules="contactNumberRules"
            :required="required"
            @input="updateValue"
            @keypress="isNumberKey"
            @paste="onPaste"
        ></v-text-field>
        <span v-if="errorMsgCC" class="mt-3">
            <span class="red--text" v-html="errorMsgCC"></span>
        </span>
    </div>

</template>

<script>

export default {
    inheritAttrs: false,
    props: {
        refName: [String],
        label: [String],
        value: [String, Number],
        errorMsgCC: [String],
        required: [Boolean],
    },
    methods: {
        updateValue(value) {
            this.$emit('input', value)
        },
        isNumberKey(evt) {
            const charCode = evt.which ? evt.which : evt.keyCode
            if (charCode > 31 && (charCode < 48 || charCode > 57)) {
                event.returnValue = false
                return false
            } else {
                event.returnValue = true
                return true
            }
            
        },
        onPaste(evt) {
            const data = evt.clipboardData.getData('text')
            const splitData = data.split("")
            this.showInvalidFormatError = false
            for (let i = 0; i < splitData.length; i++) {
                if (isNaN(parseInt(splitData[i]))) {
                    event.returnValue = false
                    return false
                }
                
            }
        },

    },
    computed: {
        contactNumberRules() {
            if(!this.value) {
                return [
                    () => true
                ]
            }
            return [
                (v) => {
                const validate = /^(00|034|084|087|04|05|06|09)/.test(v)
                return (
                    !validate || "Numbers Starting With '00, 034, 084, 087, 04, 05, 06, 09' Are Not Allowed"
                )
                },
                // (v) => /[0-9]+$/.test(v) || "Only Numeric Characters Allowed.",
                (v) => v.startsWith(0) || "Phone Number must start with 0.",
                (v) =>
                (v && v.length > 9) || "Phone Number must have at least 10 digits.",
                // v => /(00|034|084|087|04|05|06|09)+$/.test(v) || "Numbers Starting With '00, 034, 084, 087, 04, 05, 06, 09' Are Not Allowed",
            ]
        },

    }
}

</script>