<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" width="90%">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          @click="generateQuoteOpened"
          v-bind="attrs"
          v-on="on"
          color="indigo"
          depressed
          icon
          small
        >
          <i
            class="fas fa-tag"
            id="target-3"
            style="
              font-size: 13px;
              transform: translateY(1px);
              color: cornflowerblue;
            "
          ></i>
        </v-btn>
      </template>
      <v-card flat>
        <v-card-title style="background-color: #0062cc; color: #fff !important">
          <v-row class="d-flex justify-space-between" align="center">
            <v-col cols="auto">
              <span class="headline">Generate Quote</span>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="mt-2" depressed>
          <v-card outlined style="padding: 16px">
            <div class="w-100 d-flex justify-space-between align-items-center">
              <v-card outlined style="padding: 16px; padding-bottom: 0">
                <div
                  style="display: flex; gap: 20px"
                  :class="`${
                    !showQuotingDetails ? 'generate-quote-meter-card' : ''
                  }`"
                >
                  <v-row align="center" class="mb-2 align-items-center">
                    <v-col cols="1">
                      <v-icon
                        class="d-flex justify-center"
                        size="32"
                        :color="typeColor"
                      >
                        {{ typeIcon }}
                      </v-icon>
                    </v-col>
                    <v-col cols="auto">
                      <v-img
                        style="width: 110px; height: 54px"
                        contain
                        :src="$helpers.supplierLogo(selectedMeter.supplierId)"
                      ></v-img>
                    </v-col>
                    <v-col cols="auto" class="">
                      <div
                        v-if="
                          selectedMeter.estimateActual.toLowerCase() ===
                          'estimate'
                        "
                        class="text-center"
                      >
                        Estimate
                      </div>
                      <SupplyNumber
                        v-else
                        :type="selectedMeter.meterType"
                        :supply-number="selectedMeter.supplyNumber"
                        :pc="selectedMeter.pc"
                        :mtc="selectedMeter.mtc"
                        :llf="selectedMeter.llf"
                        :disabled="true"
                      />
                    </v-col>

                    <v-col
                      v-if="estimate"
                      cols="1"
                      style="margin-right: 29px"
                    ></v-col>

                    <!-- <v-col v-if="!estimate" cols="8"></v-col> -->
                    <v-col class="d-flex" cols="auto" align="center">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-row dense align="center" v-bind="attrs" v-on="on">
                            <v-col cols="auto">
                              <v-icon small color="deep-orange"
                                >mdi-calendar-remove</v-icon
                              >
                            </v-col>
                            <v-col>
                              <span>{{
                                $helpers.formatDate(selectedMeter.ced)
                              }}</span>
                            </v-col>
                          </v-row>
                        </template>
                        <span>Contract End Date</span>
                      </v-tooltip>
                    </v-col>
                    <v-col cols="auto">
                      <v-menu
                        ref="menu"
                        v-model="menu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <v-date-picker
                          ref="picker"
                          :disabled="!showQuotingDetails"
                          v-model="csd"
                          :min="formattedMinContractEndDate"
                          @change="dateOfCSDChanged"
                          locale="en-GB"
                        ></v-date-picker>
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            class="d-inline-block mr-2"
                            type="tel"
                            hide-details
                            v-bind="attrs"
                            v-on="on"
                            readonly
                            :disabled="!showQuotingDetails"
                            v-model="csdText"
                            label="Contract Start Date"
                            v-mask="'##/##/####'"
                            @input="contractStartDateChanged"
                          ></v-text-field>

                          <v-icon
                            :disabled="!showQuotingDetails"
                            class="d-inline-block mr-2"
                            v-bind="attrs"
                            v-on="on"
                          >
                            mdi-calendar
                          </v-icon>
                        </template>
                      </v-menu>
                    </v-col>
                    <v-col cols="2" v-if="meterTypeId == 9">
                      <v-select
                        ref="selectedMeterType"
                        label="Meter Type"
                        hide-details
                        v-model="selectedMeterType"
                        :items="contractMeterTypesList"
                        :disabled="!showQuotingDetails"
                        @change="changeMeterType"
                      ></v-select>
                    </v-col>
                    <v-col cols="2" v-if="meterTypeId == 10">
                      <div
                        class=""
                        style="
                          font-size: 12px;

                          color: var(--v-primary-base);
                          caret-color: var(--v-primary-base);
                        "
                      >
                        Postcode
                      </div>

                      <v-text-field
                        style="padding: 0; margin: 0"
                        hide-details
                        v-model="selectedAddress.postcode"
                        disabled
                      ></v-text-field>
                    </v-col>
                    <v-col cols="2" v-if="meterTypeId == 10">
                      <div
                        class=""
                        style="
                          font-size: 12px;

                          color: var(--v-primary-base);
                          caret-color: var(--v-primary-base);
                        "
                      >
                        LDZ
                      </div>

                      <v-text-field
                        style="padding: 0; margin: 0"
                        hide-details
                        v-model="selectedAddress.ldz"
                        disabled
                      ></v-text-field>
                    </v-col>
                    <!-- <v-col cols="auto" v-if="meterTypeId === 10">
                      <label class="text-secondary text-sm">LDZ</label>
                      <b-input-group>
                        <b-form-input
                          v-model="selectedAddress.ldz"
                          disabled
                        ></b-form-input>
                      </b-input-group>
                    </v-col> -->
                  </v-row>
                </div>
              </v-card>
              <v-btn
                variant="flat"
                v-if="shouldRefreshQuote"
                text="Button"
                :style="{
                  backgroundColor: !showQuotingDetails ? '#218838' : '#dc3545',
                }"
                style="width: 160px; height: 45px"
                class="align-self-center d-flex justify-content-center align-items-center"
                color="#fff"
                @click="showQuotingDetails = !showQuotingDetails"
                ><i
                  :class="`fas mr-1 ${
                    !showQuotingDetails
                      ? 'fa-angle-double-down'
                      : 'fa-angle-double-up'
                  }`"
                ></i>
                {{
                  !showQuotingDetails ? "Show Details" : "Hide Details"
                }}</v-btn
              >
            </div>

            <div v-if="stepNo === 1 && showQuotingDetails">
              <!--  -->

              <v-row
                class="mt-4"
                v-if="
                  selectedMeterType &&
                  csdText &&
                  quoteTypePriceList &&
                  quoteTypePriceList.length > 0
                "
              >
                <v-col :class="{ submitCard: mobileView }">
                  <v-card outlined style="color: transparent">
                    <v-container>
                      <v-card outlined style="background: #e5f2ff">
                        <ComparativePrices
                          :changed-meter-type="changedMeterType"
                          :quote-type-price-list="quoteTypePriceList"
                        ></ComparativePrices>

                        <EacPrices
                          :quote-type-price-list="quoteTypePriceList"
                          :eac-disabled="false"
                          :eac-guided-value="0"
                        ></EacPrices>
                      </v-card>
                      <v-card
                        outlined
                        style="background: #e5f2ff; margin-top: 12px"
                      >
                        <TotalEac
                          :total-eac="totalEac"
                          :total-yearly-cost="totalYealyCost"
                        ></TotalEac>
                      </v-card>
                      <v-btn
                        :disabled="isLoading"
                        :style="{
                          backgroundColor: isLoading
                            ? 'rgb(0 123 255 / 30%)'
                            : 'rgb(0 123 255 / 100%)',
                          borderColor: isLoading
                            ? 'rgb(0 123 255 / 30%)'
                            : 'rgb(0 123 255 / 100%)',
                          color: '#fff',
                        }"
                        text="Button"
                        class="mt-2 mb-2"
                        variant="primary"
                        @click="fetchQuoteUploadELEC"
                        >{{ isLoading ? "Quoting..." : getQuoteText }}</v-btn
                      >
                    </v-container>
                  </v-card>
                </v-col>
              </v-row>
            </div>

            <table
              class="table-generate-quote text-center table-hover"
              style="margin-top: 30px; width: 100%"
              v-if="shouldRefreshQuote"
            >
              <thead>
                <!-- filters -->
                <th colspan="5">
                  <div
                    v-if="shouldRefreshQuote"
                    :style="{
                      marginTop: '20px',
                      marginBottom: '10px',
                      gap: '50px',
                    }"
                    class="d-flex align-items-center"
                  >
                    <div class="d-flex flex-column justify-space-between">
                      <div class="filter-label-generate-quote" style="">
                        Supplier:
                      </div>
                      <!-- <b-dropdown
                        text="Select Suppliers "
                        class="mt-2 generate-quote-supplier-dropdown"
                      >
                        <template #button-content>
                          {{
                            selectedSupplierNames.join(", ") ||
                            "No Supplier Selected"
                          }}
                        </template>

                        <b-form-checkbox-group
                          style="width: 198px"
                          v-model="selectedSuppliers"
                          stacked
                        >
                          <b-form-checkbox
                            style="margin-left: 12px; padding: 6px 6px 6px 24px"
                            v-for="supplier in uniqueSuppliers"
                            :key="supplier.value"
                            :value="supplier.value"
                          >
                            {{ supplier.text }}
                          </b-form-checkbox>
                        </b-form-checkbox-group>
                      </b-dropdown> -->
                      <v-menu offset-y :close-on-content-click="false">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            class="justify-space-between"
                            small
                            color="grey darken-4"
                            outlined
                            v-bind="attrs"
                            v-on="on"
                          >
                            {{
                              selectedSupplierNames.join(", ") ||
                              "No Supplier Selected"
                            }}
                            <v-icon right>mdi-chevron-down</v-icon>
                          </v-btn>
                        </template>

                        <v-list>
                          <v-list-item-group
                            v-model="selectedSuppliers"
                            multiple
                            active-class="primary--text"
                          >
                            <v-list-item
                              v-for="supplier in uniqueSuppliers"
                              :key="supplier.value"
                              :value="supplier.value"
                            >
                              <v-list-item-action>
                                <v-checkbox
                                  color="indigo darker-4"
                                  :input-value="
                                    selectedSuppliers.includes(supplier.value)
                                  "
                                ></v-checkbox>
                              </v-list-item-action>
                              <v-list-item-content>
                                <v-list-item-title>{{
                                  supplier.text
                                }}</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list-item-group>
                        </v-list>
                      </v-menu>
                    </div>
                    <div class="d-flex flex-column">
                      <span class="filter-label-generate-quote"
                        >Term (Months):</span
                      >
                      <div
                        class="d-flex justify-content-center mt-2 align-items-center"
                        style="gap: 8px"
                      >
                        <div v-for="term in terms" :key="term">
                          <v-btn
                            small
                            color="blue darken-4"
                            class="generate-quote-button white--text"
                            :outlined="!selectedTerms.includes(term)"
                            @click="toggleTermSelection(term)"
                          >
                            {{ term }}
                          </v-btn>
                        </div>
                      </div>
                    </div>
                    <div
                      class="d-flex justify-content-end align-self-end flex-column"
                    >
                      <v-btn
                        color="blue darken-4"
                        class="generate-quote-button white--text"
                        :outlined="!selectedFixedCommission"
                        @click="showOnlyFixedCommission"
                        small
                      >
                        Show Only Fixed Commission
                      </v-btn>
                    </div>
                  </div>
                  <!-- </div> -->
                </th>
                <!-- Global Uplift Standing Charge  -->
                <th class="position-relative">
                  <div class="filter-label-generate-quote global-uplift">
                    Global Uplift
                  </div>
                  <v-text-field
                    v-model="globalSCUplift"
                    suffix="p"
                    disabled
                    hide-details
                    style="
                      width: 70%;
                      margin: auto;
                      font-weight: 400;
                      font-size: 13px;
                    "
                    class="mb-1"
                    :min="0"
                    :max="globalSCMaxUplift"
                  ></v-text-field>
                  <v-slider
                    v-model="globalSCUplift"
                    thumb-label
                    :step="globalSCIncUplift"
                    :min="0"
                    hide-details
                    :max="globalSCMaxUplift"
                    @change="onStandingChargeUpliftChange(1)"
                    style="width: 70%; margin: auto"
                  ></v-slider>
                  <!-- <div class="filter-label-generate-quote global-uplift">
                    Global Uplift
                  </div>
                  <b-input-group
                    size="sm"
                    append="p"
                    class="mb-2"
                    min="0"
                    style="width: 70%; margin: auto"
                    :max="globalSCMaxUplift"
                  >
                    <b-form-input
                      v-model="globalSCUplift"
                      disabled
                    ></b-form-input>
                  </b-input-group>
                  <b-form-input
                    v-model="globalSCUplift"
                    class="mb-2"
                    type="range"
                    :step="globalSCIncUplift"
                    min="0"
                    :max="globalSCMaxUplift"
                    @change="onStandingChargeUpliftChange(1)"
                  ></b-form-input> -->
                </th>
                <!-- Global Uplift Unit Rate -->

                <th>
                  <v-text-field
                    v-model="globalURUplift"
                    suffix="p"
                    disabled
                    hide-details
                    style="
                      width: 70%;
                      margin: auto;
                      font-weight: 400;
                      font-size: 13px;
                    "
                    :min="0"
                    :max="globalURMaxUplift"
                    class="mb-1"
                  ></v-text-field>
                  <v-slider
                    v-model="globalURUplift"
                    class="mb-0"
                    hide-details
                    thumb-label
                    :step="globalURIncUplift"
                    :min="0"
                    :max="globalURMaxUplift"
                    @change="onStandingChargeUpliftChange(2)"
                    style="width: 70%; margin: auto"
                  ></v-slider>
                  <!-- <b-input-group
                    append="p"
                    size="sm"
                    class="mb-2"
                    min="0"
                    style="width: 70%; margin: auto"
                    :max="globalURMaxUplift"
                  >
                    <b-form-input
                      v-model="globalURUplift"
                      disabled
                    ></b-form-input>
                  </b-input-group>
                  <b-form-input
                    v-model="globalURUplift"
                    class="mb-2"
                    type="range"
                    :step="globalURIncUplift"
                    min="0"
                    :max="globalURMaxUplift"
                    @change="onStandingChargeUpliftChange(1)"
                  ></b-form-input> -->
                </th>
              </thead>
              <thead>
                <tr class="generat-modal-head">
                  <th>Supplier</th>
                  <th>Term</th>
                  <th>Total Cost / Rates</th>
                  <th>Monthly Cost</th>
                  <!-- <th>Savings</th> -->
                  <th>Total Yearly Savings</th>
                  <!-- <th>Monthly Savings</th> -->
                  <th>Standing Charge Uplift</th>
                  <th>Unit Rate Uplift</th>
                  <th>Estimated Commission</th>
                  <th style="width: 150px">Actions</th>
                </tr>
              </thead>
              <tr v-show="isLoading">
                <td colspan="9">
                  <b-skeleton-wrapper :loading="isLoading">
                    <template v-slot:loading>
                      <b-skeleton-table
                        :rows="9"
                        :columns="9"
                        hide-header="true"
                        :table-props="{ bordered: false, striped: false }"
                      ></b-skeleton-table>
                    </template>
                  </b-skeleton-wrapper>
                </td>
              </tr>
              <tbody>
                <template v-if="sortedQuotes.length > 0 && !isLoading">
                  <template v-for="(l, index) in sortedQuotes">
                    <tr
                      v-if="unixTimestamp > l.effectiveDate"
                      class="text-secondary text-sm mb-3"
                      :key="`item-${index}`"
                    >
                      <!-- Supplier -->
                      <td class="d-flex justify-content-center mb-3">
                        <div
                          class="estimate-container flex-column"
                          style="width: 110px; height: 50px"
                        >
                          <v-img
                            style="width: 110px; height: 54px"
                            contain
                            :src="l.supplierExternalLogo"
                          ></v-img>
                          <div
                            class="text-sm mt-2"
                            style="font-weight: bold; color: #555"
                          >
                            {{ l.supplierName }}
                          </div>
                        </div>
                      </td>
                      <!-- Term -->
                      <td class="mb-3">
                        <div class="text-secondary text-sm">
                          {{ l.term }} Months
                        </div>
                      </td>
                      <!-- Total Cost / Rates -->
                      <td class="mb-3">
                        <div class="text-left mb-1">
                          <span style="font-weight: bold; font-size: 12px">
                            Total Cost:
                          </span>
                          <span>{{ formattedTotalCost(l) }}</span>
                        </div>
                        <div class="text-left mb-1">
                          <span style="font-weight: bold; font-size: 12px">
                            Rates:
                          </span>
                          <span
                            class="mr-1"
                            v-if="quoteTypePriceListIds.includes(1)"
                            ><strong style="font-weight: 600">
                              Standing Charge:
                            </strong>
                            {{
                              formatPriceRates(
                                +l.standingCharge + +l.standingChargeUplift
                              )
                            }}
                          </span>
                          <span
                            class="mr-1"
                            v-if="quoteTypePriceListIds.includes(2)"
                          >
                            <strong style="font-weight: 600">
                              Unit Rate: </strong
                            >{{
                              formatPriceRates(+l.unitRate + +l.unitRateUplift)
                            }}
                          </span>
                          <span
                            class="mr-1"
                            v-if="quoteTypePriceListIds.includes(3)"
                            ><strong style="font-weight: 600">
                              Day Rate:
                            </strong>
                            {{
                              formatPriceRates(+l.dayRate + +l.unitRateUplift)
                            }}
                          </span>
                          <span
                            class="mr-1"
                            v-if="quoteTypePriceListIds.includes(12)"
                            ><strong style="font-weight: 600">
                              Night Rate:
                            </strong>
                            {{
                              formatPriceRates(+l.nightRate + +l.unitRateUplift)
                            }}
                          </span>
                          <span
                            class="mr-1"
                            v-if="quoteTypePriceListIds.includes(4)"
                            ><strong style="font-weight: 600">
                              Weekday Rate:
                            </strong>
                            {{
                              formatPriceRates(
                                +l.weekdayRate + +l.unitRateUplift
                              )
                            }}
                          </span>
                          <span
                            class="mr-1"
                            v-if="quoteTypePriceListIds.includes(6)"
                            ><strong style="font-weight: 600">
                              Eve & Weekend Rate:
                            </strong>
                            {{
                              formatPriceRates(
                                +l.eveWeekendRate + +l.unitRateUplift
                              )
                            }}
                          </span>
                          <span
                            class="mr-1"
                            v-if="quoteTypePriceListIds.includes(7)"
                            ><strong style="font-weight: 600">
                              Eve, Weekend & Night Rate:
                            </strong>
                            {{
                              formatPriceRates(
                                +l.eveWeekendNightRate + +l.unitRateUplift
                              )
                            }}
                          </span>
                          <span
                            class="mr-1"
                            v-if="quoteTypePriceListIds.includes(8)"
                            ><strong style="font-weight: 600">
                              Winter Day Rate:
                            </strong>
                            {{
                              formatPriceRates(
                                +l.winterDayRate + +l.unitRateUplift
                              )
                            }}
                          </span>
                          <span
                            class="mr-1"
                            v-if="quoteTypePriceListIds.includes(9)"
                            ><strong style="font-weight: 600">
                              All Other Times Rate:
                            </strong>
                            {{
                              formatPriceRates(
                                +l.allOtherTimesRate + +l.unitRateUplift
                              )
                            }}
                          </span>
                          <span
                            class="mr-1"
                            v-if="quoteTypePriceListIds.includes(10)"
                            ><strong style="font-weight: 600"> KvA: </strong>
                            {{ formatPriceRates(+l.kvA) }}
                          </span>
                          <span
                            class="mr-1"
                            v-if="quoteTypePriceListIds.includes(11)"
                            ><strong style="font-weight: 600">
                              Evening Rate:
                            </strong>
                            {{
                              formatPriceRates(
                                +l.eveningRate + +l.unitRateUplift
                              )
                            }}
                          </span>
                          <span
                            class="mr-1"
                            v-if="quoteTypePriceListIds.includes(5)"
                            ><strong style="font-weight: 600">
                              Weekend Rate:
                            </strong>
                            {{
                              formatPriceRates(
                                +l.weekendRate + +l.unitRateUplift
                              )
                            }}
                          </span>
                          <span
                            class="mr-1"
                            v-if="quoteTypePriceListIds.includes(13)"
                            ><strong style="font-weight: 600">
                              Off Peak:
                            </strong>
                            {{
                              formatPriceRates(+l.offPeake + +l.unitRateUplift)
                            }}
                          </span>
                        </div>
                        <div class="text-left mb-1">
                          <span style="font-weight: bold; font-size: 12px">
                            Tariff:
                          </span>
                          <span>{{ l.tariff }}</span>
                          <span>{{ l.tariffCode }}</span>
                        </div>
                        <div
                          class="text-left text-danger mb-1"
                          v-if="l.expiryDate"
                        >
                          <span style="font-weight: bold; font-size: 12px">
                            Expiry Date:
                          </span>
                          <span>{{ formatDateTime(l.expiryDate) }}</span>
                        </div>
                      </td>
                      <!-- Monthly Cost -->
                      <td class="mb-3">
                        {{ formatPrice(l.monthlyCost) }}
                      </td>

                      <!-- Total Savings-->
                      <td
                        :class="`mb-3 ${
                          totalSavings(l) < 0 ? 'text-danger' : ''
                        }`"
                      >
                        {{ formatPrice(totalSavings(l)) }}
                      </td>

                      <!-- Standing Charge Uplift -->
                      <td class="mb-3">
                        <div
                          class="d-flex justify-content-center align-items-center flex-column"
                          v-if="!l.fixedCommAmount"
                        >
                          <v-text-field
                            style="
                              width: 70%;
                              margin: auto;
                              font-weight: 400;
                              font-size: 13px;
                            "
                            v-model="l.standingChargeUplift"
                            suffix="p"
                            hide-details
                            disabled
                            class="mb-2"
                            :rules="[
                              (v) =>
                                (v >= 0 && v <= l.scMaxUplift) ||
                                'Value must be between 0 and ' + l.scMaxUplift,
                            ]"
                          ></v-text-field>
                          <v-slider
                            v-model="l.standingChargeUplift"
                            class="mb-2"
                            style="width: 70%; margin: auto"
                            hide-details
                            :step="l.scIncUplift"
                            :min="0"
                            :max="l.scMaxUplift"
                            @change="
                              onStandingChargeUpliftChange(
                                index,
                                l.id,
                                l.standingChargeUplift
                              )
                            "
                          ></v-slider>
                          <!-- <b-input-group
                            append="p"
                            class="mb-2"
                            min="0"
                            style="width: 70%"
                            :max="l.scMaxUplift"
                          >
                            <b-form-input
                              v-model="l.standingChargeUplift"
                              disabled
                            ></b-form-input>
                          </b-input-group>
                          <b-form-input
                            v-model="l.standingChargeUplift"
                            class="mb-2"
                            type="range"
                            :step="l.scIncUplift"
                            min="0"
                            :max="l.scMaxUplift"
                            @change="
                              onStandingChargeUpliftChange(
                                index,
                                l.id,
                                l.standingChargeUplift
                              )
                            "
                          ></b-form-input> -->
                        </div>
                      </td>
                      <!-- Unit Rate Uplift -->
                      <td class="mb-3">
                        <div
                          class="d-flex justify-content-center align-items-center flex-column"
                          v-if="!l.fixedCommAmount"
                        >
                          <v-text-field
                            style="
                              width: 70%;
                              margin: auto;
                              font-weight: 400;
                              font-size: 13px;
                            "
                            v-model="l.unitRateUplift"
                            suffix="p"
                            disabled
                            hide-details
                            class="mb-2"
                            :rules="[
                              (v) =>
                                (v >= 0 && v <= l.urMaxUplift) ||
                                'Value must be between 0 and ' + l.urMaxUplift,
                            ]"
                          ></v-text-field>
                          <v-slider
                            v-model="l.unitRateUplift"
                            class="mb-2"
                            hide-details
                            :step="l.urIncUplift"
                            :min="0"
                            :max="l.urMaxUplift"
                            @change="
                              unitRateUpliftChanged(
                                index,
                                l.id,
                                l.unitRateUplift
                              )
                            "
                            style="width: 70%; margin: auto"
                          ></v-slider>
                          <!-- <b-input-group
                            append="p"
                            class="mb-2"
                            type="range"
                            style="width: 70%"
                          >
                            <b-form-input
                              v-model="l.unitRateUplift"
                              disabled
                            ></b-form-input>
                          </b-input-group>
                          <b-form-input
                            v-model="l.unitRateUplift"
                            class="mb-2"
                            type="range"
                            min="0"
                            :step="l.urIncUplift"
                            :max="l.urMaxUplift"
                            @change="
                              unitRateUpliftChanged(
                                index,
                                l.id,
                                l.unitRateUplift
                              )
                            "
                          ></b-form-input> -->
                        </div>
                      </td>
                      <!-- Estimated Commission-->
                      <td class="mb-3">
                        {{
                          !l.fixedCommAmount
                            ? totalEst(index)
                            : totalEstFixed(l.fixedCommAmount)
                        }}
                      </td>
                      <!-- Actions-->
                      <!-- v-if="l.saleType === 1" -->
                      <td class="mb-3">
                        <div class="d-flex flex-column align-items-start">
                          <div
                            v-if="shouldShowSendContract(l)"
                            style="
                              background-color: transparent;
                              border: none;
                              cursor: pointer;
                              padding: 0;
                            "
                            id="quote"
                            @click="openSendContractModal(l, index)"
                          >
                            <i
                              class="fas fa-file-signature"
                              id="target-3"
                              style="font-size: 14px; color: #146ebe"
                            ></i>

                            <span
                              class="hovered"
                              style="
                                font-size: 11px;
                                margin-left: 6px;
                                color: #146ebe;
                              "
                              >SEND CONTRACT</span
                            >
                          </div>

                          <!-- <b-btn
                      size="sm"
                      style="
                        background-color: transparent;
                        border: none;
                        padding: 0;
                      "
                      id="quote"
                      @click="openSendQuoteModal"
                    >
                      <a v-b-tooltip.hover.top="'Send Quote'">
                        <i
                          class="fas fa-tags"
                          id="target-3"
                          style="font-size: 14px; color: #9575cd"
                        ></i>
                      </a>
                      <span
                        class="hovered"
                        style="
                          font-size: 11px;
                          margin-left: 6px;
                          color: #9575cd;
                        "
                        >SEND QUOTE</span
                      >
                    </b-btn>
                    <b-btn
                      size="sm"
                      style="
                        background-color: transparent;
                        border: none;
                        padding: 0;
                      "
                      id="submit"
                    >
                      <div>
                        <a
                          @click.stop="openSubmitContractModal"
                          style="color: #3f51b5"
                        >
                          <SvgIcon
                            id="lblGas"
                            type="mdi"
                            :path="path.send"
                            :size="15"
                            color="#3f51b5"
                            class="cursor-pointer"
                          ></SvgIcon>
                          <span
                            class="hovered"
                            style="font-size: 11px; margin-left: 6px"
                          >
                            SUBMIT CONTRACT</span
                          >
                        </a>
                      </div>
                    </b-btn> -->
                        </div>
                      </td>
                    </tr>
                  </template>
                </template>
                <template v-else-if="!isLoading && sortedQuotes.length === 0">
                  <tr>
                    <td colspan="9">
                      <h4
                        class="text-center"
                        style="
                          height: 30vh;
                          display: flex;
                          justify-content: center;
                          align-items: center;
                        "
                      >
                        No data found
                      </h4>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
    <SendContractBes
      v-if="quoteSupplierId === 13"
      :contactId="606769"
      :selectedMeter="selectedMeter"
      @closeSendContractModal="closeSendContractModalBes"
      :showSendContractModal="showSendContractModalBes"
    />
    <SendContract
      v-else
      :contactId="606769"
      :selectedMeter="selectedMeter"
      @closeSendContractModal="closeSendContractModal"
      :showSendContractModal="showSendContractModal"
    />
  </v-row>
</template>

<script>
import dayjs from "dayjs"
import { mask } from "vue-the-mask"
// import { email } from "vuelidate/lib/validators";
import SupplyNumber from "@/components/supply-number"

import { mapGetters, mapMutations } from "vuex"
import { somethingWentWrong } from "@/helpers/utilities"
import TotalEac from "@/components/forms/total-eac.vue"
import ComparativePrices from "@/components/forms/comparative-prices.vue"
import SendContractBes from "@/components/forms/send-contract-bes.vue"
import SendContract from "@/components/forms/send-contract.vue"
import EacPrices from "@/components/forms/eac-prices.vue"

import api from "@/store/api"

export default {
  name: "GenerateQuoteModal",
  data: () => ({
    quoteSupplierId: 0,
    meterTypeId: 0,
    showSendContractModalBes: false,
    showSendContractModal: false,
    menu: false,
    commission360: -1,
    unixTimestamp: dayjs().unix(),
    sortCount: 0,
    selectedFixedCommission: false,
    globalSCMaxUplift: 0,
    globalURMaxUplift: 0,
    globalSCUplift: 0,
    globalURUplift: 1,
    globalURIncUplift: Infinity,
    globalSCIncUplift: Infinity,
    terms: [12, 24, 36, 48, 60],
    selectedTerms: [12, 24, 36, 48, 60],
    selectedSuppliers: [],
    standingChargeUplift: 0,
    campartivePrice: 0,
    showQuotingDetails: true,
    isLoading: false,
    eacInserted: 0,
    quoteTypePriceList: [],
    pulledQuotes: [],
    showErrors: false,
    contactAddresses: [],
    buttonClicked: false,
    changedMeterType: false,
    stepNo: 1,
    renewalPaymentTypeList: [],
    isMeterTypeChanged: false,
    description: "",
    uploads: [],
    prevMeterId: null,

    file: null,
    userId: "",
    uploadSelected: 0,
    uploadedBy: 0,
    id: 0,
    descriptionMsgErr: false,
    fileMsgErr: false,
    typeMsgErr: false,
    saleId: 0,
    forOnce: true,
    value: null,

    selectedHomeAddress: "",
    once: true,
    isEmail: null,
    isOpen: "",

    currentNum: 1,
    addressId: null,
    meterId: null,
    contractEndDate: null,

    supplierName: null,
    supplierId: null,
    pc: null,
    eFormmodal: false,
    showEFormBtn: false,

    formHasErrors: false,
    selectedMeterType: null,

    csd: null,
    csdText: null,
    estimate: false,
    triggerUpdate: 0,

    contractType: null,
    contractTypeValidationError: false,
    significantPersonContactId: null,
    selectedCampaign: 2,
    showPostcodes: false,
    dialog: false,
    isDobTextChanged: false,
    e6: 1,
    e1: 1,

    soldSupplierId: null,
    meterSelected: {},
    selectedSignificantPerson: {
      companyId: null,
      contactNumber: null,
      altContactNumber: null,
    },
    dbSelectedSignificantPerson: {},
    contractMeterTypesList: [],
    termTypesList: [],
    profileClass: null,

    suppliers: [],
    shouldRefreshQuote: false,
    contractWidgets: [],
  }),
  props: {
    selectedMeter: {
      type: Object,
    },

    isHideMeterEdit: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    SupplyNumber,
    TotalEac,
    EacPrices,
    SendContract,
    SendContractBes,
    ComparativePrices,
  },
  directives: { mask },

  computed: {
    ...mapGetters("company", ["details", "mobileView", "companyId", "comapny"]),
    ...mapGetters("employee", ["userId"]),
    ...mapGetters("meters", ["meters", "generateQuoteClicked"]),

    ...mapGetters("addresses", ["selectedAddress"]),
    isGas() {
      return this.type.toLowerCase() === "gas"
    },
    typeColor() {
      // return this.isGas ? "red" : "amber"
      return this.meterTypeId == 10
        ? "red"
        : this.meterTypeId == 2705
        ? "blue"
        : "amber"
    },
    typeIcon() {
      // return this.isGas ? "mdi-fire" : "mdi-lightbulb"
      return this.meterTypeId == 10
        ? "mdi-fire"
        : this.meterTypeId == 2705
        ? "mdi-water"
        : "mdi-lightbulb"
    },
    quoteTypesOnly() {
      return this.quoteTypePriceList.filter((x) => x.quoteTypePriceId !== 1)
    },
    quoteTypePriceListIds() {
      return this.quoteTypePriceList.map((x) => {
        return x.quoteTypePriceId
      })
    },
    quoteTypeRateListIds() {
      const priceListIds = []

      this.quoteTypePriceList.forEach((x) => {
        if (x.quoteTypePriceId !== 1) {
          priceListIds.push(x.quoteTypePriceId)
        }
      })
      return priceListIds
    },
    formattedMinContractEndDate() {
      let minDate =
        dayjs(this.contractEndDate * 1000) <= dayjs()
          ? dayjs()
          : dayjs(this.contractEndDate * 1000).add(1, "day")

      return minDate.format("YYYY-MM-DDTHH:mm:ss.SSS")
    },
    formattedMaxContractEndDate() {
      const maxDate = dayjs().add(this.selectedMeter.window + 1, "day")

      return maxDate.format("YYYY-MM-DDTHH:mm:ss.SSS")
    },

    filteredPulledQuotes() {
      const filteredQuotes = this.pulledQuotes
        .filter((quote) => this.selectedSuppliers.includes(quote.supplierID))
        .filter((quote) => this.selectedTerms.includes(quote.term))
      if (this.selectedFixedCommission) {
        return filteredQuotes.filter(
          (quote) => this.selectedFixedCommission && quote.fixedCommAmount
        )
      }
      return filteredQuotes
    },
    calculatedQuotes() {
      return this.filteredPulledQuotes.map((quote) => {
        // To assign default

        if (!quote.fixedCommAmount) {
          if (quote.urMaxUplift != 0 || quote.urIncUplift != 0) {
            if (quote.urMaxUplift >= +this.globalURUplift) {
              quote.unitRateUplift = +this.globalURUplift
            } else if (+this.globalURUplift > quote.urMaxUplift) {
              quote.unitRateUplift = +quote.urMaxUplift
            }
          } else {
            quote.unitRateUplift = 0
          }
          if (quote.scMaxUplift != 0 || quote.scIncUplift != 0) {
            if (quote.scMaxUplift >= +this.globalSCUplift) {
              quote.standingChargeUplift = +this.globalSCUplift
            } else if (+this.globalSCUplift > quote.scMaxUplift) {
              quote.standingChargeUplift = +quote.scMaxUplift
            }
          } else {
            quote.standingChargeUplift = 0
          }
        } else {
          quote.standingChargeUplift = 0
          quote.unitRateUplift = 0
        }
        // monthlyCost and for sorting
        const totalCost = this.totalCost(quote)

        const monthlyCost = totalCost / quote.term
        quote.monthlyCost = monthlyCost

        // For Global Uplifts
        if (
          quote.scMaxUplift > this.globalSCMaxUplift &&
          quote.scMaxUplift != 0
        ) {
          this.globalSCMaxUplift = quote.scMaxUplift
        }
        if (
          quote.urMaxUplift > this.globalURMaxUplift &&
          quote.urMaxUplift != 0
        ) {
          this.globalURMaxUplift = quote.urMaxUplift
        }

        // Min  & Max Global Slider calculations
        if (
          quote.urIncUplift < this.globalURIncUplift &&
          quote.urIncUplift != 0
        ) {
          this.globalURIncUplift = quote.urIncUplift
        }
        if (
          quote.scIncUplift < this.globalSCIncUplift &&
          quote.scIncUplift != 0
        ) {
          this.globalSCIncUplift = quote.scIncUplift
        }

        const q = { ...quote }
        return q
      })
    },
    sortedQuotes() {
      if (this.sortCount > 0) {
        const calculate = [...this.calculatedQuotes]
        return calculate.sort((a, b) => a.monthlyCost - b.monthlyCost)
      } else {
        const calculate = [...this.calculatedQuotes]
        return calculate.sort((a, b) => a.monthlyCost - b.monthlyCost)
      }
    },

    uniqueSuppliers() {
      // Use reduce to create a unique list based on supplierId
      const uniqueSupplierObj = this.pulledQuotes.reduce((acc, quote) => {
        // If the supplierId isn't already a key in the accumulator, add it
        if (!acc[quote.supplierID]) {
          acc[quote.supplierID] = {
            value: quote.supplierID,
            text: quote.supplierName,
          }
        }
        return acc
      }, {})

      // Convert the uniqueSupplierObj back to an array of the desired structure
      return Object.values(uniqueSupplierObj)
    },
    selectedSupplierNames() {
      if (this.selectedSuppliers.length === this.uniqueSuppliers.length) {
        return ["All"] // Return 'All' if every supplier is selected
      } else {
        // Otherwise, map selected IDs to their names for display
        return this.uniqueSuppliers
          .filter((supplier) => this.selectedSuppliers.includes(supplier.value))
          .map((supplier) => supplier.text)
      }
    },
    getQuoteText() {
      if (this.shouldRefreshQuote) {
        return "Refresh Quote"
      }
      return "Get Quote"
    },

    totalEac() {
      var result = 0

      this.quoteTypesOnly.forEach((q) => {
        var index = this.quoteTypePriceList.findIndex(
          (x) => x.quoteTypePriceId === q.quoteTypePriceId
        )
        result =
          result +
          parseFloat(
            this.quoteTypePriceList[index].eac == ""
              ? 0
              : this.quoteTypePriceList[index].eac
          )
      })
      return parseFloat(result.toFixed(2))
    },
    totalYealyCost() {
      const standingCharge = this.quoteTypePriceList.find(
        (quote) => quote.typeName === "Standing Charge"
      ).price
      const standingChargeCalculated =
        (standingCharge / 100) * ((365 / 12) * 12)

      let totalCost = standingChargeCalculated

      this.quoteTypePriceList.forEach((x) => {
        if (x.quoteTypePriceId !== 1) {
          const price = +x.price
          const rateCalculated = (+price / 100) * (+this.totalEac / 12) * 12

          totalCost += rateCalculated
        }
      })

      return totalCost.toFixed(2)
    },
  },
  watch: {
    generateQuoteClicked() {
      this.onMountedCalled()
    },
    globalURUplift(newVal) {
      this.sortedQuotes.filter((quote) => {
        if (
          (quote.urMaxUplift != 0 || quote.urIncUplift != 0) &&
          !quote.fixedCommAmount
        ) {
          if (quote.urMaxUplift >= newVal) {
            quote.unitRateUplift = +newVal
          } else if (newVal > quote.urMaxUplift) {
            quote.unitRateUplift = +quote.urMaxUplift
          }
        } else {
          quote.unitRateUplift = 0
        }

        return quote
      })
    },
    globalSCUplift(newVal) {
      this.sortedQuotes.filter((quote) => {
        if (
          (quote.scMaxUplift != 0 || quote.scIncUplift != 0) &&
          !quote.fixedCommAmount
        ) {
          if (quote.scMaxUplift >= newVal) {
            quote.standingChargeUplift = +newVal
          } else if (newVal > quote.scMaxUplift) {
            quote.standingChargeUplift = +quote.scMaxUplift
          }
        } else {
          quote.standingChargeUplift = 0
        }

        return quote
      })
      if (!this.globalSCUplift) {
        this.globalSCUplift = 0
      }
    },

    // eacInserted(newVal) {
    //   if (newVal) {
    //     this.fetchQuoteUploadELEC();
    //   }
    // },
  },

  mounted() {
    this.commission360 = this.$store.getters.StateUser.commission360
    this.onMountedCalled()
  },
  methods: {
    ...mapMutations("meters", [
      "setSubContractClicked",
      "setQuoteForSendContract",
      "setQuoteTypePriceList",
    ]),
    closeSendContractModal() {
      this.showSendContractModal = false
    },
    formatDateTime(date) {
      if (!date || date === 0) {
        return null
      }
      return dayjs(date * 1000).format("DD/MM/YYYY HH:mm:ss")
    },
    closeSendContractModalBes() {
      this.showSendContractModalBes = false
    },
    supplierLogo(id) {
      return `https://hive.utilityhive.com/uploads/SupplierLogos/${id}.jpg`
    },
    shouldShowSendContract(row) {
      return this.contractWidgets.some(
        (widget) =>
          widget.supplierID === row.supplierID &&
          widget.meterType === this.selectedMeter.meterTypeId
      )
    },
    async fetchTabTitles() {
      const response = await api.get(`getAllSignableContractWidgets`)
      this.contractWidgets = response.data

      // this.contractWidgets = [
      //   {
      //     widgetType: "CONTRACT",
      //     saleType: 0,
      //     id: 21,
      //     widgetDescription:
      //       "Valda Energy Electricity Contract with LOA [Direct Debit]",
      //     supplierID: 112,
      //     meterType: 9,      //     basePath: ""
      //   },
      //   {
      //     widgetType: "CONTRACT",
      //     saleType: 0,
      //     id: 22,
      //     widgetDescription:
      //       "\r\nValda Energy Electricity Contract with LOA - [Customer to fill Direct Debit]",
      //     supplierID: 13,
      //     meterType: 9,
      //     widget: "PaxWKPncFG",
      //     basePath: ""
      //   }
      // ]
    },
    generateQuoteOpened() {
      console.log("generateQuoteOpened")
      this.onMountedCalled()
      this.fetchContractMeterTypes()
    },
    openSubmitContractModal() {
      this.setSubContractClicked()
      this.$bvModal.show(`submit-contract`)
    },
    contractStartDateChanged() {
      if (this.csdText.length >= 1) {
        const tempDay = this.csdText.slice(0, 2)
        const tempMonth = this.csdText.slice(3, 5)
        const tempYear = this.csdText.slice(6, 9)
        // const d = new Date()
        // const minYear = d.getFullYear();

        if (parseInt(tempDay) > 31) {
          const temp = this.csdText.slice(2, 10)
          this.csdText = "31" + temp
        }

        if (this.csdText.length >= 2 && parseInt(tempDay) === 0) {
          const temp = this.csdText.slice(2, 10)
          this.csdText = "01" + temp
        }

        if (parseInt(tempMonth) > 12) {
          const temp = this.csdText.slice(5, 10)
          this.csdText = tempDay + "/12" + temp
        }

        if (this.csdText.length >= 5 && parseInt(tempMonth) === 0) {
          const temp = this.csdText.slice(5, 10)
          this.csdText = tempDay + "/01" + temp
        }

        if (parseInt(tempYear) <= 0) {
          // const temp = this.csdText.slice(6, 10)
          this.csdText = tempDay + tempMonth + "/2021"
        }
        // if(this.form.csdText.length >= 7 && parseInt(tempYear) === 0) {
        //     const temp = this.csdText.slice(6, 10)
        //     this.csdText = minYear + temp
        // }
        const csd = this.csdText.split("/").reverse().join("-")
        const currentEndDate = dayjs(csd).format("YYYY-MM-DD")
        this.csd = currentEndDate
        if (this.csd === "Invalid Date") {
          this.csd = ""
        }
      }
    },
    openSendContractModal(quote, index) {
      this.quoteSupplierId = quote.supplierID
      const updatedQuote = {
        ...quote,
      }
      updatedQuote.csdText = this.csdText
      updatedQuote.csd = this.csd
      updatedQuote.totalEac = this.totalEac
      updatedQuote.fixedCommAmount = !quote.fixedCommAmount
        ? this.totalEstWithoutFormatPrice(index)
        : this.totalEstFixedWithoutFormatPrice(quote.fixedCommAmount)
      this.setQuoteForSendContract(updatedQuote)
      this.setQuoteTypePriceList(this.quoteTypePriceList)
      if (quote.supplierID === 13) {
        this.showSendContractModal = false
        this.showSendContractModalBes = true
      } else {
        this.showSendContractModalBes = false
        this.showSendContractModal = true
      }
    },
    openSendQuoteModal() {
      this.$bvModal.show("send-quote-modal")
    },
    totalEstFixed(fixedAmount) {
      let fixedCommAmount = fixedAmount
      if (this.commission360 > 0) {
        fixedCommAmount = (this.commission360 / 100) * fixedCommAmount
      }
      return this.formatPrice(fixedCommAmount)
    },
    totalEstFixedWithoutFormatPrice(fixedAmount) {
      let fixedCommAmount = fixedAmount
      if (this.commission360 > 0) {
        fixedCommAmount = (this.commission360 / 100) * fixedCommAmount
      }
      return this.formatPriceRates(fixedCommAmount)
    },
    showOnlyFixedCommission() {
      this.selectedFixedCommission = !this.selectedFixedCommission
    },
    dateOfCSDChanged() {
      this.isCSDChanged = true
      this.csdText = dayjs(this.csd).format("DD/MM/YYYY")
    },

    toggleTermSelection(term) {
      const index = this.selectedTerms.indexOf(term)
      if (index === -1) {
        // Term is not currently selected, so select it
        this.selectedTerms.push(term)
      } else {
        // Term is currently selected, so deselect it
        this.selectedTerms.splice(index, 1)
      }
    },
    renwalDate(renewalDate) {
      return dayjs(renewalDate * 1000) <= dayjs()
    },
    formatDate(timestamp, time = false) {
      if (!timestamp) {
        return null
      }
      const dateFormat = "DD/MM/YYYY"
      // transform to miliseconds because we are getting it in seconds
      const date = dayjs(timestamp * 1000)
      return time ? date.format(`${dateFormat} HH:mm`) : date.format(dateFormat)
    },
    relevantEac(term) {
      const totalRelevantEac = (this.totalEac / 12) * parseInt(term ? term : 0)
      return parseFloat(totalRelevantEac.toFixed(2))
    },
    totalEst(index) {
      const filteredQuotes = [...this.sortedQuotes]
      const selectedQuote = filteredQuotes[index]

      var totalUnitRate =
        parseFloat(+selectedQuote.unitRateUplift) *
        this.relevantEac(selectedQuote.term)
      var termYears = parseInt(selectedQuote.term) / 12
      var totalSC =
        parseFloat(+selectedQuote.standingChargeUplift) * 365 * termYears
      let total = 0

      if (totalUnitRate > 0 || totalSC > 0) {
        total = (totalUnitRate + totalSC) / 100
      }
      if (this.commission360 > 0) {
        total = (this.commission360 / 100) * total
      }

      return this.formatPrice(parseFloat(total))
    },
    totalEstWithoutFormatPrice(index) {
      const filteredQuotes = [...this.sortedQuotes]
      const selectedQuote = filteredQuotes[index]

      var totalUnitRate =
        parseFloat(+selectedQuote.unitRateUplift) *
        this.relevantEac(selectedQuote.term)
      var termYears = parseInt(selectedQuote.term) / 12
      var totalSC =
        parseFloat(+selectedQuote.standingChargeUplift) * 365 * termYears
      let total = 0

      if (totalUnitRate > 0 || totalSC > 0) {
        total = (totalUnitRate + totalSC) / 100
      }
      if (this.commission360 > 0) {
        total = (this.commission360 / 100) * total
      }

      return this.formatPriceRates(parseFloat(total))
    },
    onStandingChargeUpliftChange(index, id) {
      this.calculatedQuotes.map((quote) => {
        if (quote.id === id) {
          const totalCost = this.totalCost(quote)
          // console.log(totalCost, "total cost");
          const monthlyCost = totalCost / quote.term
          quote.monthlyCost = monthlyCost
        }
        const q = { ...quote }
        return q
      })
      this.sortCount++

      this.$forceUpdate()
    },
    unitRateUpliftChanged(index, id) {
      this.calculatedQuotes.map((quote) => {
        if (quote.id === id) {
          const totalCost = this.totalCost(quote)
          // console.log(totalCost, "total cost");
          const monthlyCost = totalCost / quote.term
          quote.monthlyCost = monthlyCost
        }
        const q = { ...quote }
        return q
      })
      this.sortCount++

      this.$forceUpdate()
    },
    totalCost(quote) {
      const standingCharge = +quote.standingCharge + +quote.standingChargeUplift
      const standingChargeCalculated =
        (standingCharge / 100) * ((365 / 12) * quote.term)

      const rateIdToKeyName = {
        2: "unitRate",
        3: "dayRate",
        12: "nightRate",
        4: "weekdayRate",
        6: "eveWeekendRate",
        7: "eveWeekendNightRate",
        8: "winterDayRate",
        9: "allOtherTimesRate",
        10: "kvA", // Assuming kvA is a rate; adjust as needed
        11: "eveningRate",
        5: "weekendRate",
        13: "offPeake", // Ensure correct spelling (offPeake vs offPeak)
      }

      let totalCost = standingChargeCalculated

      this.quoteTypeRateListIds.forEach((rateId) => {
        const rateKeyName = rateIdToKeyName[rateId]

        if (rateKeyName && quote[rateKeyName] !== undefined) {
          // Perform the calculation for the current rate and add it to the total cost
          let price
          if (rateId === 10) {
            price = +quote[rateKeyName]
          } else {
            price = +quote[rateKeyName] + +quote.unitRateUplift
          }
          const rateCalculated =
            (+price / 100) * (+this.totalEac / 12) * +quote.term
          // console.log(rateCalculated, "rate", quote.id);
          totalCost += rateCalculated
        }
      })

      return totalCost
    },

    savingstotalCost(quote) {
      // const accoumalatedStandingCharge =
      //       this.campartivePrice + +quote.standingChargeUplift;

      const accoumalatedStandingCharge = this.quoteTypePriceList.find(
        (quote) => quote.typeName === "Standing Charge"
      ).price
      const standingChargeCalculated =
        (accoumalatedStandingCharge / 100) * ((365 / 12) * quote.term)

      var rateCalculated = 0

      this.quoteTypesOnly.forEach((q) => {
        if (q.quoteTypePriceId !== 1) {
          var index = this.quoteTypePriceList.findIndex(
            (x) => x.quoteTypePriceId === q.quoteTypePriceId
          )
          let rate = parseFloat(
            this.quoteTypePriceList[index].baseRate == ""
              ? 0
              : this.quoteTypePriceList[index].baseRate
          )
          // rate = rate + +quote.unitRateUplift;
          let singleRateCalculated =
            (+rate / 100) * (this.totalEac / 12) * quote.term
          rateCalculated = rateCalculated + singleRateCalculated
        }
      })

      return standingChargeCalculated + rateCalculated
    },
    formattedTotalCost(quote) {
      const totalCost = this.totalCost(quote)

      return this.formatPrice(totalCost)
    },
    savings(quote) {
      const totalCost = this.totalCost(quote)

      return this.formatPrice(this.campartivePrice - totalCost)
    },
    totalSavings(quote) {
      const totalCost = this.totalCost(quote)
      const comparativeTotalRates = this.savingstotalCost(quote)

      return (comparativeTotalRates - totalCost) / (quote.term / 12)
    },

    async fetchQuoteUploadELEC() {
      this.isLoading = true
      try {
        let response
        const csd = dayjs(this.csd).unix()
        if (this.meterTypeId == 9) {
          const s = this.selectedMeter.supplyNumber.split("")
          const region = s.slice(0, 2).join("")

          // this.shouldRefreshQuote = true;
          // this.showQuotingDetails = false;
          // const response = await api.get(
          //   `/getQuoteUploadELEC/10/03/801/201/10/9/7/4/1709751600`
          // );
          response = await api.get(
            `getQuoteUploadELEC/${region}/${this.selectedMeter.pc}/${this.selectedMeter.mtc}/${this.selectedMeter.llf}/${this.totalEac}/${this.selectedMeter.meterTypeId}/${this.supplierId}/${this.selectedMeterType}/${csd}`
          )
        } else if (this.meterTypeId == 10) {
          let postCode = this.selectedAddress.postcode.toString()

          const removedWhiteSpacePostCode = postCode.replace(/\s+/g, "")
          console.log(
            removedWhiteSpacePostCode,
            "removedWhiteSpacePostCode",
            postCode.replace(/\s+/g, "")
          )
          response = await api.get(
            `getQuoteUploadGas/${this.totalEac}/${removedWhiteSpacePostCode}/${this.supplierId}/${csd}`
          )
        }
        this.pulledQuotes = response.data
        this.pulledQuotes = response.data.map((quote, index) => {
          return {
            ...quote, // Spread operator to copy properties of the current object
            id: index + 1, // Assign an incremental ID, starting from 1
            standingChargeUplift: 0,
            unitRateUplift: 0,
          }
        })

        this.selectedSuppliers = this.uniqueSuppliers.map(
          (supplier) => supplier.value
        )
        this.shouldRefreshQuote = true
        this.showQuotingDetails = false
        this.campartivePrice = this.quoteTypePriceList.find(
          (quote) => quote.typeName === "Standing Charge"
        ).price
      } catch (err) {
        somethingWentWrong()
      } finally {
        this.isLoading = false
      }
    },

    async fetchContractMeterTypes() {
      this.profileClass = this.selectedMeter.pc
      if (this.profileClass === null || this.profileClass === "") {
        this.profileClass = "0"
      }
      const response = await api.get(
        `contractMeterTypes/${this.meterTypeId}/${this.profileClass}`
      )
      this.contractMeterTypesList = response.data.map((option) => ({
        value: option.id,
        text: option.value,
      }))
    },
    async getQuoteTypeIds() {
      if (this.selectedMeterType !== null) {
        const res = await api.get(`quoteTypePriceIds/${this.selectedMeterType}`)
        if (res && res.status === 200) {
          this.quoteTypePriceList = res.data.sort((a, b) => a.sort - b.sort)
        }
      }
    },
    isSupplierUnique(supplierId) {
      return !this.suppliers.some((supplier) => supplier.id === supplierId)
    },

    resetModal() {
      // reset all component data properties
      const defaultData = this.$options.data.call(this)
      Object.keys(defaultData).forEach((key) => {
        this[key] = defaultData[key]
      })
    },
    changeMeterType() {
      this.isMeterTypeChanged = !this.isMeterTypeChanged
      this.changedMeterType = !this.changedMeterType
      this.getQuoteTypeIds()
      // this.getSupplierUplift();
      // if (this.eacOptionSelected === 427) {
      //   this.getEACGuide();
      // }
    },
    onMountedCalled() {
      // reset modal
      this.fetchTabTitles()
      this.meterId = this.selectedMeter.meterId
      if (this.prevMeterId && this.prevMeterId !== this.meterId) {
        this.resetModal()
      }
      this.meterId = this.selectedMeter.meterId
      this.prevMeterId = this.meterId

      this.addressId = this.selectedMeter.addressId
      this.contractEndDate = this.selectedMeter.ced
      this.meterTypeId = this.selectedMeter.meterTypeId
      this.supplierName = this.selectedMeter.supplierName
      this.supplierId = this.selectedMeter.supplierId
      this.pc = this.selectedMeter.pc
      this.estimate =
        this.selectedMeter.estimateActual.toLowerCase() === "estimate"
      this.fetchContractMeterTypes()
      const minDate =
        dayjs(this.contractEndDate * 1000) <= dayjs()
          ? dayjs().format("YYYY-MM-DD")
          : dayjs(this.contractEndDate * 1000)
              .add(1, "day")
              .format("YYYY-MM-DD")
      this.csd = minDate

      if (this.meters) {
        const filterMeter = this.meters.filter(
          (meter) => meter.meterId === this.meterId
        )
        this.meterSelected = filterMeter[0]
      }

      // this.fetchQuoteUploadELEC();

      if (this.meterTypeId === 10) {
        this.selectedMeterType = 25
        this.getQuoteTypeIds()
      }
      this.errorForm.name =
        this.creditSafeCompanyName || this.experianCompanyName
      this.errorForm.score = this.experianScore || this.creditScore
      this.errorForm.limit = this.experianLimit || this.creditLimit
      // For Gas Meters
    },
    formatPrice(value) {
      if (!value || value === 0) {
        value = 0
      }
      var val = (value / 1).toFixed(2)
      return "£" + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    formatPriceRates(value) {
      if (!value || value === 0) {
        value = 0
      }
      var val = (value / 1).toFixed(2)
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
  },
}
</script>

<style>
.position-relative {
  position: relative;
}
.global-uplift {
  position: absolute;
  display: flex;
  justify-content: center;
  top: 0;
  left: 100%;
  transform: translate(-50%, -16px);
}

.filter-label-generate-quote {
  display: flex;
  align-items: center;
  width: 200px;
  color: #777;
  font-weight: 500;
  font-size: 13px;
}
.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.generate-quote-supplier-dropdown.show > .btn-secondary.dropdown-toggle,
.generate-quote-supplier-dropdown .btn-secondary:hover {
  color: #444;
  background-color: transparent;
  border-color: #aaa;
  font-size: 13px;
  padding: 4px 12px;
}
.generate-quote-supplier-dropdown button {
  border: 1px solid #aaa;
  background-color: transparent;
  color: #444;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 2px;
  min-width: 200px;
  max-width: 700px;
  font-size: 13px;
  padding: 4px 12px;
}
.generate-quote-supplier-dropdown .custom-control-label {
  font-weight: 400;
  font-size: 13px;
  display: flex;
  align-items: center;
  padding-top: 2px;
}
</style>
