import api from "@/store/api"
import { chunkArray } from "@/store/helpers/utils"

const state = {
  error: null,
  loading: false,
  loas: [],
  saleTypes: [],
}

const getters = {
  loading: state => state.loading,
  error: state => state.error,
  loas: state => state.loas,
  // getLoasByType: state => {
  //   return {
  //     utilityHive: state.saleTypes.includes(2),
  //     css: state.saleTypes.includes(1)
  //   }
  // },
  getLoasByType: state => type => {
    if (!state.loas.length) return []
    return state.loas.filter(({ saleTypeId }) => saleTypeId === type)
  },
  groupedLoas: state => chunkArray(state.loas, 3),

}

const mutations = {
  setLoas: (state, loas) => (state.loas = loas),
  setLoading: (state, status) => (state.loading = status),
  setError: (state, error) => (state.error = error)
}

const actions = {
  async fetchLoas({ commit, rootGetters }) {
    commit("setLoading", true)
    try {
      const response = await api.get(
        `loasByCompanyId/${rootGetters["company/companyId"]}`
      )
      const loas = response.data
      const descendingLoas = loas.sort(function(a, b){return (b.loaId - a.loaId)});
     
      commit("setLoas", descendingLoas)
    } catch (error) {
      commit("setError", error)
    }
    commit("setLoading", false)
  },
  async fetchLoasAfterUpdate({ commit }, companyId) {
    commit("setLoading", true)
    try {
      const response = await api.get(`loasByCompanyId/${companyId}`)
      commit("setLoas", response.data)
    } catch (error) {
      commit("setError", error)
    }
    commit("setLoading", false)
  },
  // async selectLOA({ commit }, addressId) {
  //   commit("setSelectedAddress", addressId)
  // },
}

export default { state, getters, mutations, actions, namespaced: true }
