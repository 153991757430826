import { getInitialState } from "@/helpers/init"
import api from "@/store/api"

const { username } = getInitialState()

const state = {
  connexUser: username,
  id: 0,
  userName: "",
  campaignAssigned: [],
  connexCampaignAccess: [],
  saleTypes: [],
  employeeDetails: {},
  leadGeneratorDetails: {},
}

const getters = {
  connexUser: state => state.connexUser,
  userId: state => state.id,
  userName: state => state.userName,
  campaignAssigned: state => state.campaignAssigned,
  connexCampaignAccess: state => state.connexCampaignAccess,
  saleTypes: state => state.saleTypes,
  employeeDetails: state => state.employeeDetails,
  leadGeneratorDetails: state => state.leadGeneratorDetails,
  employeeCampaign: state => {
    return {
      utilityHive: state.saleTypes.includes(2),
      css: state.saleTypes.includes(1)
    }
  },
}

const mutations = {
  setEmployeeId: (state, id) => (state.id = id),
  setUserName: (state, userName) => (state.userName = userName),
  setEmployeeDetails: (state, employeeDetails) => (state.employeeDetails = employeeDetails),
  setLeadGeneratorDetails: (state, leadGeneratorDetails) => (state.leadGeneratorDetails = leadGeneratorDetails),
  setCampaignsAssigned: (state, campaigns) =>
    (state.connexCampaignAccess = campaigns),
  setSaleTypes: (state, campaigns) =>
    (state.saleTypes = campaigns),
}

const actions = {
  async fetchEmpoyeeDetails({ commit, state }) {
    try {
      const response = await api.get(`employee/${state.connexUser}`)
      commit("setEmployeeId", response.data.employeeId)
      commit(
        "setUserName",
        `${response.data.firstName} ${response.data.lastName}`
      )
      commit("setCampaignsAssigned", response.data.connexCampaignAccess)
      commit("setSaleTypes", response.data.saleTypes)

    } catch (error) {
      //
    }
  },
  async fetchEmployee({ commit }, connexUser) {
    try {
      const response = await api.get(`employee/${ connexUser }`)
      commit("setEmployeeDetails", response.data)
    } catch (error) {
      //
    }
  },

  async fetchLeadGenerator({ commit }, companyId) {
    try {
      const response = await api.get(`readyForQuote/${companyId } `)
      commit("setLeadGeneratorDetails", response.data)
    } catch (error) {
      //
    }
  },

}

export default { state, getters, mutations, actions, namespaced: true }
