<template>
  <v-container>
    <v-row>
      <v-col cols="auto">
        <div class="labels mb-1">Meter Type</div>
        <v-btn-toggle v-model="form.meterType">
          <v-btn value="9">
            <v-icon @click="showInfoButtons = true" color="amber"
              >mdi-lightbulb</v-icon
            >
          </v-btn>
          <v-btn @click="showInfoButtons = true" value="10">
            <v-icon color="red">mdi-fire</v-icon>
          </v-btn>
          <v-btn @click="showInfoButtons = true" value="2705">
            <v-icon color="blue">mdi-water</v-icon>
          </v-btn>
        </v-btn-toggle>
      </v-col>
      <v-col>
        <v-select
          label="Site Name"
          :items="sites"
          v-model="form.siteId"
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="d-flex justify-start" cols="12">
        <!-- <div class="labels mb-1">Supply Number</div> -->
        <SupplyNumber
          :key="key"
          :type="form.meterType === '9' ? 'electricity' : 'gas'"
        ></SupplyNumber>
      </v-col>
    </v-row>
    <div class="d-flex">
      <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-icon class="d-inline-block mr-2" v-bind="attrs" v-on="on">
            mdi-calendar
          </v-icon>
          <v-text-field
            class="d-inline-block mr-2"
            type="tel"
            ref="contractEndDate"
            v-model="contractEndDate"
            label="Contract End Date"
            v-mask="'##/##/####'"
            :rules="dobTextRules"
            @keyup="contractEndDateChanged"
          ></v-text-field>
        </template>
        <v-date-picker
          ref="picker"
          v-model="form.contractEndDate"
          min="1950-01-01"
          @change="save"
          locale="en-GB"
        ></v-date-picker>
      </v-menu>
      <v-text-field
        label="Annual Consumption"
        v-model="form.annualConsumption"
        @keypress="validationAnual"
      ></v-text-field>
    </div>

    <v-row>
      <v-col>
        <v-text-field
          prepend-icon="mdi-currency-gbp"
          label="Outstanding Debt"
          v-model="form.outstandingDebt"
          @keypress="validationDebt"
        ></v-text-field>
      </v-col>
      <v-col class=" d-flex flex-wrap justify-center" cols="5">
        <div class="labels">Already Agreed Renewal</div>
        <v-switch
          v-model="form.alreadyAgreedRenewal"
          inset
          @click="switchRenewalsEnDate"
        ></v-switch>
        <!-- <v-select placeholder="Please Select" :items="agreed.value"></v-select> -->
      </v-col>
    </v-row>
    <v-menu
      v-if="form.alreadyAgreedRenewal"
      ref="menuAgreed"
      v-model="menuAgreed"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          type="tel"
          ref="contractRenewalEndDate"
          label="Contract End Date"
          class="d-inline-block mr-2"
          placeholder="DD/MM/YYYY"
          v-mask="'##/##/####'"
          v-model="contractRenewalEndDate"
          :rules="renewalEndDateRules"
          @keyup="contractRenewalEndDateChanged"
        ></v-text-field>
        <v-icon class="d-inline-block mr-2" v-bind="attrs" v-on="on">
          mdi-calendar
        </v-icon>
      </template>
      <v-date-picker
        ref="pickerAgreed"
        v-model="form.contractRenewalEndDate"
        min="1950-01-01"
        locale="en-GB"
        @change="formContractRenewalEndDateChanged"
      ></v-date-picker>
    </v-menu>
    <v-row>
      <v-col>
        <v-file-input
          label="Add Proof"
          id="file"
          v-model="form.file"
        ></v-file-input>
      </v-col>
    </v-row>
    <div class="d-flex justify-end mt-2">
      <v-btn
        depressed
        dark
        color="accent"
        right
        @click="submitNoteInformationRequest"
      >
        Save
      </v-btn>
    </div>
  </v-container>
</template>
<script>
import dayjs from "dayjs"
import { mask } from "vue-the-mask"
import { changesSaved, somethingWentWrong } from "@/helpers/utilities"
import { mapActions, mapGetters } from "vuex"
import SupplyNumber from "@/components/supply-number"
import api from "@/store/api"

export default {
  directives: { mask },
  data() {
    return {
      contractEndDate: "",
      contractRenewalEndDate: "",
      key: 78,
      showRenewalEndDate: false,
      menu: false,
      menuAgreed: false,
      menuEnd: false,
      showContractRenewalEndDate: false,
      form: {
        file: null,
        meterType: "9",
        siteId: 0,
        contractEndDate: "",
        contractRenewalEndDate: "",
        outstandingDebt: "",
        annualConsumption: "",
        alreadyAgreedRenewal: false
      },
      sites: [],
      selectedType: "",
      selectedSize: "",
      agreed: [
        { id: 1, value: "No" },
        { id: 2, value: "Yes", status: false }
      ],
      formHasErrors: false
    }
  },
  components: { SupplyNumber },
  mounted() {
    this.sites = []
    this.addresses.forEach(address => {
      this.sites.push({ text: address.siteName, value: address.addressId })
    })
  },
  computed: {
    ...mapGetters("forms", ["supplyDetails"]),
    ...mapGetters("addresses", ["selectedAddress", "addresses"]),
    ...mapGetters("employee", ["userId", "userName"]),
    ...mapGetters("company", ["companyId"]),
    dobTextRules() {
      return [v => !!v || "This field is required"]
    },
    renewalEndDateRules() {
      return [v => !!v || "This field is required"]
    },
    outstandingDebtRules() {
      return [v => /[0-9]+$/.test(v) || "Only Numeric Characters Allowed."]
    },
    formattedRenewalDate() {
      return this.form.contractRenewalEndDate
        ? dayjs(this.form.contractRenewalEndDate).format("DD/MM/YYYY")
        : ""
    },
    computed_items: function() {
      let filterType = this.selectedType,
        filterSize = this.selectedSize
      return this.items.filter(function(item) {
        let filtered = true
        if (filterType && filterType.length > 0) {
          filtered = item.type == filterType
        }
        if (filtered) {
          if (filterSize && filterSize.length > 0) {
            filtered = item.size == filterSize
          }
        }
        return filtered
      })
    }
  },
  methods: {
    ...mapActions("notes", ["fetchNotes", "createNotes"]),
    ...mapActions("forms", ["postSupplyDetails"]),
    contractEndDateChanged() {
      if (this.contractEndDate.length >= 1) {
        const tempDay = this.contractEndDate.slice(0, 2)
        const tempMonth = this.contractEndDate.slice(3, 5)
        const tempYear = this.contractEndDate.slice(6, 9)
        // const d = new Date()
        // const minYear = d.getFullYear();

        if (parseInt(tempDay) > 31) {
          const temp = this.contractEndDate.slice(2, 10)
          this.contractEndDate = "31" + temp
        }
        if (this.contractEndDate.length >= 2 && parseInt(tempDay) === 0) {
          const temp = this.contractEndDate.slice(2, 10)
          this.contractEndDate = "01" + temp
        }
        if (parseInt(tempMonth) > 12) {
          const temp = this.contractEndDate.slice(5, 10)
          this.contractEndDate = tempDay + "/12" + temp
        }
        if (this.contractEndDate.length >= 5 && parseInt(tempMonth) === 0) {
          const temp = this.contractEndDate.slice(5, 10)
          this.contractEndDate = tempDay + "/01" + temp
        }
        if (parseInt(tempYear) <= 0) {
          // const temp = this.contractEndDate.slice(6, 10)
          this.contractEndDate = tempDay + tempMonth + "/2021"
        }
        // if(this.form.contractEndDate.length >= 7 && parseInt(tempYear) === 0) {
        //     const temp = this.contractEndDate.slice(6, 10)
        //     this.contractEndDate = minYear + temp
        // }
        const contractEndDate = this.contractEndDate
          .split("/")
          .reverse()
          .join("-")
        const currentEndDate = dayjs(contractEndDate).format("YYYY-MM-DD")
        this.form.contractEndDate = currentEndDate
        if (this.form.contractEndDate === "Invalid Date") {
          this.form.contractEndDate = ""
        }
      }
    },
    contractRenewalEndDateChanged() {
      if (this.contractRenewalEndDate.length >= 1) {
        const tempDay = this.contractRenewalEndDate.slice(0, 2)
        const tempMonth = this.contractRenewalEndDate.slice(3, 5)
        const tempYear = this.contractRenewalEndDate.slice(6, 9)

        if (parseInt(tempDay) > 31) {
          const temp = this.contractRenewalEndDate.slice(2, 10)
          this.contractRenewalEndDate = "31" + temp
        }
        if (
          this.contractRenewalEndDate.length >= 2 &&
          parseInt(tempDay) === 0
        ) {
          const temp = this.contractRenewalEndDate.slice(2, 10)
          this.contractRenewalEndDate = "01" + temp
        }
        if (parseInt(tempMonth) > 12) {
          const temp = this.contractRenewalEndDate.slice(5, 10)
          this.contractRenewalEndDate = tempDay + "/12" + temp
        }
        if (
          this.contractRenewalEndDate.length >= 5 &&
          parseInt(tempMonth) === 0
        ) {
          const temp = this.contractRenewalEndDate.slice(5, 10)
          this.contractRenewalEndDate = tempDay + "/01" + temp
        }
        if (parseInt(tempYear) <= 0) {
          // const temp = this.contractEndDate.slice(6, 10)
          this.contractRenewalEndDate = tempDay + tempMonth + "/2021"
        }
        const contractRenewalEndDate = this.contractRenewalEndDate
          .split("/")
          .reverse()
          .join("-")
        const currentDOB = dayjs(contractRenewalEndDate).format("YYYY-MM-DD")
        this.form.contractRenewalEndDate = currentDOB
        if (this.form.contractRenewalEndDate === "Invalid Date") {
          this.form.contractRenewalEndDate = ""
        }
      }
    },
    save() {
      this.contractEndDate = dayjs(this.form.contractEndDate).format("DDMMYYYY")
    },
    formContractRenewalEndDateChanged() {
      this.contractRenewalEndDate = dayjs(
        this.form.contractRenewalEndDate
      ).format("DD/MM/YYYY")
    },
    validationDebt(evt) {
      const charCode = evt.which ? evt.which : evt.keyCode
      if (
        charCode > 31 &&
        (charCode < 32 || charCode > 57) &&
        charCode !== 46
      ) {
        event.returnValue = false
        return false
      }
    },
    validationAnual(evt) {
      const charCode = evt.which ? evt.which : evt.keyCode
      if (
        charCode > 31 &&
        (charCode < 32 || charCode > 57) &&
        charCode !== 46
      ) {
        event.returnValue = false
        return false
      }
    },
    switchRenewalsEnDate() {
      if (this.showRenewalEndDate === false) {
        this.showRenewalEndDate = true
      } else {
        this.showRenewalEndDate = false
      }
    },
    showARCED() {
      if (this.agreed === "Yes") {
        this.showContractRenewalEndDate = true
      }
    },
    validate() {
      this.$refs.form.validate()
    },
    saveContractEndDate(date) {
      this.$refs.menu.save(date)
    },

    async submitNoteInformationRequest() {
      const contractEndDate = this.contractEndDate
      const contractRenewalEndDate = this.contractRenewalEndDate
      if (contractEndDate === "" || contractEndDate === null) {
        const form = {
          contractEndDate: this.contractEndDate
        }
        this.formHasErrors = false

        Object.keys(form).forEach(f => {
          if (!this.form[f]) this.formHasErrors = true

          this.$refs[f].validate(true)
        })
        return false
      }
      if (
        this.form.alreadyAgreedRenewal &&
        (contractRenewalEndDate === "" || contractRenewalEndDate === null)
      ) {
        const form = {
          contractRenewalEndDate: this.contractRenewalEndDate
        }
        this.formHasErrors = false
        Object.keys(form).forEach(f => {
          if (!this.form[f]) this.formHasErrors = true

          this.$refs[f].validate(true)
        })
        return false
      }
      const meterType = this.form.meterType === "9" ? "Electricity" : "Gas"
      const supplyNumber =
        this.form.meterType === "9"
          ? this.supplyDetails.sId +
            this.supplyDetails.mpIdA +
            this.supplyDetails.mpIdB +
            this.supplyDetails.cd
          : this.supplyDetails.supplyNumber
      const noteDescription = `${meterType} ${
        this.form.siteId
      }<br/>${supplyNumber}<br/>CED: ${
        this.form.contractEndDate
          ? dayjs(this.form.contractEndDate).format("DD/MM/YYYY")
          : ""
      }<br/>Annual Consumption: ${this.form.annualConsumption}<br/>Debt: £${
        this.form.outstandingDebt
      }<br/>Already Renewed: ${this.form.alreadyAgreedRenewal ? "Yes" : "No"}${
        this.form.alreadyAgreedRenewal
          ? "<br/>Already Renewed CED: " +
            dayjs(this.form.contractRenewalEndDate).format("DD/MM/YYYY")
          : ""
      }`
      const tmpContractEndDate = this.contractEndDate
        .split("/")
        .reverse()
        .join("-")
      const currentCED = dayjs(tmpContractEndDate).format("YYYY-MM-DD")
      let currentRenewalCED = 0
      if (this.form.alreadyAgreedRenewal) {
        const tmpRenewalCED = this.contractRenewalEndDate
          .split("/")
          .reverse()
          .join("-")
        currentRenewalCED = dayjs(tmpRenewalCED).format("YYYY-MM-DD")
      }
      const formData = new FormData()
      formData.append("file", this.form.file)
      formData.append("companyId", this.companyId)
      formData.append("noteTypeId", 3)
      formData.append("note", noteDescription)
      formData.append("userId", this.userId)
      formData.append("saleId", 0)
      formData.append("meterTypeId", this.form.meterType)
      formData.append("supplyNumber", supplyNumber)
      formData.append("pc", this.supplyDetails.pc)
      formData.append("mtc", this.supplyDetails.mtc)
      formData.append("llf", this.supplyDetails.llf)
      formData.append("addressId", this.form.siteId)
      formData.append("ced", dayjs(currentCED).unix())
      formData.append("annualConsumption", this.form.annualConsumption)
      formData.append("outstandingDebt", this.form.outstandingDebt)
      formData.append(
        "alreadyAgreedRenewal",
        this.form.alreadyAgreedRenewal ? 1 : 0
      )
      formData.append(
        "renewalCed",
        this.form.alreadyAgreedRenewal ? dayjs(currentRenewalCED).unix() : 0
      )

      try {
        // const response = await this.createNotes(noteDetails) /saveInfoRequesNote
        const response = await api.post("saveInfoRequesNote", formData)
        if (response && response.status === 200) {
          changesSaved("Information Request Added Successfully")
          setTimeout(() => {
            this.fetchNotes()
          }, 1000)
          this.form.file = null
          this.form.meterType = "10"
          this.form.siteId = 0
          this.form.contractEndDate = ""
          this.form.contractRenewalEndDate = ""
          this.form.outstandingDebt = ""
          this.form.annualConsumption = ""
          this.form.alreadyAgreedRenewal = false
          this.contractEndDate = ""
          this.contractRenewalEndDate = ""
          const supplyDetailsData = {
            electricSupplyNo: "",
            pc: "",
            mtc: "",
            llf: "",
            sId: "",
            mpIdA: "",
            mpIdB: "",
            cd: "",
            supplyNumber: ""
          }
          this.postSupplyDetails(supplyDetailsData)
          this.key = Math.floor(Math.random() * 101)
        } else {
          somethingWentWrong()
        }
      } catch (error) {
        somethingWentWrong()
      }
    }
  }
}
</script>
<style>
.labels {
  font-size: 12px;
}
</style>
