var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-list-item',[_c('v-list-item-content',[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"1"}},[_c('v-icon',{staticClass:"d-flex justify-center",attrs:{"size":"32","color":_vm.typeColor}},[_vm._v(" "+_vm._s(_vm.typeIcon)+" ")])],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-img',{staticStyle:{"width":"110px","height":"54px"},attrs:{"contain":"","src":_vm.$helpers.supplierLogo(_vm.supplierId)}})],1),_c('v-col',{attrs:{"cols":"3"}},[(_vm.estimate)?_c('div',{staticClass:"text-center"},[_vm._v("Estimate")]):_c('SupplyNumber',{attrs:{"type":this.type,"supply-number":_vm.supplyNumber,"pc":_vm.pc,"mtc":_vm.mtc,"llf":_vm.llf,"disabled":true}})],1),(_vm.estimate)?_c('v-col',{staticStyle:{"margin-right":"29px"},attrs:{"cols":"1"}}):_vm._e(),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-row',[(!_vm.estimate)?_c('v-col',{attrs:{"cols":"auto"}},[_c('v-row',[(!_vm.estimate)?_c('v-col',{attrs:{"cols":"6"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('RegReports',{attrs:{"supply-number":_vm.supplyNumber}})],1)]}}],null,false,3273264379)},[_c('span',[_vm._v("REGISTRATION REPORTS")])])],1):_vm._e(),(!_vm.estimate)?_c('v-col',{attrs:{"cols":"6"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('Invoices',{attrs:{"supply-number":_vm.supplyNumber}})],1)]}}],null,false,3308114164)},[_c('span',[_vm._v("INVOICES")])])],1):_vm._e()],1),_c('v-row',[(!_vm.estimate)?_c('v-col',{attrs:{"cols":"6"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('PreviousSales',{attrs:{"supply-number":_vm.supplyNumber}})],1)]}}],null,false,1022073871)},[_c('span',[_vm._v("SALES ACHIVE")])])],1):_vm._e(),(!_vm.estimate)?_c('v-col',{attrs:{"cols":"6"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('MeterDocuments',{key:_vm.meterId,attrs:{"meter-id":_vm.meterId,"user-id":_vm.userId}})],1)]}}],null,false,1998176221)},[_c('span',[_vm._v("METER DOCUMENTS")])])],1):_vm._e()],1)],1):_vm._e(),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"auto","align":"center"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-row',_vm._g(_vm._b({attrs:{"dense":"","align":"center"}},'v-row',attrs,false),on),[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-icon',{attrs:{"small":"","color":"deep-orange"}},[_vm._v("mdi-calendar-remove")])],1),_c('v-col',[_c('span',[_vm._v(_vm._s(_vm.$helpers.formatDate(_vm.contractEndDate)))])])],1)]}}])},[_c('span',[_vm._v("Contract End Date")])])],1)],1)],1),(_vm.estimate)?_c('div',{staticStyle:{"margin-left":"2px"}}):_vm._e(),_c('v-col',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-row',_vm._g(_vm._b({attrs:{"dense":"","align":"center"}},'v-row',attrs,false),on),[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-icon',{attrs:{"small":"","color":_vm.renwalDate(_vm.renewalDate) ? 'green' : 'red'}},[_vm._v("mdi-calendar-refresh")])],1),_c('v-col',[_c('span',{class:_vm.renwalDate(_vm.renewalDate) ? 'green--text' : 'red--text'},[_vm._v(_vm._s(_vm.$helpers.formatDate(_vm.renewalDate)))]),_c('div',{staticClass:"text-body-2 grey--text mt-1"},[_vm._v(" "+_vm._s(_vm.window)+" Days ")])])],1)]}}])},[_c('span',[_vm._v("Renewal Date")])])],1),_c('v-col',{attrs:{"cols":"auto"}},[(!_vm.isHideMeterEdit)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"blue","depressed":"","icon":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.editMeter($event)}}},[_vm._v("mdi-pencil")])],1)]}}],null,false,2254526968)},[_c('span',[_vm._v("Edit Meter")])]):_vm._e(),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"depressed":"","icon":"","small":""}},'v-btn',attrs,false),on),[_c('div',[_c('GenerateQuoteModal',{attrs:{"selectedMeter":_vm.meter}})],1)])]}}])},[_c('span',[_vm._v("Generate Quote")])]),(
            !_vm.isHideMeterEdit &&
            ((!_vm.estimate && _vm.userId === _vm.readyForQuoteData.assignedById) ||
              _vm.details.dataSourceId === 2725)
          )?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"depressed":"","icon":"","small":""}},'v-btn',attrs,false),on),[_c('div',[_c('SubmitContract',{attrs:{"contract-end-date":_vm.contractEndDate,"meter-id":_vm.meterId,"address-id":_vm.addressId,"meter-type-id":_vm.meterTypeId,"supplier-name":_vm.supplierName,"pc":_vm.pc,"supplier-id":parseInt(_vm.supplierId)}})],1)])]}}],null,false,3519714883)},[_c('span',[_vm._v("Submit Contract")])]):_vm._e(),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"depressed":"","icon":"","small":""}},'v-btn',attrs,false),on),[_c('div',[_c('RemoveMeter',{attrs:{"meter-id":_vm.meterId,"address-id":_vm.addressId}})],1)])]}}])},[_c('span',[_vm._v("Delete Meter")])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }