<template>
    <v-dialog
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
        v-model="eFormmodal" 
        width="1300"
    >

    <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-btn
            icon
            dark
            @click="closeModal"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            B30 Fixed for Business Online Energy Supply Contract AW7_E6 & LOA 
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              dark
              text
              @click="dialog = false"
            >
              Save
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-form class="px-5 mt-3" ref="form" v-model="valid" lazy-validation>
            <v-row>
                <v-col>
                    <h2>Fixed for Business Online Energy Supply Contract</h2>
                </v-col>
                <v-col>
                    <v-img
                        style="width: 110px; height:54px;"
                        contain
                        :src="$helpers.supplierLogo(eFormDetails.soldSupplierId)"
                    ></v-img>
                </v-col>
            </v-row>
            <v-row>
                <!-- <v-col>
                    <v-btn
                      value="utility hive"
                      v-if="eFormDetails.saleType === 2"
                      class="accent"
                    >
                      Utility Hive
                    </v-btn>
                    <v-btn
                      value="commercial support service"
                      v-if="eFormDetails.saleType === 1"
                      class="green white--text"
                    >
                      Commercial Support Service
                    </v-btn>
                     <v-btn class="white--text primary ml-3">
                        <i :class="eFormDetails.contractTypeIcon"></i>
                        <span class="ml-2">{{eFormDetails.contractType}}</span>
                      </v-btn>
                </v-col> -->
                <v-col>
                    <v-text-field
                        ref="contactName"
                        :value="eFormDetails.saleType === 2 ? 'Utility Hive' : 'Commercial Support Service'"
                        label="TPI Name"
                    ></v-text-field>
                </v-col>
                <v-col>
                    <v-text-field
                        ref="contactName"
                        :value="eFormDetails.contractType"
                        label="Sale Type"
                    ></v-text-field>
                </v-col>

            </v-row>
            <div class="p-5 mt-4">
                <!-- BUSINESS CONTACT DETAILS -->
                <div class="box-shadow p-3">
                    <div class="mt-3">
                        <h3>Business Contact Details</h3>
                    </div>
                    <div class="mt-2">
                        <v-row>
                            <v-col>
                                <v-text-field
                                    ref="businessName"
                                    v-model="eFormDetails.companyName"
                                    label="Business Name"
                                    disabled
                                ></v-text-field>
                            </v-col>
                            <v-col>
                                <v-text-field
                                    ref="contactName"
                                    :value="eFormDetails.firstName + ' ' + eFormDetails.lastName"
                                    label="Contact Name"
                                    disabled
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field
                                    ref="contactName"
                                    v-model="eFormDetails.businessType"
                                    label="Business Type"
                                    disabled
                                ></v-text-field>
                            </v-col>
                            <v-col>
                                <v-text-field
                                    ref="contactName"
                                    v-model="eFormDetails.tradingType"
                                    label="Trading as"
                                    disabled
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field
                                    ref="contactName"
                                    v-model="eFormDetails.telephone"
                                    label="Telephone"
                                    disabled
                                ></v-text-field>
                            </v-col>
                            <v-col>
                                <v-text-field
                                    ref="contactName"
                                    v-model="eFormDetails.emailAddress"
                                    label="Email Address"
                                    disabled
                                ></v-text-field>
                            </v-col>
                            <v-col>
                                <v-text-field
                                    ref="contactName"
                                    v-model="eFormDetails.altContactNumber"
                                    label="Mobile"
                                    disabled
                                ></v-text-field>
                            </v-col>
                        </v-row>                        
                    </div>
                </div>
                <!-- SUPPLY DETAILS -->
                <div class="box-shadow p-3 mt-5">
                    <div class="mt-2">
                        <h3>Supply Details</h3>
                    </div>
                    <div class="mt-2">
                        <v-row>
                            <v-col>
                                <v-text-field
                                    ref="contactName"
                                    :value="eFormDetails.siteName"
                                    label="Supply Name"
                                    disabled
                                ></v-text-field>
                            </v-col>
                            <v-col>
                                <v-text-field
                                    ref="contactName"
                                    :value="eFormDetails.add1 + eFormDetails.add2"
                                    label="Supply Address"
                                    disabled
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field
                                ref="contactName"
                                :value="eFormDetails.town + eFormDetails.county"
                                label="Town, Country"
                                disabled
                            ></v-text-field>
                            </v-col>
                            <v-col>
                                <v-text-field
                                ref="contactName"
                                :value="eFormDetails.postcode"
                                label="Post Code"
                                disabled
                            ></v-text-field>
                            </v-col>
                        </v-row>                      
                    </div>
                </div>
                <!-- BILLING DETAILS -->
                <div class="box-shadow p-3 mt-5">
                    <div class="mt-2">
                        <h3>Billing Details (if different from supply address)</h3>
                    </div>
                    <div class="mt-2">
                        <!-- {{eFormDetails}} -->
                        <v-row>
                            <v-col>
                                <v-text-field
                                    ref="contactName"
                                    v-model="form.billingName"
                                    label="Billing Name"
                                ></v-text-field>
                            </v-col>
                            <v-col>
                                <v-text-field
                                    ref="billing address"
                                    v-model="form.billingAddress"
                                    label="Billing Address"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field
                                    ref="contactName"
                                    v-model="form.billingTowncountry"
                                    label="Town, Country"
                                ></v-text-field>
                            </v-col>
                            <v-col>
                                <v-text-field
                                    ref="contactName"
                                    v-model="form.billingPostCode"
                                    label="Post Code"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </div>
                </div>
                <!-- ELECTRICITY -->
                <div  class="box-shadow p-3 mt-5">
                    <div class="mt-2">
                        <h3>Electricity</h3>
                    </div>
                    <div class="mt-2">
                        <v-row>
                            <v-col>
                                <v-text-field
                                    ref="contactName"
                                    v-model="form.eProductCode"
                                    label="Product Code"
                                ></v-text-field>
                            </v-col>
                            <v-col>
                                <v-menu
                                    ref="ePriceFormattedDate"
                                    v-model="ePriceReviewDate"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            :value="setCsdDate"
                                            label="Price review date"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        key="1"
                                        v-model="form.ePriceReviewDate"
                                        ref="ePriceReviewDate"
                                        :max="new Date().toISOString().substr(0, 10)"
                                        min="1950-01-01"
                                        locale="en-GB"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col>
                                <v-menu
                                    ref="ePriceReviewFormattedDate"
                                    v-model="ePriceReviewNoticeDate"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            :value="setCedDate"
                                            label="Price review notice date"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        key="2"
                                        v-model="form.ePriceReviewNoticeDate"
                                        ref="ePriceReviewNoticeDate"
                                        :max="new Date().toISOString().substr(0, 10)"
                                        min="1950-01-01"
                                        locale="en-GB"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field
                                    ref="contactName"
                                    :value="eFormDetails.quoteTypePriceList[0].baseRate"
                                    v-model="form.eStandingCharge"
                                    label="Standing charge(p/per day)"
                                ></v-text-field>
                            </v-col>
                            <v-col>
                                <v-text-field
                                    ref="contactName"
                                    v-model="form.eUniteRate"
                                    label="Unit rate(per kWH)"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <p class="font-weight-bold">Unit Rate ( per kWh)</p>
                        <v-row>
                            <v-col>
                                <v-text-field
                                    ref="contactName"
                                    v-model="form.eUnitRateDay"
                                    label="Day"
                                ></v-text-field>
                            </v-col>
                            <v-col>
                                <v-text-field
                                    ref="contactName"
                                    v-model="form.eUnitRateNight"
                                    label="Night"
                                ></v-text-field>
                            </v-col>
                            <v-col>
                                <v-text-field
                                    ref="contactName"
                                    v-model="form.eUnitRateOffPeak"
                                    label="Off peak"
                                ></v-text-field>
                            </v-col>
                            <v-col>
                                <v-text-field
                                    ref="contactName"
                                    v-model="form.eUnitRateWknd"
                                    label="Eve/Wknd"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field
                                    ref="contactName"
                                    v-model="form.eMeterSerailNumber"
                                    label="Meter Serial Number"
                                ></v-text-field>
                            </v-col>
                            <v-col>
                                <!-- <v-text-field
                                    ref="contactName"
                                    v-model="form.eMeterType"
                                    label="Meter Type"
                                ></v-text-field> -->
                                <v-select
                                ref="selectedMeterType"
                                label="Meter Type"
                                v-model="form.selectedMeterTypeList"
                                :items="contractMeterTypesList"
                                ></v-select>
                                
                            </v-col>
                            <v-col>
                                <v-text-field
                                    ref="contactName"
                                    v-model="form.EAC"
                                    label="EAC"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </div>
                </div>
                <!-- GAS -->
                <div v-if="eFormDetails.meterTypeID === 10" class="box-shadow p-3 mt-5">
                    <div class="mt-2">
                        <h3>Gas</h3>
                    </div>
                    <div class="mt-2">
                        <v-row>
                            <v-col>
                                <v-text-field
                                    ref="contactName"
                                    v-model="form.gProductCode"
                                    label="Product Code"
                                ></v-text-field>
                            </v-col>
                            <v-col>
                                <v-menu
                                    ref="gPriceFormattedDate"
                                    v-model="gPriceReviewDate"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            :value="setCsdDate"
                                            label="Price review date"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        key="3"
                                        v-model="eFormDetails.gPriceReviewDate"
                                        ref="gPriceReviewDate"
                                        :max="new Date().toISOString().substr(0, 10)"
                                        min="1950-01-01"
                                        locale="en-GB"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col>
                                <v-menu
                                    ref="gPriceReviewFormattedDate"
                                    v-model="gPriceReviewNoticeDate"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            :value="setCedDate"
                                            label="Price review notice date"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        key="4"
                                        v-model="eFormDetails.gPriceReviewNoticeDate"
                                        ref="gPriceReviewNoticeDate"
                                        :max="new Date().toISOString().substr(0, 10)"
                                        min="1950-01-01"
                                        locale="en-GB"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field
                                    ref="contactName"
                                    v-model="form.gStandingCharge"
                                    label="Standing charge(p/per day)"
                                ></v-text-field>
                            </v-col>
                            <v-col>
                                <v-text-field
                                    ref="contactName"
                                    v-model="form.gUnitRate"
                                    label="Unit rate(per kWH)"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field
                                    ref="contactName"
                                    v-model="form.gMeterSerailNumber"
                                    label="Meter Serial Number"
                                ></v-text-field>
                            </v-col>
                            <v-col>
                                <v-select
                                ref="selectedMeterType"
                                label="Tariff Type"
                                v-model="form.selectedMeterTypeList"
                                :items="contractMeterTypesList"
                                ></v-select>
                            </v-col>
                            <v-col>
                                <v-text-field
                                    ref="contactName"
                                    v-model="form.AQ"
                                    label="AQ"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </div>
                </div>
                <!-- TOTAL ESTIMATED COSR -->
                <div class="box-shadow p-3 mt-5">
                    <div class="mt-2">
                        <h3>Total Estimated Commission Cost*</h3>
                    </div>
                    <v-row>
                        <v-col>
                            <v-text-field
                                ref="contactName"
                                v-model="form.totalEstCommissionCost"
                                label="Total Estimated Commission Cost*"
                            ></v-text-field>
                        </v-col>
                        <v-col class="d-flex align-center">
                            <h3 class="font-weight-bold ml-3 mr-5">LOA</h3>
                            <v-radio-group
                                v-model="form.isLoa"
                                row
                                >
                                <v-radio
                                    label="YES"
                                    color="red"
                                    value=true
                                ></v-radio>
                                <v-radio
                                    label="NO"
                                    color="red"
                                    value=false
                                ></v-radio>
                            </v-radio-group>
                        </v-col>
                    </v-row>
                </div>
                <!-- MORE ABOUT -->
                <div class="box-shadow p-3 mt-5">
                    <div class="mt-2">
                        <h3>More about your current supply</h3>
                    </div>
                    <p class="font-weight-bold">Current supplier for electricity and gas</p>
                    <v-row>
                        <v-col>
                            <v-text-field
                                ref="contactName"
                                v-model="form.currentSuppElec"
                                label="Electricity"
                            ></v-text-field>
                        </v-col>
                        <v-col>
                            <v-text-field
                                ref="contactName"
                                v-model="form.currentSupGas"
                                label="Gas"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <p class="font-weight-bold mt-2">Fixed term contract details</p>
                    <v-row>
                        <v-col>
                            <v-text-field
                                ref="contactName"
                                v-model="form.fixedTermElecEndDate"
                                label="Electricity End Date"
                            ></v-text-field>
                        </v-col>
                        <v-col>
                            <v-text-field
                                ref="contactName"
                                v-model="form.fixedTermGasEndDate"
                                label="Gas End Date"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </div>
                <!-- Preferred PAyment -->
                <div class="box-shadow p-3 mt-5">
                    <div class="mt-2">
                        <h3>Preferred payment</h3>
                    </div>
                    <p class="red--text"> * I have agreed to pay by <span class="font-weight-bold">Direct Debit Whole Amount Monthly (Variable)</span></p>
                    <v-row>
                        <v-col>
                            <p class="font-weight-bold mt-2">Expected yearly spend (excluding VAT & CCL)</p>
                            <v-text-field
                                ref="contactName"
                                v-model="form.expectedYearlySpend"
                                label="Expected yearly spend"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <p class="font-weight-bold mt-2">Agreed electricity standard payment amount (inc relevant VAT & CCL)</p>
                            <v-text-field
                                ref="contactName"
                                v-model="form.elecStandardPayment"
                                label="Agreed electricity standard payment"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <p class="font-weight-bold mt-2">Agreed gas standard payment amount (inc relevant VAT & CCL)</p>
                            <v-text-field
                                ref="contactName"
                                v-model="form.gasStandardPayment"
                                label="Agreed gas standard payment"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </div>
                <!-- SBUMIT UTTON AND AGREEMENT -->
                <div class="box-shadow p-3 mt-5">
                    <v-row class="mt-1">
                        <v-col>
                            <p class="secondary--text">* Your agreed monthly amount is based on your consumption, payment method and contract(s). If any of these change, 
                                your payment amount may adjust accordingly. If your contract is Direct Debit only, changing your payment method may result in moving onto other prices
                                which could be more expensive, Please ask the sales represntative for further details. All prices shown are eclusive of Climate Change Levy (CCL) and VAT.
                            </p>
                        </v-col>
                    </v-row>
                    <div class="mt-4">
                        <v-btn
                            depressed
                            color="accent"
                            @click="submitEForm"
                            block
                            dark
                            large
                        >
                            Submit Contract
                        </v-btn>
                    </div>
                </div>
            </div>
            
        </v-form>
    </v-card>
    </v-dialog>
</template>

<script>
// import { mergeExistingEntriesIntoForm } from "@/helpers/utilities"
import { mapGetters } from "vuex"
import api from "@/store/api"
import { changesSaved, somethingWentWrong } from "@/helpers/utilities"
import dayjs from 'dayjs'


// import { required } from "@/helpers/form-validations"

export default {
  props: {
    eFormmodal : { Type : Boolean , default : false},
    contractMeterTypesList : { Type: Array , default: null }
  },
  data: () => ({
    valid: true,
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
    contactNumber: null,
    isSendingRequest: false,
    formHasErrors: false,
    errorMsg: "",
    errorMsgCC: "",
    showMessage: false,
    tab:null,
    ePriceReviewDate:null,
    ePriceReviewNoticeDate:null,
    gPriceReviewDate:null,
    gPriceReviewNoticeDate:null,
    priceQuote: {},
    form: {
        billingName:null,
        billingAddress:null,
        billingTowncountry:null,
        billingPostCode:null,
        eProductCode:null,
        ePriceReviewDate:null,
        ePriceReviewNoticeDate:null,
        eStandingCharge:null,
        eUniteRate:null,
        eUnitRateDay:null,
        eUnitRateNight:null,
        eUnitRateOffPeak:null,
        eUnitRateWknd:null,
        eMeterSerailNumber:null,
        eMeterType:null,
        EAC:null,
        gProductCode:null,
        gStandingCharge:null,
        gUnitRate:null,
        gMeterSerailNumber:null,
        gTariffType:null,
        AQ:null,
        totalEstCommissionCost:null,
        isLoa:false,
        currentSuppElec:null,
        currentSupGas:null,
        fixedTermElecEndDate:null,
        fixedTermGasEndDate:null,
        expectedYearlySpend:null,
        elecStandardPayment:null,
        gasStandardPayment: null,
        selectedMeterTypeList:null
    },   

  }),
  mounted() {
    // this.setCsdDate()
    // this.setCedDate()
    if(this.eFormDetails.quoteTypePriceList) {
        this.initialSetup()
    }
    

    // this.setStandingChargeUplift()
    // this.setUnitRateCommission()
    // this.setPerDayRate()
    // this.setTotalCommission()
    
  },

  computed: {
    ...mapGetters("forms", ["eFormDetails"]),

    setCedDate() {
        return dayjs(this.eFormDetails.ced * 1000).format("DD/MM/YYYY")
    },
    setCsdDate() {
        return dayjs(this.eFormDetails.csd * 1000).format("DD/MM/YYYY")  
    },
    ePriceFormattedDate() {
        if(this.eFormDetails.ePriceReviewDate === "" && this.eFormDetails.ePriceReviewDate === 0) {
            return ""
        }

        return this.eFormDetails.ePriceReviewDate
            ? dayjs(this.eFormDetails.ePriceReviewDate).format("DD/MM/YYYY")
            : ""
    },
    ePriceReviewFormattedDate() {
        if(this.eFormDetails.ePriceReviewNoticeDate === "" && this.eFormDetails.ePriceReviewNoticeDate === 0) {
            return ""
        }

        return this.eFormDetails.ePriceReviewNoticeDate
            ? dayjs(this.eFormDetails.ePriceReviewNoticeDate).format("DD/MM/YYYY")
            : ""
    },
    gPriceFormattedDate() {
        if(this.eFormDetails.gPriceReviewDate === "" && this.eFormDetails.gPriceReviewDate === 0) {
            return ""
        }

        return this.eFormDetails.gPriceReviewDate
            ? dayjs(this.eFormDetails.gPriceReviewDate).format("DD/MM/YYYY")
            : ""
    },
    gPriceReviewFormattedDate() {
        if(this.eFormDetails.gPriceReviewNoticeDate === "" && this.eFormDetails.gPriceReviewNoticeDate === 0) {
            return ""
        }

        return this.eFormDetails.gPriceReviewNoticeDate
            ? dayjs(this.eFormDetails.gPriceReviewNoticeDate).format("DD/MM/YYYY")
            : ""
    },

    nameValidation() {
      return [(v) => /[a-zA-Z]+$/.test(v) || "Field must only contain letters"]
    },
    surnameValidation() {
      return [(v) => /[a-zA-Z]+$/.test(v) || "Field must only contain letters"]
    },
    jobTitleValidation() {
      return [(v) => /[a-zA-Z]+$/.test(v) || "Field must only contain letters"]
    },
  },
  watch: {
    eFormmodal() {
        this.initialSetup()
    }
  },

  methods: {
    initialSetup() {
       
        this.priceQuote =  JSON.parse(JSON.stringify(this.eFormDetails.quoteTypePriceList));

        this.form.selectedMeterTypeList =  this.eFormDetails.selectedSupplierMeterTypeID

        this.setTotalYearlySpend()
        this.setBillingdetails()

        this.priceQuote.forEach( (el,index) => {
            console.log('el',el.price)
            console.log(typeof(el))
            console.log('in',index)
            if(index === 0) {
                this.form.eStandingCharge =   el.uplift
                this.form.gStandingCharge =   el.uplift
            }
            if(index === 1) {
                    this.form.eUniteRate =   el.uplift
                    this.form.gUniteRate =   el.uplift
                    this.form.eUnitRateDay =   el.price
                    this.form.totalEstCommissionCost =   el.eac
                
            }
            if(index === 2) {
                    this.form.eUnitRateNight =   el.price
                
            }
        })
    },
    setBillingdetails() {
        this.form.billingName = this.eFormDetails.siteName
        this.form.billingAddress = this.eFormDetails.add1 + this.eFormDetails.add2
        this.form.billingTowncountry = this.eFormDetails.town + this.eFormDetails.county
        this.form.billingPostCode = this.eFormDetails.postCode
    },
    setTotalYearlySpend() {
       const total = this.priceQuote.reduce(
        (total, item) => total + Number(item.price),
        0
      ) || 0

      this.form.expectedYearlySpend = total
    },

    // setStandingChargeUplift() {
    //     if(this.priceQuote) {
    //         this.form.eStandingCharge =   this.priceQuote[0].uplift
    //         this.form.gStandingCharge =   this.priceQuote[0].uplift
    //     }
    // },
    // setUnitRateCommission() {
    //     if(this.priceQuote) {
    //         this.form.eUniteRate =   this.priceQuote[1].uplift
    //         this.form.gUniteRate =   this.priceQuote[1].uplift
    //     }
    // },

    // setPerDayRate() {
    //     if(this.priceQuote) {
    //         this.form.eUnitRateDay =   this.priceQuote[1].price
    //         this.form.eUnitRateNight =   this.priceQuote[2].price
    //     }
    // },

    // setTotalCommission() {
    //     if(this.priceQuote) {
    //         this.form.totalEstCommissionCost =   this.priceQuote[1].eac
    //     }
    // },

    // setCsdDate() {
    //     this.eFormDetails.ePriceReviewDate = dayjs(this.eFormDetails.csd * 1000).format("DD/MM/YYYY")  
    //     this.eFormDetails.gPriceReviewDate = dayjs(this.eFormDetails.csd * 1000).format("DD/MM/YYYY")
    // },
    // setCedDate() {
    //     this.eFormDetails.ePriceReviewNoticeDate = dayjs(this.eFormDetails.ced * 1000).format("DD/MM/YYYY")
    //     this.eFormDetails.gPriceReviewNoticeDate = dayjs(this.eFormDetails.ced * 1000).format("DD/MM/YYYY")
    // },
    async submitEForm() {
        const formData = {
            ...this.eFormDetails,
            ...this.form
        }
        const res = await api.post("sendSignableContract", formData)
        console.log(res)
        if(res && res.status === 200) {
            changesSaved('E Form submitted Successfully')
        }else {
            somethingWentWrong
        }
    },

    closeModal() {
        this.$emit('closeEFormModal')
    },
    isNumberKey(evt) {
        this.showInvalidFormatError = false
        const charCode = evt.which ? evt.which : evt.keyCode
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
          event.returnValue = false
          return false
        } else {
          event.returnValue = true
          return true
        }
    },
    jobTitleCapitalLetter() {
      this.form.jobTitle = this.form.jobTitle
        .toLowerCase()
        .split(" ")
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(" ")
    },
    lastNameCapitalLetter() {
      this.form.lastName = this.form.lastName
        .toLowerCase()
        .split(" ")
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(" ")
    },
    firstNameCapitalLetter() {
      this.form.firstName = this.form.firstName
        .toLowerCase()
        .split(" ")
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(" ")
    },
    validateContactAndSave() {
      const contactNumber = this.form.contactNumber ? this.form.contactNumber : ""
      const firstName = this.form.firstName
      const lastName = this.form.lastName
      const jobTitle = this.form.jobTitle
      // console.log(this.$refs.contactNumber.$refs['contactNumber'])
      if ( 
          this.formEditMode && 
          (
            !/[a-zA-Z]+$/.test(firstName) ||
            this.firstName === "" ||
            (lastName !== "" && !/[a-zA-Z]+$/.test(lastName)) ||
            (jobTitle !== "" && !/[a-zA-Z]+$/.test(jobTitle))
          )
      ) {
        const form = {
          firstName: this.form.firstName,
          lastName: this.form.lastName,
          jobTitle: "",
        }
        this.formHasErrors = false
        Object.keys(form).forEach((f) => {
          if (!this.form[f]) this.formHasErrors = true
          this.$refs[f].validate(true)
        })
        return false
      }
      if ( 
          this.formEditMode && 
          contactNumber &&
          contactNumber !== "" &&
          (
            contactNumber.length < 10 ||
            /^(00|034|084|087|04|05|06|09)/.test(contactNumber)
          )
      ) {
        this.$refs.contactNumber.$refs['contactNumber'].validate(true)
        return false
      }
      if ( 
          !this.formEditMode && 
          (
            !/[a-zA-Z]+$/.test(firstName) ||
            this.firstName === "" 
          )
      ) {
        const form = {
          firstName: this.form.firstName,
        }
        this.formHasErrors = false
        Object.keys(form).forEach((f) => {
          if (!this.form[f]) this.formHasErrors = true
          this.$refs[f].validate(true)
        })
        return false
      }



      this.submitForm()
    },

    // -------Lets separate the function Update and edit Contactac -----
    submitForm() {
      if (!this.formEditMode) {
        this.addContact()
      } else {
        this.editContact()
      }
    },
    // async addContact() {
    //   if(this.isSendingRequest) {
    //     return false
    //   }
    //   this.isSendingRequest = true
    //   const defaultContact = getContactState()
    //   const noteMessage = `Contact Added - ${this.form.title} ${this.form.firstName} ${this.form.lastName}<br>
    //                       ${this.form.jobTitle}<br>
    //                       ${this.form.contactNumber}<br>
    //                       ${this.form.emailAddress}`
    //   const res = await this.createContact({
    //     ...defaultContact,
    //     ...this.form,
    //     companyId: this.companyId,
    //     noteMessage: noteMessage,
    //     currentUserId: this.userId,
    //   })
    //   const self = this
    //   if (res.status === 200) {
    //     this.isSendingRequest = true

    //     if (isNaN(parseFloat(res.data))) {
    //       this.showMessage = true
    //       if (res.data.includes("alternate")) {
    //         self.errorMsg = res.data
    //       } else {
    //         self.errorMsgCC = res.data
    //         setTimeout(() => {
    //           this.showMessage = false
    //         }, 3000)
    //       }
    //       this.isSendingRequest = false
    //         somethingWentWrong()
    //     } else {

    //       changesSaved("Contact Added Sucessfully")
    //       this.fetchMainData(this.companyId)
    //       this.fetchContacts()
    //       this.fetchNotes()
    //       this.contactNumber = null
    //       this.isSendingRequest = false
    //       this.form = {
    //         title: "",
    //         firstName: "",
    //         lastName: "",
    //         jobTitle: "",
    //         contactNumber: "",
    //         emailAddress: "",
    //       }
    //       this.formHasErrors = false
    //       this.errorMsg = ""
    //       this.errorMsgCC = ""
    //       this.$refs.form.resetValidation()
    //       this.closeForm()
    //     }
    //   }
    // },
    // async editContact() {
    //   // const noteMessage = `Contact Updated - ${this.form.title} ${this.form.firstName} ${this.form.lastName}`

    //   let noteMessage = ''

    //   if (this.form.title !== this.selectedContact.title) {
    //     noteMessage += `Title changed from ${
    //       this.selectedContact.title ? this.selectedContact.title : ''
    //     } to ${this.form.title}<br>`
    //   }

    //   if (this.form.firstName !== this.selectedContact.firstName) {
    //     noteMessage += `First Name changed from ${
    //       this.selectedContact.firstName ? this.selectedContact.firstName : ''
    //     } to ${this.form.firstName}<br>`
    //   }

    //   if (this.form.lastName !== this.selectedContact.lastName) {
    //     noteMessage += `Last Name changed from ${
    //       this.selectedContact.lastName ? this.selectedContact.lastName : ''
    //     } to ${this.form.lastName}<br>`
    //   }

    //   if (this.form.jobTitle !== this.selectedContact.jobTitle) {
    //     noteMessage += `Job Title changed from ${
    //       this.selectedContact.jobTitle ? this.selectedContact.jobTitle : ''
    //     } to ${this.form.jobTitle}<br>`
    //   }

    //   if (this.form.contactNumber !== this.selectedContact.contactNumber) {
    //     noteMessage += `Contact Number changed from ${
    //       this.selectedContact.contactNumber ? this.selectedContact.contactNumber : ''
    //     } to ${this.form.contactNumber}<br>`
    //   }

    //   if (this.form.emailAddress !== this.selectedContact.emailAddress) {
    //     noteMessage += `Email changed from ${
    //       this.selectedContact.emailAddress ? this.selectedContact.emailAddress : ''
    //     } to ${this.form.emailAddress}<br>`
    //   }


    //   const res = await this.updateContact({
    //     ...this.selectedContact,
    //     ...this.form,
    //     companyId: this.companyId,
    //     noteMessage: noteMessage === '' ? '' : `Contact Updated - ${this.form.title} ${this.form.firstName} ${this.form.lastName}<br>${noteMessage}`,
    //     currentUserId: this.userId,
    //     leadId: this.selectedContact.leadId
    //       ? this.selectedContact.leadId
    //       : 0,
    //     securityPassword: this.selectedContact.securityPassword ? this.selectedContact.securityPassword : '',
    //   })
    //   const self = this
    //   if (res.status === 200) {
    //     if (isNaN(parseFloat(res.data))) {
    //       this.showMessage = true

    //       if (res.data.includes("alternate")) {
    //         self.errorMsg = res.data
    //       } else {
    //         self.errorMsgCC = res.data
    //         setTimeout(() => {
    //           this.showMessage = false
    //         }, 3000)
    //       }
    //       somethingWentWrong()
    //     } else {
    //       changesSaved("Contact Successfully Edited")
    //       this.fetchNotes()
    //       this.fetchMainData(this.companyId)
    //       this.fetchContacts()
    //       this.closeForm()
    //     }
    //   }
    // },

    // bindContactDetails() {
    //   if (this.formEditMode) {
    //     Object.entries(this.selectedContact).forEach(
    //       mergeExistingEntriesIntoForm(this.form)
    //     )
    //   }
    // },
  },
}
</script>


<style scoped>
.box-shadow {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}
.p-3 {
    padding: 1.5rem;
}
</style>