<template>
  <v-dialog v-model="visible" width="750">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        depressed
        color="indigo"
        plain
        small
        v-on="on"
        v-bind="attrs"
        :disabled="previousQuotes.length < 1"
      >
        View Previous
        <v-badge
          class="pl-2"
          :content="previousQuotes.length.toString()"
          color="indigo"
          inline
        >
        </v-badge>
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="auto"> Previous Ready For Quotes </v-col>
          <v-col>
            <v-chip color="accent" text-color="white" small>
              {{ previousQuotes.length }}
            </v-chip>
          </v-col>
        </v-row>
      </v-card-title>

      <LoadingSpinner v-if="loading" />

      <div v-else class="px-5 pb-5 mt-4">
        <!-- Add margin top if index > 1 -->
        <!-- <div v-if="previousQuotes.length" class="d-flex flex-column" v-for="quote in previousQuotes" :key="quote.quoteId"> -->
        <QuoteItem v-for="quote in previousQuotes" :key="quote.readyForQuoteId"
          :quote-id="quote.readyForQuoteId"
          :quote-date="quote.quoteDate"
          :from-name="quote.readyForQuoteFromName"
          :from-id="quote.readyForQuoteFrom"
          :assigned-date="quote.assignedDate"
          :assigned-name="quote.assignedByName"
          :assigned-id="quote.assignedById"
          :assigned-pic="quote.readyForQuoteFromProfilePic"
          :disposition="quote.disposition"
          :disposition-i-d="quote.dispositionID"
          :negative="quote.negative"
          :neutral="quote.neutral"
          :positive="quote.positive"
          :disposition-date-time="quote.dispositionDateTime"
        />
        <!-- </div> -->
      </div>

      <v-divider></v-divider>

      <v-card-actions>
        <v-btn text @click="visible = false">
          Close
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import LoadingSpinner from "@/components/loading-spinner"

export default {
  components: {
    QuoteItem: () => import("@/components/quote-item"),
    LoadingSpinner
  },
  props: {
    company: {
      type: String,
      default: ""
    },
    
  },
  data: () => ({ visible: false }),
   methods: {
    ...mapActions("quotes", ["fetchQuotes"])
  },
  mounted() {
    this.fetchQuotes()
  },
  computed: {
    ...mapGetters("quotes", ["previousQuotes","loading"])
  }
}
</script>

<style></style>
