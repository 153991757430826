import Vue from "vue"
import Vuex from "vuex"

import employee from "./modules/employee"
import company from "./modules/company"
import notes from "./modules/notes"
import contacts from "./modules/contacts"
import addresses from "./modules/addresses"
import meters from "./modules/meters"
import loas from "./modules/loas"
import quotes from "./modules/quotes"
import forms from "./modules/forms"
import ui from "./modules/ui"
import supplier from "./modules/supplier"


Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    employee,
    company,
    notes,
    contacts,
    addresses,
    meters,
    loas,
    quotes,
    forms,
    ui,
    supplier
  }
})
