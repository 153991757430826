<template>
  <v-card outlined rounded="lg" class="red lighten-4" >
    <v-row align="center" class="py-2 px-2">
      <v-col class="text-button red--text">
          <v-icon class="mr-1 red--text">mdi-alert-octagon</v-icon>
         Complaints
      </v-col>
      <v-col class="d-flex justify-end">
        <v-btn  
            color="error" 
            @click="openAddComplaintModal"
        >
        Add Complaint
        </v-btn>
      </v-col>
    </v-row>
    
    <div class="mt-3 m-2">
      <v-simple-table class=" lighten-4">
          <template v-slot:default>
            <thead>
                <tr>
                    <th class="mt-2">Added By</th>
                    <th>Handled By</th>
                    <th class="text-center">ID</th>
                    <th>Status</th>
                    <th class="text-center">Details</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="com in complaints" :key="com.complaintID" class="pt-2">
                    <td class="pt-2">
                        <UserAvatar
                            class="d-flex justify-content-start"
                            :small="true"
                            :portrait="com.complaintHandledBy"
                            :label="com.complaintHandledByName"
                            caption="Handled By"
                        ></UserAvatar>
                    </td>
                    <td class="pt-2">
                        <UserAvatar
                            class="d-flex justify-content-start"
                            :small="true"
                            :portrait="com.complaintHandledBy"
                            :label="com.complaintHandledByName"
                            caption="Handled By"
                        ></UserAvatar>
                    </td>
                    <td class="text-center">
                        {{com.complaintID}}
                    </td>
                    <td>
                        {{com.status}}
                    </td>
                    <td @click="openComplaintDetailsModal(com.complaintID)" class="text-center cursor-pointer text-center">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <span
                                v-bind="attrs"
                                v-on="on"
                                >
                                    <v-icon class="mr-1 blue--text">mdi-information</v-icon>
                                </span>
                            </template>
                            <span>Details</span>
                        </v-tooltip>
                    </td>
                </tr>
            </tbody>
          </template>
      </v-simple-table>
    </div>
  </v-card>
</template>

<script>

// import axios from 'axios'
import api from "@/store/api"
import UserAvatar from "@/components/user-avatar"


export default {
    props: {
        // addComplaintModal:Function,
        // default:null,
    },
  components: {
    UserAvatar,
  },
  data() {
    return {
      complaints:[],
      complaintDetails:[],

    }
  },
  mounted() {
    this.fetchComplaints()
  },
  methods: {
    async fetchComplaints() {
    //   const res = await axios.get(
    //     apiUrl +
    //       `Sale/getComplaintLog`
    //   )
      const res = await api.get(`getWebComplaintLog`)
      console.log('Compalaints', res)
      if (res && res.status === 200) {
        this.complaints = res.data
        // this.isLoading = false
      }
    },
    openAddComplaintModal() {
      this.$emit('addComplaintModal')
    },
    async openComplaintDetailsModal(complaintDetails) {
      this.$emit('complaintDetailsModal',complaintDetails)
    },
  },

}
</script>
