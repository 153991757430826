import api from "@/store/api"

export const trackerMethod = async (
  userId,
  page,
  phone,
  supplyNumber,
  companyId
) => {
  let loading = true
  try {   
    const details = {
      userid: userId,
      webpageVisited: page,
      telephoneNumberViewed: phone,
      supplyNumberViewed: supplyNumber,
      companyId: companyId,
    }
    api
    const response = await api.post(`updateSecurityLog`, details)
    if (response.status === 200) {
      console.log(response.data)
    }
  } catch (err) {
    console.log(err)
  } finally {
    loading = false
  }
  return loading
}
