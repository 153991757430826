<template>
  <v-sheet
    color="transparent"
    :height="height"
    :width="width"
    class="d-flex align-center justify-center"
  >
    <v-progress-circular
      :size="50"
      indeterminate
      :color="`${spinnerColor} lighten-2`"
    ></v-progress-circular>
  </v-sheet>
</template>

<script>
export default {
  props: {
    width: [Number, String],
    height: {
      type: [Number, String],
      default: 150
    },
    spinnerColor: {
      type: String,
      default: "grey"
    }
  }
}
</script>
