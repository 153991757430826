export const getInitialState = () => {
  const url = document.location.href
  const params = new URL(url).searchParams

  const username = params.get("u") || params.get("username")
  const companyId = params.get("c") || params.get("companyid")

  if (!companyId) {
    return {
      username,
      companyId: null,
      error: new Error("Missing Company ID in URL"),
      loading: false
    }
  }

  return { username, companyId, error: null, loading: true }
}
