<template>
    <v-container fluid>
        <v-form>
            <v-select label="Company"  v-model="form.service" :items="filteredLoas"></v-select>
            <v-select label="LOA Type" v-model="form.loaTypeId" :items="loaTypes"></v-select>
            <v-select label="Signed By" v-model="form.signedById" :items="contactsFiltered"></v-select>
            <v-menu
            ref="menuAdd"
            v-model="menuAdd"
            :close-on-content-click="true"
            transition="scale-transition"
            offset-y
            min-width="auto"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="formattedDate"
                        label="Signed Date"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                </template>
                <v-date-picker
                    ref="picker"
                    v-model="form.cotDate"
                    :max="new Date().toISOString().substr(0, 10)"
                    min="1950-01-01"
                    @change="save"
                    locale="en-GB"
                ></v-date-picker>
            </v-menu>
            
            <v-file-input
                label="LOA File"
                id="file" v-model="form.file"
            ></v-file-input>
            <div class="d-flex justify-end">
                <v-btn  depressed dark color="accent" right @click="submitLOAsNote">
                    Save
                </v-btn>
            </div>
        </v-form>
    </v-container>
</template>
<script>
import dayjs from "dayjs"
import {changesSaved, somethingWentWrong } from "@/helpers/utilities"
import api from "@/store/api"

import {mapActions, mapGetters} from 'vuex'

export default {
    data(){
        return {
            menuAdd:false,
            signedOn: "",
            loaCompanies: [],
            loaTypes: [],
            date: new Date().toISOString().substr(0, 10),
            form: {
                cotDate: new Date().toISOString().substr(0, 10),
                loaTypeId: 0,
                signedById: 0,
                signedOn: "",
                service: null,
                file:''
            }
        }
    },
    computed: {
        ...mapGetters('loas',['loas']),
        ...mapGetters("employee", ["campaignAssigned", "employeeCampaign", "userId"]),
        ...mapGetters('contacts',['contacts']),
    ...mapGetters("company", ["companyId", "details", "company"]),

        filteredLoas(){
           if(this.employeeCampaign.utilityHive && this.employeeCampaign.css) {
               const filteredLOAArray = this.loaCompanies
                  return filteredLOAArray.map((option) => ({
                      text: option.value,
                      value: option.id
                    }))
          } else if(this.employeeCampaign.css){
              const filteredLOAArray = this.loaCompanies.filter(loa => loa.id === 1)
              return filteredLOAArray.map((option) => ({
                  text: option.value,
                  value: option.id
                }))
            } else if (this.employeeCampaign.utilityHive){
                const filteredLOAArray = this.loaCompanies.filter(loa => loa.id === 2)
                return filteredLOAArray.map((option) => ({
                  text: option.value,
                  value: option.id
                }))
            } else {
                return 'No Campaigns Assigned'
            }
        },
        formattedDate() {
            if(this.form.cotDate === "" && this.form.cotDate === 0) {
                return ""
            }

            return this.form.cotDate
                ? dayjs(this.form.cotDate).format("DD/MM/YYYY")
                : ""
        },
        contactsFiltered(){
            // const contact = []
            const contact = this.contacts
            return contact.map((option) => ({
                text: `${option.title} ${option.firstName} ${option.lastName}`,
                value: option.contactId
            }))
        },
    },
    mounted(){
        this.fetchLOACompanies()
        this.fetchLOATypes()
        this.fetchCompanyDetails()
    },
    methods: {
        ...mapActions('company',['fetchCompanyDetails']),
        ...mapActions('loas',['fetchLoasAfterUpdate']),

        async fetchLOACompanies(){
          const response = await api.get('loaCompanies')
          if(response && response.status === 200){
            this.loaCompanies = response.data
          }
        },
        async fetchLOATypes(){
          const response = await api.get('loaTypes')
          if(response && response.status === 200){
            this.loaTypes = response.data.map((option) => ({
                text: option.value,
                value: option.id
            }))
          }
        },
        validate() {
            this.$refs.form.validate()
        },
        save(date) {
            this.$refs.menuAdd.save(date)
        },       
        async submitLOAsNote() {
           
            const i = this.contacts.findIndex(x=>x.contactId == this.form.signedById)        
            const signedByName = i  > -1 ? this.contacts[i].firstName +' '+this.contacts[i].lastName : ''
 
            const li = this.loaTypes.findIndex(x=>x.value == this.form.loaTypeId)  
            const loaName = li > -1 ? this.loaTypes[li].text : ''
          
            const loaNoteDescription = `LOA UPLOADED - ${this.form.loaTypeId}<br/>${this.company.companyName}<br/> ${loaName}<br/>${signedByName}<br/>${dayjs(this.form.cotDate).format("DD/MM/YYYY")}`

        const formData = new FormData()
        formData.append('file', this.form.file)
        formData.append('loaTypeId', this.form.loaTypeId)
        formData.append('companyId', this.companyId)     
        formData.append('contactId', this.form.signedById)
        formData.append('description', loaNoteDescription)
        formData.append('uploadedByEmployeeId', this.userId)     
        formData.append('loaDate', dayjs(this.form.cotDate).unix())
        formData.append('saleTypeId', this.form.service)     
      api
        .post('/saveLoaFile', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(function(response) {
          if (response.status === 200) {
            changesSaved('LOA Successfully Uploaded!')
            setTimeout(() => {
             window.location.reload(true)
            }, 2000)
          }
        })
        .catch(function() {
          somethingWentWrong()
        })
        },
        signedOnDateChanged() {
            const signedOn = this.signedOn.split("/").reverse().join("-")
            this.form.signedOn = dayjs(signedOn).format("YYYY-MM-DD")
            if(this.form.signedOn === 'Invalid Date') {
                this.form.signedOn = ''
            }
        },
    }
}
</script>