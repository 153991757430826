var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"d-flex flex-column",attrs:{"height":"100%","flat":"","color":_vm.color,"rounded":"lg","light":""}},[(_vm.siteName)?_c('v-card-title',{staticClass:"subtitle-1 font-weight-medium"},[_vm._v(" "+_vm._s(_vm.siteName)+" ")]):_vm._e(),(_vm.cotDate)?_c('v-card-subtitle',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-calendar-account")]),_vm._v(" "+_vm._s(_vm.$helpers.formatDate(_vm.cotDate))+" ")],1)]}}],null,false,960278537)},[_c('span',[_vm._v("Change of Tenancy")])])],1):_vm._e(),_c('v-card-text',{staticStyle:{"font-size":"13px"}},[_c('div',{staticClass:"blue-grey--text"},[_c('div',[_vm._v(" "+_vm._s(_vm.firstLine)+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm.secondLine)+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm.town)+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm.county)+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm.postCode)+" ")])]),(_vm.showDetails)?_c('div',{staticClass:"pt-3"},[_c('v-row',[_c('v-col',[_c('div',{staticClass:"text-caption"},[_vm._v("From")]),_c('div',{staticClass:"teal--text"},[_vm._v(_vm._s(_vm.$helpers.formatDate(_vm.startDate)))])]),_c('v-col',[_c('div',{staticClass:"text-caption"},[_vm._v("To")]),_c('div',{staticClass:"teal--text"},[_vm._v(_vm._s(_vm.$helpers.formatDate(_vm.endDate)))])])],1),_c('v-row',{staticClass:"text-caption"},[_c('v-col',{staticClass:"mb-4"},[_vm._v(_vm._s(_vm.timeFromStartDate))]),_c('v-col'),(_vm.allowEdit)?_c('v-col',{staticClass:"align-items-center",attrs:{"cols":"3"}},[_c('p',{staticClass:"blue--text cursor-pointer font-weight-bold",staticStyle:{"width":"25px"},on:{"click":_vm.editAddressClicked}},[_vm._v(" EDIT ")])]):_vm._e()],1)],1):_vm._e()]),(!_vm.noActions)?_c('v-divider'):_vm._e(),(!_vm.noActions)?_c('v-card-actions',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"orange","depressed":"","icon":"","small":""},on:{"click":_vm.toggleMetersCard}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-gauge")])],1)]}}],null,false,4180191327)},[_c('span',[_vm._v("Address Meters")])])],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('PaymentInfo',{attrs:{"site-name":_vm.siteName,"address-id":_vm.addressId}})],1)]}}],null,false,3749571481)},[_c('span',[_vm._v("Payment Information")])])],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('FurtherAddressInfo',{attrs:{"site-name":_vm.siteName,"address-id":_vm.addressId}})],1)]}}],null,false,1122801695)},[_c('span',[_vm._v("Further Address Information")])])],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"blue","depressed":"","icon":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.editAddress($event)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1)]}}],null,false,516408677)},[_c('span',[_vm._v("Edit Address")])])],1),(!_vm.primary)?_c('v-col',{attrs:{"cols":"auto"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"depressed":"","icon":"","small":""}},'v-btn',attrs,false),on),[_c('div',[_c('ConfirmDelete',{attrs:{"body-content":_vm.siteName,"first-line":_vm.firstLine,"second-line":_vm.secondLine,"town":_vm.town,"county":_vm.county,"post-code":_vm.postCode},on:{"delete-confirmed":_vm.handleDeleteAddress}})],1)])]}}],null,false,2150533891)},[_c('span',[_vm._v("Delete Address")])])],1):_vm._e()],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }