<script>
export default {
  props: {
    firstName: {
      type: String,
      default: '',
    },
    lastName: {
      type: String,
      default: '',
    },
    caption: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    portrait: [String, Number],
    imgPath: {
      type: String,
      default: '',
    },
    fullName: {
      type: String,
      default: '',
    },
    small: {
      type: Boolean,
      default: false,
    },
    isLive: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    avatarSize() {
      if (this.small) {
        return '2.5rem'
      }
      return '3.5rem'
    },
    avatarPath() {
      if (!this.portrait) {
        return this.imgPath
      }
      return `https://hive.utilityhive.com/uploads/ProfilePics/${this.portrait}.jpg`
    },
    name() {
      if (!this.firstName && !this.lastName && !this.fullName) {
        return ''
      }
      if (!this.fullName) {
        return `${this.firstName} ${this.lastName}`
      }
      return this.fullName
    },
  },
}
</script>

<template>
  <div class="user-avatar" :class="{ 'user-avatar--small': small }">
    <v-row no-gutters align-v="center">
      <v-col cols="auto">
        <v-avatar color="accent" size="50">
            <img
              v-if="avatarPath"
            :src="avatarPath"
            :alt="name"
              @error="$helpers.onProfilePictureError"
            />
            <v-icon v-else dark> mdi-account-circle </v-icon>
        </v-avatar>

      </v-col>
      <v-col class="pl-2">
        <span v-if="label" class="user-avatar__label text-black-50">
          {{ label }}
        </span>
        <p v-if="!isLive" class="user-avatar__name">{{ name }}</p>
        <div v-if="caption && !isLive" class="user-avatar__caption">
          <span>{{ caption }}</span>
        </div>
        <div v-if="isLive" class="user-avatar__name">
          <span> {{ name }} </span>
          <span class="ml-1">{{ caption }}</span>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<style lang="scss">
.user-avatar {
//   color: $brand-primary;
}

.user-avatar .user-avatar__name {
  font-family: Inter;

  margin-bottom: 0.2rem;
  font-weight: 500;
  line-height: 1.2;
  font-size: 12px;
}
.user-avatar .user-avatar--name {
  font-family: Inter;
  margin-bottom: 0.2rem;
  font-weight: 500;
  line-height: 1.2;
  color: white;
}

.user-avatar__label,
.user-avatar__caption {
  display: inline-block;
  font-size: 0.85rem;
}

.user-avatar .user-avatar__label {
  margin-bottom: 0.2rem;
}

.user-avatar .user-avatar__caption {
  line-height: 1.2;
  color: #74788d;
}
.user-avatar .user-avatar--caption {
  line-height: 1.2;
  color: white;
}

.user-avatar.user-avatar--small {
  font-size: 0.85rem;
  line-height: 1.1;
}
.user-avatar.user-avatar--small .user-avatar__caption,
.user-avatar.user-avatar--small .user-avatar__label {
  font-size: 0.8rem;
}
</style>
