<template>
  <v-container fluid>
    <v-row>
      <v-col cols="auto">
        <p class="mb-1" style="font-size:12px">Meter Type</p>
        <v-btn-toggle v-model="meterInfo.meterTypeId">
          <v-btn value="9">
            <v-icon color="amber">mdi-lightbulb</v-icon>
          </v-btn>
          <v-btn value="10">
            <v-icon color="red">mdi-fire</v-icon>
          </v-btn>
          <v-btn value="2705">
            <v-icon color="blue">mdi-water</v-icon>
          </v-btn>
        </v-btn-toggle>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-text-field
          label="Meter Serial Number"
          v-model="meterInfo.meterSerialNumber"
          @input="meterSerialNumberUpperCase"
        >
        </v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field
          label="Meter Reading"
          v-model="meterInfo.meterReading"
          @keypress="isNumberKeyAndSpecialCharacters"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6"></v-col>
      <v-col class="d-flex justify-end" cols="6">
        <v-btn depressed dark color="accent" right @click="saveMeterInfoNote">
          Save
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import dayjs from "dayjs"
import { mapActions, mapGetters } from "vuex"
import { changesSaved, somethingWentWrong } from "@/helpers/utilities"
import api from "@/store/api"
export default {
  data: () => ({
    tab: null,
    meterInfo: {
      meterTypeId: 0,
      meterSerialNumber: "",
      meterReading: ""
    }
  }),
  computed: {
    ...mapGetters("notes", ["noteTypes"]),
    ...mapGetters("company", ["companyId"]),
    ...mapGetters("employee", ["userId", "userName"])
  },
  methods: {
    ...mapActions("notes", ["createNotes"]),
    meterSerialNumberUpperCase() {
      this.meterInfo.meterSerialNumber = this.meterInfo.meterSerialNumber.toUpperCase()
    },
    isNumberKeyAndSpecialCharacters(evt) {
      const charCode = evt.which ? evt.which : evt.keyCode
      if (
        (charCode >= 65 && charCode <= 90) ||
        (charCode >= 97 && charCode <= 122)
      ) {
        event.returnValue = false
        return false
      } else {
        event.returnValue = true
        return true
      }
    },

    async saveMeterInfoNote() {
      // if(this.meterInfo.meterTypeId === 0 || this.meterInfo.meterSerialNumber === "" || this.meterInfo.meterReading === "") {
      //     return false
      // }
      const meterType =
        this.meterInfo.meterTypeId === "9" ? "Electricity" : "Gas"
      const noteDetails = {
        companyId: this.companyId,
        noteTypeId: 5,
        note: `${meterType}<br/>Meter Serial: ${this.meterInfo.meterSerialNumber}<br/>Meter Reading: ${this.meterInfo.meterReading}`,
        userId: this.userId,
        createdByEmployeeId: this.userId,
        createdDate: dayjs().unix(),
        description: `${meterType}<br/>Meter Serial: ${this.meterInfo.meterSerialNumber}<br/>Meter Reading: ${this.meterInfo.meterReading}`,
        employeeName: this.userName,
        icon: "gauge",
        noteId: 0,
        noteType: "meter information",
        profilePicFileName: `https://hive.utilityhive.com/uploads/ProfilePics/${this.userId}.jpg`,
        saleId: 0
      }
      try {
        const res = await this.createNotes(noteDetails)
        if (res.status === 200) {
          if (this.meterInfo.meterTypeId === "9") {
            this.insertEcoesSearch(
              this.userId,
              this.meterInfo.meterSerialNumber
            )
          }
          changesSaved("Meter Information Successfully Added")
          this.meterInfo.meterTypeId = 0
          this.meterInfo.meterSerialNumber = ""
          this.meterInfo.meterReading = ""
        } else {
          somethingWentWrong()
        }
      } catch (error) {
        somethingWentWrong()
      }
    },

    async insertEcoesSearch(searchedById, value) {
      const searchDetails = {
        searchedById: searchedById,
        searchTypeId: 2,
        value: value
      }
      await api.post("insertEcoesSearch", searchDetails)
    }
  }
}
</script>
