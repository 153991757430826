<template>
  <v-app v-if="userId">
    <NavigationDrawer />

    <LoadingSpinner
      v-if="loadingApp"
      width="100vw"
      height="100vh"
      spinner-color="primary"
    />
    <v-fade-transition>
      <div>
        <v-app-bar
          v-if="!isActive"
          app
          elevate-on-scroll
          elevation="5"
          :height="!loadingApp ? '185' : '71'"
        >
          <v-container fluid class="py-0">
            <TopBar />
            <AppBar class="mt-4" v-if="!loadingApp" />
          </v-container>
        </v-app-bar>
      </div>
    </v-fade-transition>
    <v-fade-transition>
      <div v-show="!loadingApp">
        <ErrorPage v-if="error" />
        <div v-else>
          <Main :isActive="isActive" />
        </div>
      </div>
    </v-fade-transition>
  </v-app>
  <v-app v-else-if="!loading && !userId">
    <h1 class="mt-2 ml-2">Not Found</h1>
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from "vuex"

import AppBar from "./layout/app-bar"
import Main from "./layout/main"
import TopBar from "./layout/top-bar"
import ErrorPage from "./components/error-page"
import LoadingSpinner from "./components/loading-spinner"

import { trackerMethod } from "@/helpers/logs.js"

export default {
  name: "App",
  components: {
    AppBar,
    Main,
    TopBar,
    ErrorPage,
    LoadingSpinner,
    NavigationDrawer: () => import("@/components/navigation-drawer"),
  },
  data: () => ({
    loadingApp: true,
    telephone: "",
    loading: true,
    supplyNumber: "",
    active: null,
    showPopup: false,
  }),
  watch: {
    cID() {
      console.log(this.cID)
      console.log("changed")
      console.log(this.companyId)
    },
  },
  computed: {
    ...mapGetters("company", [
      "details",
      "loading",
      "error",
      "cID",
      "companyId",
    ]),
    ...mapGetters("employee", ["userId"]),
    isActive() {
      return this.details.companyActiveStatus === 1 ||
        this.details.companyActiveStatus === 3
        ? false
        : true
    },
  },
  methods: {
    ...mapActions("employee", ["fetchEmpoyeeDetails"]),
    ...mapActions("company", ["fetchCompanyDetails"]),
  },
  created() {},
  async mounted() {
    const urlParams = new URLSearchParams(window.location.search)
    const companyId = urlParams.get("c")
    const page = window.location.href
    console.log(page)
    if (!this.error) {
      this.fetchEmpoyeeDetails()
      setTimeout(() => {
        this.fetchCompanyDetails()
      }, 2000)
      setTimeout(async () => {
        this.loading = await trackerMethod(
          this.userId,
          page,
          this.telephone,
          this.supplyNumber,
          companyId
        )
        console.log(this.loading, "loading")
      }, 2500)
    }
    setTimeout(() => {
      this.loadingApp = false
    }, 850)
  },
}
</script>

<style lang="scss">
@import "./styles/overrides.scss";
</style>
