import Swal from 'sweetalert2'

export function inRange(num, start, end) {
  if (!end) {
    end = start
    start = 0
  }
  return num >= start && num <= end
}

export function fallbackCopyTextToClipboard(text) {
  const textArea = document.createElement("textarea")
  textArea.value = text

  // Avoid scrolling to bottom
  textArea.style.top = "0"
  textArea.style.left = "0"
  textArea.style.position = "fixed"

  document.body.appendChild(textArea)
  textArea.focus()
  textArea.select()

  try {
    const successful = document.execCommand("copy")
    const msg = successful ? "successful" : "unsuccessful"
    console.log(msg)
  } catch (err) {
    console.log(err)
  }

  document.body.removeChild(textArea)
}

/*
Explanation: we have our form local data with only two properties: { name: "", primary: false }
In other side we have the object we got from the API: { name: "Luigi", primary: true, telephone: "", etc}
So we only want the values from the properties that exists in the local form data, in this case name and primary
*/
export const mergeExistingEntriesIntoForm = form => ([key, value]) => {
  const validEntry = form[key] !== undefined
  if (validEntry) {
    form[key] = value
  }
}

export const changesSaved = (notificationMessage = 'Changes Successfully Saved!') => {
  Swal.fire({
    background: '#E6F5E9',
    html:
    `<i class="fas fa-check fa-lg" style="color:#4B908C"></i> <br> <h4 style="color:#4B908C; margin-top: 0.8rem; font-family: Inter, sans-serif; font-weight:normal">${ notificationMessage }</h4>`,
    position: 'top-end',
    showConfirmButton: false,
    timer: 2000,
    width: 300,
  })

}

export const somethingWentWrong = () => {
  Swal.fire({
    background: '#fe5151',
    html: `<i class="fas fa-times fa-lg" style="color:#fff"></i> <br> <h4 style="color:#fff; margin-top: 0.8rem; font-family: Inter, sans-serif; font-weight:normal">Sorry Something Went wrong</h4>`,
    position: 'top-end',
    showConfirmButton: false,
    timer: 2000,
    width: 300,
  })
}


export function copyTextToClipboard(text) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text)
    return
  }

  navigator.clipboard.writeText(text).then(
    function() {
      console.log("Async: Copying to clipboard was successful!")
    },
    function(err) {
      console.log(err, "Error")
    }
  )
}

