import api from "@/store/api"

const state = {
  natureOfBusinesses: [],
  besNatureOfBusinesses: [],
  greenEnergyList: [],
  tradingTypes: [],
  titles: [],
  suppliers: [],
  paymentTypes: [],
  contractTypes: [],
  supplyDetails: {},
  eFormDetails:{},
  addressDetails: {},
  mainData: {},
  metersData: [],
  loadingMainData: false
}

const getters = {
  natureOfBusinessList: state => state.natureOfBusinesses,
  besNatureOfBusinessList: state => state.besNatureOfBusinesses,
  greenEnergyList: state => state.greenEnergyList,
  tradingTypeList: state => state.tradingTypes,
  titleList: state => state.titles,
  supplierList: state => state.suppliers,
  paymentTypesList: state => state.paymentTypes,
  contractTypesList: state => state.contractTypes,
  supplyDetails: state => state.supplyDetails,
  eFormDetails: state => state.eFormDetails,
  addressDetails: state => state.addressDetails,
  metersData: state => state.metersData,
  mainData: state => state.mainData,
  loadingMainData: state => state.loadingMainData,
}

const mutations = {
  toggleDrawer: (state, value) => (state.visibility.drawer = value),
  setData: (state, { data, type }) => (state[type] = data),
}

const actions = {
  async fetchNatureOfBusiness({ commit }) {
    const response = await api.get("natureOfBusinessList")
    commit("setData", { type: "natureOfBusinesses", data: response.data })
  },
  async fetchBESNatureOfBusiness({ commit },meterTypeId) {
    const response = await api.get(`selectBESContractTypes/${meterTypeId}`)
    commit("setData", { type: "besNatureOfBusinesses", data: response.data })
  },
  async fetchGreenEnergyResources({ commit }) {
    const response = await api.get("getBESContractGreenEnergyTypes")
    commit("setData", { type: "greenEnergyList", data: response.data })
  },
  
  async fetchMainData({ commit }, companyId) {
    commit("setData", { type: "loadingMainData", data: true })

    const response = await api.get(`mainData/${companyId}`)
    if(response && response.status === 200) {
      commit("setData", { type: "mainData", data: response.data.mainData })
      commit("setData", { type: "metersData", data: response.data.meters })

      setTimeout(() => {
        commit("setData", { type: "loadingMainData", data: false })
      }, 1000);
    }else {
      setTimeout(() => {
        commit("setData", { type: "loadingMainData", data: false })
      }, 1000);
    }

      
  },
  async fetchMainDataImmediately({ commit }, companyId) {
    commit("setData", { type: "loadingMainData", data: true })

    const response = await api.get(`mainData/${companyId}`)
    if(response && response.status === 200) {
      commit("setData", { type: "mainData", data: response.data.mainData })
      commit("setData", { type: "metersData", data: response.data.meters })

      commit("setData", { type: "loadingMainData", data: false })
    }else {
      commit("setData", { type: "loadingMainData", data: false })
    }
    
      
  },
  async fetchTradingTypes({ commit }) {
    const response = await api.get("tradingTypes")
    commit("setData", { type: "tradingTypes", data: response.data })
  },
  async fetchTitles({ commit }) {
    const response = await api.get("titles")
    const titles = response.data.map((option) => ({
      value: option.value,
      text: option.value
  }))
    commit("setData", { type: "titles", data: titles })
  },
  async fetchSuppliers({ commit }) {
    const response = await api.get(`suppliers/-1`)
    const suppliers = response.data.map((option) => ({
      value: option.supplierId,
      text: option.supplierName
  }))
    commit("setData", { type: "suppliers", data: suppliers })
  },
  async fetchPaymentTypes({ commit }) {
    const response = await api.get("paymentTypes")
    const paymentTypes = response.data.map((option) => ({
      value: option.id,
      text: option.value
  }))
    commit("setData", { type: "paymentTypes", data: paymentTypes })
  },

  
  async fetchContractTypes({ commit }) {
    const response = await api.get('contractTypes')
    const contractTypes = response.data.map((option) => ({
      value: option.id,
      text: option.value
  }))
    commit("setData", { type: "contractTypes", data: contractTypes })
  },

  postSupplyDetails({ commit }, supplyDetails) {
    commit("setData", { type: "supplyDetails", data: supplyDetails })

  },
  postAddressDetails({ commit }, addressDetails) {
    commit("setData", { type: "supplyDetails", data: addressDetails })

  },
  postEFormDetails({ commit }, formDetails) {
    commit("setData", { type: "eFormDetails", data: formDetails })

  },


}

export default { state, getters, mutations, actions, namespaced: true }
