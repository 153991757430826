<template>
    <v-dialog v-model="visible" width="1300">
        <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              color="info"
              depressed
              icon
              small
              @click="invoicesModalOpened"
            >
                <v-icon  small>mdi-currency-gbp</v-icon>
            </v-btn>
        </template>

        <v-card>
            <v-card-title style="background-color: #007bff ;color: #fff !important;">
                INVOICES
            </v-card-title>
            <v-card-subtitle class="pt-1 pb-2" style="background-color: #007bff ;color: #fff !important;">
                Invoice Information
            </v-card-subtitle>

            <v-card-text class="mt-2">
                <v-row>
                  <v-col class="d-flex justify-center" cols="12">
                        <v-simple-table>
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th class="text-headers text-left">
                                            Sale ID
                                        </th>
                                        <th class="text-headers text-left">
                                            Invoice Imported
                                        </th>
                                        <th v-if="permission.viewfullpaymentdetails" class="text-headers text-left">
                                            Payment ID
                                        </th>
                                        <th class="text-headers text-center" >
                                          Contract Received Date
                                        </th>
                                        <th class="text-headers text-left" style="width: 220px">
                                            Business Name
                                        </th>
                                        <th class="text-headers text-left" style="width: 220px">
                                          Supply Number
                                        </th>
                                        <th v-if="permission.viewfullpaymentdetails" class="text-headers text-left" style="width: 250px">
                                            Commission Payment
                                        </th>
                                        <th class="text-headers text-left" style="width: 250px">
                                            Uplift
                                        </th>
                                        <th class="text-headers text-left" style="width: 250px">
                                            EAC
                                        </th>
                                        <th class="text-headers text-left">
                                            Contract Start Date
                                        </th>
                                        <th class="text-headers text-left" style="width: 250px">
                                            Term
                                        </th>
                                        <th class="text-headers text-left">
                                            Contract End Date
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                    class="p-2 "
                                    style="height:90px"
                                    v-for="previousSale in invoicesItems"
                                    :key="previousSale.newSaleID"
                                    >
                                        <td style="width:250px">
                                          <div>
                                            <span><strong>{{previousSale.companyName}}</strong></span>
                                          </div>
                                          <div v-if="permission.allowsaleidlink">
                                            <span>
                                              <strong>Sale ID: </strong>
                                              <a
                                              class="blue--text"
                                              :href="
                                              'http://hive.utilityhive.com/sales/sales-form?SaleID=' +
                                                  previousSale.newSaleID
                                              "
                                              button
                                              style="text-decoration:none"
                                              target="_blank"
                                              >
                                               {{ previousSale.newSaleID }}
                                              </a>
                                            </span>
                                          </div>
                                        </td>

                                        <td>
                                          {{  formattedDate(previousSale.effectiveDate) }}
                                                                                  
                                        </td>
                                        <td v-if="permission.viewfullpaymentdetails">
                                          {{ previousSale.id }}
                                        </td>

                                        <td>
                                          {{ formattedDate(previousSale.autoDate) }}
                                        </td>

                                        <td>
                                          {{ previousSale.customerName }}
                                        </td>
                                        <td>
                                          {{ previousSale.supplyNumber }}
                                        </td>

                                        <td v-if="permission.viewfullpaymentdetails">
                                          {{ formatPrice(previousSale.commissionPayment) }}
                                          
                                        </td>


                                        <td>
                                          {{ previousSale.commRate }}
                                        </td>

                                        <td>
                                          {{ previousSale.actualAQ }}
                                        </td>

                                        <td>
                                          {{ formattedDate(previousSale.contractStartDate) }}
                                        </td>

                                        <td>
                                          {{ previousSale.contLength }}
                                        </td>

                                        <td>
                                          {{ previousSale.ceDate ? formattedDate(previousSale.ceDate) : '' }}
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-col>
                      
                      <!-- <v-tabs class="mb-4" v-model="tab" color="accent" grow>
                        <v-tab>
                          Expected
                        </v-tab>
                        <v-tab>
                          Received
                        </v-tab>
                      </v-tabs>
                      <v-tabs-items v-model="tab">
                        <v-tab-item>
                          <InvoicesExpected />
                        </v-tab-item>
                        <v-tab-item>
                          <InvoicesReceived/>
                        </v-tab-item>
                      </v-tabs-items> -->
                </v-row>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
        <v-btn text @click="visible = false">
          Close
        </v-btn>
        <v-spacer></v-spacer>
        <!-- <v-btn color="blue" text @click="openFormAndClose">
          Edit
        </v-btn> -->
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import api from '@/store/api'
import { mapGetters } from 'vuex'
import dayjs from 'dayjs'

// import LoadingSpinner from "@/components/loading-spinner"
// import EmptyCard from "@/components/empty-card"
// import InvoicesExpected from '@/components/invoices-expected.vue'
// import InvoicesReceived from '@/components/invoices-received.vue'

export default {
    components: {
    },
    props: {
      supplyNumber: {
        type: String,
        default: ''
      }
    },

    data() {
        return {
          tab: null,
          visible: false,
          permission: {
            viewfullpaymentdetails: false,
            allowsaleidlink: false,
          },
          invoicesItems: [],


        }
    },

    computed: {
      ...mapGetters('employee', ['userId']),
    },


    created(){
        this.fetchPermission()
    },
    methods: {
      formatPrice(value) {
      var val = (value / 1).toFixed(2)
      return "£" + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
      tooltipStatus(status) {
      if (status === "qualityCheck") {
        return "Quality Check"
      }
      if (status === "failed") {
        return "Failed"
      }
      if (status === "live") {
        return "Live"
      }
      if (status === "declared") {
        return "Declared"
      }
      if (status === "sold") {
        return "Sold"
      }
      return "Processing"
    },
      formattedDate(date) {
        return date ? dayjs(date * 1000).format("DD/MM/YYYY") : ''
      },
        async fetchPermission() {
          const pagePermission = this.permission
          const res = await api.get(
           `permission/${this.userId}`
          )
          if (res.status === 200) {
            const result = res.data
            for (var p in result) {
              console.log(result[p])
              pagePermission[result[p].name] = result[p].hasPermission
            }
          }
        },
        async invoicesModalOpened() {
          const res = await api.get(
           `getReceivedPaymentsBySupplyNumber/${this.supplyNumber}`
          )
          if (res.status === 200) {
            const result = res.data
            this.invoicesItems = result
          }

        },
      openFormAndClose(){
        //
      }
    }
}
</script>

<style scoped>
.text-headers {
  font-family: Inter, Rubik;
  font-weight: 700;
  color: #ea5791 !important;
  font-size: 1rem !important;
}
</style>