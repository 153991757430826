<template>
  <v-expand-transition>
    <v-card v-show="showCard" outlined rounded="lg" :loading="loading">
      <template slot="progress">
        <v-progress-linear
          color="accent"
          height="8"
          indeterminate
        ></v-progress-linear>
      </template>
      <v-card-title class="py-2">
        <v-row align="center">
          <v-col cols="auto">
            <div class="text-button">
              <v-icon class="mr-1" small>mdi-gauge</v-icon>
              Meter Information For
              <span class="black--text font-weight-bold">
                {{ selectedAddress.siteName }}
              </span>
            </div>
          </v-col>
          <v-col cols="auto">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <SupplierInteraction />
                </div>
              </template>
              <span>Supplier Interaction</span>
            </v-tooltip>
          </v-col>
          <v-col>
            <div class="text-right">
              <v-btn
                color="blue"
                depressed
                icon
                small
                @click="openForm({ formName: 'meter-information' })"
              >
                <v-icon small>mdi-plus</v-icon>
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-card-title>
      <v-divider></v-divider>
      <LoadingSpinner v-if="loading" />
      <div v-else>
        <div v-if="!meters.length" class="pa-3">
          <EmptyCard
            content="No meters"
            @button-click="openForm({ formName: 'meter-information' })"
          />
        </div>
        <v-list v-else class="meters-list">
          <MeterItem
            v-for="meter of getUniqueMeteres"
            :key="meter.meterId"
            :meter="meter"
            :meter-id="meter.meterId"
            :address-id="meter.addressId"
            :meter-type-id="meter.meterTypeId"
            :type="meter.meterType"
            :supplier-name="meter.supplierName"
            :supplier-id="meter.supplierId.toString()"
            :estimate="meter.estimateActual.toLowerCase() === 'estimate'"
            :pc="meter.pc"
            :mtc="meter.mtc"
            :llf="meter.llf"
            :is-hide-meter-edit="meter.isHideMeterEdit"
            :supply-number="meter.supplyNumber"
            :contract-end-date="meter.ced"
            :renewal-date="meter.renewalDate"
            :window="meter.window"
          />
        </v-list>
      </div>
    </v-card>
  </v-expand-transition>
</template>

<script>
import { mapGetters, mapActions } from "vuex"

import MeterItem from "@/components/meter-item"
import LoadingSpinner from "@/components/loading-spinner"

export default {
  components: {
    MeterItem,
    LoadingSpinner,
    EmptyCard: () => import("@/components/empty-card"),
    SupplierInteraction: () =>
      import("@/components/dialogs/supplier-interaction"),
  },
  computed: {
    ...mapGetters("meters", ["meters", "showCard", "loading"]),
    ...mapGetters("addresses", ["selectedAddress"]),
    ...mapGetters("company", ["companyId"]),
    getUniqueMeteres() {
      const filteredList = this.meters.filter((value, index, self) => {
        return self.findIndex((v) => v.meterId === value.meterId) === index
      })
      return filteredList
    },
  },
  mounted() {
    this.fetchTradingTypes()
    this.fetchNatureOfBusiness()
    this.fetchGreenEnergyResources()
    // this.fetchBESNatureOfBusiness()
    this.fetchTitles()
    this.fetchSuppliers()
    this.fetchPaymentTypes()
    this.fetchContractTypes()
    let uri = window.location.search.substring(1)
    let params = new URLSearchParams(uri)
    this.fetchEmployee(params.get("u"))
    this.fetchLeadGenerator(this.companyId)
  },
  methods: {
    ...mapActions("ui", ["openForm"]),
    ...mapActions("employee", ["fetchEmployee", "fetchLeadGenerator"]),
    ...mapActions("forms", [
      "fetchBESNatureOfBusiness",
      "fetchGreenEnergyResources",
      "fetchNatureOfBusiness",
      "fetchContractTypes",
      "fetchTradingTypes",
      "fetchTitles",
      "fetchSuppliers",
      "fetchPaymentTypes",
    ]),
  },
}
</script>

<style></style>
