<template>
  <div>
    <!-- <label class="mb-2 d-block" v-if="!disabled" :for="id">Supply Number</label> -->
    <v-hover v-slot="{ hover }">
      <v-card outlined max-width="250px">
        <div class="supply-number">
          <v-fab-transition>
            <v-btn
              v-show="hover"
              color="blue"
              fab
              dark
              x-small
              absolute
              top
              right
              @click="handleCopy"
            >
              <v-icon x-small>mdi-content-copy</v-icon>
            </v-btn>
          </v-fab-transition>

          <v-row no-gutters>
            <v-col cols="2">
              <div
                class="d-flex fill-height justify-center align-center blue-grey lighten-5"
              >
                <span class="font-weight-medium">{{ label }}</span>
              </div>
            </v-col>
            <v-col cols="10" :class="{ 'grey lighten-4': disabled }" >
              <div v-if="isElectric">
                <v-text-field
                  :id="id"
                  v-show="false"
                  name="electricSupplyNo"
                  maxlength="08"
                  v-model="electricSupplyNo"
                  @change="saveSupplyNumberDetails"
                  @keypress="isNumberKey"
                  @paste="onPaste"
                ></v-text-field>
                <v-row class="first-row" no-gutters id="container">
                  <v-col  cols="4">
                    <v-text-field
                      class="text-body-2 "
                      solo
                      flat
                      dense
                      name="PC"
                      maxlength="2"
                      v-model="PC"
                      :disabled="disabled || supplyNumberReadOnly"
                      @change="saveSupplyNumberDetails"
                      @keyup="moveNextByCheckingPCLength"
                      hide-details
                      @keypress="isNumberKeyAndLessThan8"
                      @paste="onPaste"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      id="MTC"
                      ref="mtc"
                      class="text-body-2"
                      solo
                      flat
                      dense
                      name="mtsc"
                      maxlength="3"
                      v-model="MTC"
                      :disabled="disabled || supplyNumberReadOnly"
                      @change="saveSupplyNumberDetails"
                      @keyup="moveNextByCheckingMTCLength"
                      hide-details
                      @keypress="isNumberKey"
                      @paste="onPaste"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      id="LLF"
                      ref="LLF"
                      class="text-body-2"
                      solo
                      flat
                      dense
                      name="LLF"
                      maxlength="3"
                      v-model="LLF"
                      :disabled="disabled || supplyNumberReadOnly"
                      @change="saveSupplyNumberDetails"
                      @keyup="moveNextByCheckingLLFLength"
                      hide-details
                      @paste="onPaste"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="second-row ma-0" no-gutters>
                  <v-col cols="3">
                    <v-text-field
                      id="sId"
                      ref="sId"
                      class="text-body-2"
                      solo
                      flat
                      dense
                      name="id"
                      maxlength="2"
                      color="red"
                      v-model="sId"
                      @change="saveSupplyNumberDetails"
                      @keyup="moveNextByCheckingSIDLengthAndValueBetween10To23"
                      :disabled="disabled || supplyNumberDidabled"
                      hide-details
                      @keypress="isSIDNumberKey"
                      @paste="pasteAndforwardData($event, 'sId')"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <v-text-field
                      id="mpIDA"
                      ref="mpIDA"
                      class="text-body-2"
                      solo
                      flat
                      dense
                      name="mpid1"
                      maxlength="4"
                      v-model="mpId.a"
                      @change="saveSupplyNumberDetails"
                      @keyup="moveNextByCheckingMPIDALength"
                      :disabled="disabled || supplyNumberDidabled"
                      hide-details
                      @keypress="isNumberKey"
                      @paste="pasteAndforwardData($event, 'mpIDA')"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <v-text-field
                      id="mpIDB"
                      ref="mpIDB"
                      class="text-body-2 "
                      solo
                      flat
                      dense
                      name="mpid2"
                      maxlength="4"
                      v-model="mpId.b"
                      @change="saveSupplyNumberDetails"
                      @keyup="moveNextByCheckingMPIDBLength"
                      :disabled="disabled || supplyNumberDidabled"
                      hide-details
                      @keypress="isNumberKey"
                      @paste="pasteAndforwardData($event, 'mpIDB')"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <v-text-field
                      id="cd"
                      ref="cd"
                      class="text-body-2"
                      solo
                      flat
                      dense
                      name="cd"
                      maxlength="3"
                      v-model="cd"
                      @change="saveSupplyNumberDetails"
                      :disabled="disabled || supplyNumberDidabled"
                      hide-details
                      @keypress="isNumberKey"
                      @paste="onPaste"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </div>

              <div class="gas-input" v-if="String(type).toLowerCase() === 'gas' ">
                <v-text-field
                  ref="gasSNRules"
                  :id="id"
                  class="text-body-2"
                  solo
                  flat
                  dense
                  name="gasSupplyNo"
                  maxlength="10"
                  v-model="SupplyNumber"
                  :rules="gasSNRules"
                  @change="saveSupplyNumberDetails"
                  :disabled="disabled || supplyNumberDidabled"
                  
                  hide-details
                  @keypress="isNumberKey"
                  @paste="onPaste"
                  required
                ></v-text-field>
              </div>
              <div class="water-input" v-if="String(type).toLowerCase() === 'water' ">
                <v-text-field
                  ref="waterSNRules"
                  :id="id"
                  class="text-body-2"
                  solo
                  flat
                  dense
                  name="gasSupplyNo"
                  maxlength="13"
                  v-model="SupplyNumber"
                  :rules="waterSNRules"
                  @change="capitalizeAndSave"
                  :disabled="disabled || supplyNumberDidabled"
                  
                  hide-details
                  @keypress="isNumberKeyafterTen"
                  @paste="onPaste"
                  required
                ></v-text-field>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-card>
    </v-hover>
  </div>
</template>

<script>
import { copyTextToClipboard } from "@/helpers/utilities"
import { mapGetters, mapActions } from "vuex"
import { trackerMethod } from '@/helpers/logs.js'

export default {
  props: {
    rules: {
      type: String,
      // required: true,
      default: ""
    },
    // ref: String,
    type: {
      type: String,
      required: true,
      default: ""
    },
    id: String,
    supplyNumber: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    },
    filled: {
      type: Boolean,
      default: false
    },
    supplyNumberReadOnly: {
      type: Boolean,
      default: false,
    },
    supplyNumberDidabled: {
      type: Boolean,
      default: false,
    },
    readOnly: Boolean,
    pc: {
      type: String,
      default: ""
    },
    mtc: {
      type: String,
      default: ""
    },
    llf: {
      type: String,
      default: ""
    }
  },
  data: () => ({
    sId: "",
    previousSID: "",
    mpId: {
      a: "",
      b: ""
    },
    PC: "",
    MTC: "",
    LLF: "",
    SupplyNumber: "",
    cd: "",
    showButton: false,
    showInvalidFormatError: false,
    telephone: '',
  }),
  computed: {
    ...mapGetters("forms", ["supplyDetails"]),
    ...mapGetters("employee", ["userId"]),
    ...mapGetters("company", ["companyId"]),

    gasSNRules(){
      return [
          v => (v && v.length > 5) || 'Meter must have at least 6 digits.'
      ]
    },
    waterSNRules(){
      return [
          v => (v && (v.length > 8 && v.length < 14)) || 'Meter must have at least 9 - 13 digits.'
      ]
    },
    
    isElectric: function() {
      const type = String(this.type).toLowerCase()
      return type === "electricity"
    },
    electricSupplyNo: function() {
      return `${this.PC}${this.MTC}${this.LLF}${this.SupplyNumber}`
    },
    label: function() {
      return String(this.type).toLowerCase() === 'electricity' ? "S" : String(this.type).toLowerCase() === 'gas' ?  "M" : 'SPID'
    },
  },
  mounted() {
    this.page = window.location.href

    if (this.isElectric && this.supplyNumber) {
      this.splitSupplyNumber(this.supplyNumber)
    }
    this.PC = this.pc
    this.MTC = this.mtc
    this.LLF = this.llf
    this.SupplyNumber = this.supplyNumber
  },
  methods: {
    ...mapActions("forms", ["postSupplyDetails"]),
    handleCopy() {
      trackerMethod(
        this.userId,
        this.page,
        this.telephone,
        this.SupplyNumber,
        this.companyId
      )
      copyTextToClipboard(this.SupplyNumber)
    },
    pasteAndforwardData(evt, inputName) {
      const data = evt.clipboardData.getData('text')
      const splitData = data.split("")
      this.showInvalidFormatError = false
      for (let i = 0; i < splitData.length; i++) {
        if (isNaN(parseInt(splitData[i]))) {
          this.showInvalidFormatError = true
          this.saveSupplyNumberDetails()
          event.returnValue = false
          return false
        }
        
      }
      if(inputName === 'sId' && splitData.length > 2) {
        const newData = splitData.slice(2, 100)
        const mpIDAData = newData.slice(0, 4)
        const mpIDBData = newData.slice(4, 8)
        const cdData = newData.slice(8, 11)

        this.mpId.a = mpIDAData.join("")
        this.mpId.b = mpIDBData.join("")
        this.cd = cdData.join("")
      }else if(inputName === 'mpIDA' && splitData.length > 4) {
        const newData = splitData.slice(4, 100)
        const mpIDBData = newData.slice(0, 4)
        const cdData = newData.slice(4, 7)

        this.mpId.b = mpIDBData.join("")
        this.cd = cdData.join("")
      }else if(inputName === 'mpIDB' && splitData.length > 4) {
        const newData = splitData.slice(4, 100)
        const cdData = newData.slice(0, 3)
        this.cd = cdData.join("")
      }
      this.saveSupplyNumberDetails()

    },
    onPaste(evt) {
      const data = evt.clipboardData.getData('text')
      const splitData = data.split("")
      this.showInvalidFormatError = false
      for (let i = 0; i < splitData.length; i++) {
        if (isNaN(parseInt(splitData[i]))) {
          this.showInvalidFormatError = true
          this.saveSupplyNumberDetails()
          event.returnValue = false
          return false
        }
        
      }
      this.saveSupplyNumberDetails()


    },
    moveNextByCheckingPCLength() {
      if(this.PC.length >= 2){
        // const mtc = document.getElementById("MTC")
        // mtc.focus()
        const mtc = this.$refs.mtc
        mtc.focus()
      }
    },
    moveNextByCheckingMTCLength() {
      if(this.MTC.length >= 3){
        const LLF = this.$refs.LLF
        LLF.focus()
      }
    },
    moveNextByCheckingLLFLength() {
      this.LLF = this.LLF.toUpperCase()
      if(this.LLF.length >= 3){
        const sId = this.$refs.sId
        sId.focus()

      }

    },
    moveNextByCheckingSIDLengthAndValueBetween10To23() {
      if(this.sId.length === 2 && (parseInt(this.sId) < 10)) {
        this.sId = "10"
      }else if(this.sId.length === 2 && ((parseInt(this.sId) === 24 || parseInt(this.sId) === 25 ||parseInt(this.sId) === 26))) {
        this.sId = this.sId.toString()
      }else if(this.sId.length === 2 && (parseInt(this.sId) > 23 && parseInt(this.sId) < 25)) {
        this.sId = "23"
      }else if(this.sId.length === 2 && (parseInt(this.sId) > 24 && parseInt(this.sId) < 27)) {
        this.sId = "27"
      }else if(this.sId.length === 2 && (parseInt(this.sId) > 30)) {
        this.sId = "30"
      }else {
        console.log(this.sId)
      }
      if(this.sId.length >= 2){
        // const mpIDA = document.getElementById("mpIDA")
        // mpIDA.focus()
        const mpIDA = this.$refs.mpIDA
        mpIDA.focus()
      }

    },
    moveNextByCheckingMPIDALength() {
      if(this.mpId.a.length >= 4){
        const mpIDB = this.$refs.mpIDB
        mpIDB.focus()
      }
    },
    moveNextByCheckingMPIDBLength() {
      if(this.mpId.b.length >= 4){
        const cd = this.$refs.cd
        cd.focus()

      }
    },
    capitalizeAndSave() {
      this.SupplyNumber = this.SupplyNumber.toUpperCase()
      this.saveSupplyNumberDetails()
    },
    isNumberKeyafterTen(evt) {
      this.SupplyNumber = this.SupplyNumber.toUpperCase()
      if(this.SupplyNumber.toString().length < 9) {
        const charCode = evt.which ? evt.which : evt.keyCode
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
          event.returnValue = false
          return false
        } else {
          event.returnValue = true
          return true
        }
      }else return true
    },
    isNumberKey(evt) {
        this.showInvalidFormatError = false
        const charCode = evt.which ? evt.which : evt.keyCode
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
          event.returnValue = false
          return false
        } else {
          event.returnValue = true
          return true
        }
    },
    isNumberKeyAndLessThan8(evt) {
        this.showInvalidFormatError = false

        const charCode = evt.which ? evt.which : evt.keyCode
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
          event.returnValue = false
          return false
        } else {
          if(this.PC.length === 1 && parseInt(this.PC) >= 1 && parseInt(this.PC) <= 8) {
            this.PC = "0" + this.PC
          }
          if(parseInt(this.PC) >= 8 || parseInt(evt.key) === 9 || (this.PC.length === 1 && parseInt(this.PC) !== 0 && (parseInt(this.PC + evt.key) > 8))) {
            event.returnValue = false
            return false
          }
          event.returnValue = true
          return true
        }
    },
    isSIDNumberKey(evt) {
        this.showInvalidFormatError = false

        const charCode = evt.which ? evt.which : evt.keyCode
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
          event.returnValue = false
          return false
        } else {
          this.previousSID = evt.target.value
          event.returnValue = true
          return true
        }
    },
    splitSupplyNumber(number) {
      const s = number.split("")
      this.sId = s.slice(0, 2).join("")
      this.mpId.a = s.slice(2, 6).join("")
      this.mpId.b = s.slice(6, 10).join("")
      this.cd = s.slice(10).join("")
    },
    saveSupplyNumberDetails()  {
      const supplyDetailsData = {
        electricSupplyNo:  this.electricSupplyNo,
        type: this.type,
        pc: (this.type === 'electricity' || this.type === 'Electricity') ? this.PC : '',
        mtc: (this.type === 'electricity' || this.type === 'Electricity') ? this.MTC : '',
        llf: (this.type === 'electricity' || this.type === 'Electricity') ? this.LLF : '',
        sId: (this.type === 'electricity' || this.type === 'Electricity') ? this.sId : '',
        mpIdA: (this.type === 'electricity' || this.type === 'Electricity') ? this.mpId.a : '',
        mpIdB: (this.type === 'electricity' || this.type === 'Electricity') ? this.mpId.b : '',
        cd: (this.type === 'electricity' || this.type === 'Electricity') ? this.cd : '',
        supplyNumber: this.SupplyNumber, 
        addressId: this.supplyDetails ? this.supplyDetails.addressId : 0,
        supplierId: this.supplyDetails ? this.supplyDetails.supplierId : 0,
        supplierName: this.supplyDetails ? this.supplyDetails.supplierName : '',
        meterId: this.supplyDetails ? this.supplyDetails.meterId : 0,
        // before meterTypeId was passing as undefined
        meterTypeId: (this.type === 'electricity' || this.type === 'Electricity') ? 9 : 10,
        contractEndDate: this.supplyDetails ? this.supplyDetails.contractEndDate : 0,
        renewalDate: this.supplyDetails ? this.supplyDetails.renewalDate : 0,
        showInvalidFormatError: this.showInvalidFormatError,
      }
      this.postSupplyDetails(supplyDetailsData)
    },
  }
}

</script>

<style lang="scss">
:disabled {   color: #616161 !important; }
.supply-number {
  position: relative;

  .second-row {
    border-top: thin solid rgba(0, 0, 0, 0.12);
  }

  .first-row > .col,
  .second-row > .col,
  .gas-input {
    border-left: thin solid rgba(0, 0, 0, 0.12);
  }

  .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)
    > .v-input__control
    > .v-input__slot {
    padding: 0 0.375rem;
    border-radius: 0;
  }

  .v-text-field__slot input {
    text-align: center;
  }

  .v-btn--fab.v-size--x-small.v-btn--absolute.v-btn--top {
    top: 0;
  }

  .v-btn--absolute.v-btn--right {
    right: 0;
  }

  .v-btn--fab.v-size--x-small {
    height: 24px;
    width: 24px;
  }
}


</style>
