<template>
  <v-card
    height="100%"
    class="d-flex flex-column"
    flat
    :color="color"
    rounded="lg"
    light
  >
    <v-card-title v-if="siteName" class="subtitle-1 font-weight-medium">
      {{ siteName }}
    </v-card-title>

    <v-card-subtitle v-if="cotDate">
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on">
            <v-icon small>mdi-calendar-account</v-icon>
            {{ $helpers.formatDate(cotDate) }}
          </span>
        </template>
        <span>Change of Tenancy</span>
      </v-tooltip>
    </v-card-subtitle>

    <v-card-text style="font-size: 13px">
      <div class="blue-grey--text">
        <div>
          {{ firstLine }}
        </div>
        <div>
          {{ secondLine }}
        </div>
        <div>
          {{ town }}
        </div>
        <div>
          {{ county }}
        </div>
        <div>
          {{ postCode }}
        </div>
      </div>

      <div class="pt-3" v-if="showDetails">
        <v-row>
          <v-col>
            <div class="text-caption">From</div>
            <div class="teal--text">{{ $helpers.formatDate(startDate) }}</div>
          </v-col>
          <v-col>
            <div class="text-caption">To</div>
            <div class="teal--text">{{ $helpers.formatDate(endDate) }}</div>
          </v-col>
        </v-row>
        <v-row class="text-caption">
          <v-col class="mb-4">{{ timeFromStartDate }}</v-col>
          <v-col></v-col>
          <v-col v-if="allowEdit" cols="3" class="align-items-center">
            <p
              @click="editAddressClicked"
              class="blue--text cursor-pointer font-weight-bold"
              style="width: 25px"
            >
              EDIT
            </p>
          </v-col>
        </v-row>
      </div>
    </v-card-text>

    <v-divider v-if="!noActions"></v-divider>

    <v-card-actions v-if="!noActions">
      <v-row justify="center">
        <v-col cols="auto">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                color="orange"
                depressed
                icon
                small
                @click="toggleMetersCard"
              >
                <v-icon small>mdi-gauge</v-icon>
              </v-btn>
            </template>
            <span>Address Meters</span>
          </v-tooltip>
        </v-col>
        <v-col cols="auto">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div v-on="on" v-bind="attrs">
                <PaymentInfo :site-name="siteName" :address-id="addressId" />
              </div>
            </template>
            <span>Payment Information</span>
          </v-tooltip>
        </v-col>
        <v-col cols="auto">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <FurtherAddressInfo
                  :site-name="siteName"
                  :address-id="addressId"
                />
              </div>
            </template>
            <span>Further Address Information</span>
          </v-tooltip>
        </v-col>
        <v-col cols="auto">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="blue"
                v-bind="attrs"
                v-on="on"
                depressed
                icon
                small
                @click.stop="editAddress"
              >
                <v-icon small>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Edit Address</span>
          </v-tooltip>
        </v-col>
        <v-col v-if="!primary" cols="auto">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" depressed icon small>
                <div>
                  <ConfirmDelete
                    :body-content="siteName"
                    :first-line="firstLine"
                    :second-line="secondLine"
                    :town="town"
                    :county="county"
                    :post-code="postCode"
                    @delete-confirmed="handleDeleteAddress"
                  ></ConfirmDelete>
                </div>
              </v-btn>
            </template>
            <span>Delete Address</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex"
import { changesSaved, somethingWentWrong } from "@/helpers/utilities"

export default {
  components: {
    PaymentInfo: () => import("@/components/dialogs/payment-info"),
    ConfirmDelete: () => import("@/components/dialogs/confirm-delete"),
    FurtherAddressInfo: () =>
      import("@/components/dialogs/further-address-info"),
  },
  props: {
    addressId: {
      type: String,
      default: "",
    },
    siteName: String,
    cotDate: Number,
    firstLine: {
      type: String,
      default: "",
    },
    allowEdit: {
      type: Boolean,
      default: true,
    },
    secondLine: {
      type: String,
      default: "",
    },
    town: {
      type: String,
      default: "",
    },
    county: {
      type: String,
      default: "",
    },
    postCode: {
      type: String,
      default: "",
    },
    primary: Boolean,
    noActions: Boolean,
    startDate: {
      type: Number,
      default: 0,
    },
    endDate: {
      type: Number,
      default: 0,
    },
    showRemove: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      toggleEdit: false,
    }
  },
  computed: {
    ...mapGetters("meters", ["showCard", "meters", "selectedMeter"]),
    ...mapGetters("employee", ["userId"]),
    ...mapGetters("company", ["details"]),

    color() {
      return this.primary ? "green lighten-5" : "blue lighten-5"
    },
    showDetails() {
      return this.startDate || this.endDate
    },
    timeFromStartDate() {
      const totalMonths = this.$helpers.diffDates(
        this.endDate,
        this.startDate,
        "M"
      )
      const years = Math.floor(totalMonths / 12)
      const months = totalMonths - 12 * years || 1

      const monthsPlural = this.$helpers.isPlural(months)
      const yearsPlural = this.$helpers.isPlural(years)

      const monthsText = months ? `${months} month${monthsPlural}` : ""
      const yearsText = years ? `${years} year${yearsPlural}` : ""

      return years ? `${yearsText} ${monthsText}`.trim() : monthsText
    },
  },
  methods: {
    ...mapActions("meters", ["fetchMeters", "toggleCardVisibility"]),
    ...mapActions("notes", ["fetchNotes"]),
    ...mapActions("addresses", [
      "fetchPaymentInfo",
      "fetchLandlordInfo",
      "fetchPreviousTenantInfo",
      "fetchBreweryInfo",
      "deleteAddressById",
      "fetchAddresses",
      "fetchAddressesAfterRemove",
    ]),
    ...mapActions("ui", ["openForm"]),
    ...mapMutations("addresses", {
      selectAddress: "setSelectedAddress",
    }),

    editAddressClicked() {
      this.toggleEdit = !this.toggleEdit
      this.$emit("editAddressClicked")
      this.$emit("editAddressClickedToggle", this.toggleEdit)
    },

    editAddress() {
      this.fetchPaymentInfo(this.addressId),
        this.fetchLandlordInfo(this.addressId),
        this.fetchPreviousTenantInfo(this.addressId),
        this.fetchBreweryInfo(this.addressId),
        this.selectAddress(this.addressId),
        this.openForm({ formName: "address-form", edit: true })
    },
    toggleMetersCard() {
      this.toggleCardVisibility(this.addressId)
      this.selectAddress(this.addressId)
      this.fetchPaymentInfo(this.addressId)
      this.fetchMeters(this.addressId)
    },
    async handleDeleteAddress() {
      try {
        const noteMessage = `Address Deleted - ${this.siteName} <br/>
                            ${this.firstLine} <br/>
                            ${this.secondLine} <br/>
                            ${this.town} <br/>
                            ${this.county} <br/>
                            ${this.postCode}`
        const details = {
          addressId: this.addressId,
          userId: this.userId,
          noteMessage: noteMessage,
          companyId: this.details.companyId,
        }
        const response = await this.deleteAddressById(details)
        if (response && response.status === 200) {
          console.log(response)
          changesSaved("Address Removed Successfully!")
          setTimeout(() => {
            this.fetchAddressesAfterRemove(this.details.companyId)
            this.toggleCardVisibility(this.addressId)

            this.fetchNotes()
          }, 1000)
        }
      } catch (err) {
        somethingWentWrong()

        console.log(err)
      }
    },
  },
}
</script>
