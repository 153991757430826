const isPlural = (qty) => (parseInt(qty) > 1 ? "s" : "")

const profilePicture = (userId) =>
  `https://hive.utilityhive.com/uploads/ProfilePics/${userId}.jpg`

const onProfilePictureError = (event) =>
  (event.target.src = `https://hive.utilityhive.com/uploads/ProfilePics/default-avatar.png`)

const supplierLogo = (supplierId) =>
  `https://hive.utilityhive.com/uploads/SupplierLogos/${supplierId}.jpg`

export default {
  isPlural,
  profilePicture,
  supplierLogo,
  onProfilePictureError,
}
