/* eslint-disable vue/no-async-in-computed-properties */
<template>
  <div>
    <v-dialog
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      v-model="showSendContractModal"
      width="1300"
    >
      <v-card class="send_contract_modal">
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="closeModal">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            <v-tabs
              class="mb-4"
              v-model="tab"
              dark
              background-color="primary"
              grow
            >
              <v-tab
                v-for="(contractWidget, index) in contractWidgets"
                :key="index"
                >{{ contractWidget.widgetDescription }}</v-tab
              >
            </v-tabs>
          </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <!-- <v-tabs-items v-model="tab">
      <v-tab-item> -->

        <v-form class="px-5 mt-3" ref="form" v-model="valid" lazy-validation>
          <v-row class="alignment mt-1">
            <v-col cols="1">
              <v-img
                style="width: 110px; height: 54px"
                contain
                :src="quoteForSendContract.supplierExternalLogo"
              ></v-img>
            </v-col>
            <v-col v-if="tab !== null">
              <h2>
                {{
                  contractWidgets &&
                  contractWidgets[`${tab}`] &&
                  contractWidgets[`${tab}`].widgetDescription
                }}
              </h2>
            </v-col>
          </v-row>
          <div>
            <v-row>
              <!-- COMPANY INFORMATION & CONTRACT AGREED BY -->
              <v-col class="pr-0">
                <div
                  class="d-flex flex-column"
                  style="gap: 15px; margin-left: 15px; height: 100%"
                >
                  <!-- COMPANY INFORMATION -->

                  <v-card outlined class="h-100" style="padding: 16px">
                    <div class="d-flex justify-space-between align-center">
                      <div>
                        <span
                          class="text-body-18px font-weight-bold text-accent"
                        >
                          COMPANY INFORMATION
                        </span>
                      </div>
                      <div>
                        <span
                          class="cursor-pointer"
                          @click="openEditCompanyInfoModal"
                        >
                          <i
                            class="fas fa-pencil-alt text-accent"
                            style="font-size: 1.2rem"
                          ></i>
                        </span>
                      </div>
                    </div>
                    <div class="mt-2 d-flex flex-column" style="gap: 8px">
                      <v-row>
                        <v-col class="data-value-custom">
                          <DataValue
                            label="Company "
                            :value="companyDetails.companyName"
                            icon="office-building"
                            icon-color="blue lighten-1"
                          />
                        </v-col>
                        <v-col class="data-value-custom">
                          <DataValue
                            label="Telephone"
                            :value="companyDetails.telephone"
                            icon="phone"
                            icon-color="blue lighten-1"
                          />
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col class="data-value-custom">
                          <DataValue
                            label="Supply Address"
                            :value="{
                              firstLine: companyDetails.add1,
                              secondLine: companyDetails.add2,
                              town: companyDetails.town,
                              county: companyDetails.county,
                              postcode: companyDetails.postcode,
                            }"
                            icon="map-marker"
                            icon-color="red lighten-1"
                            address-type
                          />
                        </v-col>
                        <v-col
                          v-if="showRegistrationNo"
                          class="data-value-custom"
                        >
                          <div class="row row--dense align-center">
                            <div class="col col-auto">
                              <v-avatar size="32" color="blue lighten-1">
                                <!-- <SvgIcon
                              type="mdi"
                              :path="path.officeBuilding"
                              :size="15"
                            ></SvgIcon> -->
                                <v-icon color="white"
                                  >mdi-office-building</v-icon
                                >
                              </v-avatar>
                            </div>
                            <div
                              style="padding: 0"
                              class="col data-value-custom"
                            >
                              <div
                                class=""
                                style="font-size: 11px; margin-left: 4px"
                                :style="
                                  (companyDetails.tradingTypeId === 7 ||
                                    companyDetails.tradingTypeId === 31 ||
                                    companyDetails.tradingTypeId === 32) &&
                                  (companyDetails.registrationNo === '' ||
                                    companyDetails.registrationNo === null)
                                    ? 'color: red !important;'
                                    : 'color: #9e9e9e ; caret-color: #9e9e9e '
                                "
                              >
                                Registration Number
                              </div>
                              <div
                                class=""
                                style="font-size: 13px"
                                :style="
                                  (companyDetails.tradingTypeId === 7 ||
                                    companyDetails.tradingTypeId === 31 ||
                                    companyDetails.tradingTypeId === 32) &&
                                  (companyDetails.registrationNo === '' ||
                                    companyDetails.registrationNo === null)
                                    ? 'color: red !important;'
                                    : 'color: rgba(0, 0, 0, 0.87);'
                                "
                              >
                                {{ companyDetails.registrationNo }}
                              </div>
                              <!---->
                            </div>
                          </div>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col class="data-value-custom">
                          <DataValue
                            label="Trading Type"
                            :value="companyDetails.tradingType"
                            icon="briefcase"
                            icon-color="teal lighten-1"
                          />
                        </v-col>
                        <v-col class="data-value-custom">
                          <DataValue
                            label="Nature of Business"
                            :value="companyDetails.businessType"
                            icon="engine"
                            icon-color="amber lighten-1"
                          />
                        </v-col>
                      </v-row>
                      <!-- <v-row> </v-row> -->
                      <v-row>
                        <v-col class="data-value-custom">
                          <DataValue
                            label="COT Date"
                            :value="cotDateText"
                            icon="calendar-blank"
                            icon-color="blue lighten-1"
                          />
                        </v-col>
                        <v-col> </v-col>
                      </v-row>
                    </div>
                  </v-card>
                  <!--    CONTRACT AGREED BY -->

                  <v-card outlined class="h-100" style="padding: 16px">
                    <div class="d-flex justify-space-between align-center">
                      <div>
                        <span
                          class="text-body-18px font-weight-bold text-accent"
                        >
                          CONTRACT AGREED BY
                        </span>
                      </div>
                      <div class="data-displayer">
                        <button
                          type="button"
                          class="btn d-flex justify-space-between align-center"
                          style="
                            width: 165px;
                            padding: 7px 14px;
                            background-color: #0289de;

                            border-radius: 0.25rem;
                            color: #fff !important;

                            line-height: 1.5;
                          "
                          :disabled="
                            !sendContractForm.selectedAgreedBy ||
                            loadingContractAgreedBy
                          "
                          @click="saveContactAgreedDetails"
                        >
                          <i class="fas fa-check" style="font-size: 16px"></i>
                          <div
                            style="font-size: 1rem; transform: translateY(1px)"
                          >
                            SAVE CHANGES
                          </div>
                        </button>
                      </div>
                    </div>
                    <div class="mt-2 d-flex flex-column" style="gap: 8px">
                      <v-row class="">
                        <v-col cols="auto">
                          <v-select
                            ref="selectedAgreedBy"
                            :rules="selectedAgreedByRules"
                            label="Agreed By"
                            v-model="sendContractForm.selectedAgreedBy"
                            :items="significantPersonsList"
                            @change="onChangeContact"
                          ></v-select>
                        </v-col>
                      </v-row>
                      <div v-if="sendContractForm.selectedAgreedBy">
                        <v-row>
                          <v-col class="pt-0">
                            <v-text-field
                              :rules="contactNumberRules"
                              ref="Telephone"
                              label="Telephone"
                              v-model="telephone"
                            ></v-text-field>

                            <div v-if="numberAlreadyExistError" class="mt-2">
                              <div class="text-danger text-sm mt-2">
                                {{ numberAlreadyExistError }}
                              </div>
                            </div>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col class="pt-0">
                            <v-text-field
                              hide-details
                              label="Email"
                              v-model="emailAddressAgreedBy"
                              @blur="isValidEmailAgreedBy"
                            ></v-text-field>

                            <div
                              v-if="isEmailAgreedBy === false"
                              class="mt-2 text-sm"
                              style="color: red"
                            >
                              Email is Invalid.Please enter the correct email.
                            </div>
                          </v-col>
                        </v-row>
                      </div>
                    </div>
                  </v-card>
                </div>
              </v-col>

              <!-- Significant Person -->
              <v-col class="pr-0">
                <v-card outlined class="h-100" style="padding: 16px">
                  <div class="d-flex justify-space-between align-center">
                    <div>
                      <span class="text-body-18px font-weight-bold text-accent">
                        SIGNIFICANT PERSON
                      </span>
                    </div>
                    <div class="data-displayer">
                      <button
                        type="button"
                        class="btn d-flex justify-space-between align-center"
                        style="
                          width: 165px;
                          padding: 7px 14px;
                          background-color: #0289de;
                          color: white;
                          border-radius: 0.25rem;
                          color: #fff !important;

                          line-height: 1.5;
                        "
                        :disabled="
                          !sendContractForm.significantPersonContactId ||
                          loadingSignificantPerson
                        "
                        @click="validateSignificantPersonAndSubmit"
                      >
                        <i class="fas fa-check" style="font-size: 16px"></i>
                        <div
                          style="font-size: 1rem; transform: translateY(1px)"
                        >
                          SAVE CHANGES
                        </div>
                      </button>
                    </div>
                  </div>
                  <div class="d-flex flex-column" style="gap: 8px">
                    <v-row class="mt-2">
                      <v-col cols="auto">
                        <!-- :class="!sendContractForm.significantPersonContactId ? 'is-invalid' : ''" -->
                        <v-select
                          ref="significantPersonContactId"
                          :rules="significantPersonContactIdRules"
                          v-model="sendContractForm.significantPersonContactId"
                          @change="onChange"
                          label="Significant Person"
                          :items="significantPersonsList"
                        >
                        </v-select>
                      </v-col>
                    </v-row>
                    <v-row v-if="sendContractForm.significantPersonContactId">
                      <v-col :cols="mobileView ? '12' : '6'">
                        <v-select
                          label="Title"
                          :items="titleList"
                          v-model="selectedSignificantPerson.title"
                        ></v-select>
                        <v-text-field
                          label="Surname"
                          v-model="selectedSignificantPerson.lastName"
                          @input="lastNameCapitalLetter"
                        ></v-text-field>

                        <base-telephone-input
                          ref="contactNumber"
                          refName="contactNumber"
                          label="Contact Number"
                          :error-msg-c-c="errorMsgCC"
                          v-model="selectedSignificantPerson.contactNumber"
                        />
                      </v-col>
                      <v-col :cols="mobileView ? '12' : '6'">
                        <v-text-field
                          label="First Name"
                          v-model="selectedSignificantPerson.firstName"
                          @input="firstNameCapitalLetter"
                        ></v-text-field>
                        <v-text-field
                          label="Job Role"
                          v-model="selectedSignificantPerson.jobTitle"
                          @input="jobTitleCapitalLetter"
                        ></v-text-field>

                        <v-text-field
                          label="Email"
                          v-model="selectedSignificantPerson.emailAddress"
                          @blur="isValidEmail"
                        ></v-text-field>
                        <!-- <label class="text-secondary text-sm"
                        >Email Address</label
                      >
                      <b-input-group>
                        <b-form-input
                          v-model="selectedSignificantPerson.emailAddress"
                          @blur="isValidEmail"
                        ></b-form-input>
                      </b-input-group> -->
                        <div
                          v-if="isEmail === false"
                          class="mt-2"
                          style="color: red"
                        >
                          Email is Invalid.Please enter the correct email.
                        </div>
                      </v-col>
                    </v-row>

                    <v-row v-if="sendContractForm.significantPersonContactId">
                      <v-col style="padding-right: 0" cols="auto">
                        <v-menu
                          ref="menuDOB"
                          v-model="menuDOB"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              class="d-inline-block mr-2"
                              v-bind="attrs"
                              v-on="on"
                            >
                              mdi-calendar
                            </v-icon>
                            <v-text-field
                              type="tel"
                              class="d-inline-block mr-2 hello"
                              :style="{
                                width:
                                  contactAddresses &&
                                  contactAddresses.length >= 3
                                    ? '14.5rem'
                                    : 'initial',
                              }"
                              label="Date of Birth"
                              v-mask="'##/##/####'"
                              placeholder="DD/MM/YYYY"
                              ref="dobText"
                              :rules="dobTextRules"
                              v-model="dobText"
                              @keyup="dobTextChanged"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="dob"
                            ref="picker"
                            :max="formattedMaxDateOfBirth"
                            locale="en-GB"
                            @change="dateOfBirthChanged"
                          ></v-date-picker>
                        </v-menu>
                        <div
                          class="v-text-field__details mb-3"
                          v-if="dirtOfBirthError"
                        >
                          <div
                            class="v-messages theme--light error--text"
                            role="alert"
                          >
                            <div class="v-messages__wrapper">
                              <div class="v-messages__message">
                                Provide Date Of Birth
                              </div>
                            </div>
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                    <CreditInformationSendContract
                      v-if="
                        sendContractForm.significantPersonContactId &&
                        showAddAddressBar
                      "
                      :showAddNewAddress="showAddNewAddress"
                      @new-address-added="
                        fetchContactAddresses(
                          selectedSignificantPerson.contactId
                        )
                      "
                      :editClicked="editClicked"
                      v-on:selected-address-id="selectedAddressIdChanged"
                      @selected-home-address="selectedHomeAddressChanged"
                      :showAddNewAddressBtn="
                        contactAddresses && contactAddresses.length === 0
                      "
                      :edit-address-id="selectedAddressId"
                      :deleteAddressId="deleteAddressId"
                      :contact-id="selectedSignificantPerson.contactId"
                    ></CreditInformationSendContract>
                    <div
                      v-if="contactAddresses && contactAddresses.length > 0"
                      style="display: grid; margin: auto"
                    >
                      <v-row
                        class="mx-3"
                        v-if="contactAddresses && contactAddresses.length > 0"
                        style="
                          position: absolute;
                          width: 220px;
                          left: 0;
                          transform: rotate(-90deg) translate(-37%, -145%);
                          z-index: 10;
                        "
                      >
                        <v-col>
                          <v-btn
                            color="info"
                            block
                            depressed
                            @click="
                              () => {
                                showAddNewAddress = !showAddNewAddress
                              }
                            "
                          >
                            Add New Address
                          </v-btn>
                        </v-col>
                      </v-row>
                      <div style="padding: 0">
                        <v-slide-group class="slider_send_contract_container">
                          <v-slide-item
                            v-for="(address, index) in contactAddresses"
                            :key="address.addressId"
                            v-slot="{ toggle }"
                          >
                            <AddressWidgetSendContract
                              :key="index"
                              class="mb-3"
                              style="
                                padding-right: 30px;
                                margin: auto;
                                width: fit-content;
                              "
                              :address-id="address.addressId.toString()"
                              :first-line="address.add1"
                              :second-line="address.add2"
                              :town="address.town"
                              :county="address.county"
                              :post-code="address.postcode"
                              :start-date="address.fromDate"
                              :end-date="address.untilDate"
                              showEdit
                              no-actions
                              @editAddressClickedToggle="
                                editAddressClicked($event, address.addressId)
                              "
                              @deleteAddressClicked="
                                deleteAddressClicked($event, address.addressId)
                              "
                              @click="toggle"
                            ></AddressWidgetSendContract>
                          </v-slide-item>
                        </v-slide-group>
                        <CreditInformationSendContract
                          v-show="showCreditInfo"
                          v-on:selected-address-id="
                            selectedAddressEditIdChanged
                          "
                          class="mt-4"
                          :contact-id="selectedContact.contactId"
                          :editClicked="selectedEditAddressId"
                          :deleteAddressId="deleteEditAddressId"
                          :editAddressId="selectedEditAddressId"
                          @new-address-added="
                            () => {
                              showCreditInfo = false
                              fetchContactAddresses(selectedContact.contactId)
                            }
                          "
                        >
                        </CreditInformationSendContract>
                      </div>
                    </div>
                  </div>
                </v-card>
              </v-col>
              <!-- SUPPLY DETAILS & PRICES -->
              <v-col class="pr-0">
                <v-card outlined class="h-100" style="padding: 16px">
                  <div class="d-flex justify-space-between">
                    <div>
                      <span class="text-body-18px font-weight-bold text-accent">
                        SUPPLY DETAILS & PRICES
                      </span>
                    </div>
                  </div>
                  <div class="mt-3 d-flex flex-column" style="gap: 8px">
                    <v-row>
                      <v-col
                        cols="auto"
                        class="d-flex align-center supply-mobile"
                        style="min-width: 260px; padding-right: 5px"
                      >
                        <div v-if="estimate" class="text-center">Estimate</div>

                        <SupplyNumber
                          v-else
                          :key="`${selectedMeter.supplyNumber}-${selectedMeter.pc}-${selectedMeter.mtc}-${selectedMeter.llf}`"
                          :type="selectedMeter.meterType"
                          :supply-number="selectedMeter.supplyNumber"
                          :pc="selectedMeter.pc"
                          :mtc="selectedMeter.mtc"
                          :llf="selectedMeter.llf"
                          :disabled="true"
                          :read-only="true"
                        />
                      </v-col>
                    </v-row>
                    <v-row
                      class="mt-1 flex-column"
                      style="padding-left: 15px; font-size: 13px"
                    >
                      <div class="text-left mb-1">
                        <span style="font-weight: bold"> Rates : </span>
                        <span
                          v-for="(rate, index) in getDynamicRates()"
                          :key="index"
                        >
                          <span class="mr-1"
                            ><strong>{{ rate.label }}:</strong>
                            {{ rate.value }}</span
                          >
                        </span>
                      </div>
                      <div class="text-left mb-1">
                        <span style="font-weight: bold"> Tariff : </span>
                        <span class="mr-1">
                          {{ quoteForSendContract.tariffCode }}
                        </span>
                      </div>
                      <div class="text-left mb-1">
                        <span style="font-weight: bold"> Term : </span>
                        <span class="mr-1">
                          {{ quoteForSendContract.term }} Months
                        </span>
                      </div>
                      <div class="text-left mb-1">
                        <span style="font-weight: bold"> Contract Type : </span>
                        <span class="mr-1">
                          {{
                            selectedMeter.supplierId ===
                            quoteForSendContract.supplierID
                              ? "Renewal Acquisition"
                              : "Acquisition"
                          }}
                        </span>
                      </div>
                      <div class="text-left mb-1">
                        <span style="font-weight: bold">
                          Contract Start Date :
                        </span>
                        <span class="mr-1">
                          {{ quoteForSendContract.csdText }}
                        </span>
                      </div>
                      <div class="text-left mb-1">
                        <span style="font-weight: bold"> EAC : </span>
                        <span class="mr-1">
                          {{ totalEacValue }}
                        </span>
                      </div>
                      <div class="text-left mb-1">
                        <span style="font-weight: bold">
                          Total Contract Estimated Commission :
                        </span>
                        <span class="mr-1">
                          {{ quoteForSendContract.fixedCommAmount }}
                        </span>
                      </div>
                    </v-row>
                  </div>
                </v-card>
              </v-col>
              <!-- PAYMENT DETAILS -->
              <v-col>
                <v-card outlined class="h-100" style="padding: 16px">
                  <div class="d-flex justify-space-between align-center">
                    <div>
                      <span class="text-body-18px font-weight-bold text-accent">
                        PAYMENT DETAILS
                      </span>
                    </div>
                    <div class="data-displayer">
                      <button
                        type="button"
                        class="btn d-flex justify-space-between align-center"
                        style="
                          width: 165px;
                          padding: 7px 14px;
                          background-color: #0289de;
                          color: white;
                          border-radius: 0.25rem;
                          color: #fff !important;
                          line-height: 1.5;
                        "
                        :disabled="
                          !sendContractForm.typeId || loadingPaymentMethod
                        "
                        @click="savePaymentDetails"
                      >
                        <i class="fas fa-check" style="font-size: 16px"></i>
                        <div
                          style="font-size: 1rem; transform: translateY(1px)"
                        >
                          SAVE CHANGES
                        </div>
                      </button>
                    </div>
                  </div>
                  <div class="d-flex flex-column" style="gap: 8px">
                    <v-row class="mt-2">
                      <v-col>
                        <v-select
                          label="Payment Method"
                          :rules="paymentMethodRules"
                          :items="paymentList"
                          v-model="payment.typeId"
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-row v-if="payment.typeId === 1">
                      <v-col>
                        <v-text-field
                          ref="bankName"
                          label="Bank Name"
                          :rules="bankNameRules"
                          v-model="payment.bankName"
                          @input="bankNameChanged"
                        ></v-text-field>

                        <v-text-field
                          ref="sortCode"
                          label="Sort Code"
                          @paste="handlePaste"
                          :rules="sortCodeRules"
                          v-model="payment.sortCode"
                          @keypress="isSortCodeNumberKey"
                        ></v-text-field>
                      </v-col>
                      <v-col>
                        <v-text-field
                          ref="accountName"
                          label="Account Name"
                          :rules="accountNameRules"
                          v-model="payment.accountName"
                          @input="accountNameChanged"
                        ></v-text-field>

                        <v-text-field
                          ref="accountNumber"
                          label="Account Number"
                          @paste="handlePasteAccountNumber"
                          :rules="acountNumberRules"
                          v-model="payment.accountNumber"
                          @keypress="isAccountNoNumberKey($event)"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </div>
          <!-- SUPPLIER SPECIFIC INFORMATION -->
          <v-card outlined class="h-100 mt-4" style="padding: 16px">
            <div class="d-flex justify-space-between">
              <div>
                <span class="text-body-18px font-weight-bold text-accent">
                  SUPPLIER SPECIFIC INFORMATION
                </span>
              </div>
            </div>
            <div class="mt-3 d-flex flex-column" style="gap: 8px">
              <v-row class="mt-2">
                <v-col v-if="quoteForSendContract.supplierID === 112">
                  <v-text-field
                    ref="upfrontPaymentAmount"
                    style="margin-top: 0; padding-top: 0"
                    label="Upfront Payment Amount"
                    :rules="upfrontPaymentAmountRules"
                    v-model="upfrontPaymentAmount"
                    @input="changeUpfrontInputAmount"
                  ></v-text-field>
                </v-col>
                <v-col> </v-col>
                <v-col> </v-col>

                <v-col></v-col>
              </v-row>
            </div>
          </v-card>

          <div class="mt-4">
            <!-- :disabled="isDisable" -->
            <v-btn
              depressed
              color="accent"
              @click="submitEForm"
              block
              dark
              large
            >
              SEND TO
              {{ emailAddressAgreedBy ? emailAddressAgreedBy : "" }}
            </v-btn>
          </div>
        </v-form>
      </v-card>
    </v-dialog>
    <v-form ref="companyForm" v-model="validCompany" lazy-validation>
      <v-dialog v-model="showEditCompanyInfoModal" max-width="500px">
        <v-card>
          <v-card-title>
            <div
              class="d-flex justify-space-between align-center w-100 send_contract_company_container"
              style="gap: 1rem; color: #30425a"
            >
              <h5
                id="edit-companyinfo-modal___BV_modal_title_"
                class="modal-title text-body-18px text-accent w-100"
              >
                Edit Company Info
              </h5>
              <div class="data-displayer">
                <button
                  class="btn d-flex justify-space-between align-center"
                  style="
                    width: 165px;
                    padding: 7px 14px;
                    background-color: #0289de;
                    border-radius: 0.25rem;
                    color: #fff !important;
                    line-height: 1.5;
                  "
                  :disabled="loadingCompanyInfo"
                  @click="saveCompanyChanges"
                >
                  <i class="fas fa-check" style="font-size: 16px"></i>
                  <div style="font-size: 1rem; transform: translateY(1px)">
                    SAVE CHANGES
                  </div>
                </button>
              </div>
            </div>
          </v-card-title>
          <v-card-text>
            <div class="pr-3 pb-3 pl-3">
              <v-row class="mt-2">
                <v-col>
                  <v-text-field
                    v-model="companyForm.telephone"
                    label="Telephone Number"
                    prepend-icon="mdi-phone"
                    disabled
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="mt-2">
                <v-col>
                  <v-text-field
                    v-model="companyForm.companyName"
                    label="Company Name"
                    prepend-icon="mdi-office-building"
                    @input="companyNameCapitalLetter"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="mt-2">
                <v-col>
                  <v-select
                    v-model="companyForm.tradingTypeId"
                    :items="tradingTypeList"
                    item-text="value"
                    item-value="id"
                    @change="tradingTypeChanged"
                    label="Trading Type"
                    prepend-icon="mdi-domain"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row class="mt-2" v-if="showRegistrationNoEdit">
                <v-col>
                  <!-- @keypress="NoLettersAccepted" -->
                  <v-text-field
                    v-model="companyForm.registrationNo"
                    :rules="registrationNoRules"
                    @input="changeToUpperCase"
                    @paste="changeToUpperCase"
                    label="Registration Number"
                    prepend-icon="mdi-pound"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row class="mt-2">
                <v-col>
                  <v-autocomplete
                    :items="natureOfBusinessList"
                    color="white"
                    v-model="companyForm.natureOfBusinessId"
                    @change="setNatureOfBusinessType"
                    item-text="value"
                    item-value="id"
                    label="Nature of Business"
                    prepend-icon="mdi-tractor-variant"
                    clearable
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row class="mt-2">
                <v-col>
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :value="formattedDate"
                        label="COT Date"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        @input="formattedDateChanged"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="companyForm.cotDate"
                      :key="companyForm.addressId"
                      ref="picker"
                      :max="new Date().toISOString().substr(0, 10)"
                      @change="unFormattedDateChanged"
                      min="1950-01-01"
                      locale="en-GB"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>

              <v-row class="mt-2">
                <v-col>
                  <v-text-field
                    v-model="companyForm.add1"
                    label="Address Line 1"
                    @input="firstCapitalLetter('add1')"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="mt-2">
                <v-col>
                  <v-text-field
                    label="Address Line 2"
                    v-model="companyForm.add2"
                    @input="firstCapitalLetter('add2')"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="mt-2">
                <v-col>
                  <v-text-field
                    label="Town/City"
                    v-model="companyForm.town"
                    @input="firstCapitalLetter('town')"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="mt-2">
                <v-col>
                  <v-text-field
                    label="County"
                    v-model="companyForm.county"
                    @input="firstCapitalLetter('county')"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row class="align-center">
                <v-col cols="10">
                  <v-text-field
                    label="Postcode"
                    placeholder="Enter Postcode"
                    required
                    v-model="companyForm.postcode"
                    @input="capitalize"
                  ></v-text-field>
                </v-col>
                <v-col cols="2">
                  <i
                    @click="fetchPostCodeAddresses"
                    class="fas fa-lg fa-search-location"
                    style="color: #008000; font-size: 1.5em; cursor: pointer"
                  ></i>
                </v-col>
              </v-row>
              <v-row class="mt-2">
                <v-col>
                  <p v-if="inCorrectPostcodeSelected">
                    Please recheck your postcode, it seems to be incorrect
                  </p>
                  <v-select
                    v-if="showPostCode && !inCorrectPostcodeSelected"
                    :items="addresses"
                    v-model="postcodeSelected"
                    @change="addressSelected"
                    label="Select Address"
                  ></v-select>
                </v-col>
              </v-row>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-form>
  </div>
</template>

<script>
// import { mergeExistingEntriesIntoForm } from "@/helpers/utilities"
import { mapActions, mapGetters, mapMutations } from "vuex"
import BaseTelephoneInput from "@/components/common/BaseTelephoneInput"
import { mask } from "vue-the-mask"
import DataValue from "@/components/data-value"
import CreditInformationSendContract from "@/components/forms/send-contract/credit_info_send_contract.vue"
// import AutoComplete from "@/components/search-auto-complete.vue"
import { changesSaved, somethingWentWrong } from "@/helpers/utilities"
import AddressWidgetSendContract from "@/components/forms/send-contract/address_widget_send_contract"

import SupplyNumber from "@/components/supply-number.vue"

// import AddressAndCreditInformationSendContract from "@/components/forms/send-contract/address_and_credit_information_send_contract.vue"
import api from "@/store/api"
import dayjs from "dayjs"

export default {
  props: {
    contactId: { Type: Number, default: null },
    selectedMeter: { Type: Object, default: null },
    showSendContractModal: { Type: Boolean, default: null },
  },
  components: {
    DataValue,
    BaseTelephoneInput,
    CreditInformationSendContract,
    // AddressAndCreditInformationSendContract,
    AddressWidgetSendContract,
    SupplyNumber,
    // AutoComplete
  },
  data: () => ({
    showEditCompanyInfoModal: false,
    selectedEditAddressId: null,
    deleteEditAddressId: null,

    showCreditInfo: false,
    validCompany: true,
    menu: false,
    paymentMethodRules: [
      (value) => !!value || "Please Select a Payment Method",
    ],
    upfrontPaymentAmountRules: [
      (value) =>
        (value !== null && value !== undefined) ||
        "Please Enter Upfront Payment Amount",
    ],

    greenEnergyIdRules: [(value) => !!value || "Please provide a Product"],

    sortCodeRules: [
      (value) => {
        if (value?.length > 5) return true
        return "Sort code must be at least 6 digits."
      },
    ],
    bankNameRules: [(value) => !!value || "Please Provide Bank Name"],
    acountNumberRules: [
      (value) => {
        if (value?.length > 7) return true
        return "Account Number must be at least 8 digits."
      },
    ],
    accountNameRules: [(value) => !!value || "Please Provide Account Name"],
    dirtOfBirthError: false,
    alreadyExistingNumber: null,
    valid: true,
    significantPersonContactIdRules: [
      (value) => !!value || "Please Select a Significant Person",
    ],

    selectedAgreedByRules: [(value) => !!value || "Please Select Agreed By"],
    businessTypeText: null,
    upfrontInputAmount: null,
    upfrontPaymentChanged: false,
    submittedCompanyInfo: false,
    loadingCompanyInfo: false,
    selectedContactContractAgreedBy: {},
    loadingContractAgreedBy: false,
    loadingSignificantPerson: false,
    showAddNewAddress: false,
    showAddAddressBar: true,
    deleteAddressId: 0,

    phoneNoError: "",

    emailAddressAgreedBy: "",
    numberAlreadyExistError: "",
    isEmailAgreedBy: null,
    telephone: "",
    submittedSendContract: false,
    unFormattedDate: "",
    formattedDate: "",
    companyDetails: {},
    estimate: false,

    editClicked: 0,
    selectedHomeAddress: "",
    isEmail: null,
    selectedAddressId: null,

    dbSelectedSignificantPerson: {},

    selectedSignificantPerson: {},
    subBrokerId: 0,
    subBrokerSignable: {},
    natureOfBusinessId: null,
    // path: {
    //   close: mdiClose,
    //   officeBuilding: mdiOfficeBuilding,
    //   home: mdiHome,
    //   calendar: mdiCalendar,
    //   pound: mdiPound,
    //   domain: mdiDomain,

    //   phone: mdiPhone,

    //   sickle: mdiHammerSickle,
    //   account: mdiAccountBox,
    //   buildingMarker: mdiOfficeBuildingMarker
    // },
    meterId: null,
    supplierId: null,
    meterTypeId: null,
    supplierName: null,
    contractWidgets: null,
    cclValue: 0.00775,
    widgetVal: "",
    cotDateText: null,
    greenEnergyList: [],
    selectedGreenEnergy: null,
    inCorrectPostcodeSelected: false,
    showPostCode: false,
    addresses: [],
    postcodeSelected: "Select Address",

    payment: {
      accountName: null,
      accountNumber: null,
      addressId: null,
      bankName: null,
      id: 0,
      sortCode: null,
      type: null,
      typeId: null,
    },

    termInMonths: 1,
    isDobTextChanged: false,
    dob: null,
    menuDOB: false,

    dobText: null,

    contactNumber: null,
    isSendingRequest: false,
    formHasErrors: false,
    errorMsg: "",
    errorMsgCC: "",
    showMessage: false,
    tab: null,
    ePriceReviewDate: null,
    ePriceReviewNoticeDate: null,
    csdDateText: null,
    gPriceReviewDate: null,
    dateOfBirth: null,
    gPriceReviewNoticeDate: null,
    priceQuote: {},
    meterSelected: {},
    companyForm: {
      natureOfBusinessId: null,
    },
    primaryAddressTemp: {},
    sendContractForm: {
      greenEnergy: null,
      typeId: null,
      selectedAgreedBy: null,
      significantPersonContactId: null,
      bussinessType: null,
    },
    submittedPaymentDetails: false,
    paymentForm: {
      bankName: null,
      sortCode: null,
      accountName: null,
      accountNumber: null,
    },
    loadingPaymentMethod: false,
    form: {
      billingName: null,
      billingAddress: null,
      billingTowncountry: null,
      billingPostCode: null,
      eProductCode: null,
      ePriceReviewDate: null,
      ePriceReviewNoticeDate: null,
      cotDateText: null,
      csdDateText: null,
      cot: null,
      csd: null,
      eStandingCharge: null,
      unitRate: null,
      unitRateDay: null,
      unitRateNight: null,
      unitRateEvening: null,
      unitRateWeekend: null,
      upfrontPaymentAmount: 0,
      kva: null,
      greenEnergy: null,
      eMeterSerailNumber: null,
      eMeterType: null,
      EAC: null,
      gProductCode: null,
      gStandingCharge: null,
      gUnitRate: null,
      gMeterSerailNumber: null,
      gTariffType: null,
      AQ: null,
      totalEstCommissionCost: null,
      isLoa: false,
      currentSuppElec: null,
      currentSupGas: null,
      fixedTermElecEndDate: null,
      fixedTermGasEndDate: null,
      expectedYearlySpend: null,
      elecStandardPayment: null,
      gasStandardPayment: null,
      selectedMeterTypeList: null,
      valueNotSelected: true,
    },
  }),
  directives: { mask },

  mounted() {
    this.onSendContract()
  },
  watch: {
    showSendContractModal(newVal, oldVal) {
      console.log("showSendContractModal", this.showSendContractModal)
      if (newVal !== oldVal && newVal) {
        this.onSendContract()
      }
    },
    selectedSignificantPerson: {
      handler(newVal) {
        if (newVal.contactNumber !== this.alreadyExistingNumber) {
          this.errorMsgCC = ""
        }
      },
      deep: true,
    },
    "sendContractForm.selectedAgreedBy"(newVal) {
      const contact = this.contacts.find(
        (contact) => contact.contactId === newVal
      )
      this.selectedContactContractAgreedBy = { ...contact }
      this.telephone = contact.contactNumber
      this.emailAddressAgreedBy = contact.emailAddress
    },
    "payment.typeId"(newVal) {
      this.sendContractForm.typeId = newVal
    },
    "payment.bankName"(newVal) {
      this.paymentForm.bankName = newVal
    },
    "payment.sortCode"(newVal) {
      this.paymentForm.sortCode = newVal
    },
    "payment.accountName"(newVal) {
      this.paymentForm.accountName = newVal
    },
    "payment.accountNumber"(newVal) {
      this.paymentForm.accountNumber = newVal
    },
    selectedGreenEnergy(newVal) {
      if (newVal) {
        this.sendContractForm.greenEnergy = newVal
      }
    },

    meterTypeId() {
      this.fetchBesTabTitles()
    },
    supplierId() {
      this.fetchBesTabTitles()
    },
    contactId: function (newValue, oldValue) {
      if (newValue !== oldValue) {
        console.log("in contactId")
        this.fetchContactAddresses(newValue)
      }
    },
    // modalOpened(newVal, oldVal) {
    //   if(newVal !== oldVal && newVal === true) {
    //     this.fetchContactAddresses()
    //   }
    // },
    tab() {
      //   console.log(val)
    },
    selectedAddress(val) {
      console.log(val)
      this.cotDateText =
        this.selectedAddress.cotDate === 0
          ? ""
          : dayjs(this.selectedAddress.cotDate * 1000).format("DD/MM/YYYY")
    },

    paymentInfo: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.payment.accountName = this.paymentInfo.accountName
          this.payment.accountNumber = this.paymentInfo.accountNumber
          this.payment.addressId = this.paymentInfo.addressId
          this.payment.bankName = this.paymentInfo.bankName
          this.payment.id = this.paymentInfo.id
          this.payment.sortCode = this.paymentInfo.sortCode
          this.payment.type = this.paymentInfo.type
          // this.payment.typeId = this.paymentInfo.typeId;
        }
      },
      deep: true,
    },
    eFormDetails: {
      handler(newVal, oldVal) {
        console.log(oldVal)
        if (newVal.saleType) {
          this.fetchBesTabTitles()
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters("employee", ["userId"]),
    ...mapGetters("meters", [
      "meters",
      "quoteForSendContract",
      "quoteTypePriceList",
    ]),

    ...mapGetters("contacts", [
      "contacts",
      "contactAddressItems",
      "selectedContact",
      "contactAddresses",
      "primaryContact",
    ]),
    // ...mapGetters("meters", ["selectedMeter"]),
    ...mapGetters("company", ["details", "mobileView"]),

    ...mapGetters("forms", ["eFormDetails", "titleList", "paymentTypesList"]),
    ...mapGetters("addresses", [
      "selectedAddress",
      "paymentInfo",
      "addressItems",
      "primaryAddress",
    ]),
    ...mapGetters("forms", [
      "natureOfBusinessList",
      "besNatureOfBusinessList",
      "tradingTypeList",
      "supplierList",
      "contractTypesList",
    ]),
    totalEacValue() {
      return this.quoteForSendContract && this.quoteForSendContract.totalEac
    },

    contactNumberRules() {
      return [
        (v) => {
          if (!v) return true // Skip validation if empty
          const validate = /^(00|034|084|087|04|05|06|09)/.test(v)
          return (
            !validate ||
            "Numbers Starting With '00, 034, 084, 087, 04, 05, 06, 09' Are Not Allowed"
          )
        },
        // v => !!v || "Field is required", // You can remove or comment this out if an empty field is allowed.
        (v) => {
          if (!v) return true // Skip validation if empty
          return v.startsWith(0) || "Phone Number must start with 0."
        },
        (v) => {
          if (!v) return true // Skip validation if empty
          return (
            (v && v.length > 9) || "Phone Number must have at least 10 digits."
          )
        },
        // You can add or remove more rules as needed
      ]
    },

    natureOfBusinessTypesOptions() {
      console.log("natureOfBusinessTypesOptions", this.natureOfBusinessList)
      const options = this.natureOfBusinessList.map((x) => ({
        value: x.id,
        text: x.value,
      }))
      return options
    },
    modelValue() {
      const matchedObject = this.natureOfBusinessTypesOptions.find(
        (obj) => obj.value === this.companyForm?.natureOfBusinessId
      )
      return matchedObject && matchedObject.text
    },
    showRegistrationNoEdit() {
      const tradinTypeValue = this.companyForm?.tradingTypeId
      return (
        tradinTypeValue !== 6 &&
        tradinTypeValue !== 8 &&
        tradinTypeValue !== null
      )
    },
    showRegistrationNo() {
      const tradinTypeValue = this.companyDetails?.tradingTypeId
      return (
        tradinTypeValue !== 6 &&
        tradinTypeValue !== 8 &&
        tradinTypeValue !== null
      )
    },
    tradingTypesOptions() {
      const options = this.tradingTypeList.map((x) => ({
        value: x.id,
        text: x.value,
      }))
      return options
    },
    quoteTypePriceListIds() {
      return this.quoteTypePriceList.map((x) => {
        return x.quoteTypePriceId
      })
    },

    selectedItemErrors() {
      return []
    },
    natureOfBusinessIdRules() {
      if (this.valueNotSelected) {
        return [(value) => !value || "Please Provide Business Type"]
      } else {
        return [(value) => !!value || "Please Provide Business Type"]
      }
    },
    paymentList() {
      // if (this.contractType === 0) {
      return this.paymentTypesList
      // } else {
      //   return this.renewalPaymentTypeList;
      // }
    },
    eac() {
      let tempEAC = 0
      const selectItem = this.besNatureOfBusinessList.find(
        (item) => item.id === this.natureOfBusinessId
      )
      if (selectItem && selectItem.path) {
        tempEAC = parseFloat(selectItem.path.replaceAll(",", ""))
      }
      return tempEAC
    },
    significantPersonsList() {
      let personList = this.contacts
      if (this.contacts) {
        personList = this.contacts.map((contact) => ({
          value: contact.contactId,
          text: contact.firstName + " " + contact.lastName,
        }))
      }
      return personList
    },
    // upfrontPaymentAmount() {
    //   let upfrontPayment = 0

    //   if (
    //     this.totalEacValue &&
    //     this.totalEacValue != 0.0 &&
    //     this.quoteTypePriceList &&
    //     this.quoteTypePriceList.length > 0
    //   ) {
    //     if (
    //       (parseFloat(this.totalEacValue) > 12000 && this.meterTypeId === 9) ||
    //       (parseFloat(this.totalEacValue) > 52764 && this.meterTypeId === 10)
    //     ) {
    //       upfrontPayment =
    //         (((this.quoteTypePriceList[0].baseRate / 100) * 365 +
    //           (this.quoteTypePriceList[1].baseRate / 100) *
    //             this.totalEacValue) *
    //           (1 + this.cclValue) *
    //           1.2 *
    //           0.75) /
    //         12
    //     } else {
    //       upfrontPayment =
    //         (((this.quoteTypePriceList[0].baseRate / 100) * 365 +
    //           (this.quoteTypePriceList[1].baseRate / 100) *
    //             this.totalEacValue) *
    //           1.05 *
    //           0.75) /
    //         12
    //     }
    //   }
    //   if (this.quoteForSendContract.supplierID === 9) {
    //     return "0.00"
    //   } else if (!upfrontPayment) {
    //     return ""
    //   } else if (
    //     this.upfrontInputAmount !== null &&
    //     this.upfrontPaymentChanged
    //   ) {
    //     this.changeUpfrontPaymentFalse()
    //     return this.upfrontInputAmount
    //   } else {
    //     return this.formatPriceWithoutPound(upfrontPayment)
    //   }
    // },

    greenEnergyListOptions() {
      const options = this.greenEnergyList.map((x) => ({
        value: x.id,
        text: x.value,
      }))
      return options
    },
    modelValueForGreenEnergy() {
      let matchedObject
      if (this.greenEnergyList && this.greenEnergyList.length > 0) {
        matchedObject = this.greenEnergyListOptions.find(
          (obj) => obj.value === this.selectedGreenEnergy
        )
        return matchedObject.text
      }
      return null
    },
    natureOfBusinessesListForBES() {
      const dataList = []
      if (
        this.besNatureOfBusinessList &&
        this.besNatureOfBusinessList.length > 0
      ) {
        this.besNatureOfBusinessList.forEach((item) => {
          dataList.push({
            id: item.id,
            value: item.valueDescription,
          })
        })
      }
      return dataList
    },
    formattedMaxDateOfBirth() {
      const maxDate = dayjs()
        .subtract(18, "year")
        .add(1, "day")
        .format("YYYY-MM-DD")
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dobText = dayjs(this.dob).format("DD/MM/YYYY")
      return dayjs(maxDate).toISOString()
    },
    termInMonthsRules() {
      return [
        (value) => !!value || "Please provide the term",
        (value) => !!value <= 60 || "Term must not be greater than 60",
        // value => !!((value * 30) <= this.window) || `Term must be less than ${this.window} days`
      ]
    },
    registrationNoRules() {
      return [
        (value) => !!value || "Please Provide Correct Registration Number",
      ]
    },
    dobTextRules() {
      return [
        (v) => {
          const maxDate = dayjs().subtract(18, "year").format("YYYY-MM-DD")
          const dobText = v ? v.split("/").reverse().join("-") : ""
          const currentDOB = dayjs(dobText).format("YYYY-MM-DD")
          const diff = dayjs(currentDOB).diff(maxDate, "day")
          let validate = true
          if (diff >= 1 && v.length >= 10) {
            validate = false
          }

          return !!validate || "Must be at least 18 years old"
        },
      ]
    },
    setCedDate() {
      return dayjs(this.eFormDetails.ced * 1000).format("DD/MM/YYYY")
    },
    setCsdDate() {
      return dayjs(this.eFormDetails.csd * 1000).format("DD/MM/YYYY")
    },
    setBirthDate() {
      return dayjs(this.eFormDetails.dateOfBirth * 1000).format("DD/MM/YYYY")
    },
    ePriceFormattedDate() {
      if (
        this.eFormDetails.ePriceReviewDate === "" &&
        this.eFormDetails.ePriceReviewDate === 0
      ) {
        return ""
      }

      return this.eFormDetails.ePriceReviewDate
        ? dayjs(this.eFormDetails.ePriceReviewDate).format("DD/MM/YYYY")
        : ""
    },
    ePriceReviewFormattedDate() {
      if (
        this.eFormDetails.ePriceReviewNoticeDate === "" &&
        this.eFormDetails.ePriceReviewNoticeDate === 0
      ) {
        return ""
      }

      return this.eFormDetails.ePriceReviewNoticeDate
        ? dayjs(this.eFormDetails.ePriceReviewNoticeDate).format("DD/MM/YYYY")
        : ""
    },
    gPriceFormattedDate() {
      if (
        this.eFormDetails.gPriceReviewDate === "" &&
        this.eFormDetails.gPriceReviewDate === 0
      ) {
        return ""
      }

      return this.eFormDetails.gPriceReviewDate
        ? dayjs(this.eFormDetails.gPriceReviewDate).format("DD/MM/YYYY")
        : ""
    },
    dobFormattedDate() {
      if (
        this.eFormDetails.dateOfBirth === "" &&
        this.eFormDetails.dateOfBirth === 0
      ) {
        return ""
      }

      return this.eFormDetails.dateOfBirth
        ? dayjs(this.eFormDetails.dateOfBirth).format("DD/MM/YYYY")
        : ""
    },
    gPriceReviewFormattedDate() {
      if (
        this.eFormDetails.gPriceReviewNoticeDate === "" &&
        this.eFormDetails.gPriceReviewNoticeDate === 0
      ) {
        return ""
      }

      return this.eFormDetails.gPriceReviewNoticeDate
        ? dayjs(this.eFormDetails.gPriceReviewNoticeDate).format("DD/MM/YYYY")
        : ""
    },

    nameValidation() {
      return [(v) => /[a-zA-Z]+$/.test(v) || "Field must only contain letters"]
    },
    surnameValidation() {
      return [(v) => /[a-zA-Z]+$/.test(v) || "Field must only contain letters"]
    },
    jobTitleValidation() {
      return [(v) => /[a-zA-Z]+$/.test(v) || "Field must only contain letters"]
    },
    quoteTypesOnly() {
      return this.eFormDetails && this.eFormDetails.quoteTypePriceList
        ? this.eFormDetails.quoteTypePriceList.filter(
            (x) => x.quoteTypePriceId !== 1
          )
        : []
    },
  },

  methods: {
    ...mapActions("contacts", ["fetchContactAddresses", "fetchContacts"]),
    ...mapMutations("contacts", {
      selectContact: "setSelectedContact",
      setContactAddresses: "setContactAddresses",
    }),
    ...mapActions("forms", [
      "fetchBESNatureOfBusiness",
      "fetchTradingTypes",
      "postEFormDetails",

      "fetchNatureOfBusiness",
    ]),

    ...mapActions("addresses", [
      "fetchPaymentInfo",
      "fetchPaymentTypes",
      "fetchAddresses",
      "fetchAddressesByPostcode",
    ]),
    ...mapActions("company", ["fetchCompanyDetails"]),
    handlePaste(event) {
      let pasteContent = (event.clipboardData || window.clipboardData).getData(
        "text"
      )
      pasteContent = pasteContent.replace(/\s/g, "") // Remove spaces from pasted content

      if (!/^\d+$/.test(pasteContent)) {
        event.preventDefault()
      } else {
        // Set the cleaned content to the input
        event.preventDefault()
        this.payment.sortCode = pasteContent
      }
    },
    handlePasteAccountNumber(event) {
      let pasteContent = (event.clipboardData || window.clipboardData).getData(
        "text"
      )
      pasteContent = pasteContent.replace(/\s/g, "") // Remove spaces from pasted content

      if (!/^\d+$/.test(pasteContent)) {
        event.preventDefault()
      } else {
        // Set the cleaned content to the input
        event.preventDefault()
        this.payment.accountNumber = pasteContent
      }
    },
    onSendContract() {
      this.companyDetails = {
        ...this.details,
        add1: this.selectedAddress.add1,
        add2: this.selectedAddress.add2,
        town: this.selectedAddress.town,
        county: this.selectedAddress.county,
        postcode: this.selectedAddress.postcode,
      }

      this.fetchNatureOfBusiness()
      this.fetchTradingTypes()
      this.fetchSubBrokerSignable()
      this.fetchCCLValueForMeter()
      this.fetchBesTabTitles()
      this.onModalShown()
    },
    changeToUpperCase() {
      this.companyForm.registrationNo =
        this.companyForm.registrationNo.toUpperCase()
    },
    selectedAddressEditIdChanged(value) {
      this.contactAddressItems.addressId = value
      this.selectedEditAddressId = value
    },
    editAddressClicked(toggleValue, id) {
      this.showCreditInfo = true
      // if (this.lastOne && toggleValue) {
      //   this.$emit("lastOneEditShowClicked", false)
      // } else if (this.lastOne && !toggleValue) {
      //   this.$emit("lastOneEditShowClicked", true)
      // }
      this.selectedEditAddressId = id
    },
    deleteAddressClicked(toggleValue, id) {
      this.showCreditInfo = false

      // if (this.lastOne && toggleValue) {
      //   this.$emit("lastOneDeleteClicked", false)
      // } else if (this.lastOne && !toggleValue) {
      //   this.$emit("lastOneDeleteClicked", true)
      // }
      this.deleteEditAddressId = id
    },
    closeModal() {
      this.$emit("closeSendContractModal")
    },
    onChangeContact(id) {
      console.log(id, "id")
      this.selectContact(id)
    },
    saveCotDate(date) {
      this.$refs.menu.save(date)
    },
    lastOneEditShowClicked(value) {
      this.showAddAddressBar = value
    },
    changeUpfrontPaymentFalse() {
      this.upfrontPaymentChanged = false
    },
    changeUpfrontInputAmount(value) {
      console.log("value", value)
      this.upfrontPaymentChanged = true
      this.upfrontInputAmount = value
    },
    lastOneDeleteClicked(value) {
      console.log("lastOneDeleteClicked")
      this.showAddAddressBar = value
      this.deleteAddressId = this.selectedAddressId
    },
    isValidEmailAgreedBy() {
      this.isEmailAgreedBy = /^[^@]+@\w+(\.\w+)+\w$/.test(
        this.emailAddressAgreedBy
      )
      return this.isEmailAgreedBy
    },
    removeWhiteSpacesTelephone() {
      this.numberAlreadyExistError = ""
      const content = this.telephone
      this.telephone = content.replace(/\s/g, "")
    },
    isNumberKeyTelephone(evt) {
      if (this.telephone && this.telephone.length >= 11) {
        evt.returnValue = false
        return false
      }

      const charCode = evt.which ? evt.which : evt.keyCode
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        event.returnValue = false
        return false
      } else {
        event.returnValue = true
        return true
      }
    },

    openEditCompanyInfoModal() {
      if (
        this.selectedAddress &&
        Object.keys(this.selectedAddress).length > 0 &&
        this.companyForm &&
        Object.keys(this.companyForm).length === 1
      ) {
        this.companyForm = {
          ...this.selectedAddress,
          add1: this.selectedAddress.add1.trim(),
          add2: this.selectedAddress.add2.trim(),
          town: this.selectedAddress.town.trim(),
          county: this.selectedAddress.county.trim(),
        }

        this.companyForm.cotDate = dayjs(
          this.selectedAddress.cotDate * 1000
        ).format("YYYY-MM-DD")
        this.formattedDate = dayjs(this.selectedAddress.cotDate * 1000).format(
          "DD/MM/YYYY"
        )
      }

      this.companyForm = {
        ...this.companyForm,
        registrationNo: this.companyDetails.registrationNo,
        tradingTypeId: this.companyDetails.tradingTypeId,
        natureOfBusinessId: this.companyDetails.natureOfBusinessId,
        companyName: this.companyDetails.companyName,
        telephone: this.companyDetails.telephone,
      }

      this.showEditCompanyInfoModal = true
    },

    async fetchPostCodeAddresses() {
      const response = await this.fetchAddressesByPostcode(
        this.companyForm.postcode
      )
      if (response.status === 200) {
        this.inCorrectPostcodeSelected = false
        this.showPostCode = true
        this.addresses = response.addresses
      } else {
        this.inCorrectPostcodeSelected = true
        this.showPostCode = false
      }
    },
    firstCapitalLetter(str) {
      this.companyForm[str] = this.companyForm[str]
        .toLowerCase()
        .split(" ")
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(" ")
    },
    capitalize() {
      this.companyForm.postcode = this.companyForm.postcode.toUpperCase()
    },
    addressSelected() {
      const splitPostCode = this.postcodeSelected.split(",")
      if (this.postcodeSelected !== "Select your address") {
        this.companyForm.siteName = splitPostCode[0].trim()
        this.companyForm.add1 = splitPostCode[0].trim()
        this.companyForm.add2 = splitPostCode[1].trim()
        this.companyForm.town = splitPostCode[2].trim()
        splitPostCode.length - 1 === 3
          ? (this.companyForm.county = splitPostCode[3].trim())
          : (this.companyForm.county = "")
      }
    },
    async saveCompanyChanges() {
      this.submittedCompanyInfo = true
      //   this.$v.companyForm.$touch()

      //   if (this.$v.companyForm.$invalid) {
      //     return false
      //   }
      const validated = this.$refs.companyForm.validate()
      console.log(this.$refs.companyForm.validate(), "validate")
      if (!validated) {
        return
      }

      this.companyDetails.registrationNo = this.companyForm.registrationNo
      if (this.companyForm.tradingType) {
        this.companyDetails.tradingType = this.companyForm.tradingType
      }
      if (this.companyForm.businessType) {
        this.companyDetails.businessType = this.companyForm.businessType
      }
      this.companyDetails.tradingTypeId = this.companyForm.tradingTypeId
      this.companyDetails.natureOfBusinessId =
        this.companyForm.natureOfBusinessId
      this.companyDetails.companyName = this.companyForm.companyName
      this.companyDetails.telephone = this.companyForm.telephone
      this.cotDateText = this.formattedDate
      this.companyDetails.add1 = this.companyForm.add1
      this.companyDetails.add2 = this.companyForm.add2
      this.companyDetails.town = this.companyForm.town
      this.companyDetails.county = this.companyForm.county
      this.companyDetails.postcode = this.companyForm.postcode

      const data = {
        tradingType: this.companyForm.tradingType
          ? this.companyForm.tradingType
          : this.companyDetails.tradingType,
        tradingTypeId: this.companyForm.tradingTypeId,
        companyName: this.companyForm.companyName,
        natureOfBusinessId: this.companyForm.natureOfBusinessId,
        registrationNo: this.companyForm.registrationNo,
        telephone: this.companyForm.telephone,
        userId: this.userId,
        companyId: this.details.companyId,
        primaryAddress: this.primaryAddress
          ? this.primaryAddress.addressId
          : "",
        primaryContact: this.primaryContact
          ? this.primaryContact.contactId
          : "",
        website: this.details.website,
      }
      console.log(data, "")
      this.loadingCompanyInfo = true
      try {
        const res = await api.post(`updateCompany`, data)
        if (res && res.status === 200) {
          this.fetchCompanyDetails(this.details.companyId)
          await this.updateAddress()
          changesSaved()
        }
      } catch (e) {
        somethingWentWrong()
      } finally {
        this.loadingCompanyInfo = false
      }
      // const address = this.addresses.find(
      //   (address) => address.addressId === this.companyForm.primaryAddress
      // );
      // this.companyDetails.add1 = address.add1;
      // this.companyDetails.add2 = address.add2;
      // this.companyDetails.town = address.town;
      // this.companyDetails.county = address.county;
      // this.companyDetails.postcode = address.postcode;

      this.showEditCompanyInfoModal = false
    },
    async updateAddress() {
      const cotDate = this.companyForm.cotDate.split("/").reverse().join("-")
      const dateToPass = dayjs(cotDate).format("YYYY-MM-DD")
      const dateInUnixForm = dayjs(dateToPass).unix()
      const data = {
        ...this.selectedAddress,
        add1: this.companyForm.add1,
        add2: this.companyForm.add2,
        addressId: this.companyForm.addressId,
        addressTypeId: this.companyForm.addressTypeId,
        companyId: this.details.companyId,
        cotDate: dateInUnixForm,
        county: this.companyForm.county,
        currentUserId: this.userId,
        postcode: this.companyForm.postcode,
        siteName: this.companyForm.companyName,
        town: this.companyForm.town,
      }

      const res = await api.post(`updateAddress`, data)

      if (res && res.status === 200) {
        this.fetchAddresses(this.details.companyId)
      } else {
        somethingWentWrong()
      }
    },
    setNatureOfBusinessType(result) {
      console.log(result, "result")
      const businessType = this.natureOfBusinessTypesOptions.find(
        (option) => option.value === this.companyForm.natureOfBusinessId
      )

      this.companyForm.businessType = businessType.text
    },
    NoLettersAccepted(evt) {
      const charCode = evt.which ? evt.which : evt.keyCode
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        event.returnValue = false
        return false
      } else {
        if (
          this.companyForm.registrationNo &&
          this.companyForm.registrationNo.length >= 6
        ) {
          event.returnValue = false
          return false
        }
        event.returnValue = true
        return true
      }
    },
    tradingTypeChanged() {
      const tradingType = this.tradingTypesOptions.find(
        (option) => option.value === this.companyForm.tradingTypeId
      )
      this.companyForm.tradingType = tradingType.text
    },
    companyNameCapitalLetter() {
      this.companyForm.companyName = this.companyForm.companyName
        .toLowerCase()
        .split(" ")
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(" ")
    },
    selectedAddressIdChanged(value) {
      this.selectedAddressId = value
    },

    selectedHomeAddressChanged(value) {
      this.selectedHomeAddress = value
    },
    async onChange(contactId) {
      const filterContact = this.contacts.filter(
        (contact) => contact.contactId === contactId
      )
      this.dbSelectedSignificantPerson = { ...filterContact[0] }
      this.selectedSignificantPerson = filterContact[0]
      const tempDob = filterContact[0].dob
      await this.fetchContactAddresses(this.selectedSignificantPerson.contactId)
      if (tempDob !== undefined) {
        this.dob =
          tempDob !== 0 ? dayjs(tempDob * 1000).format("YYYY-MM-DD") : ""
        if (this.dob === "") {
          this.dob = dayjs().subtract(18, "year").format("YYYY-MM-DD")
          this.dobText = dayjs().subtract(18, "year").format("DD/MM/YYYY")
        }
        if (tempDob) {
          this.dobText = dayjs(this.dob).format("DD/MM/YYYY")
          if (this.dobText === "Invalid Date") {
            this.dobText = ""
          }
        } else {
          this.dobText = ""
          if (this.dob) {
            this.dobText =
              tempDob !== 0 ? dayjs(tempDob * 1000).format("DD/MM/YYYY") : ""
          }
        }
      }
    },
    onMountedCalled() {
      // reset modal
      this.fetchPaymentTypes()

      this.meterId = this.selectedMeter.meterId

      this.addressId = this.selectedMeter.addressId
      this.meterTypeId = this.selectedMeter.meterTypeId
      this.fetchBESNatureOfBusiness(this.selectedMeter.meterTypeId)
      this.supplierName = this.selectedMeter.supplierName
      this.supplierId = this.selectedMeter.supplierId
      this.estimate =
        this.selectedMeter.estimateActual.toLowerCase() === "estimate"

      if (this.paymentInfo) {
        this.payment.accountName = this.paymentInfo.accountName
        this.payment.accountNumber = this.paymentInfo.accountNumber
        this.payment.addressId = this.paymentInfo.addressId
        this.payment.bankName = this.paymentInfo.bankName
        this.payment.id = this.paymentInfo.id
        this.payment.sortCode = this.paymentInfo.sortCode
        this.payment.type = this.paymentInfo.type
        // this.payment.typeId = this.paymentInfo.typeId;
      } else {
        this.payment.addressId = this.addressId
      }
      if (
        this.selectedSignificantPerson &&
        this.selectedSignificantPerson.contactId
      ) {
        this.fetchContactAddresses(this.selectedSignificantPerson.contactId)
      }
    },
    setGreenEnergyID(result) {
      console.log("result setNatureOfBusinessID", result)
      if (!result) {
        this.selectedGreenEnergy = null
      }
    },

    setGreenEnergyType(result) {
      this.selectedGreenEnergy = result.value
    },
    setBusinessID(result) {
      console.log("result setNatureOfBusinessID", result)
      if (!result) {
        this.eFormDetails.besBusinessType = null
        this.natureOfBusinessId = null
      }
    },

    setBusinessType(id) {
      // this.businessTypeText = result.text;
      this.eFormDetails.besBusinessType = id
      // this.natureOfBusinessId = id;
      this.sendContractForm.bussinessType = id
    },
    onModalShown() {
      console.log("onmodal shown called")
      this.postEFormDetails({})
      this.upfrontPaymentAmount = 0
      this.setContactAddresses([])
      this.besOnClickMountedCalled()
      this.onMountedCalled()
    },
    besOnClickMountedCalled() {
      this.cotDateText =
        this.selectedAddress.cotDate === 0
          ? ""
          : dayjs(this.selectedAddress.cotDate * 1000).format("DD/MM/YYYY")
      const filterMeter = this.meters.filter(
        (meter) => meter.meterId === this.meterId
      )
      this.meterSelected = filterMeter[0]
      console.log(this.meterSelected, "selcted meter bes")
      // this.setCsdDate()
      // this.setCedDate()
      this.fetchBesTabTitles()

      this.fetchGreenEnergyResources()
      if (this.eFormDetails.quoteTypePriceList) {
        this.initialSetup()
      }

      this.validateBESBusinessType()
    },
    async fetchCCLValueForMeter() {
      const response = await api.get(`getCCLCharge`)
      if (response.status === 200) {
        const filteredItem = response.data.find(
          (item) => parseInt(item.path) === this.meterTypeId
        )
        this.cclValue = parseFloat(filteredItem.valueDescription)
      }
    },
    async fetchBrokerId() {
      const res = await api.get(`getSubBrokerdetails/${this.userId}`)

      if (res && res.status === 200) {
        this.subBrokerId = res.data.subBrokerID
      }
    },
    async fetchSubBrokerSignable() {
      await this.fetchBrokerId()
      const response = await api.get(`getSubrokerSignable/${this.subBrokerId}`)
      console.log(response.data, "subbroker signable")
      this.subBrokerSignable = response.data
    },
    async fetchBesTabTitles() {
      // if (this.eFormDetails.saleType && this.meterTypeId && this.supplierId) {
      const response = await api.get(
        `getSignableContractWidget/1/${this.meterTypeId}/${this.quoteForSendContract.supplierID}`
      )
      this.contractWidgets = response.data
      console.log(
        response.data,
        "data",
        this.contractWidgets,
        this.contractWidgets[0],
        this.contractWidgets[1]
      )
      // }
    },
    formatPrice(value) {
      var val = (value / 1).toFixed(2)
      return "£" + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    formatPriceWithoutPound(value) {
      var val = (value / 1).toFixed(2)
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    validateBESBusinessType() {
      setTimeout(() => {
        const form = {
          besBusinessType: this.natureOfBusinessId,
          upfrontPaymentAmount: this.upfrontPaymentAmount,
        }
        this.formHasErrors = false

        Object.keys(form).forEach((f) => {
          if (!this.form[f]) this.formHasErrors = true

          this.$refs[f].validate(true)
        })
      }, 3000)
    },
    async fetchGreenEnergyResources() {
      // const response = await api.get(
      //   `/getBESContractGreenEnergyTypes`
      // );
      const response = await api.get(`getBESContractGreenEnergyTypes`)

      this.greenEnergyList = response.data
      const tempGreenEnergy = this.greenEnergyList[1]
      this.selectedGreenEnergy = tempGreenEnergy.id
    },

    bankNameChanged() {
      this.payment.bankName = this.payment.bankName
        .toLowerCase()
        .split(" ")
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(" ")
    },
    accountNameChanged() {
      this.payment.accountName = this.payment.accountName
        .toLowerCase()
        .split(" ")
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(" ")
    },

    isSortCodeNumberKey(evt) {
      const charCode = evt.which ? evt.which : evt.keyCode
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        event.returnValue = false
        return false
      } else {
        if (
          this.payment &&
          this.payment.sortCode &&
          this.payment.sortCode.length >= 6
        ) {
          event.returnValue = false
          return false
        }
        event.returnValue = true
        return true
      }
    },
    isAccountNoNumberKey(evt) {
      const charCode = evt.which ? evt.which : evt.keyCode
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        event.returnValue = false
        return false
      } else {
        if (
          this.payment &&
          this.payment.accountNumber &&
          this.payment.accountNumber.length >= 8
        ) {
          event.returnValue = false
          return false
        }
        event.returnValue = true
        return true
      }
    },
    saveAndContinue() {
      changesSaved()
    },
    async saveDOBAndContinue() {
      this.dirtOfBirthError = false
      //   this.contacatAddressCountError = false

      //   this.fetchAddressesByContactId(this.selectedSignificantPerson.contactId)

      if (
        this.details.tradingTypeId === 6 ||
        this.details.tradingTypeId === 8
      ) {
        if (
          (this.dob === null || this.dob === "" || this.dobText === "") &&
          !this.isDobTextChanged
        ) {
          this.dirtOfBirthError = true
          return false
        }
        // if (!this.contactAddresses.length) {
        //   this.contacatAddressCountError = true
        //   return false
        // }
      }
      if (
        (this.dob === null || this.dob === "" || this.dobText === "") &&
        !this.isDobTextChanged
      ) {
        this.saveAndContinue()
        return
      }
      const maxDate = dayjs().subtract(18, "year").format("YYYY-MM-DD")
      const dobText = this.dobText.split("/").reverse().join("-")
      const currentDOB = dayjs(dobText).format("YYYY-MM-DD")
      const diff = dayjs(currentDOB).diff(maxDate, "day")
      if (diff >= 1) {
        const form = {
          dobText: this.dobText,
        }
        this.formHasErrors = false

        Object.keys(form).forEach((f) => {
          if (!this.form[f]) this.formHasErrors = true

          this.$refs[f].validate(true)
        })

        return false
      }

      const dobData = {
        contactId: this.selectedSignificantPerson.contactId,
        dob: dayjs(this.dob).unix(),
      }
      try {
        const response = await api.post("updateDob", dobData)
        if (response && response.status === 200) {
          console.log("dob updated")
          this.fetchContacts()
        }
      } catch (error) {
        somethingWentWrong()
      }
    },
    dateOfBirthChanged() {
      this.dobText = dayjs(this.dob).format("DD/MM/YYYY")
    },

    dobTextChanged() {
      this.isDobTextChanged = true
      if (this.dobText && this.dobText.length >= 1) {
        const tempDay = this.dobText.slice(0, 2)
        const tempMonth = this.dobText.slice(3, 5)

        if (parseInt(tempDay) > 31) {
          const temp = this.dobText.slice(2, 10)
          this.dobText = "31" + temp
        }
        if (
          this.dobText &&
          this.dobText.length >= 2 &&
          parseInt(tempDay) === 0
        ) {
          const temp = this.dobText.slice(2, 10)
          this.dobText = "01" + temp
        }
        if (parseInt(tempMonth) > 12) {
          const temp = this.dobText.slice(5, 10)
          this.dobText = tempDay + "/12" + temp
        }
        if (
          this.dobText &&
          this.dobText.length >= 5 &&
          parseInt(tempMonth) === 0
        ) {
          const temp = this.dobText.slice(5, 10)
          this.dobText = tempDay + "/01" + temp
        }
        const dobText = this.dobText.split("/").reverse().join("-")

        const currentDOB = dayjs(dobText).format("YYYY-MM-DD")

        this.dob = currentDOB
        if (this.dob === "Invalid Date") {
          this.dob = ""
        }
        // }
      }
    },
    jobTitleCapitalLetter() {
      this.selectedSignificantPerson.jobTitle =
        this.selectedSignificantPerson.jobTitle
          .toLowerCase()
          .split(" ")
          .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
          .join(" ")
    },
    initialSetup() {
      this.priceQuote = JSON.parse(
        JSON.stringify(this.eFormDetails.quoteTypePriceList)
      )

      this.form.selectedMeterTypeList =
        this.eFormDetails.selectedSupplierMeterTypeID

      this.setTotalYearlySpend()
      this.setBillingdetails()

      this.priceQuote.forEach((el, index) => {
        console.log("el", el.price)
        console.log(typeof el)
        console.log("in", index)
        if (index === 0) {
          this.form.eStandingCharge = el.uplift
          this.form.gStandingCharge = el.uplift
        }
        if (index === 1) {
          this.form.unitRate = el.uplift
          this.form.gUniteRate = el.uplift
          this.form.unitRateDay = el.price
          this.form.totalEstCommissionCost = el.eac
        }
        if (index === 2) {
          this.form.unitRateNight = el.price
        }
      })
    },
    setBillingdetails() {
      //   this.form.billingName = this.eFormDetails.siteName
      this.form.billingAddress =
        this.eFormDetails.add1 || this.eFormDetails.add2
          ? this.eFormDetails.add1 + this.eFormDetails.add2
          : ""
      //   this.form.billingTowncountry =
      //     this.eFormDetails.town + this.eFormDetails.county
      //   this.form.billingPostCode = this.eFormDetails.postCode
    },
    setTotalYearlySpend() {
      const total =
        this.priceQuote.reduce(
          (total, item) => total + Number(item.price),
          0
        ) || 0

      this.form.expectedYearlySpend = total
    },

    calculateQuoteTypePriceList() {
      const rateIdToKeyName = {
        1: "standingCharge",
        2: "unitRate",
        3: "dayRate",
        12: "nightRate",
        4: "weekdayRate",
        6: "eveWeekendRate",
        7: "eveWeekendNightRate",
        8: "winterDayRate",
        9: "allOtherTimesRate",
        10: "kvA", // Assuming kvA is a rate; adjust as needed
        11: "eveningRate",
        5: "weekendRate",
        13: "offPeake", // Ensure correct spelling (offPeake vs offPeak)
      }

      let updatedQuoteTypePriceList = this.quoteTypePriceList.map((price) => ({
        ...price,
      }))

      updatedQuoteTypePriceList.map((price) => {
        const rateKeyName = rateIdToKeyName[price.quoteTypePriceId]

        if (
          rateKeyName &&
          this.quoteForSendContract[rateKeyName] !== undefined
        ) {
          if (rateKeyName === "standingCharge") {
            let standingCharge =
              +this.quoteForSendContract[rateKeyName] +
              +this.quoteForSendContract.standingChargeUplift
            price.baseRate = standingCharge.toString()
            price.price = standingCharge.toString()
          } else {
            let rate
            if (rateKeyName === "kvA") {
              rate = +this.quoteForSendContract[rateKeyName]
            } else {
              rate =
                +this.quoteForSendContract[rateKeyName] +
                +this.quoteForSendContract.unitRateUplift
            }
            price.baseRate = rate.toString()
            price.price = rate.toString()
          }

          return price
        }
      })
      console.log(updatedQuoteTypePriceList, "updated Quote Type price List")
      return updatedQuoteTypePriceList
    },
    getDynamicRates() {
      let rates = []
      if (this.quoteTypePriceListIds.includes(1)) {
        const standingCharge = this.formatPriceRates(
          +this.quoteForSendContract.standingCharge +
            +this.quoteForSendContract.standingChargeUplift
        )
        rates.push({ label: "Standing Charge", value: standingCharge })
      }
      if (this.quoteTypePriceListIds.includes(2)) {
        const unitRate = this.formatPriceRates(
          +this.quoteForSendContract.unitRate +
            +this.quoteForSendContract.unitRateUplift
        )
        rates.push({ label: "Unit Rate", value: unitRate })
      }
      if (this.quoteTypePriceListIds.includes(3)) {
        const dayRate = this.formatPriceRates(
          +this.quoteForSendContract.dayRate +
            +this.quoteForSendContract.unitRateUplift
        )
        rates.push({ label: "Day Rate", value: dayRate })
      }
      if (this.quoteTypePriceListIds.includes(12)) {
        const nightRate = this.formatPriceRates(
          +this.quoteForSendContract.nightRate +
            +this.quoteForSendContract.unitRateUplift
        )
        rates.push({ label: "Night Rate", value: nightRate })
      }
      if (this.quoteTypePriceListIds.includes(4)) {
        const weekdayRate = this.formatPriceRates(
          +this.quoteForSendContract.weekdayRate +
            +this.quoteForSendContract.unitRateUplift
        )
        rates.push({ label: "Weekday Rate", value: weekdayRate })
      }
      if (this.quoteTypePriceListIds.includes(6)) {
        const eveWeekendRate = this.formatPriceRates(
          +this.quoteForSendContract.eveWeekendRate +
            +this.quoteForSendContract.unitRateUplift
        )
        rates.push({ label: "Eve & Weekend Rate", value: eveWeekendRate })
      }
      if (this.quoteTypePriceListIds.includes(7)) {
        const eveWeekendNightRate = this.formatPriceRates(
          +this.quoteForSendContract.eveWeekendNightRate +
            +this.quoteForSendContract.unitRateUplift
        )
        rates.push({
          label: "Eve, Weekend & Night Rate",
          value: eveWeekendNightRate,
        })
      }
      if (this.quoteTypePriceListIds.includes(8)) {
        const winterDayRate = this.formatPriceRates(
          +this.quoteForSendContract.winterDayRate +
            +this.quoteForSendContract.unitRateUplift
        )
        rates.push({ label: "Winter Day Rate", value: winterDayRate })
      }
      if (this.quoteTypePriceListIds.includes(9)) {
        const allOtherTimesRate = this.formatPriceRates(
          +this.quoteForSendContract.allOtherTimesRate +
            +this.quoteForSendContract.unitRateUplift
        )
        rates.push({ label: "All Other Times Rate", value: allOtherTimesRate })
      }
      if (this.quoteTypePriceListIds.includes(10)) {
        const kvA = this.formatPriceRates(+this.quoteForSendContract.kvA)
        rates.push({ label: "KvA", value: kvA })
      }
      if (this.quoteTypePriceListIds.includes(11)) {
        const eveningRate = this.formatPriceRates(
          +this.quoteForSendContract.eveningRate +
            +this.quoteForSendContract.unitRateUplift
        )
        rates.push({ label: "Evening Rate", value: eveningRate })
      }
      if (this.quoteTypePriceListIds.includes(5)) {
        const weekendRate = this.formatPriceRates(
          +this.quoteForSendContract.weekendRate +
            +this.quoteForSendContract.unitRateUplift
        )
        rates.push({ label: "Weekend Rate", value: weekendRate })
      }
      if (this.quoteTypePriceListIds.includes(13)) {
        const offPeak = this.formatPriceRates(
          +this.quoteForSendContract.offPeake +
            +this.quoteForSendContract.unitRateUplift
        )
        rates.push({ label: "Off Peak", value: offPeak })
      }
      return rates
    },
    noteToSend() {
      const contractName =
        this.contractWidgets && this.contractWidgets[this.tab]
          ? this.contractWidgets[this.tab].widgetDescription
          : ""
      const email = this.emailAddressAgreedBy || ""

      // Get dynamic rates
      const ratesArray = this.getDynamicRates()
      const ratesString = ratesArray
        .map((rate) => `${rate.label}: ${rate.value}`)
        .join(" ")

      const tariff = this.quoteForSendContract.tariffCode || ""
      const term = `${this.quoteForSendContract.term} Months`
      const contractType =
        this.selectedMeter.supplierId === this.quoteForSendContract.supplierID
          ? "Renewal"
          : "Acquisition"
      const contractStartDate = this.quoteForSendContract.csdText || ""
      const eac = this.totalEacValue || ""
      const totalCommission = this.quoteForSendContract.fixedCommAmount || ""

      // Construct the final string with dynamic rates
      return `${contractName} contract sent to\n${email} - Rates : ${ratesString}\nTariff : ${tariff}\nTerm : ${term}\nContract Type : ${contractType}\nContract Start Date : ${contractStartDate}\nEAC : ${eac}\nTotal Contract Estimated Commission : ${totalCommission}`
    },
    async submitEForm() {
      // if (!this.selectedAgreedBy) {
      //   return false;
      // }
      this.submittedSendContract = true
      const validated = this.$refs.form.validate()

      if (!validated) {
        return
      }
      const updatedQuoteTypePriceList = this.calculateQuoteTypePriceList()
      const sortedAddressItems = this.contactAddresses.sort((r1, r2) =>
        r1.untilDate > r2.untilDate ? 1 : r1.untilDate < r2.untilDate ? -1 : 0
      )
      //   widgetVal = 'CYwYpy5C5T';
      const filteredPayment = this.paymentTypesList.filter(
        (paymentType) => paymentType.value === this.payment.typeId
      )
      const estimatedCommission =
        this.quoteForSendContract &&
        parseFloat(this.quoteForSendContract.fixedCommAmount.replace(",", ""))
      const note = this.noteToSend()
      const formData = {
        note: note,
        unitRateUpliftAmount: Number(this.quoteForSendContract.unitRateUplift),
        soldContractValue: estimatedCommission,
        supplierID: this.quoteForSendContract.supplierID,
        employeeId: this.userId,
        saleTypeID: this.quoteForSendContract.saleType,
        meterTypeID: this.meterTypeId,
        fixedCommAmount: estimatedCommission,
        masterContractTypeID: parseInt(
          this.quoteForSendContract.supplierID === 13
        )
          ? 0
          : 2,
        soldSupplierId: parseInt(this.quoteForSendContract.supplierID),
        addressId: this.addressId ? this.addressId : 0,
        customerEmail: this.selectedSignificantPerson.emailAddress,
        significantId: this.sendContractForm.significantPersonContactId,
        agreedContractId: this.sendContractForm.selectedAgreedBy,
        meterId: this.meterId,
        tariffCode: this.quoteForSendContract.tariffCode,
        term: parseInt(this.quoteForSendContract.term),
        masterContractType:
          parseInt(this.quoteForSendContract.supplierID) === 13 ? 0 : 2,
        csd: dayjs(this.quoteForSendContract.csd).unix(),
        eac: this.totalEacValue,
        companyId: this.companyDetails.companyId
          ? this.companyDetails.companyId
          : 0,
        businessType:
          this.natureOfBusinessesListForBES && this.natureOfBusinessId
            ? this.natureOfBusinessesListForBES.find(
                (item) => item.id === this.natureOfBusinessId
              ).value
            : "",
        upfrontPaymentAmount:
          !this.upfrontPaymentAmount || this.upfrontPaymentAmount === ""
            ? 0
            : Number(this.upfrontPaymentAmount.replace(",", "")),
        greenEnergy: this.greenEnergyList.find(
          (item) => item.id === this.selectedGreenEnergy
        ).value,
        quoteTypePriceList: updatedQuoteTypePriceList,
        contractType:
          this.selectedMeter.supplierId === this.quoteForSendContract.supplierID
            ? "Renewal Acquisition"
            : "Acquisition",
        paymentTypeName: filteredPayment[0].text,
        homeAddressId:
          sortedAddressItems && sortedAddressItems.length > 0
            ? sortedAddressItems[sortedAddressItems.length - 1].addressId
            : this.selectedAddressId
            ? this.selectedAddressId
            : 0,
        widget: this.contractWidgets.length
          ? this.contractWidgets[this.tab].widget
          : "",
        processedContractValue: 0,
        widgetId: this.contractWidgets.length
          ? this.contractWidgets[this.tab].id
          : 0,
        isSubBroker: true,
        llf: this.selectedMeter.llf,
        mtc: this.selectedMeter.mtc,
        pc: this.selectedMeter.pc,
        singnificantPersonID: this.sendContractForm.significantPersonContactId,
      }

      console.log("formData", formData)
      try {
        let res
        if (this.quoteForSendContract.supplierID === 112) {
          res = await api.post("sendSignableContractValda", formData)
        } else if (this.quoteForSendContract.supplierID === 7) {
          res = await api.post("sendSignableContractOpus", formData)
        } else {
          res = await api.post(`sendSignableContractBES`, formData)
        }
        if (res && res.status === 200) {
          changesSaved("Contract submitted Successfully")
          this.closeModal()
        }
      } catch (e) {
        somethingWentWrong()
      }
    },

    isNumberKey(evt) {
      if (
        this.selectedSignificantPerson &&
        this.selectedSignificantPerson.contactNumber.length >= 11
      ) {
        evt.returnValue = false
        return false
      }
      const charCode = evt.which ? evt.which : evt.keyCode
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        event.returnValue = false
        return false
      } else {
        event.returnValue = true
        return true
      }
    },
    removeWhiteSpaces() {
      const content =
        this.selectedSignificantPerson &&
        this.selectedSignificantPerson.contactNumber
      this.selectedSignificantPerson.contactNumber = content.replace(/\s/g, "")
    },
    isValidEmail() {
      this.isEmail = /^[^@]+@\w+(\.\w+)+\w$/.test(
        this.selectedSignificantPerson.emailAddress
      )
      return this.isEmail
    },
    lastNameCapitalLetter() {
      this.selectedSignificantPerson.lastName =
        this.selectedSignificantPerson.lastName
          .toLowerCase()
          .split(" ")
          .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
          .join(" ")
    },
    firstNameCapitalLetter() {
      this.selectedSignificantPerson.firstName =
        this.selectedSignificantPerson.firstName
          .toLowerCase()
          .split(" ")
          .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
          .join(" ")
    },
    validateContactAndSave() {
      const contactNumber = this.form.contactNumber
        ? this.form.contactNumber
        : ""
      const firstName = this.form.firstName
      const lastName = this.form.lastName
      const jobTitle = this.form.jobTitle
      // console.log(this.$refs.contactNumber.$refs['contactNumber'])
      if (
        this.formEditMode &&
        (!/[a-zA-Z]+$/.test(firstName) ||
          this.firstName === "" ||
          (lastName !== "" && !/[a-zA-Z]+$/.test(lastName)) ||
          (jobTitle !== "" && !/[a-zA-Z]+$/.test(jobTitle)))
      ) {
        const form = {
          firstName: this.form.firstName,
          lastName: this.form.lastName,
          jobTitle: "",
        }
        this.formHasErrors = false
        Object.keys(form).forEach((f) => {
          if (!this.form[f]) this.formHasErrors = true
          this.$refs[f].validate(true)
        })
        return false
      }
      if (
        this.formEditMode &&
        contactNumber &&
        contactNumber !== "" &&
        (contactNumber.length < 10 ||
          /^(00|034|084|087|04|05|06|09)/.test(contactNumber))
      ) {
        this.$refs.contactNumber.$refs["contactNumber"].validate(true)
        return false
      }
      if (
        !this.formEditMode &&
        (!/[a-zA-Z]+$/.test(firstName) || this.firstName === "")
      ) {
        const form = {
          firstName: this.form.firstName,
        }
        this.formHasErrors = false
        Object.keys(form).forEach((f) => {
          if (!this.form[f]) this.formHasErrors = true
          this.$refs[f].validate(true)
        })
        return false
      }

      this.submitForm()
    },

    // -------Lets separate the function Update and edit Contactac -----
    submitForm() {
      if (!this.formEditMode) {
        this.addContact()
      } else {
        this.editContact()
      }
    },
    formatPriceRates(value) {
      if (!value || value === 0) {
        value = 0
      }
      var val = (value / 1).toFixed(2)
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    unFormattedDateChanged(value) {
      const unixDate = dayjs(value).unix()

      this.formattedDate = dayjs(unixDate * 1000).format("DD/MM/YYYY")
      this.saveCotDate(value)
    },
    formattedDateChanged(value) {
      const tmpFromDate = value.split("/").reverse().join("-")

      const formattedDate = dayjs(tmpFromDate).format("YYYY-MM-DD")

      this.unFormattedDate = formattedDate
    },
    async validateSignificantPersonAndSubmit() {
      if (this.isEmail === false) {
        return
      }
      const contactNumber = this.selectedSignificantPerson.contactNumber

      // if (altContactNumber === '' || altContactNumber.length === 0) this.formHasErrors = false
      if (!this.sendContractForm.significantPersonContactId) {
        const form = {
          significantPersonContactId:
            this.sendContractForm.significantPersonContactId,
        }
        this.formHasErrors = false

        Object.keys(form).forEach((f) => {
          if (!this.form[f]) this.formHasErrors = true

          this.$refs[f].validate(true)
        })
        return false
      }

      if (
        contactNumber !== null &&
        contactNumber !== "" &&
        (contactNumber.length < 10 ||
          /^(00|034|084|087|04|05|06|09)/.test(contactNumber))
      ) {
        this.formHasErrors = true

        this.$refs.contactNumber.$refs["contactNumber"].validate(true)
        return false
      }
      await this.saveSignificantPersonAndContinue()
    },
    async saveSignificantPersonAndContinue() {
      this.loadingSignificantPerson = true
      let noteMessage = ""

      if (
        this.selectedSignificantPerson.title !==
        this.dbSelectedSignificantPerson.title
      ) {
        noteMessage += `Title changed from ${
          this.dbSelectedSignificantPerson.title
            ? this.dbSelectedSignificantPerson.title
            : ""
        } to ${this.selectedSignificantPerson.title}<br>`
      }

      if (
        this.selectedSignificantPerson.firstName !==
        this.dbSelectedSignificantPerson.firstName
      ) {
        noteMessage += `First Name changed from ${
          this.dbSelectedSignificantPerson.firstName
            ? this.dbSelectedSignificantPerson.firstName
            : ""
        } to ${this.selectedSignificantPerson.firstName}<br>`
      }

      if (
        this.selectedSignificantPerson.lastName !==
        this.dbSelectedSignificantPerson.lastName
      ) {
        noteMessage += `Last Name changed from ${
          this.dbSelectedSignificantPerson.lastName
            ? this.dbSelectedSignificantPerson.lastName
            : ""
        } to ${this.selectedSignificantPerson.lastName}<br>`
      }

      if (
        this.selectedSignificantPerson.jobTitle !==
        this.dbSelectedSignificantPerson.jobTitle
      ) {
        noteMessage += `Job Title changed from ${
          this.dbSelectedSignificantPerson.jobTitle
            ? this.dbSelectedSignificantPerson.jobTitle
            : ""
        } to ${this.selectedSignificantPerson.jobTitle}<br>`
      }

      if (
        this.selectedSignificantPerson.contactNumber !==
        this.dbSelectedSignificantPerson.contactNumber
      ) {
        noteMessage += `Contact Number changed from ${
          this.dbSelectedSignificantPerson.contactNumber
            ? this.dbSelectedSignificantPerson.contactNumber
            : ""
        } to ${this.selectedSignificantPerson.contactNumber}<br>`
      }

      if (
        this.selectedSignificantPerson.emailAddress !==
        this.dbSelectedSignificantPerson.emailAddress
      ) {
        noteMessage += `Email changed from ${
          this.dbSelectedSignificantPerson.emailAddress
            ? this.dbSelectedSignificantPerson.emailAddress
            : ""
        } to ${this.selectedSignificantPerson.emailAddress}<br>`
      }

      const significantPersonData = {
        // altContactNumber: this.selectedSignificantPerson.altContactNumber,
        companyId: this.selectedSignificantPerson.companyId,
        contactId: this.selectedSignificantPerson.contactId,
        contactNumber: this.selectedSignificantPerson.contactNumber,
        currentUserId: this.selectedSignificantPerson.currentUserId,
        dob: dayjs(this.dob).unix(),
        emailAddress: this.selectedSignificantPerson.emailAddress,
        firstName: this.selectedSignificantPerson.firstName,
        jobTitle: this.selectedSignificantPerson.jobTitle,
        lastName: this.selectedSignificantPerson.lastName,
        leadId: this.selectedSignificantPerson.leadId
          ? this.selectedSignificantPerson.leadId
          : 0,
        primaryContact: this.selectedSignificantPerson.primaryContact,
        securityPassword: this.selectedSignificantPerson.securityPassword
          ? this.selectedSignificantPerson.securityPassword
          : "",
        title: this.selectedSignificantPerson.title,
        noteMessage:
          noteMessage === ""
            ? ""
            : `Contact Updated - ${this.selectedSignificantPerson.title} ${this.selectedSignificantPerson.firstName} ${this.selectedSignificantPerson.lastName}<br>${noteMessage}`,
      }
      try {
        const self = this
        const response = await api.post(`updateContact`, significantPersonData)
        self.errorMsg = ""
        self.errorMsgCC = ""
        if (response.status === 200) {
          let error = false
          if (isNaN(parseFloat(response.data))) {
            error = true
            if (response.data.includes("alternate")) {
              self.errorMsg = response.data
            } else {
              self.alreadyExistingNumber =
                self.selectedSignificantPerson.contactNumber
              self.errorMsgCC = response.data
            }
            if (error) {
              return false
            }
            // this.somethingWentWrong(error)
            // return false
          }
          await this.saveDOBAndContinue()
          this.saveAndContinue()
        } else {
          this.somethingWentWrong()
        }
      } catch (error) {
        this.somethingWentWrong()
      } finally {
        this.loadingSignificantPerson = false
      }
    },
    async savePaymentDetails() {
      if (
        this.payment.typeId === 1 &&
        (this.payment.bankName === "" ||
          this.payment.accountName === "" ||
          this.payment.accountNumber === "" ||
          this.payment.sortCode === "" ||
          this.errorMsg)
      ) {
        const form = {
          sortCode: this.payment.sortCode,
          accountNumber: this.payment.accountNumber,
          bankName: this.payment.bankName,
          accountName: this.payment.accountName,
        }
        this.formHasErrors = false

        Object.keys(form).forEach((f) => {
          if (!this.form[f]) this.formHasErrors = true

          this.$refs[f].validate(true)
        })
        return false
      }
      const filteredPayment = this.paymentTypesList.filter(
        (paymentType) => paymentType.value === this.payment.typeId
      )
      const paymentData = {
        id: this.payment.id,
        type: filteredPayment ? filteredPayment[0].text : "",
        typeId: this.payment.typeId ? this.payment.typeId : 0,
        bankName: this.payment.bankName,
        sortCode: this.payment.sortCode,
        accountName: this.payment.accountName,
        accountNumber: this.payment.accountNumber, // TODO: When getting the payment method the account number is coming the same as Account Name. I think there is a bug in saving the record.
        addressId: this.payment.addressId,
      }
      this.loadingPaymentMethod = true

      try {
        const response = await api.post(`savePayment`, paymentData)
        if (response && response.status === 200) {
          this.fetchPaymentInfo(this.selectedAddress.addressId)
          this.saveAndContinue()
        }
      } catch (error) {
        this.somethingWentWrong()
      } finally {
        this.loadingPaymentMethod = false
      }
    },
    async saveContactAgreedDetails() {
      this.numberAlreadyExistError = ""
      this.selectedContactContractAgreedBy.currentUserId = this.userId
      this.selectedContactContractAgreedBy.contactNumber = this.telephone
      this.selectedContactContractAgreedBy.emailAddress =
        this.emailAddressAgreedBy

      //   this.selectedContactContractAgreedBy.companyId = this.selectedContact.companyId
      this.loadingContractAgreedBy = true
      try {
        const res = await api.post(
          `updateContact`,
          this.selectedContactContractAgreedBy
        )
        if (res && res.status === 200) {
          console.log("accessed")
          if (isNaN(parseFloat(res.data))) {
            if (res.data) {
              this.numberAlreadyExistError = res.data
            } else {
              this.numberAlreadyExistError = "This Number Already Exists"
            }

            return false
          }

          this.fetchContacts(this.details.companyId)

          changesSaved("Contact Information Successfully Updated")
        }
      } catch (e) {
        somethingWentWrong()
      } finally {
        this.loadingContractAgreedBy = true
      }
    },
  },
}
</script>

<style>
.send_contract_company_container.w-100 {
  width: 100%;
}
.send_contract_modal .h-100 {
  height: 100%;
}
.send_contract_modal .theme--dark.v-btn.v-btn--disabled.v-btn--has-bg {
  background-color: #444 !important;
}
.send_contract_modal
  .v-input--solo-inverted
  .v-input__control
  .v-input__slot::before {
  border-bottom: none !important;
}
.send_contract_modal .box-shadow {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}
.send_contract_modal .p-3 {
  padding: 1.5rem;
}
.send_contract_modal.alignment {
  align-items: center;
}

.send_contract_modal .error_container {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}
.send_contract_modal .w-100 {
  width: 100%;
}
.send_contract_modal button:disabled {
  opacity: 60%;
  cursor: auto;
}
.slider_send_contract_container .v-slide-group__content {
  gap: 10px;
}
.slider_send_contract_container {
  margin-left: 30px;
}
</style>
