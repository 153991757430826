<template>

  <div>

    <v-dialog v-model="trustPilotReviewVisible" width="1300">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
          class="d-block"
            depressed
            color="indigo"
            plain
            small
            v-on="on"
            v-bind="attrs"
            :disabled="trustPilotReviews.length < 1"
          >
            Trust Pilot
            <v-badge
              class="pl-2"
              :content="trustPilotReviews.length.toString()"
              color="indigo"
              inline
            >
            </v-badge>
          </v-btn>
        </template>
    
        <v-card>
          <v-card-title>
            <v-row>
              <v-col cols="auto">Trust Pilot Reviews </v-col>
              <v-col>
                <v-chip color="accent" text-color="white" small>
                  {{ trustPilotReviews.length }}
                </v-chip>
              </v-col>
            </v-row>
          </v-card-title>
    
          <LoadingSpinner v-if="loading" />
    
          <div v-else class="px-5 pb-5 mt-4">
            <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-headers text-left">
                        Company
                      </th>
                      <th class="text-headers text-left">
                        Service
                      </th>
                      <th class="text-headers text-left">
                        Rating
                      </th>

                      <th class="text-headers text-left">
                        Review Comments
                      </th>


                      <th class="text-headers text-left">
                        Employee
                      </th>


                      <th class="text-headers text-left">
                        Employee
                      </th>


                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      class="p-2 "
                      style="height:90px"
                      v-for="review in trustPilotReviews" 
                      :key="review.reviewID"
                    >
                      <td style="width:300px">
                        <div>
                          <span
                            ><strong>{{ review.companyName }}</strong></span
                          >
                        </div>
                        <div>
                          <span>
                            <strong>Company ID: </strong>
                            <span>
                              {{ review.companyID }}
                            </span>
                          </span>
                        </div>
                      </td>


                      <td style="width:150px">
                        <div class="estimate-container">
                          {{ review.service }}
                        </div>
                      </td>
                      <td style="width:200px">
                        <div class="estimate-container">

                          <v-rating
                            v-model="review.rating"
                            density="compact"
                            color="orange"
                          ></v-rating>

                        </div>
                      </td>


                      <td style="width:200px">
                        <div class="estimate-container">
                          {{ review.reviewComments }}
                        </div>
                      </td>
                      <td style="width:250px">
                        <DateValue
                          label="Sent By"
                          :value="review.sentByName"
                          :user-id="review.sentBy.toString()"
                          icon="account-circle"
                          :extra-info="$helpers.formatDate(review.sentDate, true)"
                          :profile-pic="review.sentBy.toString()"
                        />
                      </td>


                      <td style="width:250px">
                        {{ review.contactName }}                        
                        
                      </td>









                    </tr>
                  </tbody>
                </template>
            </v-simple-table>





          </div>
    
          <v-divider></v-divider>
    
          <v-card-actions>
            <v-btn text @click="trustPilotReviewVisible = false">
              Close
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="visible" width="1300">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            depressed
            color="indigo"
            plain
            small
            v-on="on"
            v-bind="attrs"
            :disabled="googleReviews.length < 1"
          >
            Google
            <v-badge
              class="pl-2"
              :content="googleReviews.length.toString()"
              color="indigo"
              inline
            >
            </v-badge>
          </v-btn>
        </template>

        <v-card>
          <v-card-title>
            <v-row>
              <v-col cols="auto"> Google Reviews </v-col>
              <v-col>
                <v-chip color="accent" text-color="white" small>
                  {{ googleReviews.length }}
                </v-chip>
              </v-col>
            </v-row>
          </v-card-title>

          <LoadingSpinner v-if="loading" />

          <div v-else class="px-5 pb-5 mt-4">
            <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-headers text-left">
                        Company
                      </th>
                      <th class="text-headers text-left">
                        Service
                      </th>
                      <th class="text-headers text-left">
                        Rating
                      </th>

                      <th class="text-headers text-left">
                        Review Comments
                      </th>


                      <th class="text-headers text-left">
                        Employee
                      </th>

                      <th class="text-headers text-left">
                        Employee
                      </th>


                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      class="p-2 "
                      style="height:90px"
                      v-for="review in googleReviews" 
                      :key="review.reviewID"
                    >
                      <td style="width:300px">
                        <div>
                          <span
                            ><strong>{{ review.companyName }}</strong></span
                          >
                        </div>
                        <div>
                          <span>
                            <strong>Company ID: </strong>
                            <span>
                              {{ review.companyID }}
                            </span>
                          </span>
                        </div>
                      </td>


                      <td style="width:150px">
                        <div class="estimate-container">
                          {{ review.service }}
                        </div>
                      </td>
                      <td style="width:200px">
                        <div class="estimate-container">

                          <v-rating
                            v-model="review.rating"
                            density="compact"
                            color="orange"
                          ></v-rating>

                        </div>
                      </td>


                      <td style="width:200px">
                        <div class="estimate-container">
                          {{ review.reviewComments }}
                        </div>
                      </td>
                      <td style="width:250px">
                        <DateValue
                          label="Sent By"
                          :value="review.sentByName"
                          :user-id="review.sentBy.toString()"
                          icon="account-circle"
                          :extra-info="$helpers.formatDate(review.sentDate, true)"
                          :profile-pic="review.sentBy.toString()"
                        />
                      </td>


                      <td style="width:250px">

                        {{ review.contactName }}
                      </td>









                    </tr>
                  </tbody>
                </template>
            </v-simple-table>




          </div>

          <v-divider></v-divider>

          <v-card-actions>
            <v-btn text @click="visible = false">
              Close
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>




  </div>





</template>

<script>
import { mapActions, mapGetters } from "vuex"
import LoadingSpinner from "@/components/loading-spinner"
import DateValue from "@/components/data-value.vue"

export default {
  components: {
    LoadingSpinner,
    DateValue,
  },
  props: {
    company: {
      type: String,
      default: ""
    },
    
  },
  data: () => ({ visible: false, trustPilotReviewVisible: false }),
   methods: {
    ...mapActions("company", ["getWebCustomerReviewRequest"])
  },
  mounted() {
    this.getWebCustomerReviewRequest()
  },
  computed: {
    ...mapGetters("company", ["webCustomerReviewRequest","loading"]),
    trustPilotReviews() {
      const tempTrustPilotReviews = []
      if(this.webCustomerReviewRequest && this.webCustomerReviewRequest.length > 0) {
        this.webCustomerReviewRequest.forEach(item => {
          if(!item.isGoogleReview && item.requestAccepted) {
            tempTrustPilotReviews.push(item)
          }
        })
      }
      return tempTrustPilotReviews
    },
    googleReviews() {
      const tempGoogleReviews = []
      if(this.webCustomerReviewRequest && this.webCustomerReviewRequest.length > 0) {
        this.webCustomerReviewRequest.forEach(item => {
          if(item.isGoogleReview && item.requestAccepted) {
            tempGoogleReviews.push(item)
          }
        })
      }
      return tempGoogleReviews
    },
  }
}
</script>


<style scoped>
.text-headers {
  font-family: Inter, Rubik;
  font-weight: 700;
  color: #ea5791 !important;
  font-size: 1rem !important;
}
</style>