<template>
    <v-card color="indigo lighten-5" flat>
        <v-card-text>
            <v-container>
                <v-row align="center" class="d-flex justify-end">
                    <v-col cols="3">
                        <div>Total EAC</div>
                        <v-text-field disabled v-model="totalEac"></v-text-field>
                    </v-col>
                    <v-col cols="3">
                        <div>Relevant EAC</div>
                        <v-text-field disabled v-model="relevantEac"></v-text-field>
                    </v-col>
                    <v-col cols="4" v-if="!isFixedCommissionOn">
                        <div>Total Est Contract Value</div>
                        <v-text-field  disabled prepend-icon="mdi-currency-gbp" v-model="totalEst"></v-text-field>
                    </v-col>
                     <v-col cols="4" v-if="isFixedCommissionOn">
                        <div>Total Est Contract Value</div>
                        <v-text-field prepend-icon="mdi-currency-gbp" v-model="totalEstInput" @keypress="validationtotal" @input="updateValue"></v-text-field>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>

    </v-card>
</template>

<script>
export default({
    props: {
    totalEac: {
      type: Number,
      default: 0
    },  
    relevantEac: {
      type: Number,
      default: 0
    },
    totalEst: {
      type: Number,
      default: 0
    }, 
     totalEstInput: {
      type: Number,
      default: 0
    },
    isFixedCommissionOn: {
      type: Boolean,
      default: false,
    },
  },
  methods:{
    validationtotal(evt) {
          const charCode = evt.which ? evt.which : evt.keyCode
          if (charCode > 31 && (charCode < 32 || charCode > 57) && charCode !== 46) {
              event.returnValue = false
              return false
     }
    },

    updateValue() {
      let est = this.totalEstInput;     
      this.$emit("update-number", est);
    },
  }
});
</script>