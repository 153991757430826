<template>
  <div
    style="height: 680px; width: 600px; display: flex; justify-content: center; font-size: 13px;"
  >
    <v-row
      class="mt-3"
      style="margin-left: 90px;height: 680px;display: flex; justify-content: center;"
    >
      <v-col cols="6" class="">
        <v-row class="text-left" style="display: flex; flex-direction: column;">
          <div
            class="text-sm mt-1"
            style="color: #000; height: 50px; display: flex; align-items: center;"
          >
            <b class="mr-4">Import Date</b>
          </div>
          <div class="text-sm mt-1" style="color: #000; height: 16px;">
            <b class="mr-4">Customer Name:</b>
          </div>
          <div class="text-sm mt-1" style="color: #000; height: 16px;">
            <b class="mr-4">Broker</b>
          </div>
          <div class="text-sm mt-1" style="color: #000; height: 16px;">
            <b class="mr-4">Contract Received Date</b>
          </div>
          <div class="text-sm mt-1" style="color: #000; height: 16px;">
            <b class="mr-4">Item ID</b>
          </div>

          <div class="text-sm mt-1" style="color: #000; height: 16px;">
            <b class="mr-4">Product</b>
          </div>

          <div class="text-sm mt-1" style="color: #000; height: 16px;">
            <b class="mr-4">MPAN/MPRN</b>
          </div>

          <div class="text-sm mt-1" style="color: #000; height: 16px;">
            <b class="mr-4">Profile Class</b>
          </div>

          <div class="text-sm mt-1" style="color: #000; height: 16px;">
            <b class="mr-4">Supplier</b>
          </div>

          <div class="text-sm mt-1" style="color: #000; height: 16px;">
            <b class="mr-4">Old Supplier</b>
          </div>
        </v-row>
        <v-row
          class="text-left mt-2"
          style="display: flex; flex-direction: column;"
        >
          <div class="text-sm mt-1" style="color: #000; height: 16px;">
            <b class="mr-4">Contract Start Date</b>
          </div>
          <div class="text-sm mt-1" style="color: #000; height: 16px;">
            <b class="mr-4">Application Date</b>
          </div>
          <div class="text-sm mt-1" style="color: #000; height: 16px;">
            <b class="mr-4">Future Start Date</b>
          </div>
          <div class="text-sm mt-1" style="color: #000; height: 16px;">
            <b class="mr-4">Term</b>
          </div>

          <div class="text-sm mt-1" style="color: #000; height: 16px;">
            <b class="mr-4">Contract End Date</b>
          </div>

          <div class="text-sm mt-1" style="color: #000; height: 16px;">
            <b class="mr-4">Uplift</b>
          </div>
        </v-row>
        <v-row
          class="text-left mt-2"
          style="display: flex; flex-direction: column;"
        >
          <!-- <div
            class="text-sm mt-1"
            style="color: #000; height: 16px;"
            ><b class="mr-4">Contract Status</b>
          </div> -->
          <div class="text-sm mt-1" style="color: #000; height: 16px;">
            <b class="mr-4">Primary Status</b>
          </div>
          <div class="text-sm mt-1" style="color: #000; height: 16px;">
            <b class="mr-4">Secondary Status</b>
          </div>
          <div class="text-sm mt-1" style="color: #000; height: 16px;">
            <b class="mr-4">Third Status</b>
          </div>
          <div class="text-sm mt-1" style="color: #000; height: 16px;">
            <b class="mr-4">Objections Received</b>
          </div>

          <div class="text-sm mt-1" style="color: #000; height: 16px;">
            <b class="mr-4">Broker Reference</b>
          </div>
          <div class="text-sm mt-1" style="color: #000; height: 16px;">
            <b class="mr-4">Objections Abandoned Date</b>
          </div>
        </v-row>
        <v-row
          class="text-left mt-2"
          style="display: flex; flex-direction: column;"
        >
          <div class="text-sm mt-1" style="color: #000; height: 16px;">
            <b class="mr-4">Projected Live Commission</b>
          </div>
          <div class="text-sm mt-1" style="color: #000; height: 16px;">
            <b class="mr-4">Total Commission Received</b>
          </div>
          <div class="text-sm mt-1" style="color: #000; height: 16px;">
            <b class="mr-4">Amount Paid</b>
          </div>
          <div class="text-sm mt-1" style="color: #000; height: 16px;">
            <b class="mr-4">Projected Contract Value</b>
          </div>
          <div class="text-sm mt-1" style="color: #000; height: 16px;">
            <b class="mr-4">Total Commission Paid Against</b>
          </div>

          <div class="text-sm mt-1" style="color: #000; height: 16px;">
            <b class="mr-4">Date Paid</b>
          </div>

          <div class="text-sm mt-1" style="color: #000; height: 16px;">
            <b class="mr-4">Sale ID</b>
          </div>
        </v-row>
      </v-col>
      <v-col cols="6">
        <v-row class="text-left" style="display: flex; flex-direction: column;">
          <div
            class="text-sm mt-1"
            style="color: #000; height: 50px; display: flex; align-items: center;"
          >
            <div class="">
              <UserAvatar
                v-if="regReport.importedBy"
                class="d-flex justify-content-start"
                :small="true"
                :portrait="regReport.importedBy"
                :label="regReport.importedByName"
                :caption="$helpers.formatDate(regReport.importDate, true)"
              ></UserAvatar>
              <date-time
                v-else
                :date="regReport.importDate"
                only-date
              ></date-time>
            </div>
          </div>
          <div class="text-sm mt-1" style="color: #000; height: 16px;">
            {{ regReport.customerName }}
          </div>
          <div class="text-sm mt-1" style="color: #000; height: 16px;">
            {{ regReport.broker }}
          </div>
          <div
            class="text-sm mt-1"
            style="color: #000; height: 16px;"
            ><date-time :date="regReport.contractReceivedDate" only-date></date-time>
          </div>
          <div class="text-sm mt-1" style="color: #000; height: 16px;">
            {{ regReport.itemID }}
          </div>

          <div class="text-sm mt-1" style="color: #000; height: 16px;">
            {{ regReport.product }}
          </div>

          <div class="text-sm mt-1" style="color: #000; height: 16px;">
            {{ supplyNumber }}
          </div>

          <div class="text-sm mt-1" style="color: #000; height: 16px;">
            {{ regReport.profileClass }}
          </div>

          <div class="text-sm mt-1" style="color: #000; height: 16px;">
            {{ regReport.supplier }}
          </div>

          <div class="text-sm mt-1" style="color: #000; height: 16px;">
            {{ regReport.oldSupplier }}
          </div>
        </v-row>
        <v-row
          class="text-left mt-2"
          style="display: flex; flex-direction: column;"
        >
          <div class="text-sm mt-1" style="color: #000; height: 16px;">
            <date-time
              :date="regReport.contractStartDate"
              only-date
            ></date-time>
          </div>
          <div class="text-sm mt-1" style="color: #000; height: 16px;">
            <date-time :date="regReport.applicationDate" only-date></date-time>
          </div>
          <div class="text-sm mt-1" style="color: #000; height: 16px;">
            <date-time :date="regReport.futureStartDate" only-date></date-time>
          </div>
          <div class="text-sm mt-1" style="color: #000; height: 16px;">
            {{ regReport.term }}
          </div>

          <div class="text-sm mt-1" style="color: #000; height: 16px;">
            <date-time :date="regReport.contractEndDate" only-date></date-time>
          </div>

          <div class="text-sm mt-1" style="color: #000; height: 16px;">
            {{ regReport.uplift }}
          </div>
        </v-row>
        <v-row
          class="text-left mt-2"
          style="display: flex; flex-direction: column;"
        >
          <!-- <div
            class="text-sm mt-1"
            style="color: #000; height: 16px;"
            > {{ regReport.contractStatus }}
          </div> -->
          <div class="text-sm mt-1" style="color: #000; height: 16px;">
            {{ regReport.supplierPrimaryStatus }}
          </div>
          <div class="text-sm mt-1" style="color: #000; height: 16px;">
            {{ regReport.secondaryStatus }}
          </div>
          <div class="text-sm mt-1" style="color: #000; height: 16px;">
            {{ regReport.thirdStatus }}
          </div>
          <div class="text-sm mt-1" style="color: #000; height: 16px;">
            {{ regReport.objectionsReceived }}
          </div>

          <div class="text-sm mt-1" style="color: #000; height: 16px;">
            {{ regReport.brokerReference }}
          </div>
          <div class="text-sm mt-1" style="color: #000; height: 16px;">
            <date-time
              :date="regReport.objectionAbandonDate"
              only-date
            ></date-time>
          </div>
        </v-row>
        <v-row
          class="text-left mt-2"
          style="display: flex; flex-direction: column;"
        >
          <div class="text-sm mt-1" style="color: #000; height: 16px;">
            {{ regReport.plc }}
          </div>
          <div class="text-sm mt-1" style="color: #000; height: 16px;">
            {{ regReport.totalCommissionsReceived }}
          </div>
          <div class="text-sm mt-1" style="color: #000; height: 16px;">
            {{ regReport.amountPaid }}
          </div>
          <div class="text-sm mt-1" style="color: #000; height: 16px;">
            {{ regReport.pcv }}
          </div>
          <div class="text-sm mt-1" style="color: #000; height: 16px;">
            {{ regReport.totalCommissionPaidAgainst }}
          </div>

          <div class="text-sm mt-1" style="color: #000; height: 16px;">
            <date-time :date="regReport.datePaid" only-date></date-time>
          </div>

          <div class="text-sm mt-1" style="color: #000; height: 16px;">
            {{ regReport.saleId }}
          </div>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import dayjs from "dayjs"
import DateTime from "@/components/date-time.vue"
import UserAvatar from "@/components/user-avatar"

export default {
  components: {
    DateTime,
    UserAvatar
  },
  data() {
    return {
      visible: false
    }
  },
  props: {
    regReport: {
      type: Object
    },
    supplyNumber: {
      type: String
    }
  },
  computed: {
    crd() {
      if (
        this.regReport.contractReceivedDate === null ||
        this.regReport.contractReceivedDate === 0
      ) {
        return ""
      }
      return dayjs(this.regReport.contractReceivedDate * 1000).format(
        "DD/MM/YYYY"
      )
    },
    csd() {
      if (
        this.regReport.contractStartDate === null ||
        this.regReport.contractStartDate === 0
      ) {
        return ""
      }
      return dayjs(this.regReport.contractStartDate * 1000).format("DD/MM/YYYY")
    },
    applicationDate() {
      if (
        this.regReport.applicationDate === null ||
        this.regReport.applicationDate === 0
      ) {
        return ""
      }
      return dayjs(this.regReport.applicationDate * 1000).format("DD/MM/YYYY")
    },
    fsd() {
      if (
        this.regReport.futureStartDate === null ||
        this.regReport.futureStartDate === 0
      ) {
        return ""
      }
      return dayjs(this.regReport.futureStartDate * 1000).format("DD/MM/YYYY")
    },
    ced() {
      if (
        this.regReport.contractEndDate === null ||
        this.regReport.contractEndDate === 0
      ) {
        return ""
      }
      return dayjs(this.regReport.contractEndDate * 1000).format("DD/MM/YYYY")
    },
    oad() {
      if (
        this.regReport.objectionAbandonDate === null ||
        this.regReport.objectionAbandonDate === 0
      ) {
        return ""
      }
      return dayjs(this.regReport.objectionAbandonDate * 1000).format(
        "DD/MM/YYYY"
      )
    },
    datePaid() {
      if (this.regReport.datePaid === null || this.regReport.datePaid === 0) {
        return ""
      }
      return dayjs(this.regReport.datePaid * 1000).format("DD/MM/YYYY")
    },
    importDate() {
      if (
        this.regReport.importDate === null ||
        this.regReport.importDate === 0
      ) {
        return ""
      }
      return dayjs(this.regReport.importDate * 1000).format("DD/MM/YYYY")
    }
  }
}
</script>
