import axios from 'axios'

import api from "@/store/api"
import { chunkArray } from "@/store/helpers/utils"
import {
  getAddressState,
  getBreweryInfoState,
  getLandlordInfoState,
  getPaymentInfoState,
  getPreviousTenantInfoState
} from "@/store/helpers/default-state"

const GET_ADDRESS_API_KEY = 'p5WeeRHxhk-CT6yMVDqzPQ25933'

const state = {
  error: null,
  loading: {
    addresses: false,
    paymentInfo: false,
    landlord: false,
    previousTenant: false,
    brewery: false
  },
  addresses: [],
  selectedAddress: getAddressState(),
  paymentInformation: getPaymentInfoState(),
  landlordInformation: getLandlordInfoState(),
  previousTenantInformation: getPreviousTenantInfoState(),
  breweryInformation: getBreweryInfoState(),
  primaryAddress: getAddressState()
}

const getters = {
  error: state => state.error,
  loading: state => state.loading.addresses,
  loadingPaymentInfo: state => state.loading.paymentInfo,
  loadingLandlordInfo: state => state.loading.landlord,
  addresses: state => state.addresses,
  addressItems: state => {
    if (!state.addresses.length) return []
    return state.addresses.map(
      ({ addressId, siteName, add1, add2, county, town, postcode }) => ({
        id: addressId,
        value: `${siteName}, ${add1}, ${add2}, ${county}, ${town}, ${postcode}`
          .trim()
          .replace(/, ,/g, "") // remove empty spaces with commas
      })
    )
  },
  groupedAddresses: state => chunkArray(state.addresses, 3),
  primaryAddress: state => state.primaryAddress,
  selectedAddress: state => state.selectedAddress,
  paymentInfo: state => state.paymentInformation,
  landlord: state => state.landlordInformation,
  previousTenant: state => state.previousTenantInformation,
  brewery: state => state.breweryInformation
}

const mutations = {
  setAddresses: (state, addresses) => {
    // sort addresses: Primary contact first in the list
    state.addresses = addresses.sort(
      (a, b) => b.primaryAddress - a.primaryAddress
    )

    state.primaryAddress =
      addresses.length && addresses.find(address => address.primaryAddress)
  },
  setLoading: (state, { status, element }) => (state.loading[element] = status),
  setError: (state, error) => (state.error = error),
  // setSelectedAddress: (state, addressId) => {
  //   if (addressId !== state.selectedAddress.addressId.toString()) {
  //     const address = state.addresses.find(
  //       address => address.addressId.toString() === addressId
  //     )
  //     state.selectedAddress = address
  //   }
  // },
  setSelectedAddress: (state, addressId) => {
    const address = state.addresses.find(
      address => address.addressId.toString() === addressId.toString()
    )
    if (address) {
      state.selectedAddress = address
    }
  },
  setAddressExtraInfo: (state, { type, data }) => {
    state[type] = data
  },
  createAddress: (state, address) => {
    state.addresses.push(address)
  },
  updateAddress: (state, address) => {
    const index = state.addresses.findIndex(
      ({ addressId }) => addressId === address.addressId
    )

    state.addresses.splice(index, 1, address)
  },
  updateLandlord: (state, landlord) => {
    state.landlordInformation = landlord
  },
  updatePreviousTenant: (state, previousTenant) => {
    state.previousTenantInformation = previousTenant
  },
  //
  updateBrewery: (state, brewery) => {
    state.breweryInformation = brewery
  },
  deleteAddress: (state, addressId) =>
  (state.addresses = state.addresses.filter(
    address => address.addressId.toString() !== addressId
  )),
}

const actions = {
  async fetchAddresses({ commit, dispatch, state, rootGetters, getters }) {
    commit("setLoading", { element: "addresses", status: true })
    try {
      const response = await api.get(
        `addresses/${rootGetters["company/companyId"]}`
      )
      commit("setAddresses", response.data)
    } catch (error) {
      commit("setError", error)
    }
    commit("setLoading", { element: "addresses", status: false })

    if (!state.error) {
      dispatch("fetchPrimaryMeters", getters.primaryAddress.companyId, {
        root: true
      })
    }
  },
  async fetchAddressesAfterRemove({ commit }, companyId) {
    commit("setLoading", { element: "addresses", status: true })
    try {
      const response = await api.get(`addresses/${companyId}`)
      commit("setAddresses", response.data)
    } catch (error) {
      commit("setError", error)
    }
    commit("setLoading", { element: "addresses", status: false })
  },
  async selectAddress({ commit }, addressId) {
    commit("setSelectedAddress", addressId)
  },
  async fetchPaymentInfo({ commit }, addressId) {
    commit("setLoading", { element: "paymentInfo", status: true })
    try {
      const response = await api.get(`paymentByAddressId/${addressId}`)
      commit("setAddressExtraInfo", {
        type: "paymentInformation",
        data: response.data[0]
      }) // FIXME: response should be an object
    } catch (error) {
      commit("setError", error)
    }
    // FIXME: make process a bit longer to have a smoother experience
    setTimeout(() => {
      commit("setLoading", { element: "paymentInfo", status: false })
    }, 500)
  },
  async fetchLandlordInfo({ commit }, addressId) {
    commit("setLoading", { element: "landlord", status: true })
    try {
      const response = await api.get(`landlordByAddressId/${addressId}`)
      commit("setAddressExtraInfo", {
        type: "landlordInformation",
        data: response.data
      }) // FIXME: response should be an object
    } catch (error) {
      commit("setError", error)
    }
    // FIXME: make process a bit longer to have a smoother experience
    setTimeout(() => {
      commit("setLoading", { element: "landlord", status: false })
    }, 500)
  },
  async fetchPreviousTenantInfo({ commit }, addressId) {
    commit("setLoading", { element: "paymentInfo", status: true })
    try {
      const response = await api.get(`previousTenantByAddressId/${addressId}`)
      commit("setAddressExtraInfo", {
        type: "previousTenantInformation",
        data: response.data
      }) // FIXME: response should be an object
    } catch (error) {
      commit("setError", error)
    }
    commit("setLoading", { element: "paymentInfo", status: false })
  },
  async fetchBreweryInfo({ commit }, addressId) {
    commit("setLoading", { element: "brewery", status: true })
    try {
      const response = await api.get(`breweryByAddressId/${addressId}`)
      commit("setAddressExtraInfo", {
        type: "breweryInformation",
        data: response.data
      }) // FIXME: response should be an object
    } catch (error) {
      commit("setError", error)
    }
    commit("setLoading", { element: "brewery", status: false })
  },
  // eslint-disable-next-line no-unused-vars
  async fetchAddressesByPostcode({ commit }, postcode) {
    try {
      const res = await axios.get(`https://api.getAddress.io/find/${postcode}?api-key=${GET_ADDRESS_API_KEY}&expand=true`)
      let singleAddress = "";
      const addresses = [];
      
      const tempAddress = res.data.addresses.sort((a, b) => {
        const numA = Number(a.line_1.match(/\d+/)) || Number(a.line_2.match(/\d+/));
        const numB = Number(b.line_1.match(/\d+/)) || Number(b.line_2.match(/\d+/));
        
        if (isNaN(numA)) {
          return 1;
        }
        
        if (isNaN(numB)) {
          return -1;
        }
        
        return numA - numB;
      });
      
    
      
      tempAddress.forEach(address => {
        singleAddress = "";
        address.line_1 ? singleAddress += address.line_1 + ", " : singleAddress += ""
        address.line_2 ? singleAddress += address.line_2 + ", " : singleAddress += ""
        address.town_or_city ? singleAddress += address.town_or_city + (address.county ? ", " : "") : singleAddress += ""
        address.county ? singleAddress += address.county : singleAddress += ""

        addresses.push(singleAddress)
      })
      return { addresses, status: res.status }
    } catch (error) {
      return error
    }
  },
  async createAddress({ commit }, address) {
    try {
      // eslint-disable-next-line
      const response = await api.post("updateAddress", address)
      commit("createAddress", response) // we should pass the response.data, but API is returning only id
      return response
    } catch (error) {
      commit("setError", error)
      return error
    }
  },
  async updateAddress({ commit }, address) {
    try {
      // eslint-disable-next-line
      const response = await api.post("updateAddress", address)
      commit("updateAddress", address)
      return response
    } catch (error) {
      commit("setError", error)
      return error
    }
  },
  async updateLandlord({ commit }, landlord) {
    try {
      // eslint-disable-next-line
      const response = await api.post("updateLandlord", landlord)
      commit("updateLandlord", landlord)
      return response
    } catch (error) {
      commit("setError", error)
      return error
    }
  },
  async updatePreviousTenant({ commit }, previousTenant) {
    try {
      // eslint-disable-next-line
      const response = await api.post("updatePreviousTenant", previousTenant)
      commit("updatePreviousTenant", previousTenant)
      return response
    } catch (error) {
      commit("setError", error)
      return error
    }
  },
  async updateBrewery({ commit }, brewery) {
    try {
      // eslint-disable-next-line
      const response = await api.post("updateBrewery", brewery)
      commit("updateBrewery", brewery)
      return response
    } catch (error) {
      commit("setError", error)
      return error
    }
  },
  async changePrimaryAddress({ commit, rootGetters }, addressId) {
    try {
      await api.post(`changePrimaryAddress/${rootGetters["company/companyId"]}/${addressId}`)
      commit("updateAddress", addressId)
    } catch (error) {
      commit("setError", error)
    }
  },
  async deleteAddressById({ commit }, details) {
    try {
      const res = await api.post(`deleteAddress`, details)
      commit("deleteAddress", details)
      return res
    } catch (error) {
      commit("setError", error)
      return error
    }
  },

}

export default { state, getters, mutations, actions, namespaced: true }
