<template>
  <v-card outlined rounded="lg" :loading="loading">
    <template slot="progress">
      <v-progress-linear
        color="accent"
        height="8"
        indeterminate
      ></v-progress-linear>
    </template>

    <v-card-title class="py-2">
      <v-row align="center" justify="space-between">
        <v-col class="text-button">
          <v-icon class="mr-1" small>mdi-note-multiple</v-icon>
          Notes
        </v-col>
        <v-scroll-x-reverse-transition>
          <v-col v-show="showSearch">
            <v-text-field
              ref="searchNote"
              dense
              single-line
              color="blue"
              hide-details
              clearable
              label="Search Note..."
              @input="searchNote"
            ></v-text-field>
          </v-col>
        </v-scroll-x-reverse-transition>
        <v-col cols="auto">
          <v-btn
            color="blue"
            depressed
            icon
            small
            @click="showSearchField"
          >
            <v-icon small>mdi-magnify</v-icon>
          </v-btn>
          <v-btn
            :color="showForm ? 'red' : 'blue'"
            depressed
            icon
            small
            @click="showForm = !showForm"
          >
            <v-icon small>{{ showForm ? "mdi-close" : "mdi-plus" }}</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>
    <v-divider></v-divider>

    <v-expand-transition>
      <div v-show="showForm">
        <template>
          <v-tabs class="mb-4 " v-model="tabHeader" color="accent"  grow>
            <v-tab>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-on="on" v-bind="attrs" small>mdi-account</v-icon>
                </template>
                <span>Add Notes</span>
              </v-tooltip>
            </v-tab>
            <v-tab>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-on="on" v-bind="attrs" small>mdi-gauge</v-icon>
                </template>
                <span>Add Meter Information</span>
              </v-tooltip>
            </v-tab>
            <v-tab>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <!-- <v-icon v-on="on" v-bind="attrs" small>mdi-file-document</v-icon> -->
                  <i v-on="on" v-bind="attrs" class="fas fa-info" style="font-size: 14px; color: #ffa500;"></i>            
                </template>
                <span>Add Information Request</span>
              </v-tooltip>
            </v-tab>
            <v-tab>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <!-- <v-icon v-on="on" v-bind="attrs" small>mdi-email</v-icon> -->
                  <i v-on="on" v-bind="attrs" class="fas fa-user-shield" style="font-size: 14px; color: #28a745;"></i>            
                </template>
                <span>Add LOA's</span>
              </v-tooltip>
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tabHeader">
            <v-tab-item>
              <NotesForm />
            </v-tab-item>
            <v-tab-item>
              <NoteMeterInfo />
            </v-tab-item>
            <v-tab-item>
              <NoteInfoRequest />
            </v-tab-item>
            <v-tab-item>
              <NoteLoas />
            </v-tab-item>
          </v-tabs-items>
        </template>
      </div>
    </v-expand-transition>
    <LoadingSpinner v-if="loading" />

    <div v-else>
      <div v-if="!notes.length" class="pa-3">
        <EmptyCard content="No notes" />
      </div>
      <div v-else>
        <v-tabs v-model="tab" fixed-tabs slider-color="accent" center-active>
          <v-tooltip top v-for="(tab, key) in noteTypes" :key="tab.type + key">
            <template v-slot:activator="{ on, attrs }">
              <v-tab v-bind="attrs" v-on="on" :key="tab.type">
                <i v-if="tab.icon === 'user-shield'" class="fas fa-user-shield" style="font-size: 14px; color: #28a745;"></i>            
                <i v-else-if="tab.icon === 'info'" class="fas fa-info" style="font-size: 14px; color: #ffa500;"></i>            
                <span v-else>
                  <span v-if="!tab.icon" style="color: #007bff">{{ tab.type }}</span>
                  <v-icon v-else-if="tab.icon === 'check-bold'" style="color: #007bff" small>mdi-{{ tab.icon }}</v-icon>
                  <v-icon v-else-if="tab.icon === 'gauge'" style="color: #00FFFF" small>mdi-{{ tab.icon }}</v-icon>
                  <v-icon v-else-if="tab.icon === 'server'" style="color: red" small>mdi-{{ tab.icon }}</v-icon>
                  <v-icon v-else small>mdi-{{ tab.icon }}</v-icon>
                </span>
              </v-tab>
            </template>
            <span v-if="tab.type === 'loa'"> {{ tab.type.toUpperCase() }} Notes</span>
            <span v-else-if="tab.type === 'sale note'">Sale Notes</span>
            <span v-else class="text-capitalize">{{ tab.type }} Notes</span>
          </v-tooltip>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item v-for="tab in noteTypes" :key="tab.type">
            <v-list class="overflow-y-auto" max-height="730px">
              <!--  -->
              <!-- <v-virtual-scroll
              :bench="benched"
              :items="getNotesByType(tab.type)"
              height="730"
              item-height="64"
            > -->
              <!-- <template v-slot:default="{ item }"> -->
              <v-list-item
                v-for="note in getNotesByType(tab.type)"
                :key="note.noteId"
              >
                <v-list-item-icon>
                  <i v-if="note.icon === 'user-shield'" class="fas fa-user-shield" style="font-size: 14px; color: #28a745;"></i>            
                  <i v-else-if="note.icon === 'info'" class="fas fa-info" style="font-size: 14px; color: #ffa500;"></i>            
                  <span v-else>
                    <span v-if="!note.icon" style="color: #007bff">{{ note.type }}</span>
                    <v-icon v-else-if="note.icon === 'check-bold'" style="color: #007bff" small>mdi-{{ note.icon }}</v-icon>
                    <v-icon v-else-if="note.icon === 'gauge'" style="color: #00FFFF" small>mdi-{{ note.icon }}</v-icon>
                    <v-icon v-else-if="note.icon === 'server'" style="color: red" small>mdi-{{ note.icon }}</v-icon>
                    <v-icon v-else small>mdi-{{ note.icon }}</v-icon>
                  </span>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-subtitle>
                    {{ $helpers.formatDate(note.createdDate, true) }} -
                    <v-avatar :size="16" v-if="note.createdByEmployeeId">
                      <img
                        :src="$helpers.profilePicture(note.createdByEmployeeId)"
                        :alt="note.employeeName"
                        @error="$helpers.onProfilePictureError"
                      />
                    </v-avatar>
                    {{ note.employeeName }} <span v-if="note.noteTypeId === 4"> - Sale ID - {{ note.saleId }}</span>
                  </v-list-item-subtitle>
                  <span
                    class="mt-2 "
                    v-html="note.description"
                  >
                  </span>
                </v-list-item-content>
              </v-list-item>
              <!-- </template> -->
              <!-- </v-virtual-scroll> -->
            </v-list>
          </v-tab-item>
        </v-tabs-items>
      </div>
    </div>
  </v-card>
</template>

<script>
import { mapMutations, mapActions, mapGetters } from "vuex"
import LoadingSpinner from "@/components/loading-spinner"
import NotesForm from '@/components/forms/notes-form'
import NoteMeterInfo from '@/components/forms/note-meter-info'
import NoteLoas from '@/components/forms/note-loas'
import NoteInfoRequest from '@/components/forms/note-info-request'



export default {
  components: {
    LoadingSpinner,
    NotesForm,
    NoteMeterInfo,
    NoteInfoRequest,
    NoteLoas,
    EmptyCard: () => import("@/components/empty-card")
  },
  data() {
    return {
      tab: 0,
      tabHeader:null,
      showForm: false,
      showSearch: false,
    }
  },
  computed: {
    ...mapGetters("notes", [
      "notes",
      "allNotes",
      "loading",
      "error",
      "noteTypes",
      "getNotesByType"
    ])
  },
  methods: {
    ...mapActions("notes", ["fetchNotes", "createNotes"]),
    ...mapMutations("notes", {
      setNotes: "setNotes"
    }),
    searchNote(searchTerm) {
      if(!searchTerm) {
        return this.setNotes(this.allNotes)
      }
      searchTerm =  searchTerm.toLowerCase()
      const filteredNotes = this.allNotes.filter(note => (note.employeeName.toLowerCase().includes(searchTerm) || note.description.toLowerCase().includes(searchTerm)))
      this.setNotes(filteredNotes)
    },
    showSearchField() {
      this.showSearch = !this.showSearch
      const searchNoteRef = this.$refs.searchNote.$el.querySelector('input')
      setTimeout(() => {
        searchNoteRef.focus()
      }, 200);
    }
  },
  mounted() {
    setTimeout(() => {
    this.fetchNotes()
    }, 2000)
  }
}
</script>
