import api from "@/store/api"
import { getSupplierState } from "@/store/helpers/default-state"

const state = {
  error: null,
  loading: false,
  suppliers: [],
  selectedSupplier: getSupplierState(),
}

const getters = {
  error: state => state.error,
  loading: state => state.loading,
  suppliers: state => state.suppliers,
  selectedSupplier: state => state.selectedSupplier,
  suppliersList: state => state.suppliers,
  suppliersItems: state => {
    if (!state.suppliers.length) return []
    return state.suppliers.map(
      (option) => ({
        id: `${option.supplierId}`,
        electricity: option.electricity,
        gas: option.gas,
        name: option.supplierName,
        value: `${option.generalEmail}`
      })
    )
  },
}

const mutations = {
  setLoading: (state, status) => (state.loading = status),
  setError: (state, error) => (state.error = error),
  // setSuppliers: (state, { data, type }) => (state[type] = data),
  setSuppliers: (state, suppliers) => (state.suppliers = suppliers),
  setData: (state, { data, type }) => (state[type] = data),
  setSelectedSupplier: (state, supplierId) => {
    const supplier = state.suppliers.find(
      supplier => supplier.supplierId.toString() === supplierId.toString()
    )
    if (supplier) {
      state.selectedSupplier = supplier
    }
  },
}

const actions = {
  async fetchSuppliers({ commit },meterTypeID = -1) {
    try {
      const response = await api.get(`suppliers/${meterTypeID}`)
      commit('setSuppliers', response.data)
    } catch (error) {
      commit("setError", error)
    }
  },
  selectSupplier({ commit }, supplierId) {
    commit("setSelectedSupplier", supplierId)
  },
}



export default { state, getters, mutations, actions, namespaced: true }