import api from "@/store/api"

import { getMeterInfoState } from "@/store/helpers/default-state"

const state = {
  error: null,
  loading: false,
  meters: [],
  primaryMeters: [],
  showCard: false,
  loadingHeaderMeters: false,
  selectedMeter: getMeterInfoState(),
  quoteForSendContract: {},
  quoteTypePriceList: [],
}

const getters = {
  error: (state) => state.error,
  loading: (state) => state.loading,
  meters: (state) => state.meters,
  primaryMeters: (state) => state.primaryMeters,
  showCard: (state) => state.showCard,
  loadingHeaderMeters: (state) => state.loadingHeaderMeters,
  selectedMeter: (state) => state.selectedMeter,
  quoteForSendContract: (state) => state.quoteForSendContract,
  quoteTypePriceList: (state) => state.quoteTypePriceList,
}

const mutations = {
  setMeters: (state, meters) => (state.meters = meters),
  setLoading: (state, status) => (state.loading = status),
  setError: (state, error) => (state.error = error),
  setPrimaryMeters: (state, meters) => (state.primaryMeters = meters),
  setCardVisibility: (state) => (state.showCard = !state.showCard),
  setLoadingPrimaryMeters: (state, status) =>
    (state.loadingHeaderMeters = status),
  setSelectedMeter: (state, meterId) => {
    if (!meterId) {
      state.selectedMeter = getMeterInfoState()
      return
    }
    if (meterId !== state.selectedMeter.meterId.toString()) {
      const meter = state.meters.find(
        (meter) => meter.meterId.toString() === meterId
      )
      state.selectedMeter = meter
    }
  },
  setRemoveMeter: (state, meterId) =>
    (state.meters = state.meters.filter(
      (meter) => meter.meterId.toString() !== meterId
    )),
  setQuoteForSendContract: (state, quoteForSendContract) =>
    (state.quoteForSendContract = quoteForSendContract),
  setQuoteTypePriceList: (state, quoteTypePriceList) =>
    (state.quoteTypePriceList = quoteTypePriceList),
}

const actions = {
  // eslint-disable-next-line
  async fetchMeters({ commit, rootGetters }, addressId) {
    let lazyLoader = null
    // Use addressId to fetch meters by companyId
    // Address id is pulled from address card when you click on meters icon
    const currentAddressId = rootGetters["addresses/selectedAddress"].addressId

    if (currentAddressId === addressId) {
      clearTimeout(lazyLoader)
      return // dont fetch same address again
    }
    commit("setLoading", true)

    try {
      const response = await api.get(`metersByAddressId/${addressId}`)
      commit("setMeters", response.data)
    } catch (error) {
      commit("setError", error)
    }

    // make the load a bit long (miliseconds) to have a smooth animation on show meters card
    lazyLoader = setTimeout(() => {
      commit("setLoading", false)
    }, 1000)
  },
  async fetchMetersAfterRemove({ commit }, addressId) {
    commit("setLoading", true)

    try {
      const response = await api.get(`metersByAddressId/${addressId}`)
      commit("setMeters", response.data)
    } catch (error) {
      commit("setError", error)
    }

    // make the load a bit long (miliseconds) to have a smooth animation on show meters card
    setTimeout(() => {
      commit("setLoading", false)
    }, 1000)
  },

  async fetchPrimaryMetersAfterSave({ commit }, companyId) {
    commit("setLoadingPrimaryMeters", true)
    // use companyId to fetch meters/companyId
    try {
      const response = await api.get(`meters/${companyId}`)
      commit("setPrimaryMeters", response.data)
    } catch (error) {
      console.log(error)
    }

    // make the load a bit long (miliseconds) to have a smooth animation on show meters card
    setTimeout(() => {
      commit("setLoadingPrimaryMeters", false)
    }, 1000)
  },

  fetchPrimaryMeters: {
    root: true,
    // eslint-disable-next-line
    async handler({ commit }, companyId) {
      commit("setLoadingPrimaryMeters", true)
      // use companyId to fetch meters/companyId
      try {
        const response = await api.get(`meters/${companyId}`)
        commit("setPrimaryMeters", response.data)
      } catch (error) {
        console.log(error)
      }

      commit("setLoadingPrimaryMeters", false)
    },
  },
  toggleCardVisibility({ commit, state, rootGetters }, addressId) {
    const currentAddressId =
      rootGetters["addresses/selectedAddress"].addressId.toString()

    if (currentAddressId === addressId || !state.showCard) {
      commit("setCardVisibility")
    }
  },
  async removeMeter({ commit }, details) {
    try {
      const response = await api.post(`deleteMeter`, details)
      if (response && response.status === 200) {
        commit("setRemoveMeter", details)
      }
      return response
    } catch (error) {
      commit("setError", error)
      return error
    }
  },
  async selectMeter({ commit }, meterId) {
    commit("setSelectedMeter", meterId)
  },
}

export default { state, getters, mutations, actions, namespaced: true }
