<template>
  <div>
    <v-row>
      <v-col>
        <div class="font-weight-medium">
          {{ companyName }}
        </div>
      </v-col>
    </v-row>
    <v-row v-if="(creditSafeScore === '' || creditSafeScore === 0 || creditSafeScore === null ) && (experianScore === '' || experianScore === 0 || experianScore === null )">
      <v-col>
        <v-alert class="mb-0" type="info">
          <v-row align="center">
            <v-col class="grow">
              This company doesn't have credit score yet.
            </v-col>
            <v-col class="shrink">
              <v-btn @click="openForm({ formName: 'credit-score' })">
                Get Score
              </v-btn>
            </v-col>
          </v-row>
        </v-alert>
      </v-col>
    </v-row>
    <div v-else>
      <v-row>
        <v-col></v-col>
        <v-col></v-col>
        <v-col class="d-flex" style="justify-content:end;">
          <v-img v-if="!isExperian" max-width="90px" max-height="16px" :src="creditSafeLogo"> </v-img>
          <v-img v-if="isExperian" max-width="90px" max-height="16px" :src="experianLogo"> </v-img>
        </v-col>
      </v-row>
      <v-row>
        <v-col v-if="!isExperian">
          <v-progress-linear
            :value="creditSafeScore"
            :color="CSbarColor"
            height="20"
            rounded
            only-read
          >
            <template v-slot:default="">
              <strong class="white--text">{{ CSbarLabel }}</strong>
            </template>
          </v-progress-linear>
        </v-col>
        <v-col v-if="isExperian">
          <v-progress-linear
            :value="experianScore"
            :color="EXbarColor"
            height="20"
            rounded
            only-read
          >
            <template v-slot:default="">
              <strong class="white--text">{{ EXbarLabel }}</strong>
            </template>
          </v-progress-linear>
        </v-col>
      </v-row>
      <v-row v-if="!isExperian">
        <v-col>
          <DataValue label="Score" :value="creditSafeScore || '0'"></DataValue>
        </v-col>
        <v-col class="text-center">
          <DataValue
            label="Score Date"
            :value="$helpers.formatDate(creditScoreDate)"
          ></DataValue>
        </v-col>
        <v-col class="text-right">
          <DataValue label="Limit" prefix="£" :value="creditSafeLimit || '0'"></DataValue>
        </v-col>
      </v-row>
      <v-row v-if="isExperian">
        <v-col>
          <DataValue label="Score" :value="experianScore || '0'"></DataValue>
        </v-col>
        <v-col class="text-center">
          <DataValue
            label="Score Date"
            :value="$helpers.formatDate(experianScoreDate)"
          ></DataValue>
        </v-col>
        <v-col class="text-right">
          <DataValue label="Limit" prefix="£" :value="experianLimit || '0'"></DataValue>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex"

import DataValue from "@/components/data-value"
import { inRange } from "@/helpers/utilities"

export default {
  components: {
    DataValue
  },
  props: {
    creditSafeScore: {
      type: Number,
      default: 0
    },
    creditSafeLimit: {
      type: Number,
      default: 0
    },
    creditScoreDate: {
      type: Number,
      default: () => 0
    },
    creditSafeCompanyName: {
      type: String,
      default: ""
    },
    experianCompanyName: {
      type: String,
      default: ""
    },
    experianScore: {
      type: Number,
      default: 0
    },
    experianLimit: {
      type: Number,
      default: 0
    },
    experianScoreDate: {
      type: Number,
      default: 0,
    },
    companyId: {
      type: Number,
      default: null,
    },
    companyName: {
      type: String,
      default: '',
    }
  },
  created() {
    this.getLatest()
  },
  data: () => ({
    creditSafeLogo: require("@/assets/credit-safe-logo.svg"),
    experianLogo: require("@/assets/experian-logo.svg"),
    isExperian:false,
  }),
  computed: {
    CSbarLabel() {
      if (this.creditSafeScore === '' || this.creditSafeScore === null || this.creditSafeScore === undefined) return ""
      const isVeryPoor = inRange(this.creditSafeScore, 0, 19) && "Very Poor"
      const isPoor = inRange(this.creditSafeScore, 20, 39) && "Poor"
      const isFair = inRange(this.creditSafeScore, 40, 59) && "Fair"
      const isGood = inRange(this.creditSafeScore, 60, 79) && "Good"

      return isVeryPoor || isPoor || isFair || isGood || "Excellent"
    },
    EXbarLabel() {
      if (this.experianScore === '' || this.experianScore === null || this.experianScore === undefined) return ""
      
      const serious = this.experianScore === 0 ? 'Dissolved/ Serious Adverse Information' : ''
      const aboveSerious = this.experianScore === 1 ? 'Serious Adverse Information' : ''

      const maxRisk = inRange(this.experianScore, 2, 15) && "Maximum Risk"
      const highRisk = inRange(this.experianScore, 16, 25) && "High Risk"
      const aboveRisk = inRange(this.experianScore, 26, 50) && "Above Average Risk"
      const belowRisk = inRange(this.experianScore, 51, 80) && "Below Average Risk"
      const lowRisk = inRange(this.experianScore, 81, 90) && "Low Risk"
      const vLowRisk = inRange(this.experianScore, 91, 100) && "Very Low Risk"

      return aboveSerious || serious || maxRisk || highRisk || aboveRisk || belowRisk || lowRisk || vLowRisk || 'N/A'
    },
    CSbarColor() {
      if (this.CSbarLabel === 'Excellent' || this.CSbarLabel === 'Good') {
        return 'green'
      } else if (this.CSbarLabel === 'Fair') {
        return 'amber'
      } else if (this.CSbarLabel === 'Poor' || this.CSbarLabel === 'Very Poor') {
        return 'red'
      } else {
        return 'blue-grey'
      }
    },
    EXbarColor() {
      if (this.EXbarLabel === 'Very Low Risk') {
        return 'green'
      } else if (this.EXbarLabel === 'Low Risk') {
        return 'light-green'
      } else if (this.EXbarLabel === 'Below Average Risk') {
        return 'green lighten-2'
      } else if (this.EXbarLabel === 'Above Average Risk') {
        return 'orange'
      }else if (this.EXbarLabel === 'High Risk') {
        return 'orange lighten-2'
      }else if (this.EXbarLabel === 'Maximum Risk') {
        return 'red lighten-3'
      }else if (this.EXbarLabel === 'Serious Adverse Information') {
        return 'red lighten-2'
      }else if (this.EXbarLabel === 'Dissolved/ Serious Adverse Information') {
        return 'red'
      }else {
        return 'blue-grey lighten-2'
      }
    },
  },
  methods: {
    ...mapActions("ui", ["openForm"]),
    getLatest() {
      if(this.experianScoreDate > this.creditScoreDate) {
        this.isExperian = true
      }else this.isExperian = false

      // return this.isExperian
    },
  }
}
</script>

<style></style>
