import dayjs from "dayjs"

const formatDate = (timestamp, time = false) => {
  if (!timestamp) {
    return null
  }
  const dateFormat = "DD/MM/YYYY"
  // transform to miliseconds because we are getting it in seconds
  const date = dayjs(timestamp * 1000)
  return time ? date.format(`${dateFormat} HH:mm`) : date.format(dateFormat)
}
const dateTime = (date) => {
      const parsedDate = date ? date * 1000 : null

      if (!parsedDate) {
        return '--'
      }

      return dayjs(parsedDate).format('DD/MM/YYYY HH:mm')
}

const diffDates = (endDate, startDate, unit) => {
  return dayjs(endDate * 1000).diff(startDate * 1000, unit)
}

export default {
  formatDate,
  diffDates,
  dateTime
}
