<template>
  <v-card class="mt-0" color="green lighten-5" flat>
    <v-card-text class="pt-0">
      <v-container class="pt-0">
        <v-row
          v-for="(q, index) in quoteTypesOnly"
          align="center"
          justify="space-between"
          :key="index"
        >
          <v-col :cols="'3'">
            <div
              class=""
              style="
                font-size: 12px;

                color: var(--v-primary-base);
                caret-color: var(--v-primary-base);
              "
            >
              {{ q.typeName }}
            </div>

            <v-text-field
              suffix="p/kWh"
              style="padding: 0; margin: 0"
              hide-details="auto"
              v-model="q.baseRate"
              @input="onBaseRateChange(q)"
              @keypress="isSortCodeNumberKey($event, q)"
              oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1').replace(/(\.\d{3}).+/g, '$1');"
            ></v-text-field>
          </v-col>
          <v-col cols="3" style="margin-top: 19px !important">
            <span
              class=""
              style="
                font-size: 12px;
                height: 20px;
                line-height: 20px;
                letter-spacing: normal;
                color: var(--v-primary-base);
                caret-color: var(--v-primary-base);
              "
              >EAC</span
            >
            <v-text-field
              style="padding: 0; margin: 0"
              @keypress="eacValidation"
              v-model="q.eac"
              @paste="onPaste"
              :disabled="eacDisabled"
            ></v-text-field>
          </v-col>

          <v-col :cols="'3'"> </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  data: () => ({
    localUnitRateUpliftUpto3Decimals: "0",
    selectedTypeIds: [],
    localUnitRateUplift: 0,
  }),
  props: {
    unitRateUplift: {
      type: [Number, String],
      default: 0,
    },
    eacGuidedValue: {
      type: Number,
      default: 0,
    },
    eacDisabled: {
      type: Boolean,
    },

    quoteTypePriceList: {
      type: Array,
    },
  },
  mounted() {
    this.localUnitRateUplift = this.unitRateUplift
    if (this.eacDisabled) {
      this.quoteTypePriceList.map((q, index) => {
        if (index === 1) {
          q.eac = this.eacGuidedValue
        } else {
          q.eac = 0
        }
      })
    }
  },
  watch: {
    eacDisabled: {
      handler(newVal, oldVal) {
        console.log(newVal, oldVal)
        if (newVal) {
          this.quoteTypePriceList.map((q, index) => {
            if (index === 1) {
              q.eac = this.eacGuidedValue
            } else {
              q.eac = 0
            }
          })
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters("company", ["mobileView"]),
    quoteTypesOnly() {
      return this.quoteTypePriceList.filter((x) => x.quoteTypePriceId !== 1)
    },
  },
  methods: {
    unitRateUpliftChanged(value) {
      this.$emit("unit-rate-uplift-changed", value)
      this.localUnitRateUpliftUpto3Decimals = parseFloat(
        this.localUnitRateUplift
      ).toFixed(1)
      this.quoteTypesOnly.forEach((q) => {
        const index = this.quoteTypePriceList.findIndex(
          (x) => x.quoteTypePriceId === q.quoteTypePriceId
        )
        const price =
          parseFloat(
            this.quoteTypePriceList[index].baseRate
              ? this.quoteTypePriceList[index].baseRate
              : 0
          ) + parseFloat(value)

        this.quoteTypePriceList[index].price = price
        q.price = parseFloat(price).toFixed(2)
        q.uplift = parseFloat(this.localUnitRateUplift)
      })
    },
    onBaseRateChange(q) {
      const price =
        parseFloat(q.baseRate ? q.baseRate : 0) +
        parseFloat(this.localUnitRateUplift)
      q.price = parseFloat(price).toFixed(2)
      q.baseRate = q.baseRate
        .replace(/[^0-9.]/g, "")
        .replace(/(\..*?)\..*/g, "$1")
        .replace(/(\.\d{3}).+/g, "$1")
    },
    onPaste(evt) {
      const data = evt.clipboardData.getData("text")
      const splitData = data.split("")
      for (let i = 0; i < splitData.length; i++) {
        if (isNaN(parseInt(splitData[i])) || splitData[i].indexOf(".") !== -1) {
          event.returnValue = false
          return false
        }
      }
    },
    isSortCodeNumberKey(event, q) {
      const charCode = event.which ? event.which : event.keyCode

      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        event.returnValue = false
        return false
      } else {
        if (this.quoteTypesOnly) {
          const regex = /^\d{0,5}(\.\d*)?$/
          if (!regex.test(q.baseRate) && q.baseRate) {
            console.log("called")
            event.returnValue = false
            return false
          }
        }
        event.returnValue = true
        return true
      }
    },

    eacValidation(evt) {
      const charCode = evt.which ? evt.which : evt.keyCode
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        event.returnValue = false
        return false
      } else {
        if (this.quoteTypesOnly) {
          if (
            this.quoteTypesOnly &&
            this.quoteTypesOnly.baseRate &&
            this.quoteTypesOnly.baseRate.length >= 6
          ) {
            event.returnValue = false
            return false
          }
        }
        event.returnValue = true
        return true
      }
    },
  },
}
</script>
