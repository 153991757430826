import api from "@/store/api"
import { chunkArray } from "@/store/helpers/utils"
import { getContactState } from "@/store/helpers/default-state"

// const exists = ''
const state = {
  error: null,
  loading: false,
  contacts: [],
  contactAddresses: [],
  primaryContact: getContactState(),
  selectedContact: getContactState(),
}

const getters = {
  error: (state) => state.error,
  loading: (state) => state.loading,
  contacts: (state) => state.contacts,
  contactItems: (state) => {
    if (!state.contacts.length) return []
    return state.contacts.map(({ contactId, firstName, lastName }) => ({
      id: contactId,
      value: `${firstName} ${lastName}`.trim() || "Unnamed Contact",
    }))
  },
  contactAddressItems: (state) => {
    console.log("run")
    if (!state.contactAddresses.length) return []
    return state.contactAddresses.map(({ addressId, add1, add2 }) => ({
      id: addressId,
      value: `${add1} ${add2}`.trim(),
    }))
  },
  groupedContacts: (state) => chunkArray(state.contacts, 3),
  primaryContact: (state) => state.primaryContact,
  primaryContactFullName: (state) => {
    if (!state.primaryContact) return ""
    return `${state.primaryContact.firstName} ${state.primaryContact.lastName}`
  },
  primaryContactEmailAddress: (state) => {
    if (!state.primaryContact) return ""
    return `${state.primaryContact.emailAddress}`
  },
  contactAddresses: (state) => state.contactAddresses,
  selectedContact: (state) => state.selectedContact,
}

const mutations = {
  setContacts: (state, contacts) => {
    // sort contacts: Primary contact first in the list
    if (contacts.length) {
      state.contacts = contacts.sort(
        (a, b) => b.primaryContact - a.primaryContact
      )
      state.primaryContact =
        contacts.length && contacts.find((contact) => contact.primaryContact)
    } else {
      state.contacts = []
      state.primaryContact = {}
    }
  },
  setLoading: (state, status) => (state.loading = status),
  setError: (state, error) => (state.error = error),
  createContact: (state, contact) => {
    state.contacts.push(contact)
  },
  updateContact: (state, contact) => {
    const index = state.contacts.findIndex(
      ({ contactId }) => contactId === contact.contactId
    )

    state.contacts.splice(index, 1, contact)
  },
  deleteContact: (state, contactId) =>
    (state.contacts = state.contacts.filter(
      (contact) => contact.contactId.toString() !== contactId
    )),
  setContactAddresses: (state, addresses) => {
    state.contactAddresses = addresses
  },
  setSelectedContact: (state, contactId) => {
    if (!contactId) {
      state.selectedContact = getContactState()
      return
    }

    let convertedContactId = contactId
    if (typeof contactId === "string") {
      convertedContactId = parseInt(contactId, 10)
    }

    const contact = state.contacts.find(
      (contact) => contact.contactId === convertedContactId
    )

    state.selectedContact = contact
  },
  // setSelectedContact: (state, contactId) => {
  //   if (!contactId) {
  //     state.selectedContact = getContactState()
  //     return
  //   }
  //   if (state.selectedContact && contactId !== state.selectedContact.contactId.toString()) {
  //     const contact = state.contacts.find(
  //       contact => contact.contactId.toString() === contactId
  //     )
  //     state.selectedContact = contact
  //   }else {
  //     let convertedContactId = contactId
  //     if(typeof contactId === 'string') {
  //       convertedContactId = parseInt(contactId)
  //     }
  //     const contact = state.contacts.find(
  //       contact => contact.contactId === convertedContactId
  //     )
  //     state.selectedContact = contact
  //   }
  // }
}

const actions = {
  async fetchContacts({ commit, rootGetters }) {
    commit("setLoading", true)
    try {
      const response = await api.get(
        `contacts/${rootGetters["company/companyId"]}`
      )
      commit("setContacts", response.data)
      commit("setLoading", false)
      return response
    } catch (error) {
      commit("setError", error)
      commit("setLoading", false)
    }
    commit("setLoading", false)
  },
  async fetchContactsAfterUpdate({ commit }, companyId) {
    commit("setLoading", true)

    try {
      const response = await api.get(`contacts/${companyId}`)
      commit("setContacts", response.data)
    } catch (error) {
      commit("setError", error)
    }

    // make the load a bit long (miliseconds) to have a smooth animation on show meters card
    setTimeout(() => {
      commit("setLoading", false)
    }, 1000)
  },
  async fetchContactAddresses({ commit }, contactId) {
    try {
      const response = await api.get(`addressesByContactId/${contactId}`)
      commit("setContactAddresses", response.data)
    } catch (error) {
      commit("setError", error)
    }
  },
  async deleteContactById({ commit }, details) {
    try {
      const res = await api.post(`deletecontact`, details) // check if response is okay
      commit("deleteContact", details)
      return res
    } catch (error) {
      commit("setError", error)
      return error
    }
  },
  async createContact({ commit }, contact) {
    try {
      // eslint-disable-next-line
      const res = await api.post("updateContact", contact)
      if (res.status === 200) {
        if (isNaN(parseFloat(res.data))) {
          return res
        }
        commit("createContact", contact) // we should pass the response.data, but API is returning only id
        return res
      }
    } catch (error) {
      commit("setError", error)
    }
  },
  async updateContact({ commit }, contact) {
    try {
      // eslint-disable-next-line
      const res = await api.post("updateContact", contact)
      if (res.status === 200) {
        if (isNaN(parseFloat(res.data))) {
          return res
        }
        commit("updateContact", contact)
        return res
      }
    } catch (error) {
      commit("setError", error)
    }
  },
  // async updateContactDOB({ commit }, contact) {
  //   try {
  //    await api.post("/UpdateDob", contact)
  //    commit("updateContact", contact)
  //   } catch (error) {
  //     commit("setError", error)
  //   }
  // },
  async changePrimaryContact({ commit, rootGetters }, contactId) {
    try {
      await api.post(
        `changePrimaryContact/${rootGetters["company/companyId"]}/${contactId}`
      )
      commit("updateContact", contactId)
    } catch (error) {
      commit("setError", error)
    }
  },
  async selectContact({ commit }, contactId) {
    commit("setSelectedAddress", contactId)
  },
}

export default { state, getters, mutations, actions, namespaced: true }
