<template>
  <div class="d-flex">
    <div class="icon-container flex-column justify-center">
      <div class="d-flex justify-center items-center" style="width:30px; height: 30px; border-radius: 100%" :style="bgColor">
        <v-icon size="20" color="white">{{ icon }}</v-icon>
      </div>
      <div v-if="resell" class="d-flex justify-center items-center mt-2" style="width:30px; height: 30px; border-radius: 100%; background-color : rgb(48, 66, 90)">
        <v-icon size="20" color="white"> mdi-alpha-r</v-icon>
      </div>
    </div>
    <div class="ml-2 d-flex flex-column">
      <span class="font-weight-bold">{{ type }}</span>
      <span class="gray-text">CSD: {{ $helpers.formatDate(csd) }}</span>
      <span class="gray-text">Term: {{ term }} Months</span>
      <span class="gray-text">CED: {{ $helpers.formatDate(ced) }}</span>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    masterType: {
      type: Number,
      default: 3
    },
    csd: {
      type: Number,
      default: 0
    },
    ced: {
      type: Number,
      default: 0
    },
    term: {
      type: Number,
      default: 0
    },
    resell: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    bgColor(){
      if(this.masterType === 1){
        return 'background-color: #007BFF'
      } else if(this.masterType === 2){
        return 'background-color: #9575cd'
      }
      return 'background-color: #28A745'
    },
    icon(){
      if(this.masterType === 1){
        return 'mdi-arrow-u-right-top'
      } else if(this.masterType === 2){
        return 'mdi-arrow-right'
      }
      return 'mdi-truck'
    },
    type(){
      if(this.masterType === 1){
        return 'Renewal'
      } else if(this.masterType === 2){
        return 'Acquisition'
      }
      return 'New Mover'
    }
  }
}
</script>
<style scoped>
.icon-container {
  display: flex;
  align-items: center;
}
</style>