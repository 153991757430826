<!-- eslint-disable vue/no-mutating-props -->
<template>
  <v-card color="green lighten-5" style="margin-top: 12px" outlined>
    <v-card-text class="pt-0">
      <v-container class="pt-0">
        <v-row align="center" justify="space-between">
          <v-col :cols="'3'"> </v-col>
          <v-col :cols="'3'">
            <div
              class=""
              style="
                font-size: 12px;

                color: var(--v-primary-base);
                caret-color: var(--v-primary-base);
              "
            >
              Total EAC
            </div>
            <v-text-field
              style="padding: 0; margin: 0"
              type="number"
              hide-details="auto"
              v-model="eacTotal"
              disabled
            ></v-text-field>
          </v-col>
          <v-col :cols="'3'">
            <div
              class=""
              style="
                font-size: 12px;

                color: var(--v-primary-base);
                caret-color: var(--v-primary-base);
              "
            >
              Total Yearly Cost
            </div>

            <v-text-field
              prefix="£"
              style="padding: 0; margin: 0"
              type="number"
              hide-details="auto"
              v-model="yearlyCost"
              disabled
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  props: {
    totalEac: {
      type: Number,
      default: 0,
    },
    totalYearlyCost: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    eacTotal: 0,
    yearlyCost: 0,
  }),
  computed: {
    ...mapGetters("company", ["mobileView"]),
  },
  watch: {
    totalEac(newVal) {
      this.eacTotal = +newVal
    },
    totalYearlyCost(newVal) {
      this.yearlyCost = +newVal
    },
  },
  mounted() {
    this.eacTotal = this.totalEac
    this.yearlyCost = +this.totalYearlyCost
  },
  methods: {
    validationtotal(evt) {
      const charCode = evt.which ? evt.which : evt.keyCode
      if (
        charCode > 31 &&
        (charCode < 32 || charCode > 57) &&
        charCode !== 46
      ) {
        event.returnValue = false
        return false
      }
    },

    updateValue() {
      let est = this.totalEstInput
      this.$emit("update-number", est)
    },
  },
}
</script>
