<template>
  <v-card outlined rounded="lg" :loading="loading">
    <template slot="progress">
      <v-progress-linear
        color="accent"
        height="8"
        indeterminate
      ></v-progress-linear>
    </template>

    <v-card-title class="py-2">
      <v-row align="center" justify="space-between">
        <v-col cols="auto">
          <v-icon class="mr-1" small>mdi-account-box</v-icon>
          <span class="text-button">
            Contact Information
          </span>
        </v-col>
        <v-col cols="6" v-if="contacts.length > 1">
          <v-pagination
            circle
            v-model="pagination"
            :length="paginationLength"
          ></v-pagination>
        </v-col>
        <v-col cols="auto">
          <v-btn
            color="blue"
            depressed
            icon
            small
            @click="openForm({ formName: 'contact-details' })"
          >
            <v-icon small>mdi-plus</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>
    <v-divider></v-divider>
    <LoadingSpinner v-if="loading" />
    <v-sheet v-else class="pa-3">
      <EmptyCard
        v-if="!contacts.length"
        content="No contacts"
        @button-click="openForm({ formName: 'contact-details' })"
      />
      <v-window v-else v-model="currentWindow">
        <v-window-item v-for="(group, index) in paginationLength" :key="group">
          <v-row>
            <v-col
              v-for="contact in groupedContacts[index]"
              cols="4"
              :key="contact.contactId"
            >
              <!-- TODO: Remove .toString method, expect contactId to be a string from API response -->
              <ContactWidget
                :contact-id="contact.contactId.toString()"
                :name="getContactFullName(contact)"
                :job-role="contact.jobTitle"
                :telephone="contact.contactNumber"
                :alt-telephone="contact.altContactNumber"
                :email="contact.emailAddress"
                :primary="contact.primaryContact"
                :date-of-birth="contact.dob"
                :password="contact.securityPassword"
              ></ContactWidget>
            </v-col>
          </v-row>
        </v-window-item>
      </v-window>
    </v-sheet>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import ContactWidget from "@/components/contact-widget"
import LoadingSpinner from "@/components/loading-spinner"

export default {
  components: {
    ContactWidget,
    LoadingSpinner,
    EmptyCard: () => import("@/components/empty-card")
  },
  data: () => ({ pagination: 1 }),
  computed: {
    ...mapGetters("contacts", ["contacts", "groupedContacts", "loading"]),
    paginationLength() {
      const pages = Math.round(this.contacts.length / 3)

      return pages || 1
    },
    currentWindow() {
      return this.pagination - 1
    }
  },
  methods: {
    ...mapActions("contacts", ["fetchContacts"]),
    ...mapActions("ui", ["openForm"]),
    getContactFullName(contact) {
      return `${contact.title} ${contact.firstName} ${contact.lastName}`.trim()
    },
  },
  mounted() {
    setTimeout(() => {
    this.fetchContacts()
    }, 2000)
  }
}
</script>

<style></style>
