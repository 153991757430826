import api from "@/store/api"

const state = {
  error: null,
  loading: false,
  previousQuotes: []
}

const getters = {
  loading: state => state.loading,
  error: state => state.error,
  previousQuotes: state => state.previousQuotes
}

const mutations = {
  setPreviousQuotes: (state, quotes) => (state.previousQuotes = quotes),
  setLoading: (state, status) => (state.loading = status),
  setError: (state, error) => (state.error = error)
}

const actions = {
  async fetchQuotes({ commit, rootGetters }) {
    commit("setLoading", true)
    try {
      const response = await api.get(
        `previousQuotes/${rootGetters["company/companyId"]}`
      )
      commit("setPreviousQuotes", response.data)
    } catch (error) {
      commit("setError", error)
    }
    commit("setLoading", false)
  }
}

export default { state, getters, mutations, actions, namespaced: true }
